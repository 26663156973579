import {
  compareDesc,
  differenceInCalendarYears,
  // differenceInYears,
  getYear,
} from "date-fns";

export function validateCheckInCheckOut(checkIn, checkOut) {
  // return (
  // isBefore(addHours(new Date(checkIn), 24), new Date(checkOut)) || isSameDay(addHours(new Date(checkIn), 24), new Date(checkOut))
  // );
  return compareDesc(new Date(checkIn), new Date(checkOut)) >= 0;
}

export function validateDateOfBirth(date) {
  return (
    compareDesc(new Date(date), new Date()) >= 0
  );
}

export function validateDateOfBirth116(date) {
  return (
    differenceInCalendarYears(new Date(), new Date(date)) >= 0 &&
    differenceInCalendarYears(new Date(), new Date(date)) <= 116
  );
}

export function travelDocumentNumberCondition(nationality, birthDate) {
  const birthDateYear = getYear(birthDate);
  const currentYear = getYear(new Date());
  return nationality === 'CZE' && birthDate && (currentYear - birthDateYear) <= 15;
  // return nationality === 'CZE' && birthDate && differenceInYears(new Date(), birthDate) <= 15;
}
