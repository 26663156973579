import React from 'react';
import { Form, Formik, FastField } from 'formik';
import { Grid, Button, Typography, CircularProgress } from '@mui/material';
import FieldCounter from '../../reservation/components/FieldCounter';
import GroupFields from '../../../components/GroupFields';
import CusTooltip from '../../../components/CusTooltip';
import { generatePropertyFields } from '../../../components/generateFields';
import { connect } from 'react-redux';
import { selecCountriesList } from '../../../selectors/countries-selector';
import { selectClientInfo } from '../../../selectors/client-selectors';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import * as Yup from 'yup';
import { isEmpty, isObject, isArray, toString } from 'lodash';
import { withTranslation } from 'react-i18next';

const validation = (isUpdating, residentialClients, propertyManager) =>
  Yup.object().shape({
    ...(!(isEmpty(residentialClients) || propertyManager === 0) && {
      residentialClientId: Yup.string().required('Required').nullable(),
    }),
    welcomerId: Yup.string().required('Required').nullable(),
    specialCode: Yup.string().required('Required').nullable(),
    localFeeId: Yup.string().required('Required').nullable(),
    accommodationFeeId: Yup.string()
      .test('test', 'Required', function (value) {
        return !this.parent.Switch_LocalFee || !!value;
      })
      .nullable(),
    numberOfBeds: Yup.number()
      .required('Required')
      .test('test', 'Max is 999', function (value) {
        return value < 1000;
      })
      .test('test', 'Min is 1', function (value) {
        return value > 0;
      })
      .nullable(),
    accommodationName: Yup.string().required('Required').nullable(),
    ...(!isUpdating && {
      address: Yup.string().required('Required').nullable(),
    }),
    ubyUsername: Yup.string().required('Required').nullable(),
    ubyPassword: Yup.string().required('Required').nullable(),
    ubyIDUB: Yup.number()
      .test('length', 'Ubyport ID has to be a 13-digit number', function (val) {
        return val && toString(val).length === 13;
      })
      .required('Required')
      .nullable(),
    // ubyClientNameShort: Yup.string()
    //   .test(
    //     'custom',
    //     'Property code is a 5-digit code that consists only of capital letters',
    //     function (val) {
    //       return val && val.match(new RegExp(/^(?=.*[A-Z])[A-Z]{5,}$/));
    //     }
    //   ).required('Required').nullable(),
  });

const propertyListingTooltip = (t) => {
  return (
    <div>
      <Typography variant="body2" align="center" className="title">
        {t('Property vs Listing')}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('Property is the whole place')}, {t('for example a hotel')}.{' '}
        {t('Listings are for example the rooms in the hotel')}.
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('Properties have different addresses')}.{' '}
        {t('The listings inside one property have the same address')}.
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('Property may also have just one listing if you do not devide it')}.
      </Typography>
    </div>
  );
};

const PropertyForm = ({
  initialValues,
  handleSubmit,
  isUpdating,
  loading,
  isSuccess,
  residentialClients,
  countriesList,
  clientInfoState,
  memberListSelect,
  hasReservation,
  t,
}) => {
  const formFields = (Switch_LocalFee) =>
    generatePropertyFields(
      t,
      memberListSelect,
      Switch_LocalFee,
      isUpdating,
      isArray(residentialClients.data)
        ? residentialClients.data
        : isObject(residentialClients.data)
        ? [residentialClients.data]
        : [],
      countriesList,
      clientInfoState.data ? clientInfoState.data.propertyManager : 1,
      hasReservation,
    );

  const _initialValues = {
    ...initialValues,
    Switch_LocalFee: initialValues.payingCapacityFee === 1,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={_initialValues}
      validationSchema={validation(
        isUpdating,
        residentialClients.data,
        clientInfoState.data ? clientInfoState.data.propertyManager : 1,
      )}
      onSubmit={handleSubmit}
      render={({ values, dirty }) => {
        return (
          <div>
            <Typography variant="h6" gutterBottom align="center">
              {isUpdating ? t('Update property') : t('New property')}
            </Typography>
            <Form>
              {formFields(values.Switch_LocalFee || false).map(
                ({ fields, title, id, name }) => (
                  <GroupFields
                    key={id}
                    fields={fields}
                    title={title}
                    values={values}
                    isUport={id === 'detail_ubyport_data'}
                    id={id}
                    lg={
                      id === 'address_gr'
                        ? 12
                        : id === 'detail_local_fee'
                        ? 6
                        : 4
                    }
                    md={
                      id === 'address_gr'
                        ? 12
                        : id === 'detail_local_fee'
                        ? 12
                        : 6
                    }
                  />
                ),
              )}
              <Grid container spacing={24}>
                {!isUpdating && (
                  <Grid item xs={12}>
                    <TitleHasUnderLine
                      marginTop={20}
                      marginBottom={25}
                      title={t('Listings')}
                      size="h6"
                    />
                    <Grid container spacing={24} justify="center">
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          align="center"
                        >
                          {t('How many listings do you have')}?
                          <CusTooltip title={propertyListingTooltip(t)} />
                        </Typography>
                        <div style={{ margin: '15px 0 30px' }}>
                          <FastField
                            name="count"
                            type="number"
                            component={FieldCounter}
                            min={2}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <div style={{ marginTop: 14 }}>
                <Button
                  variant="contained"
                  type="submit"
                  size={'large'}
                  color="primary"
                  disabled={!dirty || !!loading}
                  fullWidth
                  style={{ textTransform: 'none' }}
                >
                  {t(!!isUpdating ? 'Update' : 'Save')}
                  {!!loading && (
                    <CircularProgress size={20} style={{ marginLeft: 15 }} />
                  )}
                </Button>
                {isSuccess && (
                  <Typography color="secondary" align="center">
                    {t('Save successfully')}!
                  </Typography>
                )}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  residentialClients: state.client.residentialClients,
  countriesList: selecCountriesList(state),
  clientInfoState: selectClientInfo(state),
  memberListSelect: state.member.memberListSelect.data,
});

export default withTranslation()(connect(mapStateToProps, {})(PropertyForm));
