/* eslint-disable no-dupe-keys */
import { grey, green, red } from '@mui/material/colors';
export const drawerWidth = 240;

export const paymentsStyles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: 20,
    marginBottom: 30,
    color: grey[500],
  },
  tripContent: {
    padding: '20px 50px 0',
    color: grey[500],
  },
  payment: {
    '& .payment__item': {},
    '& .payment__item__placeholder': {
      color: grey[500],
    },
    '& .payment__item__caption': {
      marginTop: 10,
      '&>span': {
        display: 'flex',
        alignItems: 'center',
        color: grey[500],
        // '& svg': {
        //   marginRight: 15,
        // }
      },
    },
  },
  balance: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& .balance__value': {
      color: '#cea812',
      fontSize: '30px',
    },
  },
  topup: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceList: {
    paddingTop: 15,
    borderBottom: `1px solid ${grey[200]}`,
    '& .invoiceButton': {
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
    },
  },
});

export const globalStyles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: 20,
    marginBottom: 30,
    color: grey[500],
  },
  tripContent: {
    padding: '20px 50px 0',
    color: grey[500],
  },
  RemindersForm: {
    '& .RemindersForm__item': {
      border: `1px solid ${grey[300]}`,
      padding: 15,
      marginTop: 15,
    },
    '& .RemindersForm__item__placeholder': {
      color: grey[500],
    },
    '& .RemindersForm__item__caption': {
      marginTop: 10,
      '&>span': {
        display: 'flex',
        alignItems: 'center',
        color: grey[500],
        // '& svg': {
        //   marginRight: 15,
        // }
      },
    },
  },
  invoiceList: {
    paddingTop: 15,
    borderBottom: `1px solid ${grey[200]}`,
    '& .invoiceButton': {
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
    },
  },
});

export const tableStyles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: 0,
    boxShadow: 'none',
  },
  smUp: {
    color: grey[500],
    '& $root': {
      padding: 15,
      marginBottom: 15,
      borderRadius: 4,
      boxShadow: `0px 1px 5px 0px ${grey[300]}, 0px 2px 2px 0px ${grey[300]}, 0px 3px 1px -2px ${grey[300]}`,
    },
  },
  table: {
    minWidth: 700,
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  filledMissing: {
    margin: '0 15px',
    '&.filled': {
      color: green[500],
    },
    '&.missing': {
      color: red[500],
    },
  },
  IconButton: {
    margin: 0,
    padding: 3,
  },
  title: {
    fontWeight: 'bold',
  },
  displayEnd: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-end',
  },
  mbMedium: {
    marginBottom: 10,
  },
  mbSmall: {
    marginBottom: 7,
  },
  buttonDisableSuccess: {
    '&:disabled': {
      background: green[500],
      color: '#fff',
    },
  },
});

export const layoutStyles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: `rgb(245, 246, 247)`,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  brand: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 19,
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    padding: 0,
    background: `${theme.palette.primary.main}`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.dark.mainColor,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    backgroundColor: `rgb(245, 246, 247)`,
    borderLeft: `1px solid rgba(0, 0, 0, 0.12)`,
    minHeight: '100vh',
    marginTop: 60,
  },
  divider: {
    backgroundColor: 'transparent',
    margin: '15px 0',
  },
});
