import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { withTranslation } from 'react-i18next';
import {
  IconButton,
  TextField,
  Button,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  listRoom: {
    border: '1px solid #ccc',
    padding: '10px 0',
    // textAlign: 'center',
    // with: 250,
    position: 'absolute',
    zIndex: 100,
    background: '#fff',
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
  },
  specialInput: {
    borderBottom: '1px solid #848c90',
    marginBottom: 5,
    width: '100%',
    marginLeft: 10,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formLabel: {
    display: 'flex',
    margin: 0,
    paddingRight: 15,
    '& .bestguest-radio': {
      padding: '8px 5px',
      marginRight: 10,
    },
    '&:hover': {
      background: '#f5f5f5',
    },
  },
  listsContainer: {
    padding: '25px 0 15px',
  },
});

class MappingListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showListRoom: false,
      selectedValue: '',
      selectedRoom: '',
    };

    this.selectValue = this.selectValue.bind(this);
  }

  selectValue() {
    const { accommodationId, handleMapDatas, airbnbId } = this.props;
    const { selectedRoom } = this.state;
    if (selectedRoom) {
      this.setState({
        showListRoom: false,
        selectedValue: selectedRoom,
      });
      handleMapDatas({
        accommodationId: accommodationId,
        airbnbId: airbnbId,
        bookingId: selectedRoom.listingId,
      });
    }
  }

  render() {
    const { t, datas, favIcon, classes } = this.props;
    const { selectedRoom, showListRoom, selectedValue } = this.state;
    return (
      <div>
        <div className={classes.inputContainer}>
          <img src={favIcon} style={{ width: 20 }} />
          {/* <div contenteditable="true" className={classes.specialInput} onClick={() => this.setState({showListRoom: true})}>{this.state.selectedValue}</div> */}
          <TextField
            value={(selectedValue && selectedValue.listingName) || ''}
            onClick={() => this.setState({ showListRoom: true })}
            className={classes.specialInput}
          />
        </div>
        {showListRoom && (
          <div className={classes.listRoom}>
            <IconButton
              className={classes.closeIcon}
              onClick={() => this.setState({ showListRoom: false })}
            >
              <CloseIcon />
            </IconButton>
            <div className={classes.listsContainer}>
              {datas &&
                datas.length > 0 &&
                datas.map((v, k) => (
                  <FormControlLabel
                    className={classes.formLabel}
                    label={v.listingName}
                    key={k}
                    control={
                      <Radio
                        checked={selectedRoom.listingId === v.listingId}
                        onChange={(e) => this.setState({ selectedRoom: v })}
                        value={v.listingId}
                        name="bestguest-radio"
                        className="bestguest-radio"
                      />
                    }
                  />
                ))}
            </div>
            <div align="center">
              <Button
                variant={'outlined'}
                size="small"
                color={'secondary'}
                onClick={this.selectValue}
                disabled={!selectedRoom}
              >
                {t('Select')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(MappingListing));
