import createReducer from '../../redux/createReducer';
import {
  LOG_OUT,
  LOGIN,
  LOGIN_SUCCESS,
  LOG_OUT_SUCCESS,
  UPDATE_PROFILE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
} from './actionTypes';
import {
  EMAIL_VERIFY,
  EMAIL_VERIFY_SUCCESS,
  ONBOADING_NEXT_STEP,
} from '../../actions/onboading';
import { THROW_ERROR } from '../../redux/handleError';
import { produce } from 'immer';

const initialState = {
  detail: {
    isSignUpSuccess: false,
    isResetPassSuccess: false,
    userId: 0,
    loginName: '',
    firstName: '',
    lastName: '',
    email: '',
    userRole: 0,
    userLanguage: '',
    phone: '',
    tokens: {
      authToken: '',
      masterToken: '',
      authExpire: '',
      masterExpire: '',
      deviceToken: '',
    },
    deviceId: 0,
    emailVerified: 0,
    propertyManager: false,
    clientInfoFilled: false,
    clientId: 0,
    isOnboarding: false,
  },
  loading: false,
  error: '',
};

export default createReducer(initialState, {
  [LOGIN]: (state) => ({
    ...state,
    loading: true,
  }),
  [LOGIN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    detail: action.response,
  }),
  [THROW_ERROR]: (state) => ({
    ...state,
    loading: false,
  }),
  [LOG_OUT]: (state) => ({
    ...state,
    loading: true,
  }),
  [LOG_OUT_SUCCESS]: (state) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.detail = initialState.detail;
    }),
  [UPDATE_PROFILE]: (state, action) => ({
    ...state,
    loading: false,
    detail: {
      ...state.detail,
      ...action.params,
    },
  }),
  [SIGN_UP]: (state) => (
    console.log('SIGN_UP', state),
    {
      ...state,
      loading: true,
    }
  ),
  [SIGN_UP_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    detail: {
      ...state.detail,
      isSignUpSuccess: action.response,
    },
  }),
  [CONFIRM_EMAIL]: (state) => ({
    ...state,
    loading: true,
  }),
  [CONFIRM_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
  }),
  [REFRESH_TOKEN_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.detail.tokens.authToken = action.res.authToken;
      draftState.detail.tokens.authExpire = action.res.authExpire;
    }),
  [RESET_PASSWORD_SUCCESS]: (state, action) => ({
    loading: true,
    ...state,
    detail: {
      ...state.detail,
      isResetPassSuccess: action.response,
    },
  }),
  [VERIFY_EMAIL]: (state) => ({
    ...state,
    loading: true,
  }),
  [VERIFY_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    detail: {
      ...state.detail,
      isVerifyEmailSuccess: action.response,
    },
  }),
  [ONBOADING_NEXT_STEP]: (state, action) => ({
    ...state,
    detail: {
      ...state.detail,
      ...action.res,
    },
  }),
  [EMAIL_VERIFY]: (state) => ({
    ...state,
    loading: true,
  }),
  [EMAIL_VERIFY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    detail: {
      ...initialState.detail,
      ...state.detail,
      ...action.res,
      // isVerifyEmailSuccess: action.response,
    },
  }),
});
