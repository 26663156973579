/* eslint-disable react/jsx-no-target-blank */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Airbnb from '../../../styles/onboarding/connect_account/Airbnb_logo.png';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { generateAirbnbLogin } from './generateFields';
import { red, green } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import {
  addClientIntegration,
  updateClientIntegration,
} from '../../../actions/listing';

const styles = (theme) => ({
  DialogTitle: {
    textAlign: 'center',
    padding: '50px 0 30px',
    '& img': {
      height: 50,
    },
  },
  margin: {
    margin: '20px 0',
  },
  loginButton: {
    color: theme.palette.getContrastText(red['A200']),
    backgroundColor: red['A200'],
    '&:hover': {
      backgroundColor: red['A700'],
    },
  },
  DialogContent: {
    padding: '0 50px 50px',
  },
});

const validation = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const createPasswordUrl = `https://www.airbnb.com/help/article/76/how-do-i-reset-my-password`;

const AirbnbDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  classes,
  objectKey,
  initialData,
  addClientIntegration,
  updateClientIntegration,
  account,
  clientInfo,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [airLock, setAirLock] = useState();

  let initialValues = {
    username: initialData ? initialData.username : '',
    password: initialData ? initialData.password : '',
    type: objectKey,
  };

  const handleAirbnbSubmit = (values) => {
    // const checkHasAirbnb = account.invalidCredentials && account.invalidCredentials.length > 0 && account.invalidCredentials.filter(v => v.type === objectKey);
    setLoading(true);
    setAirLock(null);
    const checkHasAirbnb =
      clientInfo.integration && clientInfo.integration.airbnb;
    if (checkHasAirbnb.connected === 1) {
      let newData = {
        username: values.username,
        password: values.password,
        id: checkHasAirbnb.id,
      };
      updateClientIntegration(newData).then((res) => {
        // console.log(res)
        setLoading(false);
        if (res.data && res.result) {
          handleSubmit(values);
        }
      });
    } else {
      values.isOnboardingProcess = true;
      addClientIntegration(values).then((res) => {
        // console.log(res)
        setLoading(false);
        if (res.data && res.result) {
          handleSubmit(values);
        }
        if (res.errors.length > 0) {
          res.errors.map((v) => {
            if (v.msg === 'AIR_LOCK_ERROR') {
              setAirLock(v.note);
            }
            // return true;
          });
        }
      });
    }
  };

  const formFields = generateAirbnbLogin();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div style={{ textAlign: 'right' }}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
        <img src={Airbnb} alt="" />
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={handleAirbnbSubmit}
          enableReinitialize
          render={({ dirty, values, setFieldValue }) => {
            return (
              <Form>
                {formFields.map((v, k) => (
                  <FastField {...v} key={k} />
                ))}
                <div className={classes.margin}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className={classes.loginButton}
                    disabled={!dirty || loading}
                  >
                    {t('Login')}
                    {loading && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        {airLock && (
          <Paper style={{ marginBottom: 15, backgroundColor: green[500] }}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{ padding: '15px 0', color: '#fff' }}
            >
              {t(`Go to `)}{' '}
              <a href={airLock} target="_blank">
                {' '}
                {t(`Link`)}
              </a>{' '}
              {t(`and checked`)} "I'm not a robot" {t(`then login again`)}
            </Typography>
          </Paper>
        )}
        <Typography variant="subtitle1">
          {t(
            `Do you use Google or Facebook to log in to this Airbnb account? To connect an Airbnb property with Best Guest, we'll need you to first`,
          )}{' '}
          <a href={createPasswordUrl} target="_blank">
            {' '}
            {t(`create a password`)}
          </a>{' '}
          {t(`for your Airbnb account`)}.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
  clientInfo: state.client.clientInfo.data,
});

const mapActionToProps = {
  addClientIntegration,
  updateClientIntegration,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(styles)(AirbnbDialog));
