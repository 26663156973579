import React from 'react';
import ReservationHome from '../reservation/containers/ReservationHome';
import { Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';

class DashboardHome extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Typography color="primary" variant="h3" align="center">
          {t('BestGuest')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('Thank you for being with us!')}
        </Typography>
        <ReservationHome />
      </div>
    );
  }
}

export default withTranslation()(DashboardHome);
