import DashboardHome from '../features/dashboard/dashboard-home';
import Properties from '../features/accomodation/containers/Properties';
import CreateProperty from '../features/accomodation/containers/CreateProperty';
import PropertyHome from '../features/accomodation/containers/PropertyHome';
import PropertyDetail from '../features/accomodation/containers/PropertyDetail';
import GlobalGuestBook from '../features/guest-book/containers/GlobalGuestBook';
import ReservationDetail from '../features/reservation/containers/ReservationDetail';
import Reservation from '../features/reservation/containers/ReservationMain';
import ReservationHome from '../features/reservation/containers/ReservationHome';
import Listings from '../features/accomodation/containers/Listings';
import ListingDetail from '../features/accomodation/containers/ListingDetail';
import GuestBook from '../features/guest-book/containers/GuestBook';
import UpdateProperty from '../features/accomodation/containers/UpdateProperty';
import LocalFees from '../features/accomodation/containers/LocalFees';
import LocalFeesGlolbal from '../components/LocalFeesGlolbal';
import Invoices from '../features/invoices/containers/Invoices';
import Members from '../features/members/containers/Members';
import Automation from '../features/automation/containers/Automation';
import Referral from '../features/referral/containers/refferal';
import Account from '../features/settings/containers/AdminProfile';
import Security from '../features/settings/containers/Security';
// const Notification from '../features/settings/containers/Notification';
import Reminders from '../features/settings/containers/Reminders';
import UbyportHelp from '../features/settings/containers/UbyportHelp';

export const dashboardRouterList = [
  {
    path: '',
    component: DashboardHome,
    title: 'Dashboard',
  },
  {
    path: 'dashboard',
    component: DashboardHome,
    title: 'Dashboard',
  },
  {
    path: 'automation',
    component: Automation,
    title: 'Automation',
  },
  {
    path: 'properties',
    component: Properties,
    child: [
      {
        path: 'add',
        component: CreateProperty,
        title: 'CreateProperty',
        label: null,
      },
      {
        path: ':id',
        component: PropertyDetail,
        title: 'Property Detail',
        label: null,
      },
      {
        path: ':id/update',
        component: UpdateProperty,
        title: 'Update Property',
        label: null,
      },
      {
        path: ':accommodationId/listings',
        component: Listings,
        title: 'Listings',
        label: null,
      },
      {
        path: ':accommodationId/listings/:listingId',
        component: ListingDetail,
        title: 'Listing Detail',
        label: null,
      },
      {
        path: ':accommodationId/guestbook',
        component: GuestBook,
        title: 'GuestBook',
        label: null,
      },
      {
        path: ':accommodationId/local-fees',
        component: LocalFees,
        title: 'LocalFees',
        label: null,
      },
    ],
  },
  {
    path: 'guestbook',
    component: GlobalGuestBook,
    title: 'Guest Book',
  },
  {
    path: 'local-fees',
    component: LocalFeesGlolbal,
    title: 'LocalFees',
  },
  {
    path: 'referrals',
    component: Referral,
    title: 'Referral',
  },
  {
    path: 'members',
    component: Members,
    title: 'Members',
  },
  {
    path: 'payments',
    component: Invoices,
    title: 'Payments',
  },
  {
    path: 'uby-info',
    component: UbyportHelp,
    title: 'How to find Ubyport information',
  },
  {
    path: 'reservations',
    component: Reservation,
    child: [
      {
        path: '',
        component: ReservationHome,
        title: 'Reservation',
        label: null,
      },
      {
        path: ':id',
        component: ReservationDetail,
        title: 'Reservation Detail',
        label: null,
      },
    ],
  },
  {
    path: 'account',
    component: Account,
    title: 'Account',
    public: true,
  },
  {
    path: 'security',
    component: Security,
    title: 'Security',
  },

  {
    path: 'reminders',
    component: Reminders,
    title: 'Reminders',
  },
];

export default dashboardRouterList;
