// export const ST_WAITING = 'WAITING';
// export const ST_PROGRESS = 'IN_PROGRESS';
// export const ST_SEND = 'SEND';
// export const ST_FAILED = 'FAILED';
// export const ST_SEND_SUCCESS = 'SEND_SUCCESS';

export const ST_WAITING = 'WAITING';
export const ST_PROGRESS = 'IN_PROGRESS';
export const ST_SEND = 'SEND';
export const ST_FAILED = 'FAILED';

export const ST_WAITING_TO_SEND = 'WAITING_TO_SEND';
export const ST_FORM_FILLED = 'FORM_FILLED';

export const ST_FILLED = 'Filled';
export const ST_MISSING = 'Missing';

export const ST_NOT_COMPLETED = 'NOT_COMPLETED';
export const ST_READY_TO_SEND = 'READY_TO_SEND';
export const ST_SEND_ERROR = 'SEND_ERROR';
export const ST_SEND_SUCCESS = 'SENT';
export const ST_SENT_ERROR = 'SENT_ERROR';
// export const ST_SEND_SUCCESS = 'SEND_SUCCESS';
