/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import PhoneInputLib from 'react-phone-number-input';
import {
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
} from 'libphonenumber-js/core';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import metadata from 'libphonenumber-js/metadata.full.json';

const PureComponent = React.PureComponent || React.Component;

class InputBasic extends PureComponent {
  static defaultProps = {
    metadata: metadata,
  };

  static getDerivedStateFromProps({ value }) {
    return { value };
  }

  state = {};

  onChange = (event) => {
    const { onChange } = this.props;
    const { value } = this.state;
    let newValue = parseIncompletePhoneNumber(event.target.value);

    if (newValue === value) {
      if (this.format(newValue).indexOf(event.target.value) === 0) {
        newValue = newValue.slice(0, -1);
      }
    }

    newValue = newValue || '+';

    if (newValue.indexOf('+')) {
      newValue = `+${newValue}`;
    }

    this.setState({ value: newValue }, () => onChange(newValue));
  };

  onBlur = (event) => {
    const { onBlur } = this.props;
    const { value } = this.state;

    if (onBlur) {
      const _event = {
        ...event,
        target: {
          ...event.target,
          value,
        },
      };

      _event.stopPropagation = event.stopPropagation;
      _event.preventDefault = event.preventDefault;

      return onBlur(_event);
    }
  };

  format(value) {
    const { country, metadata } = this.props;

    return formatIncompletePhoneNumber(value, country, metadata);
  }

  focus = () => this.input.focus();

  storeInput = (ref) => (this.input = ref);

  render() {
    const { onChange, onFocus, country, metadata, ...rest } = this.props;

    const { value } = this.state;

    return (
      <TextField
        type="tel"
        autoComplete="tel"
        {...rest}
        inputRef={this.storeInput}
        value={this.format(value)}
        onChange={this.onChange}
        onFocus={onFocus}
        onBlur={this.onBlur}
      />
    );
  }
}

const PhoneInput = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();
  const [touchInput, setTouchInput] = useState(false);

  const errorMsg =
    !field.value || (!isEmpty(errors) && errors[field.name])
      ? errors[field.name]
      : undefined;

  const handleOnChange = (phone) => {
    setFieldValue(field.name, phone);
  };

  return (
    <FormControl fullWidth margin={'dense'} style={!field.value ? {} : {}}>
      {/* <FormLabel style={{fontSize: 12}}>Phone number</FormLabel > */}
      <PhoneInputLib
        displayInitialValueAsLocalNumber={false}
        //inputComponent={InputBasic}
        placeholder={t(props.label) || t('Enter phone number')}
        label={t(props.label) || t('Enter phone number')}
        value={field.value || '+'}
        name={field.name}
        onChange={handleOnChange}
        onBlur={(e) => {
          setFieldValue(field.name, field.value);
          setTouchInput(true);
        }}
        // error={errorMsg}
      />
      {
        (touched[field.name] || touchInput) &&
          field.name &&
          errors[field.name] && (
            <FormHelperText error>{t(errorMsg)}</FormHelperText>
          )
        // (touched[field.name] || props.isDynamic) && errors[field.name] && <FormHelperText error>{errorMsg}</FormHelperText>
      }
    </FormControl>
  );
};

export default PhoneInput;
