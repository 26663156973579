
import { createTheme } from '@mui/material/styles';
import { green, blue, grey } from '@mui/material/colors';

export const primaryColor = "#00162a";
export const lightPrimaryColor = "#002c54";
export const secondaryColor = "#f16d36";
export const lightSecondaryColor = "#f6a039";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
});



const themeConfig = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: secondaryColor,
      light: lightSecondaryColor,
      mainGradient: `linear-gradient(to right, ${secondaryColor} 0%,${lightSecondaryColor} 70%)`,
      contrastText: '#fff',
    },
    default: {
      main: blue[500],
      light: blue[300],
      contrastText: '#fff',
    },
    // primary: blue,
    secondary: {
      light: '#0066ff',
      main: green[500],
      contrastText: '#fff',
    },
    dark: {
      main: blue[500],
      mainColor: primaryColor,
      mainGradient: `linear-gradient(to right, ${primaryColor} 0%,${lightPrimaryColor} 70%)`,
      light: lightPrimaryColor,
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  zIndex: {
    tooltip: 99
  },
  overrides: {
    MuiTypography: {
      caption: {
        color: grey[500],
      }
    },
    MuiDialog: {
      paper: {
        padding: '15px 30px',
      },
    },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: green[500]
        },
        "&$active": {
          color: green[500]
        },
        "&$disabled": {
          color: "red"
        }
      },
    },
    MuiButton: {
      text: {
        // backgroundColor: grey[300],
      },
      textPrimary: {
        // backgroundColor: red[50],
      },
      // textSecondary: {

      // },
      // outlined: {
      //   borderColor: "purple",
      //   color: "red"
      // },
      // outlinedPrimary: {
      //   borderColor: "brown"
      // }
    },
    MuiSwitch: {
      switchBase: {
        '&$checked': {
          color: theme.palette.common.white,
          '& + $bar': {
            backgroundColor: grey[50],
          },
        },
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.sharp,
        }),
      },
      bar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: 'transparent',
        backgroundColor: theme.palette.grey[500],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      icon: {
        width: 24,
        height: 24,
      },
      iconChecked: {
        backgroundColor: grey[50],
        boxShadow: theme.shadows[1],
      },
      checked: {
        transform: 'translateX(15px)',
        '& + $bar': {
          opacity: 1,
          border: 'none',
        },
      },
    },
    MuiTooltip:{
      popper: {
        opacity: 1,
      }
    },
    MuiSelect: {
      selectMenu: {
        display: 'flex',
        alignItems: 'center',
      }
    }
  },
});

export default themeConfig;
