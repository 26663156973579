import React from 'react';
import {Tooltip} from '@mui/material';

const ButtonWithTooltip = ({ show, title, style = {}, iconStyle = {}, ...props }) => {
  // const { t } = useTranslation();

  return show ? (
    <Tooltip title={title} id="tooltip_custom" style={style}>
      <span>
        {props.children}
      </span>
    </Tooltip>
  ) : props.children;
};

export default ButtonWithTooltip;
