// import { toast } from 'react-toastify';
// import { result } from 'lodash';
// import getErrorName from '../shared/const/errorCode';

const crashReporter = store => next => action => {
  // if (action.type === 'THROW_ERROR') {
  //   const authCode = result(action.errors, 'errors[0].code', 0);
  //   const errorMsg = result(action.errors, 'errors[0].msg', 'Server error');
  //   if (authCode !== 401) {
  //     toast.error(`Oop! Error: ${getErrorName(errorMsg)}`);
  //   }
  // }
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    if (process.env.NODE_ENV !== 'development') {
    }
    throw err;
  }
};

export default crashReporter;
