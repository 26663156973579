import React from 'react';
import { Switch, FormHelperText } from '@mui/material';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

const styles = {
  switchesRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }
}

const SwitchesCustom = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div style={{ ...styles.switchesRoot, ...props.labelPosition !== 'right' && { justifyContent: 'space-between' } }}>
        {
          props.labelPosition !== 'right' &&
          <label htmlFor={`Form_Switch_${field.name}`}>{t(props.label)}</label>
        }
        <Switch
          onChange={value => setFieldValue(field.name, value.target.checked)}
          {...omit(props, ['labelPosition', 'loading'])}
          id={`Form_Switch_${field.name}`}
          checked={field.value}
          color={'secondary'}
        />
        {
          props.labelPosition === 'right' &&
          <label htmlFor={`Form_Switch_${field.name}`}>{t(props.label)}</label>
        }
      </div>
      <div>
        {
          touched[field.name] &&
          errors[field.name] && (
            <FormHelperText error>{errors[field.name]}</FormHelperText>
          )
        }
      </div>
    </div>
  );
}
export default SwitchesCustom;
