import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { withTranslation } from 'react-i18next';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import _ from 'lodash';
import { compose, withProps, lifecycle } from 'recompose';
import {Typography, Button, Radio, Grid, CircularProgress} from '@mui/material';
import axios from 'axios';
import qs from 'qs';
import { googleApiKey, ruianParamsKey } from '../custom-hooks/helperHooks';

const styles = {
  searchInput: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    minWidth: `240px`,
    width: '40%',
    height: `32px`,
    marginTop: `10px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    // left: '30%'
  },
  searchStyleMobile: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    minWidth: `240px`,
    width: '40%',
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    marginLeft: `-203px`,
    marginTop: `60px`,
    position: 'absolute'
  }
}

const getParamFromHtmlString = (html, key) => {
  let convertToHtml = new DOMParser().parseFromString(html, 'text/html');
  if (convertToHtml.getElementsByClassName(key).length > 0) {
    let str = convertToHtml.getElementsByClassName(key)[0].innerHTML;
    str = str.replace('-', ' - ');
    return str;
  }
  return null;
}

const getFormattedAddress = (location, callBack) => {
  var geocoder = new window.google.maps.Geocoder;
  return geocoder.geocode({ 'location': location }, callBack);
}

const ruianUrl = `https://ruian.fnx.io/api/v1/ruian/validate?`;

let ruianParams = {
  apiKey: ruianParamsKey,
  municipalityName: null,
  street: null,
  cp: null,
  co: null,
};

const PlacesSearchBox = compose(
  withProps(props => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places&language=${props.useMerk ? 'cs' : 'en'}&region=${props.useMerk ? 'CZ' : 'EN'}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div />,
    mapElement: <div style={{ height: `400px` }} />,
  })),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        center: {
          lat: 49.1622647,
          lng: 15.238506,
        },
        ruianData: null,
        ruianLoading: false,
        errorMsg: null,
        markers: [],
        isMobile: this.props.isMobile,
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry) {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
              } else {
                bounds.extend(place.geometry.location)
              }
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry && place.geometry.location,
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });

          // console.log('places', places)

          // start RUIAN
          this.state.checkRuian(places);
          // end RUIAN

          // refs.map.fitBounds(bounds);
        },

        checkRuian: (places) => {
          let _place = places.length > 0 && places[0];

          // search is suggest
          if (_place && _place.address_components) {
            let address_components = {};
            _place.address_components.forEach(item => item.types.forEach(item2 => address_components[item2] = item.long_name));
            this.state.getResultRuian(address_components, _place);
          } else {
            // search is street_address
            let _get_match_street_address = places.filter(v => v.types.includes('street_address'));
            if (_get_match_street_address.length > 0) {
              _get_match_street_address[0].geometry && getFormattedAddress(_get_match_street_address[0].geometry.location, (results, status) => {
                if (status == 'OK' && results.length > 0) {
                  let _new_place = results.length > 0 && results[0];
                  if (_new_place && _new_place.address_components) {
                    let address_components = {};
                    _new_place.address_components.forEach(item => item.types.forEach(item2 => address_components[item2] = item.long_name));
                    // console.log('_new_place', _new_place)
                    // console.log('address_components', address_components)
                    this.state.getResultRuian(address_components, _new_place);
                  }
                }
              });
            }
          }


        },

        getResultRuian: (address_components, _place) => {
          // console.log('address_components', address_components)
          // check RUIAN
          ruianParams = {
            ...ruianParams,
            ...address_components.premise && { cp: address_components.premise },
            ...address_components.street_number && { co: address_components.street_number },
            street: address_components.route || address_components.neighborhood || address_components.locality || null,
            municipalityName: address_components.locality || null,
          }
          if (!address_components.locality && _place.adr_address) {
            let getMunicipalityName = getParamFromHtmlString(_place.adr_address, 'locality');
            getMunicipalityName && (ruianParams.municipalityName = getMunicipalityName);
          }

          address_components.country && this.setState({ ruianData: { ...this.state.ruianData, country: address_components.country } });
          address_components.postal_code && this.setState({ ruianData: { ...this.state.ruianData, postal_code: address_components.postal_code } });
          this.setState({ ruianLoading: true });
          if (this.props.useMerk) {
            if (ruianParams.street && ruianParams.municipalityName) {
              axios(`${ruianUrl}${qs.stringify(ruianParams)}`).then(res => {
                const resData = res.data;
                if (['POSSIBLE', 'MATCH'].includes(resData.status)) {
                  this.setState({
                    ruianData: {
                      ...this.state.ruianData,
                      ...resData.place,
                      disctrict: address_components.administrative_area_level_2 || null,
                      cityDistrict: address_components.neighborhood || null,
                    },
                    ruianLoading: false,
                    errorMsg: null
                  });
                  let { ruianData } = this.state;
                  let _htmlView = `${ruianData.streetName || ''} ${ruianData.cp || ''}${ruianData.co ? '/' : ''}${ruianData.co || ''}, ${ruianData.municipalityName || ''}, ${ruianData.regionName || ''} ${ruianData.zip || ''}`;
                  this.setState({ htmlView: _htmlView });
                } else {
                  this.setState({ ruianData: null, ruianLoading: false, errorMsg: 'The address of your property has to be valid and full address' });
                }

                ruianParams = {
                  apiKey: ruianParamsKey,
                  municipalityName: null,
                  street: null,
                  cp: null,
                  co: null,
                };
              });
            } else {
              this.setState({ ruianData: null, ruianLoading: false, errorMsg: null });
            }
          } else {
            this.setState({ruianLoading: false, ruianData: null});
            if(Object.keys(address_components).length >= 8) {
              this.setState({ ruianData: { ...address_components }, ruianLoading: false });
              let { ruianData } = this.state;
              // let _htmlView = `${ruianData.route || ruianData.neighborhood || ''} ${ruianData.premise || ''}${ruianData.street_number ? '/' : ''}${ruianData.street_number || ''}, ${ruianData.locality || ruianData.administrative_area_level_2 || ''}, ${ruianData.administrative_area_level_1 || ''} ${ruianData.country || ''} ${ruianData.postal_code || ''}`;

              let _htmlView = `${ruianData.route || ruianData.neighborhood || ''} ${ruianData.street_number || '0'}, ${ruianData.sublocality || ruianData.sublocality_level_1 || ruianData.locality || ''}, ${ruianData.locality || ruianData.administrative_area_level_2 || ''}, ${ruianData.country || ''} ${ruianData.postal_code || ''}`;

              this.setState({ htmlView: _htmlView });
            }

            // this.setState({ ruianData: { ...address_components }, ruianLoading: false, errorMsg: null });
            // let { ruianData } = this.state;
            // let _htmlView = `${ruianData.route || ruianData.neighborhood || ''} ${ruianData.premise || ''}${ruianData.street_number ? '/' : ''}${ruianData.street_number || ''}, ${ruianData.locality || ruianData.administrative_area_level_2 || ''}, ${ruianData.administrative_area_level_1 || ''} ${ruianData.country || ''} ${ruianData.postal_code || ''}`;
            // this.setState({ htmlView: _htmlView });
          }

        },

        renderSelectResult: () => {
          const { ruianData, ruianLoading, htmlView, errorMsg } = this.state;
          return (
            <div>
              <Typography variant="h6" gutterBottom style={{ margin: '15px 0' }} >{this.props.useMerk ? this.props.t('Valid RUIAN Addresses') : this.props.t('Google place address')}:</Typography>
              {
                ruianLoading ? <CircularProgress /> :
                  ruianData ? (
                    <Grid container spacing={0} justify="space-between" alignItems="center">
                      <Grid item xs={11}>
                        <label htmlFor="radio-button-demo" style={{ width: '100%' }}>{htmlView}</label>
                      </Grid>
                      <Grid item xs={1}>
                        <Radio
                          id="radio-button-demo"
                          checked={true}
                          // onChange={this.handleChange}
                          value="a"
                          name="radio-button-demo"
                          aria-label="A"
                        />
                      </Grid>
                    </Grid>
                  ) : <div>{this.props.t('No result')}</div>
              }
              <div>{this.props.t(errorMsg)}</div>
            </div>
          )
        },

      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <div>
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={15}
      center={props.center}
      onBoundsChanged={props.onBoundsChanged}
    >
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          placeholder={props.t("Search Address")}
          style={props.isMobile ? styles.searchStyleMobile : styles.searchInput}
        />
      </SearchBox>
      {props.markers.map((marker, index) =>
        <Marker key={index} position={marker.position} />
      )}
    </GoogleMap>
    <div> {props.renderSelectResult()} </div>
    {/*
    {props.ruianData && <Typography variant="h6" gutterBottom style={{ margin: '15px 0' }} >Valid RUIAN Addresses:</Typography>}
    {props.ruianLoading && <CircularProgress />}
    {props.ruianData && props.renderRuianAddress()}
    */}
    <div style={{ textAlign: 'center', margin: `15px 0` }}>
      <Button variant={'contained'} color={'primary'}
        disabled={!props.ruianData}
        onClick={() => props.onSaveClose(props.ruianData, props.htmlView)}
      >{props.t('Save')}</Button>
      <Button style={{ marginLeft: 15 }} variant={'contained'} color={'default'} onClick={props.onClose} >{props.t('Cancel')}</Button>
    </div>
  </div>
);

export default withTranslation()(PlacesSearchBox);
