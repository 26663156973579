import {
  ProfileInfoUrl,
  URL_CLIENT_GET_INFO,
  URL_CLIENT_GET_RESIDENTIAL_CLIENTS,
  URL_GET_CLIENT_SIGNATURE,
  AccountUrls,
  URL_CHANGE_PASSWORD,
} from '../shared/urls';
import { makeRequest } from '../redux/apiCall';

export const LOGIN = 'login';
export const SYSTEM_LOGIN = 'system_login';
export const LOGOUT = 'logout';
export const RESET = 'reset';

export const GET_CLIENT = 'get_client';
export const GET_CLIENT_SUCCESS = 'get_client_success';
export const GET_RESIDENTIAL_CLIENT = 'get_residential_client';
export const GET_RESIDENTIAL_CLIENT_SUCCESS = 'get_residential_client_success';

export const GET_CLIENT_SIGNATURE_URL = 'get_client_signature';
export const GET_CLIENT_SIGNATURE_URL_SUCCESS = 'get_client_signature_success';
export const REMOVE_CLIENT_SIGNATURE = 'remove_client_signature';

export const SWITCH_COMPANY_TYPE = 'switch_company_type';

export const CHANGE_PASS_WORD = 'change_pass_word';
export const CHANGE_PASS_WORD_SUCCESS = 'change_pass_word_success';
export const CHANGE_PASS_WORD_FAILURE = 'change_pass_word_failure';

export const UPDATE_CLIENT_INFO_ACTION = 'update_client_info_action';
export const UPDATE_CLIENT_INFO_ACTION_SUCCESS = 'update_client_info_action_success';
export const UPDATE_CLIENT_INFO_ACTION_FAILURE = 'update_client_info_action_failure';

export const UPDATE_RESIDENTIAL_CLIENT = 'update_residential_client';
export const UPDATE_RESIDENTIAL_CLIENT_SUCCESS = 'update_residential_client_success';
export const UPDATE_RESIDENTIAL_CLIENT_FAILURE = 'update_residential_client_failure';

export const CREATE_RESIDENTIAL_CLIENT = 'create_residential_client';
export const CREATE_RESIDENTIAL_CLIENT_SUCCESS = 'create_residential_client_success';
export const CREATE_RESIDENTIAL_CLIENT_FAILURE = 'create_residential_client_failure';

export const CHANGE_PASSWORD = 'change_password';

export const login = params => ({ type: LOGIN, params });
export const logout = () => ({ type: LOGOUT });
export const reset = () => ({ type: RESET });

export const changePasswordUser = params =>
  makeRequest(
    CHANGE_PASSWORD,
    'post',
    URL_CHANGE_PASSWORD,
    params,
    changePasswordUser,
  );

export const systemLogin = params =>
  makeRequest(
    SYSTEM_LOGIN,
    'post',
    AccountUrls.LOG_IN,
    params,
    systemLogin,
  );

export const getClientInfo = () =>
  makeRequest(GET_CLIENT, 'get', URL_CLIENT_GET_INFO, null, getClientInfo);

export const getResidentialClient = () =>
  makeRequest(
    GET_RESIDENTIAL_CLIENT,
    'get',
    URL_CLIENT_GET_RESIDENTIAL_CLIENTS,
    null,
    getResidentialClient,
  );

export const switchCompanyType = propertyManager => ({
  type: SWITCH_COMPANY_TYPE,
  propertyManager,
});

export const changePassword = params => ({
  type: CHANGE_PASS_WORD,
  params,
});

export const updateClientInfo = params =>
  makeRequest(
    UPDATE_CLIENT_INFO_ACTION,
    'post',
    ProfileInfoUrl.UPDATE_CLIENT_INFO_URL,
    params,
    updateClientInfo,
  );

export const getSignatureUrl = params =>
  makeRequest(
    GET_CLIENT_SIGNATURE_URL,
    'get',
    URL_GET_CLIENT_SIGNATURE,
    params,
    getSignatureUrl,
    false,
    true
  );

export const removeClientSignature = () =>
  async (dispatch, getState) => {
    dispatch({ type: REMOVE_CLIENT_SIGNATURE });
  };

export const updateResidentialClient = params => {
  return makeRequest(
    UPDATE_RESIDENTIAL_CLIENT,
    'post',
    ProfileInfoUrl.UPDATE_RESIDENTIAL_CLIENT_URL,
    params,
    updateResidentialClient,
  );
}

export const createResidentialClient = params => {
  return makeRequest(
    CREATE_RESIDENTIAL_CLIENT,
    'post',
    ProfileInfoUrl.CREATE_RESIDENTIAL_CLIENT_URL,
    params,
    createResidentialClient,
  );
}