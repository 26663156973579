import createReducer from '../redux/createReducer';
import { OPEN_MODAL, CLOSE_MODAL } from './actions';

const initialState = {
  open: false,
  modalName: '',
};

export default createReducer(initialState, {
  [OPEN_MODAL]: (state, action) => ({
    ...state,
    open: true,
    modalName: action.modalName,
  }),
  [CLOSE_MODAL]: (state, action) => ({
    ...state,
    open: false,
    modalName: action.modalName,
  }),
});
