import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { updateClientInfo, getSignatureUrl } from '../../../actions/account';
import { setTitleGlobal } from '../../../actions/global';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StyledInput from '../components/StyledInput';
import { FastField } from 'formik';
import CusTooltip from '../../../components/CusTooltip';
import {
  companyDetailFields,
  companyFieldNoProperty,
  contactPersonFields,
  companyDetailFieldsReadOnly,
  bankAccountFields,
} from '../components/adminFields';
import AdminForm from '../components/AdminForm';
import { getFormProps } from '../components/initialAdminForm';
import YourClient from './YourClient';
import { Navigate } from 'react-router-dom';
import {
  selectClientInfo,
  selectResidentialClientsList,
} from '../../../selectors/client-selectors';
import { switchCompanyType, getClientInfo } from '../../../actions/account';
import {
  convertPhoneToInput,
  formatPhone,
  loginRedirect,
} from '../../../helpers';
import { omit } from 'lodash';
import Signature from '../../../components/Signature';
import {
  Button,
  Grid,
  Hidden,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Cancel';
import Close from '@mui/icons-material/Close';
import styled from 'styled-components';
import BoxShadow from '../../../common/BoxShadow';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { globalStyles } from '../../../styles/styles';
import ConnectClient from '../components/ConnectClient';
import Airbnb from '../../../styles/onboarding/connect_account/Airbnb_logo.png';
import Booking from '../../../styles/onboarding/connect_account/Booking_logo.png';
import { blobToBase64 } from '../../../custom-hooks/helperHooks';

const StyledSignatureImg = styled.div`
  width: 100%;
  margin: 10px 0;
  .gr-signature-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    .signature-preview {
      border: 1px dashed #e0e0e0;
    }
  }
`;

const AIRBNB_NAME = 'airbnb';
const BOOKING_NAME = 'booking';

const width = window.innerWidth;

const styles = {
  readOnlyInput: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    justifyContent: 'center',
    width: width < 500 ? width - 60 : 'auto',
  },
  localFees: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 500,
    marginBottom: 5,
    marginLeft: width < 500 ? 15 : 0,
  },
};

const AdminProfile = ({
  switchCompanyType,
  getSignatureUrl,
  updateClientInfo,
  residentClientsState,
  clientInfoState,
  settingsClientInfoState,
  account,
  getClientInfo,
  setTitleGlobal,
  label,
  title,
  classes,
  ...props
}) => {
  const isAuthenticated = account.detail.tokens.authToken;

  useEffect(() => {
    setTitleGlobal({ label: label, title: title });
  }, []);

  useEffect(() => {
    getClientInfo();
  }, [isAuthenticated]);

  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [openSignature, setOpenSignature] = useState(false);
  const [requiredSignature, setRequiredSignature] = useState();
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    clientInfoState.data.signatureId &&
      getSignatureUrl({ fileId: clientInfoState.data.signatureId }).then(
        (res) => {
          res && blobToBase64(res, (image) => setSignature(image));
        },
      );
  }, [clientInfoState.data.signatureId]);

  const handleSubmit = (values, { setErrors }) => {
    if (!signature && !clientInfoState.data.signature) {
      setRequiredSignature(true);
      return false;
    }
    const newValues = {
      ...omit(values, ['invoiceAddress_obj', 'signatureId', 'signatureUrl']),
      signature: signature,
      phoneNumber: formatPhone(values.phoneNumber),
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    Object.keys(newValues).map((key) => {
      !newValues[key] && delete newValues[key];
    });

    setLoader(true);
    updateClientInfo(newValues).then((res) => {
      setLoader(false);
      if (res.result) {
        setUpdateStatus('Update successfully!');
      } else {
        res.errors.length > 0 &&
          res.errors[0].note &&
          setErrors(res.errors[0].note);
      }
    });
  };

  const hasProperty = clientInfoState.data.propertyManager === 1;
  const { initialValues, validationSchema } = getFormProps(hasProperty);
  const clientInfoValues = clientInfoState.data
    ? {
        ...clientInfoState.data,
        phoneNumber: convertPhoneToInput(clientInfoState.data.phoneNumber),
      }
    : initialValues;
  const clientLoading = residentClientsState.loading;

  const removeSignature = () => {
    setSignature(null);
    setRequiredSignature(true);
  };

  const renderBusinessContent = () => {
    return (
      <Grid
        container
        spacing={width < 500 ? 0 : 24}
        direction="row"
        justify="space-between"
      >
        <Grid item md={5} xs={12}>
          <AdminForm
            loading={clientLoading}
            fields={hasProperty ? companyDetailFields : companyFieldNoProperty}
            title={t('Business Name')}
            key="business-name"
            customPadding={width < 500 ? { paddingLeft: 15 } : {}}
            noBoxShadow={width < 500}
            updateErrorMsg={settingsClientInfoState.isUpdatedErrorMsg}
          />
        </Grid>
        <Grid item md={5} xs={12} style={{ marginBottom: 20 }}>
          {companyDetailFieldsReadOnly(t).map((field, k) => (
            <div style={styles.readOnlyInput} key={k}>
              {field.showTooltip && <CusTooltip title={field.title} />}
              <FastField
                key={field.key}
                name={field.name}
                component={field.component || StyledInput}
                label={t(field.label)}
                type={field.type || 'text'}
                disabled={field.disabled}
              />
            </div>
          ))}
        </Grid>
      </Grid>
    );
  };

  const renderLocalFeeContent = () => {
    return (
      <BoxShadow>
        <Grid container direction="row" justify="space-between">
          <Grid item md={12} xs={12}>
            <AdminForm
              loading={clientLoading}
              fields={contactPersonFields}
              key="contact-person-fields"
              noBoxShadow={true}
              customMargin={width < 500 ? { marginLeft: -15 } : {}}
            />
          </Grid>
          {!hasProperty && (
            <Grid item md={12} xs={12}>
              <Grid container direction="row">
                <Grid
                  item
                  md={6}
                  lg={4}
                  xs={12}
                  style={{ marginBottom: 20, paddingRight: 15 }}
                >
                  <AdminForm
                    loading={clientLoading}
                    fields={bankAccountFields}
                    key="bank-account-field"
                    noBoxShadow={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={4}
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    marginLeft: 10,
                  }}
                >
                  {signature ? (
                    <StyledSignatureImg>
                      <div className="gr-signature-icon">
                        <img
                          className="signature-preview"
                          src={signature}
                          height={80}
                          alt="signature"
                        />

                        <Button onClick={() => removeSignature()}>
                          <CloseIcon fontSize={'small'} />
                          <Typography
                            variant="caption"
                            style={{ paddingLeft: 5 }}
                          >
                            {t('Remove signature')}
                          </Typography>
                        </Button>
                      </div>
                    </StyledSignatureImg>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <Button
                        style={{
                          textTransform: 'uppercase',
                          border: '1px solid #f16d36',
                          color: '#f16d36',
                          backgroundColor: '#fff',
                          width: '100%',
                          ...requiredSignature,
                        }}
                        onClick={() => setOpenSignature(true)}
                        fullWidth
                      >
                        {t('Click to Add Signature')}
                      </Button>
                      {requiredSignature && (
                        <div
                          style={{
                            color: '#f44336',
                            marginTop: 10,
                            textAlign: 'center',
                            fontSize: '80%',
                          }}
                        >
                          Required
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </BoxShadow>
    );
  };

  if (!isAuthenticated) {
    return (
      <Navigate
        noThrow
        to={loginRedirect(
          props.location && props.location.pathname,
          props.location && props.location.search,
        )}
      />
    );
  }

  return (
    <div>
      <Hidden smUp implementation="css">
        <TitleHasIcon
          variant="h5"
          title={t('Admin profile')}
          label={`${t('User ID')}: ${clientInfoState.data.clientId || '_'}`}
          icon={'account_box'}
        />
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={clientInfoValues}
          validationSchema={validationSchema}
          render={({ dirty }) => (
            <Form style={{ width: width - 45 }}>
              <Paper>{renderBusinessContent()}</Paper>
              <Paper style={{ marginTop: 20, paddingTop: 15 }}>
                <Typography variant="h5" style={styles.localFees}>
                  {!hasProperty
                    ? t('Local fees and Ubyport')
                    : t('Contact person')}
                </Typography>
                {renderLocalFeeContent()}
              </Paper>
              {!hasProperty && (
                <Paper style={{ marginTop: 20 }}>
                  <ConnectClient img={Airbnb} connectType={'AIRBNB'} />
                </Paper>
              )}
              {!hasProperty && (
                <Paper style={{ marginTop: 20 }}>
                  <ConnectClient img={Booking} connectType={'BOOKING'} />
                </Paper>
              )}

              <div align="center">
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Button
                    type="submit"
                    fullWidth
                    size={'large'}
                    style={{ margin: `15px 0` }}
                    disabled={(!dirty || loader) && !signature}
                    variant={'contained'}
                    color={'primary'}
                  >
                    {t('Save')}
                    {loader && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                  {updateStatus && (
                    <Typography color={'secondary'} align="center">
                      {t(updateStatus)}
                    </Typography>
                  )}
                </Grid>
              </div>
            </Form>
          )}
        />
      </Hidden>

      <Hidden xsDown implementation="css">
        <Paper className={classes.root}>
          <TitleHasIcon
            variant="h5"
            title={t('Admin profile')}
            label={`${t('User ID')}: ${clientInfoState.data.clientId || '_'}`}
            icon={'account_box'}
          />
          <div>
            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={clientInfoValues}
              validationSchema={validationSchema}
              render={({ dirty }) => (
                <Form>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                  >
                    <Grid item xs={12}>
                      {renderBusinessContent()}
                      <div style={styles.localFees}>
                        {!hasProperty
                          ? t('Local fees and Ubyport')
                          : t('Contact person')}
                      </div>
                      {renderLocalFeeContent()}
                      <div style={{ marginTop: 25 }}>
                        {!hasProperty && (
                          <ConnectClient
                            name={AIRBNB_NAME}
                            initialValues={clientInfoValues}
                            img={Airbnb}
                            connectType={'AIRBNB'}
                          />
                        )}
                        {!hasProperty && (
                          <ConnectClient
                            name={BOOKING_NAME}
                            initialValues={clientInfoValues}
                            img={Booking}
                            connectType={'BOOKING'}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <div align="center">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Button
                        type="submit"
                        fullWidth
                        size={'large'}
                        style={{ margin: `15px 0` }}
                        disabled={(!dirty || loader) && !signature}
                        variant={'contained'}
                        color={'primary'}
                      >
                        {t('Save')}
                        {loader && (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 10 }}
                            color="secondary"
                          />
                        )}
                      </Button>
                      {updateStatus && (
                        <Typography color={'secondary'} align="center">
                          {t(updateStatus)}
                        </Typography>
                      )}
                    </Grid>
                  </div>
                </Form>
              )}
            />
          </div>
        </Paper>
      </Hidden>

      {hasProperty && (
        <YourClient residentClientsState={residentClientsState} />
      )}

      {openSignature && (
        <Dialog
          open={openSignature}
          maxWidth="md"
          fullScreen={width < 500}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {t('Signature')}
              <div align="right">
                <IconButton
                  aria-label="Close"
                  onClick={() => setOpenSignature(false)}
                >
                  <Close />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <Signature
              isMobile={width < 500}
              currentSignature={signature}
              onClose={() => setOpenSignature(false)}
              getSignature={(sign) => {
                setSignature(sign);
                setRequiredSignature(false);
                setOpenSignature(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  residentClientsState: selectResidentialClientsList(state),
  clientInfoState: selectClientInfo(state),
  settingsClientInfoState: state.settings.clientInfo,
  account: state.account,
});

export default connect(mapStateToProps, {
  switchCompanyType,
  updateClientInfo,
  getClientInfo,
  getSignatureUrl,
  setTitleGlobal,
})(withStyles(globalStyles)(AdminProfile));
