import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProperties } from '../../../actions/property';
import { useTranslation } from 'react-i18next';

const styles = {
  reminderStyle: {
    border: '1px solid #f39813',
    borderRadius: 8,
    padding: '2px 15px',
    background: '#f7d5a2',
    marginBottom: 10,
    color: '#000',
  },
};

const PropertyReminder = ({
  // getProperties,
  propertiesList,
}) => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   getProperties();
  // }, []);

  const isMissingUport = (data) => {
    return !data.ubyIDUB || !data.ubyPassword || !data.ubyUsername;
  };

  const isMissingFee = (data) => {
    return (
      (data.payingCapacityFee === 1 && !data.accommodationFeeId) ||
      !data.localFeeId
    );
  };

  const renderReminder = (data) => {
    const lstMissing = data
      .map((item) => {
        return {
          propertyId: item.accommodationId,
          propertyName: item.accommodationName,
          missingUbyport: isMissingUport(item),
          missingFee: isMissingFee(item),
        };
      })
      .filter((item) => item.missingFee || item.missingUbyport);

    return lstMissing.map((item, key) => {
      console.log(item.missingUbyport);
      return (
        <div style={styles.reminderStyle} key={key}>
          {item.missingUbyport
            ? `${t('Ubyport information for')} /${item.propertyName}/ ${t(
                'is missing',
              )}. `
            : `${t('Variable symbol for')} /${item.propertyName}/ ${t(
                'is missing',
              )}. `}
          <Link to={`${item.propertyId}`} style={{ color: '#15a0de' }}>
            => {t('Fill')}
          </Link>
        </div>
      );
    });
  };
  return (
    <div style={{ marginBottom: 30, marginTop: 30 }}>
      {propertiesList.length && renderReminder(propertiesList)}
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getProperties })(PropertyReminder);
