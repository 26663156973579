import React from 'react';
import { suggestFomat } from '../../../helpers';


export function generateLists() {
  const fieldsArr = [
    {
      name: 'guestFirstName',
      label: "Guest's first name",
      value: "Guest's first name",
    },
    {
      name: 'guestLastName',
      label: "Guest's last name",
      value: "Guest's last name",
    },
    {
      name: 'welcomerName',
      label: "Welcomer's name",
      value: "Welcomer's name",
    },
    {
      name: 'welcomerPhone',
      label: "Welcomer's phone",
      value: "Welcomer's phone",
    },
    {
      name: 'listingName',
      label: 'Listing name',
      value: 'Listing name',
    },
    {
      name: 'propertyName',
      label: 'Property name',
      value: 'Property name',
    },
    {
      name: 'fullAddress',
      label: 'Full address',
      value: 'Full address',
    },
    {
      name: 'country',
      label: 'Country',
      value: 'Country',
    },
    {
      name: 'city',
      label: 'City',
      value: 'City',
    },
    {
      name: 'checkInDate',
      label: 'Check-in date',
      value: 'Check-in date',
    },
    {
      name: 'checkinTime',
      label: 'Check-in time',
      value: 'Check-in time',
    },
    {
      name: 'checkOutDay',
      label: 'Check-out day',
      value: 'Check-out day',
    },
    {
      name: 'checkOutTime',
      label: 'Check-out time',
      value: 'Check-out time',
    },
    {
      name: 'numberOfNights',
      label: 'Number of nights',
      value: 'Number of nights',
    },
    {
      name: 'wifiName',
      label: 'Wifi name',
      value: 'Wifi name',
    },
    {
      name: 'wifiPassword',
      label: 'Wifi password',
      value: 'Wifi password',
    },
    {
      name: 'listingCode1',
      label: 'Listing code 1',
      value: 'Listing code 1',
    },
    {
      name: 'listingCode2',
      label: 'Listing code 2',
      value: 'Listing code 2',
    },
    {
      name: 'specialCode',
      label: 'Property special code',
      value: 'Property special code',
    },
  ];

  return fieldsArr;
}

const getSuggestList = (index = 0, key = 'value') => {
  return suggestFomat(generateLists()[index][key])
}

const checkInInstructionsTooltip = () => {
  return (
    <div>
      Don't worry if you get a last minute reservation (less than 24 hours before check-in) the guest will receive the "Check-in instructions" message just within half an hour after the message "New Booking".
    </div>
  );
};

const defaultValueReviewReminder = () => {
  return (
    `<div>
      <p>Hi ${getSuggestList(0)}! :)</p>
      <p>
        Thanks for being such a great guest and leaving the place so clean. I left you a 5 star review and if you enjoyed your stay it would be great if you left me a review as well. If there is anything that could have made your stay better please send me a message. Thanks again for booking my place.
      </p>
      <p>Hope to see you next time you're in ${getSuggestList(5)}!</p>
      <p>Regards,</p>
      <p>${getSuggestList(2)}</p>
    </div>`
  )
}

const defaultValueRiview = () => {
  return (
    `<div>What a great guest! Would be happy to host ${getSuggestList(0)} anytime again!</div>`
  )
}

export function generateAutomationFields(t, data) {
  const getSuggestList = (index = 0, key = 'name') => {
    return suggestFomat(data[index][key])
  }
  const fieldsArr = [
    {
      name: 'new_booking',
      label: `${t('New booking')}`,
      placeholder: `${t('Sent immediately when a booking is confirmed')}`,
      defaultMessage: `Hi ${getSuggestList(0)}, <br/>
      Thanks so much for booking our place. We look forward to having you! :) <br/>
      Soon I will send you details on how to check-in. If you have any questions in the meantime, let me know. <br/>
      Thanks, <br/>
      ${getSuggestList(2)}`
    },
    {
      name: 'upcoming_stay',
      label: `${t('Reminder of upcoming stay')}`,
      placeholder: `${t("Sent 10 days before check-in (won't be sent if the check-in is sooner)")}`,
      defaultMessage: `Hi ${getSuggestList(0)}, <br/>
      I am looking forward to have you in my apartment soon! :)<br/>
      ${getSuggestList(2)}`
    },

    {
      name: 'fill_checkin_1day',
      label: `${t('Please fill the check-in form')}`,
      placeholder: `${t("Sent 168 & 96 & 24 hours before check-in as reminder in case the Master guest didn't fill the check-in form yet (if the check-in is sooner - it is sent right after message")} "${t('New booking')}")`,
      defaultMessage: `Dear ${getSuggestList(0)}, <br/>
      I just sent you an invitation to fill in the check-in form. 
      Please fill it in and send the invitation to all your fellow travellers that will come with you to ${getSuggestList(5)} so you can easily check-in. <br/>
      According to our law every host has to keep records of all the guests for six years, report foreigners to the Foreign police and pay the local fees for Spa and recreation stay. I know that it is not fun but If I wouldn't do it I would have serious problems and pay high penalties. Thanks to Best Guest I don't have to overwrite it from paper check-in forms to computer, otherwise I would have to. It saves time and hassle to us both.<br/>
      Thank you very much for understanding and filling the check-in forms.<br/>
      Kind regards, <br/>
      ${getSuggestList(2)}`
    },

    {
      name: 'submit_checkin',
      label: `${t('Thank you for filling the check-in form')}`,
      placeholder: `${t("Sent right after the Master guest fills the check-in form")}`,
      defaultMessage: `Dear ${getSuggestList(0)}, <br/>
      thank you for filling the check-in form. Did you have any difficulties?<br/>
      Best regards, <br/>
      ${getSuggestList(2)}`
    },


    {
      name: 'missing_checkin',
      label: `${t('Missing check-in form')}`,
      placeholder: `${t("Sent 24 & 48 hours after the Master guest filled the check-in form but Fellow guests haven't yet")}`,
      defaultMessage: `Hello ${getSuggestList(0)}, <br/>
      I haven't received the check-in forms of your fellow travellers yet. Would you please ask them if they have any difficulties?<br/>
      Regards, <br/>
      ${getSuggestList(2)}`
    },


    {
      name: 'checkin_inst',
      label: `${t('Check-in instructions')}`,
      placeholder: `${t('Sent 24 hours before check-in (if the check-in is sooner - last minute reservation - send it right after message')} "${t("Please fill the check-in form")})"`,
      defaultMessage: `Please create your check-in instructions, that will be helpful for your guests and make their check-in easier. 
      The guest will preview this message also in the Check-in instructions in the GuestApp. <br/>
      The structure can be for example: usual check-in time, early and late check-in instruction, address, how to get there and where to find keys/password (you can use listing special code or property special code), floor, room, wifi, water, house manual, rules, recommendations.<br/>
      Hi ${getSuggestList(0)} ,<br/>
      I am sending you the instructions how to get into the apartment. You can come at any time you want after ${getSuggestList(9)}. 
      If you want to arrive from ${getSuggestList(11)} to ${getSuggestList(9)} it is possible to leave your luggage in your room, take your keys (don’t lock the room because the cleaning service will have to go there) 
      and in the meantime you can go for a lunch or walk through ${getSuggestList(7)} before your room will be prepared.<br/>
      1) the address is ${getSuggestList(18)} 2) the keys are in ${getSuggestList(16)} 3) the apartment is on the ${getSuggestList(14)} floor and your room is no. ${getSuggestList(15)}<br/>
      If you need any help, you can call me on phone number ${getSuggestList(3)}.<br/>
      WIFI <br/>
      The wifi network is “${getSuggestList(12)}” and the password is “${getSuggestList(13)}”.<br/>
      WATER
      The water is drinkable, you don't need to buy it.   The water from the tap is healthy.<br/>
      RULES<br/>
      It is not possible to smoke in the apartment or in the hallway.<br/>
      HOUSE MANUAL<br/>
      Some important information are on the table in the kitchen!<br/>
      // how to use washing machine / dishwasher / heater / AC …<br/>
       Please make yourself at home and enjoy your stay!<br/>
      If you need anything, please let me know :)<br/>
      Best regards,<br/>
      ${getSuggestList(2)}`
    },

    
    {
      name: 'first_night',
  label: `${t('First night of stay')}`,
      placeholder: `${t('Sent at 9pm the first night of stay')}`,
      defaultMessage: `Dear  ${getSuggestList(0)}<br/>
      how is your stay so far? :)<br/>     
      I would like to inform you that in Czech republic the piece and quiet at night is at 10pm. Please notice it. Your are a great guest, I didn't get any complaints, I just always let all my guests know in advance. Thank you!<br/>
      Kind regards,<br/>
      ${getSuggestList(2)}`
    },

    {
      name: 'first_morning',
      label: `${t('First morning of stay')}`,
      placeholder: `${t('Sent at 10am on the first day after check-in')}`,
      defaultMessage: `Hi ${getSuggestList(0)}, <br/>
      how was your first day in ${getSuggestList(7)} ? <br/>
      Do you have everything you need? If there is anything I can do for you to make your stay even nicer, please let me know :) <br/>
      Thank you, <br/>
      ${getSuggestList(2)}`
    },
    {
      name: 'checkout_inst',
      label: `${t('Check-out instructions')}`,
      placeholder: `${t('Send at 6pm before check-out')}`,
      // showHelp: checkInInstructionsTooltip(),
      defaultMessage: `
      Hi ${getSuggestList(0)}, <br/>
      I hope you’ve enjoyed your stay in ${getSuggestList(5)}! <br/>
      Just a quick reminder that you check-out tomorrow. Check-out time is at ${getSuggestList(11)}
      Please, leave the keys on the table in your room and that's it. <br/>
      Thank you so much for choosing to stay at our place! <br/>
      Thanks again, <br/>
      ${getSuggestList(2)}`
    },
    {
      name: 'review_reminder',
      label: `${t('Review reminder')}`,
      placeholder: `${t('Sent 24 hours after check-out + review Airbnb guest')}`,
      inboxMessageValue: defaultValueReviewReminder(),
      reviewValue: defaultValueRiview(),
      defaultMessage: `Hi ${getSuggestList(0)}! :) <br/>
      Thanks for being such a great guest and leaving the place so clean. I left you a 5 star review and if you enjoyed your stay it would be great if you left me a 
      review as well. If there is anything that could have made your stay better please send me a message. Thanks again for booking my place. <br/>
      Hope to see you next time you're in ${getSuggestList(6)}! <br/>
      Regards, <br/>
      ${getSuggestList(2)}`
    },
    {
      name: 'after_checkout',
      label: `${t('After check-out')}`,
      placeholder: `${t('Sent 48 hours after check-out')}`,
      defaultMessage: `Hi ${getSuggestList(0)}, <br/>
      I hope you had a great time in ${getSuggestList(6)}, has known our culture, the beauty of the region and that you 
      will love to come again to visit us sometime soon! <br/>
      I wish you the best of luck! <br/>
      Take care, <br/>
      ${getSuggestList(2)}`
    },
  ];
  return fieldsArr;
}

