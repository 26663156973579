/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Checkbox,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { generateLogin } from '../components/generateFields';
import { VERIFY_URL } from '../../../routes/onboarding-routes';
import green from '@mui/material/colors/green';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import { connect } from 'react-redux';
import { formatPhone } from '../../../helpers';
import { addUserInfo, nextStep } from '../../../actions/onboading';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../../../components/Firebase';

const cusStyles = {
  continuesButton: {
    textAlign: 'center',
    marginTop: 50,
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0 4px',
  },
  iconTooltip: {
    marginTop: 20,
  },
  signSuccess: {
    color: green[500],
    marginBottom: 15,
  },
  logo: {
    width: '100%',
    margin: '15px 0 30px',
  },
};

const validation = Yup.object().shape({
  firstName: Yup.string().required('Required').min(3).nullable(),
  lastName: Yup.string().required('Required').min(3).nullable(),
  phoneNumber: Yup.string().required('Required').nullable(),
});

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

const Signup = ({ account, addUserInfo, nextStep }) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const formFields = generateLogin();

  const handleSubmit = (values, { setErrors }) => {
    let params = {
      ...values,
      phoneNumber: formatPhone(values.phoneNumber),
    };
    setLoading(true);
    addUserInfo(params).then((res) => {
      setLoading(false);
      if (res.data && res.result) {
        // nextStep();
        history(VERIFY_URL);
      } else {
        res.errors.length > 0 &&
          res.errors[0].note &&
          setErrors(res.errors[0].note);
      }
    });
  };

  return (
    <div>
      <Grid container spacing={24} justify="center" alignItems="center">
        <Grid
          item
          xs={8}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: -30,
          }}
        >
          <img
            src={logo}
            style={window.innerWidth < 500 ? { width: '67%' } : cusStyles.logo}
            alt=""
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 15 }} spacing={24}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="center"
            style={cusStyles.signSuccess}
          >
            {t('You signed up successfully')}!
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t('Please continue with creating your account')}.
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t('The phone number will be used for later verification')}.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
            render={({ dirty, values }) => {
              return (
                <Form>
                  {formFields.map((v, k) => (
                    <div style={cusStyles.inputTooltip} key={k}>
                      <FastField {...v} key={k} />
                    </div>
                  ))}
                  <div align="center" style={{ paddingTop: 30 }}>
                    <Checkbox
                      checked={confirmed}
                      onChange={(event) => setConfirmed(event.target.checked)}
                      color="primary"
                    />
                    {t('I agree with')}{' '}
                    <a href="#" style={cusStyles.terms}>
                      {t('Terms and Conditions')}
                    </a>
                  </div>
                  <div style={cusStyles.continuesButton}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      id="login-button"
                      disabled={!dirty || !confirmed || loading}
                    >
                      {t('CONTINUE')}
                      {loading && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { addUserInfo, nextStep })(Signup);
