import React from 'react';
import { Grid, Typography, FormHelperText } from '@mui/material';
import FormInput from '../../../common/input';
import BoxShadow from '../../../common/BoxShadow';
import { FastField } from 'formik';
import Placeholder from '../../../common/Placeholder';
import PhoneInput from '../../../common/PhoneInput';
import CusTooltip from '../../../components/CusTooltip';
import { withTranslation } from 'react-i18next';
import getErrorName from '../../../shared/const/errorCode';
import { omit } from 'lodash';

const styles = {
  middleItem: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: '0',
  },
};

class AdminForm extends React.PureComponent {
  render() {
    const { t } = this.props;
    const {
      fields,
      title,
      noBoxShadow,
      hasBorder,
      loading,
      customPadding,
      customMargin,
      updateErrorMsg,
    } = this.props;
    return (
      <div
        style={
          window.innerWidth < 500
            ? { margin: '14px 0 0 0' }
            : { margin: '14px 0 30px' }
        }
      >
        <Typography
          variant={'subtitle2'}
          gutterBottom
          style={
            (hasBorder && {
              borderBottom: '1px solid #2196f3',
              marginBottom: 15,
            }) ||
            customPadding
          }
        >
          <Placeholder loading={!!loading} type={'line'}>
            {t(title)}
          </Placeholder>
        </Typography>
        <BoxShadow noShadow={noBoxShadow}>
          <Grid container spacing={24}>
            {fields.map((field) =>
              field.name ? (
                <Grid
                  item
                  xs={12}
                  sm={fields.length == 1 ? 12 : 6}
                  md={field.md || 6}
                  lg={field.lg || 4}
                  key={field.key}
                >
                  <div style={customMargin}>
                    <div
                      style={
                        field.showHelp && field.helpContent && styles.middleItem
                      }
                    >
                      <Placeholder loading={!!loading} type={'line'}>
                        {field.name === 'phoneNumber' ? (
                          <FastField
                            key={field.key}
                            name={field.name}
                            component={PhoneInput}
                            label={t(field.label)}
                            type={field.type || 'text'}
                            isDynamic={true}
                            disabled={field.disabled}
                            isTextMask={field.isTextMask}
                          />
                        ) : (
                          <FastField
                            {...omit(field, [
                              'isTextMask',
                              'showHelp',
                              'helpContent',
                            ])}
                            key={field.key}
                            name={field.name}
                            component={field.component || FormInput}
                            label={t(field.label)}
                            type={field.type || 'text'}
                            disabled={field.disabled}
                          />
                        )}
                        {field.showHelp && field.helpContent && (
                          <CusTooltip
                            title={field.helpContent}
                            iconStyle={styles.noMargin}
                          />
                        )}
                      </Placeholder>
                    </div>
                  </div>
                  <div>
                    {field.showErrorMsg && updateErrorMsg && (
                      <FormHelperText error>
                        {getErrorName(updateErrorMsg)}
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
              ) : null,
            )}
          </Grid>
        </BoxShadow>
      </div>
    );
  }
}

export default withTranslation()(AdminForm);
