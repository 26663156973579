import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_RESIDENTIAL_CLIENT,
  GET_RESIDENTIAL_CLIENT_SUCCESS,

  UPDATE_RESIDENTIAL_CLIENT,
  UPDATE_RESIDENTIAL_CLIENT_FAILURE,
  UPDATE_RESIDENTIAL_CLIENT_SUCCESS,
} from '../../actions/account';

const initialState = {
  data: [],
  loading: false,
  error: '',
  updateError: null,
};
export default createImmerReducer(initialState, {
  [GET_RESIDENTIAL_CLIENT]: state => {
    state.loading = true;
    state.updateError = null;
  },
  [GET_RESIDENTIAL_CLIENT_SUCCESS]: (state, action) => {
    state.loading = false;
    state.updateError = null;
    state.data = action.res;
  },


  [UPDATE_RESIDENTIAL_CLIENT]:  (state, action) => {
    state.updateError = null;
  },
  [UPDATE_RESIDENTIAL_CLIENT_SUCCESS]:  (state, action) => {
    state.updateError = null;
  },
  [UPDATE_RESIDENTIAL_CLIENT_FAILURE]:  (state, action) => {
    state.updateError = action.res;
  },
});
