import { createSelector } from 'reselect';

const countriesSelector = state => state.countries;

export const selecCountriesList = createSelector(
  countriesSelector,
  countries => countries.data,
);

const visaCountrySelector = state => state.visaCountry;

export const selectVisaCountryList = createSelector(
  visaCountrySelector,
  visaCountry => visaCountry.data,
);
