import React, { useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useTranslation } from "react-i18next";

const StyledBoard = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  // border: 1px solid #e0e0e0;
`;

function Signature({ getSignature, currentSignature, isMobile }) {

  const { t } = useTranslation();

  const sigCanvasRef = useRef(null);
  function exportSignature() {
    const signature = sigCanvasRef.current;
    if (!signature.isEmpty()) {
      const img = signature.toDataURL();
      getSignature(img);
    }
  }

  useEffect(() => {
    if (!currentSignature) {
      sigCanvasRef.current.clear();
    }
  });

  const mobileWidth = window.innerWidth;

  return (
    <div style={isMobile ? {marginLeft: -15}: {}}>
      <StyledBoard>
        <SignatureCanvas
          ref={sigCanvasRef}
          canvasProps={{ width: isMobile ? mobileWidth - 80 : 600, height: 300, className: 'sigCanvas' }}
          backgroundColor="#fff"
        />
      </StyledBoard>
      <div style={{ marginTop: 10, textAlign: 'center' }}>
        <Button onClick={() => sigCanvasRef.current.clear()}>{t("Clear")}</Button>
        <Button
          onClick={exportSignature}
          style={{ marginLeft: 10 }}
          variant="contained"
          color="secondary"
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
}

export default Signature;
