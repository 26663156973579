import createReducer from '../../../redux/createReducer';
import produce from 'immer';
import {
  CREATE_CHECK_IN_FORM,
  CREATE_CHECK_IN_FORM_SUCCESS,
} from '../actionTypes';

const initialState = {
  form: {
    reservationHash: '',
    firstName: '',
    lastName: '',
    birthDate: new Date(),
    state: '',
    city: '',
    district: '',
    street: '',
    houseNumber: '',
    checkIn: new Date(),
    checkOut: new Date(),
    visaNumber: 0,
  },
  loading: false,
};

export default createReducer(initialState, {
  [CREATE_CHECK_IN_FORM]: (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  [CREATE_CHECK_IN_FORM_SUCCESS]: (state, action) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.form = action.res;
    }),
});
