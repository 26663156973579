import { combineReducers } from 'redux';
import propertyDetail from './propertyDetail';
import propertiesList from './propertiesReducer';
import listingDetail from './listingDetail';
import airbnbReservation from './airbnbReservation';
import airbnbListings from './airbnbListings';

export default combineReducers({
  propertyDetail,
  propertiesList,
  listingDetail,
  airbnbReservation,
  airbnbListings,
});
