import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { tooltipStyles } from '../containers/style';

const tooltipTitle = (title, body) => {
  return (
    <div>
      <Typography variant="body2" align="center" className="title">
        {title}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'div' }}
        className="body"
      >
        {body}
      </Typography>
    </div>
  );
};

const TooltipCustom = ({ classes, title, body, iconStyle }) => (
  <Tooltip
    title={tooltipTitle(title, body)}
    classes={{ tooltip: classes.tooltip }}
  >
    <InfoOutlined
      fontSize={'small'}
      style={iconStyle || { margin: '0 10px' }}
    />
  </Tooltip>
);
export default withStyles(tooltipStyles)(TooltipCustom);
