import {
  CLOSE_DRAWER,
  CREATE_RESIDENTIAL_CLIENT,
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  UPDATE_RESIDENTIAL_CLIENT_EPIC,
  DELETE_RESIDENTIAL_CLIENT,
  DELETE_RESIDENTIAL_CLIENT_SUCCESS,
  GET_CLIENT_INFO,
  GET_CLIENT_INFO_SUCCESS,
  GET_RESIDENTIAL_CLIENTS,
  GET_RESIDENTIAL_CLIENTS_SUCCESS,
  OPEN_DRAWER,
  SWITCH_KIND_OF_COMPANY,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_SUCCESS,
} from './actionTypes';

export const getClientInfo = () => ({
  type: GET_CLIENT_INFO,
});

export const getClientInfoSuccess = (res) => ({
  type: GET_CLIENT_INFO_SUCCESS,
  res,
});

export const createResidentialClient = (params) => ({
  type: CREATE_RESIDENTIAL_CLIENT,
  params,
});

export const updateResidentialClient = (params) => ({
  type: UPDATE_RESIDENTIAL_CLIENT_EPIC,
  params,
});

export const createResidentialClientSuccess = (res) => ({
  type: CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  res,
});

export const getResidentialClients = () => ({
  type: GET_RESIDENTIAL_CLIENTS,
});

export const getResidentialClientsSuccess = (res) => ({
  type: GET_RESIDENTIAL_CLIENTS_SUCCESS,
  res,
});

export const switchKindOfCompany = (propertyManager) => ({
  type: SWITCH_KIND_OF_COMPANY,
  propertyManager,
});

export const updateClientInfo = (params) => ({
  type: UPDATE_CLIENT_INFO,
  params,
});
export const updateClientInfoSuccess = (res) => ({
  type: UPDATE_CLIENT_INFO_SUCCESS,
  res,
});

export const deleteResidentialClient = (params) => ({
  type: DELETE_RESIDENTIAL_CLIENT,
  params,
});

export const deleteResidentialClientSuccess = (res) => ({
  type: DELETE_RESIDENTIAL_CLIENT_SUCCESS,
  res,
});

export const openDrawer = (params) => ({
  type: OPEN_DRAWER,
  params,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});
