import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, FastField } from 'formik';
import { Button, Typography, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { media } from '../styles/commonStyles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const FormWrapper = styled.div`
  padding: 20px;
  ${media.largeDesktop`
    width: 480px;
  `}
  ${media.phone`
    width: 90%;
  `}
`;

function GuestForm({
  isUpdating,
  initialValues,
  validationSchema,
  handleSubmit,
  formFields,
  loading,
  isDone,
}) {
  const { t } = useTranslation();

  if (isEmpty(formFields)) {
    return null;
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ dirty }) => (
          <Form>
            {formFields.map((field) => (
              <FastField key={field.name} {...field} />
            ))}
            <Button
              type="submit"
              variant={'contained'}
              color="primary"
              disabled={!dirty || loading}
              style={{ marginTop: 20 }}
              fullWidth
            >
              {t(isUpdating ? 'Update' : 'Send')}
              {loading && (
                <CircularProgress size={20} style={{ marginLeft: 10 }} />
              )}
            </Button>
            {isDone && (
              <Typography
                color="secondary"
                align="center"
                style={{ marginTop: 15 }}
              >
                {t(`${isUpdating ? 'Updated' : 'Saved'} successfully`)}!
              </Typography>
            )}
          </Form>
        )}
      />
    </FormWrapper>
  );
}

GuestForm.defaultProps = {
  validationSchema: {},
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  isUpdating: false,
  formFields: [],
  loading: false,
  isDone: false,
};

GuestForm.propTypes = {
  isUpdating: PropTypes.bool,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  validationSchema: PropTypes.object,
  handleSubmit: PropTypes.func,
  formFields: PropTypes.array,
  loading: PropTypes.bool,
  isDone: PropTypes.bool,
};
export default GuestForm;
