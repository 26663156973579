/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-mixed-operators */

import React, { useContext, useState, useEffect } from 'react';
import { Form, Formik, FastField } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { connect } from 'react-redux';
import {
  updateCheckInform,
  checkInform,
  getReservationDetail,
  getCheckInform,
  getSignatureUrl,
  removeDefailtSignature,
} from '../../../actions/reservation';
import format from 'date-fns/format';
import {
  selectReservationDetail,
  selectCheckinFormDetail,
} from '../../../selectors/reservation-selectors';
import {
  selecCountriesList,
  selectVisaCountryList,
} from '../../../selectors/countries-selector';
import { getFormFields } from './checkInFormFields';
import styled from 'styled-components';
import { media } from '../../../styles/commonStyles';
import Signature from '../../../components/Signature';
import { GlobalDrawerContext } from '../../../contexts';
import CloseIcon from '@mui/icons-material/Cancel';
import { CLOSE_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import {
  validateCheckInCheckOut,
  validateDateOfBirth,
  validateDateOfBirth116,
  travelDocumentNumberCondition,
} from '../../../helpers/validate';
import DrawerAppBar from '../../../shared/drawer-app-bar';
import { omit, isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import { green } from '@mui/material/colors';
import { URL_GET_CLIENT_SIGNATURE } from '../../../shared/urls';
import { addressPickerValue, addressToValue } from '../../../helpers';

const SignatureWrapper = styled.div`
  .signature {
    transition: transform 300ms ease-in-out;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translate(100%, 0);
    z-index: 200000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    padding: 30px;
  }
  .signature.open {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
  }
`;
const FormWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  ${media.largeDesktop`
    width: 820px;
  `}
  ${media.tablet`
  width: 90%;
  padding: 20px;
 `} ${media.phone`
 width: 100%;
 padding: 10px;
`};
`;

const StyledSignatureImg = styled.div`
  width: 100%;
  margin: 10px 0;
  .gr-signature-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    .signature-preview {
      border: 1px dashed #e0e0e0;
    }
  }
`;

const validation = (visaCounttryList) =>
  Yup.object().shape({
    checkIn: Yup.date().required('Required').nullable(),
    checkOut: Yup.date().test(
      'match',
      // "Check-out date must be greater than Check-in date at least 1 day",
      'Check-out date must be greater than Check-in date',
      function (checkOut) {
        return validateCheckInCheckOut(this.parent.checkIn, checkOut);
      },
    ),
    firstName: Yup.string().required('Required').min(3).nullable(),
    lastName: Yup.string().required('Required').min(3).nullable(),
    nationality: Yup.string().required('Required').nullable(),
    address: Yup.string().required('Required').nullable(),
    // street: Yup.string().required('Required').nullable(),
    // houseNumber: Yup.string().required('Required').nullable(),
    // postalCode: Yup.number().required('Required').nullable(),
    // state: Yup.string().required(),
    visaNumber: Yup.number()
      .test('match', 'Required', function (value) {
        return !visaCounttryList.includes(this.parent.nationality) || value;
      })
      .nullable(),
    identNumber: Yup.string()
      .test(
        'match',
        'Travel document number must be longer than 5 characters and shorter than 31 characters (ICAO-9303)',
        function (value) {
          if (
            travelDocumentNumberCondition(
              this.parent.nationality,
              this.parent.birthDate,
            )
          )
            return true;
          return value && value.length > 5 && value.length < 31;
        },
      )
      .test('test', 'Required', function (value) {
        return (
          value ||
          travelDocumentNumberCondition(
            this.parent.nationality,
            this.parent.birthDate,
          )
        );
      })
      .nullable(),
    birthDate: Yup.date()
      .test(
        'match',
        'Date of birth must be smaller than the current date',
        function (value) {
          return validateDateOfBirth(value);
        },
      )
      .test(
        'match',
        'Date of birth shall not be more than 116 years old',
        function (value) {
          return validateDateOfBirth116(value);
        },
      )
      .required('Required')
      .nullable(),
  });

const checkHasCheckinForm = (data, detail) => {
  const invitations =
    detail &&
    detail.invitations &&
    detail.invitations.find(
      (d) =>
        d.sendToId.firstName === data.firstName &&
        d.sendToId.lastName === data.lastName &&
        d.sendToId.email === data.email,
    );
  const forms =
    detail &&
    detail.forms &&
    detail.forms.find(
      (d) => d.firstName === data.firstName && d.lastName === data.lastName,
    );
  return invitations && !forms ? false : true;
};

const initialValues = {
  checkIn: null,
  checkOut: null,
  birthDate: null,
  firstName: '',
  lastName: '',
  houseNumber: '',
  nationality: '',
  city: '',
  state: '',
  street: '',
  postalCode: '',
  identNumber: '',
  visaNumber: '',
  invitationId: null,
};

const CheckInForm = ({
  reservationDetail,
  checkinFormDetail,
  checkInform,
  currentForm,
  countriesList,
  visaCounttryList,
  isUpdating,
  updateCheckInform,
  getReservationDetail,
  getCheckInform,
  allCheckInFormValid,
  sendToPolice,
  getSignatureUrl,
  removeDefailtSignature,
}) => {
  const { dispatch } = useContext(GlobalDrawerContext);
  const [openSignature, setOpenSignature] = useState(false);
  const [requiredSignature, setRequiredSignature] = useState();
  const [signature, setSignature] = useState();
  const [defaultSignature, setDefaultSignature] = useState();
  const [apiCalling, setApiCalling] = useState({
    loading: false,
    isDone: false,
  });
  const dataId = currentForm.formDataId;
  useEffect(() => {
    if (
      isUpdating &&
      reservationDetail &&
      checkHasCheckinForm(currentForm, reservationDetail) &&
      dataId
    ) {
      getCheckInform({ checkinFormId: dataId });
    }
  }, []);

  // const [sentSuccessful, setSentSuccessful] = useState(false); // show after send to police confirmation
  // const [confirmed, setConfirmed] = useState(false); // checkbox checked all data and confirm valid

  const defaultValues =
    reservationDetail &&
    checkHasCheckinForm(currentForm, reservationDetail) &&
    dataId
      ? {
          ...checkinFormDetail,
          birthDate: checkinFormDetail.birthdate,
          address: addressPickerValue(checkinFormDetail), //`${checkinFormDetail.street || ''}${checkinFormDetail.houseNumber || ''}, ${checkinFormDetail.district || ''}, ${checkinFormDetail.city || ''}, ${checkinFormDetail.state || ''} ${checkinFormDetail.postalCode || ''}`,
        }
      : {
          checkIn: reservationDetail.fromDate,
          checkOut: reservationDetail.toDate,
        };

  let currentValues = isUpdating
    ? {
        ...initialValues,
        ...currentForm,
        ...(currentForm && {
          birthDate: currentForm.birthdate,
          nationality: currentForm.nationality,
          address: addressPickerValue(currentForm), //`${currentForm.street || ''} ${currentForm.houseNumber || ''}, ${currentForm.district || ''}, ${currentForm.city || ''}, ${currentForm.state || ''} ${currentForm.postalCode || ''}`,
        }),
        ...defaultValues,
      }
    : initialValues;

  useEffect(() => {
    if (
      (currentValues && (currentValues.signatureId || currentValues.fileId)) ||
      currentValues.clientId
    ) {
      currentValues.signatureId ||
        (currentValues.fileId &&
          getSignatureUrl({
            fileId: currentValues.signatureId || currentValues.fileId,
            ...(currentValues.signatureId && { url: URL_GET_CLIENT_SIGNATURE }),
          }).then(
            (res) => currentValues.fileId && res && setDefaultSignature(res),
          ));
    }
  }, [currentValues.signatureId, currentValues.fileId, currentValues.clientId]);

  function handleSubmit(values, { setErrors }) {
    let _birthDate =
      values.birthDate &&
      isString(values.birthDate) &&
      (values.birthDate.includes('-00-00')
        ? values.birthDate
        : format(
            values.birthDate,
            values.useYear ? 'yyyy-00-00' : 'yyyy-MM-dd',
          ));
    let params = {
      ...values,
      birthDate: _birthDate,
      birthdate: _birthDate,
      checkIn: format(values.checkIn, 'yyyy-MM-dd'),
      checkOut: format(values.checkOut, 'yyyy-MM-dd'),
      identType: 'id',
      reservationHash: reservationDetail.reservationHash,
      ...(values.address_obj && addressToValue(values.address_obj)),
    };

    params = omit(params, [
      'index',
      'status',
      'address',
      'address_obj',
      'passport',
      'useYear',
      'sendToId',
      'signatureUrl',
    ]);
    if (!defaultSignature && !signature) {
      setRequiredSignature(true);
      return false;
    }

    Object.keys(params).forEach((item) => {
      !params[item] && delete params[item];
    });

    setApiCalling({ ...apiCalling, loading: true });
    try {
      if (dataId) {
        !params.invitationId && delete params['invitationId'];
        params.headers = { 'Content-Type': 'multipart/form-data' };
        updateCheckInform({
          ...params,
          dataId: dataId,
          signature,
        }).then((res) => {
          setApiCalling({ loading: false });
          if (res.data && res.result) {
            setApiCalling({ isDone: true });
            setTimeout(() => {
              getReservationDetail({
                reservationId: reservationDetail.reservationId,
              });
              dispatch({ type: CLOSE_DRAWER });
            }, 2000);
          } else {
            res.errors.length > 0 &&
              res.errors[0].note &&
              setErrors(res.errors[0].note);
          }
        });
      } else {
        params.headers = { 'Content-Type': 'multipart/form-data' };
        checkInform({ ...params, signature }).then((res) => {
          setApiCalling({ loading: false });
          if (res.data && res.result) {
            setApiCalling({ isDone: true });
            setTimeout(() => {
              getReservationDetail({
                reservationId: reservationDetail.reservationId,
              });
              dispatch({ type: CLOSE_DRAWER });
            }, 2000);
          } else {
            res.errors.length > 0 &&
              res.errors[0].note &&
              setErrors(res.errors[0].note);
          }
        });
      }
    } catch (e) {
      setApiCalling({ ...apiCalling, loading: false });
    }
  }

  const useYearDefault =
    currentValues.birthdate && currentValues.birthdate.includes('-00-00');
  currentValues.useYear = useYearDefault || false;
  const formFields = getFormFields(countriesList, useYearDefault);

  function removeSignature() {
    setSignature(null);
    setDefaultSignature(null);
    setRequiredSignature(true);
    // removeDefailtSignature();
  }

  const { t } = useTranslation();

  const isMobile = window.innerWidth <= 600;

  return (
    <div>
      <DrawerAppBar
        onClick={() => {
          openSignature
            ? setOpenSignature(false)
            : dispatch({ type: CLOSE_DRAWER });
        }}
      />
      <FormWrapper>
        <Typography variant="h5" color="primary" align="center" gutterBottom>
          {t(openSignature ? 'Signature' : 'Check-in form')}
        </Typography>

        <SignatureWrapper>
          <div className={`signature ${openSignature ? 'open' : ''}`}>
            <Signature
              currentSignature={signature}
              isMobile={isMobile}
              onClose={() => setOpenSignature(false)}
              getSignature={(sign) => {
                setSignature(sign);
                setRequiredSignature(false);
                setOpenSignature(false);
              }}
            />
          </div>
        </SignatureWrapper>
        <Formik
          enableReinitialize
          initialValues={currentValues}
          validationSchema={validation(visaCounttryList)}
          onSubmit={handleSubmit}
          render={({ dirty, values, ...other }) => {
            return (
              <Form style={{ marginTop: 20 }}>
                {formFields.map((v, k) => (
                  <Grid container spacing={0} key={k} style={{ marginTop: 30 }}>
                    <Grid item xs={1}>
                      <Typography
                        variant="caption"
                        color="primary"
                        style={{ paddingTop: 8 }}
                      >
                        {k + 1})
                      </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={16} key={k}>
                        {v.map((field) => (
                          <Grid
                            item
                            xs={12}
                            sm={field.sm || 6}
                            sm={field.md || 6}
                            key={field.name}
                          >
                            {/* {field.showInfo && <CusTooltip title={field.helpContent} />} */}
                            {field.name !== 'visaNumber' &&
                              field.name !== 'identNumber' && (
                                <FastField {...field} />
                              )}
                            {field.name === 'visaNumber' &&
                              visaCounttryList.includes(values.nationality) && (
                                <FastField {...field} />
                              )}
                            {field.name === 'identNumber' &&
                              !travelDocumentNumberCondition(
                                values.nationality,
                                values.birthDate,
                              ) && <FastField {...field} />}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}

                <Grid container spacing={0} style={{ marginTop: 30 }}>
                  <Grid item xs={1}>
                    <Typography
                      variant="caption"
                      color="primary"
                      style={{ paddingTop: 8 }}
                    >
                      {formFields.length + 1})
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    {signature && (
                      <StyledSignatureImg>
                        <Typography>{t('Signature')}:</Typography>
                        <div className="gr-signature-icon">
                          <img
                            className="signature-preview"
                            src={signature}
                            height={80}
                            alt="signature"
                          />
                          <IconButton onClick={() => removeSignature()}>
                            <CloseIcon fontSize={'small'} />
                            <Typography
                              variant="caption"
                              style={{ paddingLeft: 5 }}
                            >
                              {t('Remove signature')}
                            </Typography>
                          </IconButton>
                        </div>
                      </StyledSignatureImg>
                    )}
                    {defaultSignature && (
                      <StyledSignatureImg>
                        <Typography>{t('Signature')}:</Typography>
                        <div className="gr-signature-icon">
                          <img
                            className="signature-preview"
                            src={URL.createObjectURL(defaultSignature)}
                            height={80}
                            alt="signature"
                          />

                          <IconButton onClick={() => removeSignature()}>
                            <CloseIcon fontSize={'small'} />
                            <Typography
                              variant="caption"
                              style={{ paddingLeft: 5 }}
                            >
                              {t('Remove signature')}
                            </Typography>
                          </IconButton>
                        </div>
                      </StyledSignatureImg>
                    )}
                    {signature || defaultSignature ? null : (
                      <Button
                        color="primary"
                        style={{
                          marginTop: 20,
                          ...(requiredSignature && {
                            borderColor: '#f44336',
                            color: '#f44336',
                          }),
                        }}
                        onClick={() => {
                          setOpenSignature(true);
                          document.getElementById(
                            'globalDrawerId',
                          ).scrollTop = 10;
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        {t('Please add your Signature')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                {requiredSignature && (
                  <FormHelperText error style={{ textAlign: 'center' }}>
                    {t('Required')}
                  </FormHelperText>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px 0',
                  }}
                >
                  <Button
                    style={{ width: 120 }}
                    variant={'contained'}
                    color={'primary'}
                    type="submit"
                    disabled={(!dirty && !signature) || apiCalling.loading}
                  >
                    {dataId ? t('Update') : t('Save')}
                    {apiCalling.loading && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                </div>
                {apiCalling.isDone && (
                  <Typography
                    variant="caption"
                    align="center"
                    style={{ color: green[500], marginTop: 30 }}
                  >
                    {dataId ? t('Updated') : t('Saved')} {t('successfully')}!
                  </Typography>
                )}

                {/* Send to police */}
                {/* <Grid container justify="center">
                  <Grid item xs={12} sm={6} md={12} lg={12} style={{ textAlign: 'center', padding: '30px 0' }}>
                    <Divider />
                    <Typography style={{paddingTop: 15}}>
                      Control the check-in forms
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checked={confirmed}
                          onChange={event => setConfirmed(event.target.checked)}
                        />
                      }
                      label="I've checked all the data and can confirm they are valid"
                    />
                    <Button
                      color="secondary"
                      disabled={!confirmed || !allCheckInFormValid}
                      variant="contained"
                      size="small"
                      style={{ margin: '0 auto', display: 'block' }}
                      onClick={() => setSentSuccessful(true)}
                      onClick={sendToPolice}
                    >
                      Send to police
                    </Button>
                    {sentSuccessful && (
                      <Typography variant={'subtitle1'} >
                        Send successfully!
                      </Typography>
                    )}
                  </Grid>
                </Grid> */}
              </Form>
            );
          }}
        />
      </FormWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reservationDetail: selectReservationDetail(state).data,
  checkinFormDetail: selectCheckinFormDetail(state).data,
  countriesList: selecCountriesList(state),
  visaCounttryList: selectVisaCountryList(state),
});

export default connect(mapStateToProps, {
  updateCheckInform,
  checkInform,
  getReservationDetail,
  getCheckInform,
  getSignatureUrl,
  removeDefailtSignature,
})(CheckInForm);
