/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { Typography, Grid, Paper, Hidden, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles'
import { PauseCircleOutline } from '@mui/icons-material';
import {
  getNotificationsSettings,
  updateNotificationSettings,
  deleteNotificationsContacts,
} from '../../../actions/reminders';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { globalStyles } from '../../../styles/styles';
import MemberList from '../components/MemberList';
import MemberIcon from '../../../styles/title-icon/Members.png';
import RemindersIcon from '../../../styles/title-icon/Reminder.png';
import DialogConfirm from '../../../common/DialogConfirm';
import PlayButton from '../../../common/PlayButton';
import { OPEN_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import GlobalDrawer from '../../../components/GlobalDrawer';
import RemindersForm from '../components/RemindersForm';
import { GlobalDrawerContext } from '../../../contexts';
import SwitchesCustom from '../../../common/SwitchesCustom';
import CusTooltip from '../../../components/CusTooltip';
import { omit } from 'lodash';

export const remindersTypeName = {
  localFees: 'Local fees payment',
  checkinFromsMissing: 'Missing check-in form',
  checkinFromsToSend: 'Close reservation ( & send to Ubyport )',
}

const validation = Yup.object().shape({
  welcomerName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phoneNumber: Yup.string().required('Required'),
});

const Reminders = ({
  classes,
  getNotificationsSettings,
  reminderSettings,
  updateNotificationSettings,
  deleteNotificationsContacts,
}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(GlobalDrawerContext);

  useEffect(() => {
    getNotificationsSettings();
  }, []);

  let getSettings = reminderSettings.data && reminderSettings.data.settings;

  const [remindersList, setRemindersList] = useState([
    {
      name: 'localFees',
      label: remindersTypeName.localFees,
      placeholder: 'Send 3 days before deadline',
      infoIcon: `${t("You will receive the reminder every 12th day of a month")}, ${t("because the deadline according to the law is 15th of every month for Spa of recreation stay fee and every three months for Accommodation capacity fee")}.`,
      component: SwitchesCustom,
      loading: false,
      labelPosition: 'right',
    },
    {
      name: 'checkinFromsMissing',
      label: remindersTypeName.checkinFromsMissing,
      placeholder: 'Send a day before check-in if a form is missing',
      component: SwitchesCustom,
      loading: false,
      labelPosition: 'right',
    },
    {
      name: 'checkinFromsToSend',
      label: remindersTypeName.checkinFromsToSend,
      placeholder: 'Send as soon as all guests filled their checkin forms',
      component: SwitchesCustom,
      loading: false,
      labelPosition: 'right',
    },
  ]);

  const [openDeleteMember, setOpenDeleteMember] = useState({
    isOpen: false,
    deleteData: null,
    isLoadding: false,
  });

  let handleDeleteMember = (e) => {
    setOpenDeleteMember({ ...openDeleteMember, isLoadding: true });
    if (openDeleteMember.deleteData && openDeleteMember.deleteData.contactId) {
      let params = {
        contactId: openDeleteMember.deleteData.contactId,
      }
      deleteNotificationsContacts(params).then(response => {
        if (response.data) {
          setOpenDeleteMember({ isOpen: false, isLoadding: false, deleteData: null });
          getNotificationsSettings();
        }
      }).catch(error => {
        setOpenDeleteMember({ isLoadding: false });
      });
    }
  }

  let handleChangeSwitch = (e, v, k, formProps) => {
    let _newData = remindersList, _newValue = e.target.checked;
    _newData[k].loading = true;
    setRemindersList(_newData);

    const params = {
      settings: [
        {
          type: v.name,
          notificationOn: _newValue,
        }
      ]
    };

    formProps.setFieldValue(v.name, _newValue);
    updateNotificationSettings(params).then(response => {
      _newData[k].loading = false;
      if (response.data) {
        _newData[k].value = _newValue;
        setRemindersList(_newData);
      }
    }).catch(error => {
      _newData[k].loading = false;
      _newData[k].value = !_newValue;
      setRemindersList(_newData);
      formProps.setFieldValue(v.name, !_newValue);
    });

  }

  let initialValues = {
    localFees: false,
    checkinFromsMissing: false,
    checkinFromsToSend: false,
  };

  getSettings && Object.keys(getSettings).map(v => {
    initialValues[v] = getSettings[v] && getSettings[v].notificationOn === 1;
  });

  let renderReminders = (v, k, formProps, isMobile = false) => {
    return (
      <Grid item xs={12} key={v.name} className={!isMobile ? 'RemindersForm__item' : ''}>

        <Typography variant="subtitle1" component="div" className={classes.heading} style={{ display: 'flex', alignItems: 'center' }} >
          {v.loading && <CircularProgress size={20} style={{ marginLeft: 15 }} />}
          <FastField {...omit(v, ['infoIcon'])} onChange={(e) => { handleChangeSwitch(e, v, k, formProps) }} />
        </Typography>

        <Typography variant="body2" component="div" className={'RemindersForm__item__placeholder'} style={{display: 'flex', placeItems: 'center'}}>
          {t(v.placeholder)}
          { v.infoIcon && <CusTooltip title={v.infoIcon}/> }
        </Typography>

        <Typography variant="caption" component="p" className={'RemindersForm__item__caption'}>
          {
            !!formProps.values[v.name] ?
              <span>
                <span><PlayButton color="secondary" /></span>
                {t('Message is active and being sent to guests')}.
              </span> :
              <span><PauseCircleOutline style={{ marginRight: 10 }} color="inherit" /> {t('Message is not being sent to guests')}.</span>
          }
        </Typography>

      </Grid>
    )
  }

  let renderMembers = () => {
    return <MemberList
      datas={reminderSettings.data && reminderSettings.data.memberSetting}
      setOpenDeleteMember={data => setOpenDeleteMember({ ...openDeleteMember, isOpen: true, deleteData: data })}
      openUpdateMember={(data) => dispatch({ type: OPEN_DRAWER, drawerName: 'update_member', data })}
    />
  }

  let renderMembersTitle = () => {
    return (
      <TitleHasIcon variant="h5" title={t('Members')}
        label={t('Organize your team by automated reminders')} icon={MemberIcon} isImage
        actionButton={t('Add member')}
        handleAction={() => dispatch({ type: OPEN_DRAWER, drawerName: 'create_member' })}
      />
    )
  }

  let renderRemindersTitle = () => {
    return (
      <TitleHasIcon variant="h5" title={t('Reminders')}
        label={t('The reminder will be sent to the member that manages the property where something needs to be done')} icon={RemindersIcon} isImage
      />
    )
  }

  return (
    <div>
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <Paper className={classes.root} >
          {renderMembersTitle()}
          <div>{renderMembers()}</div>
        </Paper>
        <Paper className={classes.root} >
          {renderRemindersTitle()}

          <Grid container spacing={0} direction="row" className={classes.RemindersForm}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validation}
              render={({ dirty, ...formProps }) => {
                return (
                  <Form style={{ width: '100%' }}>
                    {getSettings && remindersList.map((v, k) => renderReminders(v, k, formProps))}
                  </Form>
                );
              }}
            />
          </Grid>
        </Paper>
      </Hidden>


      {/* Mobile */}
      <Hidden smUp implementation="css">
        <div>
          {renderMembersTitle()}
          {renderMembers()}
        </div>
        <div>
          {renderRemindersTitle()}
          <Grid container spacing={0} direction="row" className={classes.RemindersForm}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validation}
              render={({ dirty, ...formProps }) => {
                return (
                  <Form style={{ width: '100%' }}>
                    {
                      getSettings && remindersList.map((v, k) => (
                        <Paper className={classes.root} key={k}>{renderReminders(v, k, formProps, true)}</Paper>
                      ))
                    }
                  </Form>
                );
              }}
            />
          </Grid>
        </div>
      </Hidden>

      {/* open Delete member */}
      <DialogConfirm isOpen={openDeleteMember.isOpen}
        title={t('Deletion')} message={`${t('Are you sure that you want to delete this member')}?`}
        handleClose={() => setOpenDeleteMember({ ...openDeleteMember, isOpen: false, deleteData: null })}
        handleAccess={handleDeleteMember}
        isLoadding={openDeleteMember.isLoadding}
      />

      <GlobalDrawer isOpen={state.isOpen && state.drawerName === 'create_member' || state.drawerName === 'update_member'}>
        <RemindersForm defaultData={state.data || null} isUpdating={state.drawerName === 'update_member'} />
      </GlobalDrawer>

    </div>
  );
};

const mapStateToProps = state => ({
  reminderSettings: state.reminders.settings,
});

export default connect(
  mapStateToProps,
  {
    getNotificationsSettings,
    updateNotificationSettings,
    deleteNotificationsContacts,
  }
)(withStyles(globalStyles)(Reminders));
