import React, { PureComponent } from 'react';
import {
  Typography,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Hidden,
} from '@mui/material';
import styled from 'styled-components';
import GlobalDrawer from '../../../components/GlobalDrawer';
import YourClientForm from './YourClientForm';
import {
  initialClientValue,
  updatedClientValidationSchema,
} from '../components/initialAdminForm';
import { connect } from 'react-redux';
import {
  getResidentialClient,
  createResidentialClient,
  updateResidentialClient,
} from '../../../actions/account';
import { openDrawer, closeDrawer, deleteResidentialClient } from '../actions';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';
import VirtualTableCustom from '../../../components/VirtualTableCustom';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirm from '../../../common/DialogConfirm';
import { withTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import { isArray, isObject } from 'lodash';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import Signature from '../../../components/Signature';

const generateWidth = (screen) => {
  switch (screen) {
    case 'lg':
      return 800;
    case 'md':
      return 600;
    case 'sm':
      return '100%';
    case 'xs':
      return '100%';
    default:
      return 800;
  }
};
const refreshTimeOut = 4000;

export const InlineItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
`;

class YourClient extends PureComponent {
  state = {
    loading: false,
    deleteLoading: false,
    deleteClient: {
      isOpen: false,
      residentialClientId: null,
      isLoadding: false,
    },
    updateErrorMsg: null,
    signature: null,
    openSignature: false,
    requiredSignature: false,
  };

  componentDidMount() {
    this.setState({ updateErrorMsg: null });
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.residentialClients &&
      nextProps.residentialClients.updateError &&
      nextProps.residentialClients.updateError.msg
    ) {
      this.setState({
        updateErrorMsg: nextProps.residentialClients.updateError.msg,
        loading: false,
      });
    } else {
      this.setState({ updateErrorMsg: null });
    }
  }

  handleCreate = (values, { setErrors }) => {
    this.setState({ loading: true });
    values.signature = this.state.signature;
    values.headers = { 'Content-Type': 'multipart/form-data' };
    this.props.createResidentialClient(values).then((res) => {
      if (res.data && res.result) {
        this.props.getResidentialClient();
        this.props.closeDrawer();
      } else {
        res.errors.length > 0 &&
          res.errors[0].note &&
          setErrors(res.errors[0].note);
      }
      this.setState({ loading: false });
    });
  };

  handleUpdate = (values, { setErrors }) => {
    this.setState({ loading: true });
    values.signature = this.state.signature;
    values.headers = { 'Content-Type': 'multipart/form-data' };
    this.props.updateResidentialClient(values).then((res) => {
      if (res.data && res.result) {
        this.props.getResidentialClient();
        this.props.closeDrawer();
      } else {
        res.errors.length > 0 &&
          res.errors[0].note &&
          setErrors(res.errors[0].note);
      }
      this.setState({ loading: false });
    });
  };

  handleDeleteResidentClient = (residentialClientId) => () => {
    this.setState({
      deleteLoading: true,
      deleteClient: { ...this.state.deleteClient, isLoadding: true },
    });
    this.props.deleteResidentialClient({ residentialClientId });
    setTimeout(() => {
      this.props.getResidentialClient();
      this.setState({
        deleteLoading: false,
        deleteClient: {
          ...this.state.deleteClient,
          isOpen: false,
          isLoadding: false,
          residentialClientId: null,
        },
      });
      // window.location.reload();
    }, refreshTimeOut);
  };

  openAddingNewClient = () => {
    this.props.openDrawer({
      isUpdating: false,
      residentClient: initialClientValue,
    });
  };

  closeDrawerForm = () => {
    this.props.closeDrawer();
  };

  openEditForm = (residentClient) => () => {
    this.props.openDrawer({
      isUpdating: true,
      residentClient: residentClient,
    });
  };

  columns = () => {
    const { t } = this.props;
    return [
      { name: 'clientName', title: t('Client name') },
      {
        name: 'detail',
        title: t('Detail'),
        getCellValue: (row) => (
          <IconButton onClick={this.openEditForm(row)}>
            <EditIcon fontSize="inherit" style={{ color: '#2196f3' }} />
            <Typography style={{ paddingLeft: 4, color: '#2196f3' }}>
              {t('Edit')}
            </Typography>
          </IconButton>
        ),
      },
      {
        name: 'delete',
        title: t('Delete'),
        getCellValue: (row) => (
          <ButtonWithTooltip
            show={row.hasProperty}
            title={t(
              `You can't delete this client because there exists some property connected with this client`,
            )}
          >
            <IconButton
              disabled={row.hasProperty}
              onClick={() =>
                this.setState({
                  deleteClient: {
                    ...this.state.deleteClient,
                    isOpen: true,
                    residentialClientId: row.residentialClientId,
                  },
                })
              }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ButtonWithTooltip>
        ),
      },
    ];
  };

  render() {
    const { width, residentClientsState, drawerState, t, clientInfo } =
      this.props;
    const residentClientList = isArray(residentClientsState.data)
      ? residentClientsState.data
      : isObject(residentClientsState.data)
      ? [residentClientsState.data]
      : [];
    // const fetchingClientListLoading = residentClientsState.loading;
    const { open, residentClient, isUpdating } = drawerState;
    const { signature, openSignature } = this.state;

    return (
      <div>
        <Hidden smUp implementation="css">
          <TitleHasIcon
            variant="h5"
            title={t('Clients')}
            label={t('Add clients whom you manage their properties')}
            icon={'group'}
            actionButton={t('Add client')}
            handleAction={() => this.openAddingNewClient()}
          />
          {residentClientList &&
            residentClientList.length > 0 &&
            residentClientList.map((v, k) => (
              <Paper
                style={{
                  marginTop: 15,
                  paddingTop: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                key={k}
              >
                <div
                  style={{
                    marginLeft: 15,
                    fontWeight: 'bold',
                    flex: '0 0 120px',
                  }}
                >
                  {v.clientName}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={this.openEditForm(v)}>
                    <EditIcon fontSize="inherit" style={{ color: '#2196f3' }} />
                    <Typography style={{ paddingLeft: 4, color: '#2196f3' }}>
                      {t('Edit')}
                    </Typography>
                  </IconButton>
                  <ButtonWithTooltip
                    show={v.hasProperty}
                    title={t(
                      `You can't delete this client because there exists some property connected with this client`,
                    )}
                  >
                    <IconButton
                      disabled={v.hasProperty}
                      onClick={() =>
                        this.setState({
                          deleteClient: {
                            ...this.state.deleteClient,
                            isOpen: true,
                            residentialClientId: v.residentialClientId,
                          },
                        })
                      }
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ButtonWithTooltip>
                </div>
              </Paper>
            ))}
        </Hidden>
        <Hidden xsDown implementation="css">
          <Paper style={{ padding: 20 }}>
            <TitleHasIcon
              variant="h5"
              title={t('Clients')}
              label={t('Add clients whom you manage their properties')}
              icon={'group'}
              actionButton={t('Add client')}
              handleAction={() => this.openAddingNewClient()}
            />
            {residentClientList && residentClientList.length > 0 && (
              <Grid rows={residentClientList} columns={this.columns()}>
                <Table />
                <VirtualTableCustom />
              </Grid>
            )}
          </Paper>
        </Hidden>
        <GlobalDrawer isOpen={open}>
          <div style={{ width: generateWidth(width) }}>
            <YourClientForm
              isUpdating={isUpdating}
              handleCreate={this.handleCreate}
              handleUpdate={this.handleUpdate}
              handleDeleteResidentClient={this.handleDeleteResidentClient}
              onClose={this.closeDrawerForm}
              initialValues={residentClient}
              validationSchema={updatedClientValidationSchema}
              loading={this.state.loading}
              deleteLoading={this.state.deleteLoading}
              updateErrorMsg={this.state.updateErrorMsg}
              clientInfo={clientInfo.data}
              openSignature={() => this.setState({ openSignature: true })}
              removeSignature={() =>
                this.setState({ signature: null, requiredSignature: true })
              }
              requiredSignature={this.state.requiredSignature}
              signature={this.state.signature}
            />
          </div>
        </GlobalDrawer>
        {/* open Delete Guest */}
        <DialogConfirm
          isOpen={this.state.deleteClient.isOpen}
          title={t('Deletion')}
          message={t('Are you sure that you want to delete this client?')}
          handleClose={() =>
            this.setState({
              deleteClient: {
                ...this.state.deleteClient,
                isOpen: false,
                residentialClientId: null,
              },
            })
          }
          handleAccess={
            this.state.deleteClient.residentialClientId &&
            this.handleDeleteResidentClient(
              this.state.deleteClient.residentialClientId,
            )
          }
          isLoadding={this.state.deleteClient.isLoadding}
        />

        {openSignature && (
          <Dialog
            open={openSignature}
            maxWidth="md"
            fullWidth={true}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {t('Signature')}
                <div align="right">
                  <IconButton
                    aria-label="Close"
                    onClick={() => this.setState({ openSignature: false })}
                  >
                    <Close />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <Signature
                currentSignature={signature}
                onClose={() => this.setState({ openSignature: false })}
                getSignature={(sign) => {
                  this.setState({
                    openSignature: false,
                    signature: sign,
                    requiredSignature: false,
                  });
                }}
              />
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  drawerState: state.settings.drawer,
  residentialClients: state.client.residentialClients,
  clientInfo: state.client.clientInfo,
});

export default withTranslation()(
  connect(mapStateToProps, {
    openDrawer,
    closeDrawer,
    createResidentialClient,
    deleteResidentialClient,
    updateResidentialClient,
    getResidentialClient,
  })(YourClient),
);
