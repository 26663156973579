/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import {
  Hidden,
  Icon,
  Button,
  IconButton,
  Grid,
  Typography,
  Avatar,
} from '@mui/material';
import AddBox from '@mui/icons-material/AddBox';
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import ButtonWithTooltip from '../components/ButtonWithTooltip';

const styles = (theme) => ({
  root: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      margin: '20px 0',
    },
  },
  Avatar: {
    width: 50,
    height: 50,
    background: theme.palette.primary.mainGradient,
    borderRadius: 4,
    marginRight: 15,
  },
  Icon: {
    fontSize: '150%',
  },
  IconCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '9px',
  },
  Image: {
    width: 26,
  },
  ListItem: {
    padding: 0,
  },
  IconButton: {
    border: `1px solid ${grey[50]}`,
    color: theme.palette.primary.light,
    backgroundColor: grey[50],
    borderRadius: 4,
    padding: 5,
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  label: {
    color: grey[600],
  },
});

const TitleHasIcon = ({
  title,
  label,
  icon,
  variant = 'h5',
  isText,
  classes,
  isImage,
  disabled,
  disabledTitle,
  handleAction,
  actionButton,
  handleAction1,
  actionButton1,
}) => {
  const textIcon = isText ? classes.IconCenter : '';
  return (
    <Grid container spacing={0} className={classes.root} alignItems="center">
      <Grid item xs={10}>
        <div className={classes.centerContent}>
          <Avatar className={classes.Avatar}>
            {isImage ? (
              <img src={icon} className={classes.Image} />
            ) : (
              <Icon className={[classes.Icon, textIcon].join(' ')}>{icon}</Icon>
            )}
          </Avatar>
          <div>
            <Typography variant={variant} color="primary">
              {title}
            </Typography>
            <Typography variant={'body2'} className={classes.label}>
              {label}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={2} align="right">
        {handleAction && actionButton && (
          <div>
            {/* Desktop */}
            <Hidden xsDown implementation="css">
              <ButtonWithTooltip show={disabled} title={disabledTitle}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAction}
                  disabled={disabled}
                >
                  {actionButton}
                </Button>
              </ButtonWithTooltip>
            </Hidden>
            {/* Mobile */}
            <Hidden smUp implementation="css" className={classes.smUp}>
              <ButtonWithTooltip show={disabled} title={disabledTitle}>
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAction}
                  className={classes.IconButton}
                  disabled={disabled}
                >
                  <AddBox fontSize="large" />
                </IconButton>
              </ButtonWithTooltip>
            </Hidden>
          </div>
        )}
        {handleAction1 && actionButton1 && (
          <div>
            {/* Desktop */}
            <Hidden xsDown implementation="css">
              <ButtonWithTooltip show={disabled} title={disabledTitle}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAction1}
                  disabled={disabled}
                >
                  {actionButton1}
                </Button>
              </ButtonWithTooltip>
            </Hidden>
            {/* Mobile */}
            <Hidden smUp implementation="css" className={classes.smUp}>
              <ButtonWithTooltip show={disabled} title={disabledTitle}>
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAction1}
                  className={classes.IconButton}
                  disabled={disabled}
                >
                  <AddBox fontSize="large" />
                </IconButton>
              </ButtonWithTooltip>
            </Hidden>
          </div>
        )}
      </Grid>
    </Grid>

    // <List className={classes.root}>
    //   <ListItem className={classes.ListItem}>
    //     <Avatar className={classes.Avatar}>
    //       {isImage ? <img src={icon} className={classes.Image} /> : <Icon className={classes.Icon}>{icon}</Icon>}
    //     </Avatar>
    //     <ListItemText primary={primary} secondary={label || null} />
    //     {
    //       handleAction && actionButton && (
    //         <ListItemSecondaryAction>
    //           {/* Desktop */}
    //           <Hidden xsDown implementation="css">
    //             <Button variant="outlined" color="primary" onClick={handleAction} >{actionButton}</Button>
    //           </Hidden>
    //           {/* Mobile */}
    //           <Hidden smUp implementation="css" className={classes.smUp}>
    //             <IconButton variant="outlined" color="primary" onClick={handleAction} className={classes.IconButton}><AddBox fontSize="large" /></IconButton>
    //           </Hidden>
    //         </ListItemSecondaryAction>
    //       )
    //     }
    //   </ListItem>
    // </List>
  );
};

export default withStyles(styles)(TitleHasIcon);
