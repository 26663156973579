import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCountries } from '../features/reservation/actions';
import { setTitleGlobal } from '../actions/global';
import { loginRedirect } from '../helpers';

class PrivateRoute extends React.Component {
  componentDidMount() {
    const { label, title } = this.props;
    console.log('newprops', this.props);
    this.props.setTitleGlobal({ label: label, title: title });
  }

  render() {
    let { component, account, location } = this.props;
    const isAuthenticated = account.detail.tokens.authToken;
    const clientInfoFilled = account.detail.clientInfoFilled;
    const isOnboarding =
      account.detail.completedStep >= 1 && account.detail.completedStep < 11;
    const Component = component;

    return isAuthenticated ? (
      isOnboarding ? (
        <Navigate noThrow to="/onboarding" />
      ) : (
        clientInfoFilled && <Component {...this.props} />
      )
    ) : (
      <Navigate
        noThrow
        to={loginRedirect(
          location && location.pathname,
          location && location.search,
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  countriesList: state.countries.data,
});

export default connect(mapStateToProps, { getCountries, setTitleGlobal })(
  PrivateRoute,
);
