import React, { useState, useContext, useEffect } from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { getWelcomers, deleteWelcomer } from '../../../actions/member';
import MemberForm from '../../members/components/MemberForm';
import MembersList from '../../members/components/MembersList';

import { GlobalDrawerContext } from '../../../contexts';
import { OPEN_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import GlobalDrawer from '../../../components/GlobalDrawer';
import DialogConfirm from '../../../common/DialogConfirm';

const cusStyles = {
  welcomeHeader: {
    borderBottom: '3px solid #2196f3',
    paddingTop: 4,
    marginBottom: 30,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonFinish: {
    textAlign: 'center',
    marginTop: 50,
  },
};

const WELCOMER_ROLE = 'Welcomer';

const Welcomers = ({
  handleSubmit,
  handleBack,
  getWelcomers,
  deleteWelcomer,
  members,
}) => {
  useEffect(() => {
    getWelcomers();
  }, []);

  const { t } = useTranslation();
  const { state, dispatch } = useContext(GlobalDrawerContext);

  const onSubmit = async (values) => {
    return handleSubmit(values);
  };

  const [openDeleteMember, setOpenDeleteMember] = useState({
    open: false,
    data: null,
    isLoadding: false,
  }); // open delete Reservation

  const deleteMember = () => {
    const data = openDeleteMember.data;
    if (data && data.canDelete && data.id) {
      setOpenDeleteMember({ ...openDeleteMember, isLoadding: true });
      deleteWelcomer({ id: data.id }).then(() => {
        setOpenDeleteMember({ isOpen: false, data: null, isLoadding: false });
        getWelcomers();
      });
    }
  };

  const openDeleteMemberConfirm = (open, item) => {
    setOpenDeleteMember({
      open: open,
      data: item,
    });
  };

  let continueCheck =
    members.data.length > 0 &&
    members.data.find((v) => v.roleName === WELCOMER_ROLE);

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <div style={cusStyles.welcomeHeader}>
            <Typography variant={'h4'}>{t(`Welcomers`)}</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                dispatch({ type: OPEN_DRAWER, drawerName: 'add_new_member' })
              }
            >
              {t('Add welcomer')}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            render={({ dirty, values }) => {
              return (
                <Form>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ textAlign: 'center' }}
                  >
                    <Typography variant="subtitle1">
                      {t(
                        `Create welcomers that help you with welcoming and communicating with your guests`,
                      )}
                      .
                      {t(
                        `The welcomer appears in the message for the guest to fill the check-in form`,
                      )}
                      .
                    </Typography>
                    <div style={{ paddingTop: 30, width: '100%' }}>
                      <MembersList
                        isWelcome={true}
                        members={members.data}
                        loading={members.loading}
                        openUpdateMember={(data) =>
                          dispatch({
                            type: OPEN_DRAWER,
                            drawerName: 'update_member',
                            data,
                          })
                        }
                        openDeleteConfirm={openDeleteMemberConfirm}
                      />
                    </div>
                  </Grid>

                  <Typography
                    variant="body1"
                    color="error"
                    align="center"
                    style={{ marginTop: 30 }}
                  >
                    {!continueCheck &&
                      t(
                        `Please  add at least one Member with role Welcomer before continuing`,
                      )}
                  </Typography>

                  <div style={cusStyles.buttonFinish}>
                    <Button
                      type="button"
                      variant="contained"
                      color="default"
                      onClick={handleBack}
                      style={{ marginRight: 15 }}
                    >
                      {t(`Back`)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!continueCheck}
                    >
                      {t(`Continue`)}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>

      <GlobalDrawer isOpen={state.isOpen}>
        {(state.drawerName === 'add_new_member' ||
          state.drawerName === 'update_member') && (
          <MemberForm
            defaultData={state.data || null}
            isOnboardingProcess={true}
            isUpdating={state.drawerName === 'update_member'}
          />
        )}
      </GlobalDrawer>

      {/* open Delete Member */}
      {openDeleteMember.open && (
        <DialogConfirm
          isOpen={openDeleteMember.open}
          title={t('Deletion')}
          message={t('Are you sure that you want to delete this member?')}
          handleClose={() => openDeleteMemberConfirm(false, null)}
          handleAccess={deleteMember}
          isLoadding={openDeleteMember.isLoadding}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  members: state.member.memberList,
});

const mapActionToProps = {
  getWelcomers,
  deleteWelcomer,
};

export default connect(mapStateToProps, mapActionToProps)(Welcomers);
