import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import qs from 'qs';
import { of } from 'rxjs';
import { requestFailure } from '../redux/handleError';
import { isEmpty } from 'lodash';
import { requireValidToken } from './refreshToken';
import { getDataAfterSend } from '../custom-hooks/apiHook';

const BASE_URL = process.env.REACT_APP_BEST_GUEST_API_BASE_URL || '';

export default (actionType, requestMethod, url, successAction$) =>
  (action$, state$) =>
    action$.pipe(
      ofType(actionType),
      withLatestFrom(state$),
      mergeMap(([action, state]) => {
        return requireValidToken(
          action$,
          state$,
          state.account.detail.tokens,
          (newToken) => {
            let _getNewData = getDataAfterSend(
              requestMethod,
              url,
              action.params,
              newToken,
              false,
              true,
            );
            return ajax({
              method: _getNewData.method,
              url: _getNewData.url,
              body: _getNewData.data,
              headers: _getNewData.headers,
            }).pipe(
              successAction$,
              catchError((error) => {
                return of(
                  {
                    type: `${actionType}_failure`,
                    res: error.response.errors,
                  },
                  requestFailure(error.xhr.response.errors, error.status),
                );
              }),
            );
          },
        );
      }),
    );

export const getJSONEpic = (actionType, url, actions$) => (action$, state$) =>
  action$.pipe(
    ofType(actionType),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      return requireValidToken(
        action$,
        state$,
        state.account.detail.tokens,
        (newToken) => {
          const finalUrl = isEmpty(action.params)
            ? `${BASE_URL}${url}`
            : `${BASE_URL}${url}?${qs.stringify(action.params)}`;
          return ajax
            .getJSON(finalUrl, {
              Authorization: `${newToken}`,
            })
            .pipe(
              actions$,
              catchError((error) => {
                // return of(
                //   requestFailure(error.xhr.response.errors, error.status)
                // );
              }),
            );
        },
      );
    }),
  );
