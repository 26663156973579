import produce from 'immer';
import createReducer from '../../../redux/createReducer';
import { EXPORT_GUEST_BOOK, EXPORT_GUEST_BOOK_SUCCESS } from '../actionTypes';
import { THROW_ERROR } from '../../../redux/handleError';

const initialState = {
  isFetched: false,
  loading: false,
  error: '',
};
export default createReducer(initialState, {
  [EXPORT_GUEST_BOOK]: (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  [EXPORT_GUEST_BOOK_SUCCESS]: (state, action) =>
    produce(state, (draft) => {
      draft.isFetched = !!action.res;
      draft.loading = false;
    }),
  [THROW_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
});
