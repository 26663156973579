import React from 'react';
import { Typography } from '@mui/material';
import GoogleInput from '../../../common/GoogleInput';
import MerkCompanyPicker from '../../../common/MerkCompanyPicker';
import FileUpload from '../../../common/FileUpload';

export const companyDetailFieldsReadOnly = (t) => {
  return [
    {
      name: 'businessId',
      label: 'Business ID',
      placeholder: 'Business ID',
      key: 'businessId',
      disabled: true,
      showTooltip: true,
      title: `${t(
        'A unique eight-digit identification number of a legal entity',
      )}, ${t('a business entity or an organisation of the state')}. ${t(
        'In Czech it is called',
      )} „IČO“.`,
      md: 12,
      lg: 12,
    },
    {
      name: 'vatId',
      label: 'VAT ID',
      placeholder: 'VAT ID',
      key: 'vatId',
      disabled: true,
      showTooltip: true,
      title: `${t('Value added tax identification number')} = ${t(
        'an unambiguous identification of the tax entity',
      )}. ${t(
        'Each tax entity has its tax identification number assigned to it by the tax administrator after the tax entity has registered',
      )}. ${t('In Czech it is called')} „DIČ“.`,
      md: 12,
      lg: 12,
    },
    {
      name: 'invoiceAddress',
      label: 'Residential address',
      placeholder: 'Residential address',
      key: 'invoiceAddress',
      disabled: true,
      showTooltip: true,
      title: `${t(
        'Residential address is the official address that is registered to your Business ID',
      )}.`,
      md: 12,
      lg: 12,
    },
  ];
};
export const companyDetailFields = [
  {
    name: 'clientName',
    label: 'Company name',
    placeholder: 'Company name',
    key: 'company_name',
    component: MerkCompanyPicker,
    isTextMask: false,
    md: 12,
    lg: 12,
    showErrorMsg: true,
  },
];

export const passportFields = [
  {
    name: 'passport',
    label: 'Passport Upload',
    placeholder: 'Passport Upload',
    maxSize: 1540000,
    accept: 'image/jpeg',
    component: FileUpload,
    showReview: true,
    key: 'passport',
    md: 12,
    lg: 12,
  },
];

export const companyFieldNoProperty = [...companyDetailFields];

export const ubyportDataFields = [
  // {
  //   name: 'ubyUsername',
  //   label: 'Login name UbyPort',
  //   key: 'ubyUsername',
  //   isTextMask: false,
  // },
  // {
  //   name: 'ubyPassword',
  //   label: 'Password to UbyPort',
  //   key: 'ubyPassword',
  //   isTextMask: false,
  // },
  // {
  //   name: 'ubyIDUB',
  //   label: 'UbyPort ID',
  //   key: 'ubyIDUB',
  //   isTextMask: false,
  // },
  // {
  //   name: 'ubyClientName',
  //   label: 'UbyPort Client Name',
  //   key: 'ubyClientName',
  //   isTextMask: false,
  // },
  // {
  //   name: 'ubyClientNameShort',
  //   label: 'UbyPort Client Name Short',
  //   key: 'ubyClientNameShort',
  //   isTextMask: false,
  // },
  // {
  //   name: 'ubyClientContact',
  //   label: 'Contact (email/phone)',
  //   key: 'ubyClientContact',
  //   isTextMask: false,
  // },
];

export const contactPersonFields = [
  {
    name: 'contactName',
    label: 'Full name',
    placeholder: 'Full name',
    key: 'contact_person_name',
  },
  {
    name: 'email',
    type: 'email',
    key: 'contact_person_email',
    label: 'Email address',
    placeholder: 'Email address',
  },
  {
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Phone number',
    key: 'contact_person_phone',
  },
];

export const bankAccountFields = [
  {
    name: 'bankAccount',
    label: 'Bank account',
    placeholder: 'Bank account',
    key: 'bankAccount',
    lg: 12,
    md: 12,
    isTextMask: false,
  },
];

export const personFields = [
  {
    name: 'contactName',
    label: 'Full name',
    placeholder: 'Full name',
    key: 'contact_person_name',
    isTextMask: false,
  },
  {
    name: 'businessId',
    label: 'Business ID',
    placeholder: 'Business ID',
    key: 'businessId',
    type: 'number',
    isTextMask: false,
  },
  {
    name: 'vatId',
    label: 'VAT ID',
    placeholder: 'VAT ID',
    key: 'vatId',
    isTextMask: true,
  },
  {
    name: 'email',
    type: 'email',
    key: 'contact_person_email',
    label: 'Email address',
    placeholder: 'Email address',
    isTextMask: false,
  },
  {
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Phone number',
    key: 'contact_person_phone',
    isTextMask: false,
  },
  {
    name: 'bankAccount',
    label: 'Bank account',
    placeholder: 'Bank account',
    key: 'bankAccount',
    isTextMask: false,
  },
  {
    name: 'invoiceAddress',
    label: 'Residential address',
    placeholder: 'Residential address',
    key: 'invoiceAddress',
    isTextMask: false,
  },
  // {
  //   name: 'postalAddress',
  //   label: 'Correspondence address',
  //   key: 'postalAddress',
  //   isTextMask: false,
  // },
];

const clientNameTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(
          'Client is customer of your property management company that helps its clients to manage their properties',
        )}
        .
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(' Clients are different accommodation providers')} =>{' '}
        {t(
          'the Business IDs can be different or the bank accounts to pay local fee(s)',
        )}
        .
      </Typography>
    </div>
  );
};

const businessIdTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('is a unique eight-digit identification number of a legal entity')},{' '}
        {t('a business entity or an organisation of the state')}.{' '}
        {t('In Czech it is called')} „IČO“.
      </Typography>
    </div>
  );
};

const binvoiceAddressTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('is the official address that is registered to your Business ID')}.
      </Typography>
    </div>
  );
};

// const postalAddressTooltip = (t) => {
//   return (
//     <div>
//       <Typography variant="body2" gutterBottom headlineMapping={{ body2: 'p' }} className="body">
//         {t('is the address where you spend most of your time and when the office sends you a letter you will receive it')}
//         </Typography>
//     </div>
//   );
// };

const bankAccountTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(
          'To generate the local fees reports for you we need to know your full bank account number that is registered to your registration to the local fees',
        )}
        .
      </Typography>
    </div>
  );
};

export const yourClientFields = (t) => [
  {
    name: 'clientName',
    label: 'Client name',
    placeholder: 'Client name',
    key: 'person_clientName',
    isTextMask: false,
    showHelp: true,
    helpContent: clientNameTooltip(t),
    component: MerkCompanyPicker,
    showErrorMsg: true,
  },
  {
    name: 'businessId',
    label: 'Business ID',
    placeholder: 'Business ID',
    key: 'businessId',
    // type: 'number',
    isTextMask: false,
    disabled: true,
    showHelp: true,
    helpContent: businessIdTooltip(t),
  },
  {
    name: 'vatId',
    label: 'VAT ID',
    placeholder: 'VAT ID',
    key: 'vatId',
    disabled: true,
  },
  {
    name: 'invoiceAddress',
    label: 'Residential address',
    placeholder: 'Residential address',
    key: 'invoiceAddress',
    // component: GoogleInput,
    isTextMask: false,
    disabled: true,
    showHelp: true,
    helpContent: binvoiceAddressTooltip(t),
    lg: 12,
    md: 12,
  },
  // {
  //   name: 'contactName',
  //   label: 'Contact full name',
  //   key: 'contactName',
  //   isTextMask: false,
  // },
  // {
  //   name: 'phoneNumber',
  //   label: 'Phone number',
  //   key: 'contact_person_phone',
  //   isTextMask: false,
  // },
  // {
  //   name: 'email',
  //   key: 'contact_person_email',
  //   label: 'Email address',
  //   isTextMask: false,
  // },
  // {
  //   name: 'bankAccount',
  //   label: 'Bank account',
  //   key: 'bankAccount',
  //   isTextMask: false,
  // },
  // {
  //   name: 'invoiceAddress',
  //   label: 'Residential address',
  //   key: 'invoiceAddress',
  //   isTextMask: false,
  // },
  // {
  //   name: 'postalAddress',
  //   label: 'Correspondence address',
  //   key: 'postalAddress',
  //   isTextMask: false,
  // },
  // {
  //   name: 'airbnbEmail',
  //   key: 'contact_person_airbnb_email',
  //   label: 'Airbnb Email',
  //   isTextMask: false,
  // },
  // {
  //   name: 'bookingEmail',
  //   key: 'contact_person_booking_email',
  //   label: 'Booking Email',
  //   isTextMask: false,
  // },
];

export const yourClientLocalFeesFields = (t) => [
  {
    name: 'contactName',
    label: 'Contact full name',
    placeholder: 'Contact full name',
    key: 'contactName',
    isTextMask: false,
  },
  {
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Phone number',
    key: 'contact_person_phone',
    isTextMask: false,
  },
  {
    name: 'email',
    type: 'email',
    key: 'contact_person_email',
    label: 'Email address',
    placeholder: 'Email address',
    isTextMask: false,
  },
  {
    name: 'bankAccount',
    label: 'Bank account',
    placeholder: 'Bank account',
    key: 'bankAccount',
    isTextMask: false,
    showHelp: true,
    helpContent: bankAccountTooltip(t),
  },
  // {
  //   name: 'airbnbEmail',
  //   key: 'contact_person_airbnb_email',
  //   label: 'Airbnb Email',
  //   placeholder: 'Airbnb Email',
  //   isTextMask: false,
  // },
  // {
  //   name: 'airbnbPassword',
  //   type: 'password',
  //   key: 'contact_person_airbnb_password',
  //   label: 'Airbnb Password',
  //   placeholder: 'Airbnb Password',
  //   isTextMask: false,
  // },
];

export const yourClientAddressFields = (t) => [
  {
    name: 'invoiceAddress',
    label: 'Residential address',
    placeholder: 'Residential address',
    key: 'invoiceAddress',
    component: GoogleInput,
    isTextMask: false,
    showHelp: true,
    helpContent: binvoiceAddressTooltip(t),
    lg: 12,
    md: 12,
  },
  // {
  //   name: 'postalAddress',
  //   label: 'Correspondence address',
  //   key: 'postalAddress',
  //   isTextMask: false,
  //   showHelp: true,
  //   helpContent: postalAddressTooltip()
  // },
];
