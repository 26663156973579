export const CREATE_RESERVATION = 'create_reservation';
export const CREATE_RESERVATION_SUCCESS = 'create_reservation_success';
export const CREATE_RESERVATION_FAILURE = 'create_reservation_failure';

export const GET_RESERVATIONS = 'get_reservations';
export const GET_RESERVATIONS_SUCCESS = 'get_reservations_success';
export const GET_RESERVATIONS_FAILURE = 'get_reservations_failure';

export const GET_RESERVATION_DETAIL = 'get_reservation_detail';
export const GET_RESERVATION_DETAIL_SUCCESS = 'get_reservation_detail_success';
export const GET_RESERVATIONS_DETAIL_FAILURE = 'get_reservation_detail_failure';

export const CREATE_CHECK_IN_FORM = 'create_check_in_form';
export const CREATE_CHECK_IN_FORM_SUCCESS = 'create_check_in_form_success';
export const CREATE_CHECK_IN_FORM_FAILURE = 'create_check_in_form_failure';
export const CANCEL_CHECKING_IN_FORM = 'cancel_checking_in_form';

export const GET_COUNTRIES = 'get_countries';
export const GET_COUNTRIES_SUCCESS = 'get_countries_success';
export const GET_COUNTRIES_FAILURE = 'get_countries_failure';
export const CANCEL_GETTING_COUNTRIES = 'cacel_getting_countries';
