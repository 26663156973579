import { createSelector } from 'reselect';
import { find } from 'lodash';

export const propertySelector = state => state.property;
const propertyByIdSelector = (state, props) =>
  find(
    state.property.propertiesList.data,
    prop => prop.accommodationId === parseInt(props.accommodationId),
  );

export const selectPropertyDetail = createSelector(
  propertySelector,
  property => property.propertyDetail,
);

export const selectProperties = createSelector(
  propertySelector,
  property => property.propertiesList,
);

export const selectPropertyById = createSelector(
  propertyByIdSelector,
  propertyById => propertyById,
);
