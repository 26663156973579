import React from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  title: {
    fontSize: '45px',
    fontWeight: '550',
  },
  container: {
    margin: '20px',
  },
  grid: {
    border: '2px solid lightgray',
  },
  username: {
    fontWeight: 600,
    margin: '0 24px',
    marginTop: '20px',
    minHeight: '21px',
  },
  listItem: {
    position: 'relative',
  },
  itemIcon: {
    borderRadius: '20px',
    minWidth: '24px',
    minHeight: '24px',
  },
  status: {
    marginLeft: '8px',
    marginTop: '2px',
  },
});

const ReferralProgress = ({ classes, ...props }) => {
  const normal = (number) => {
    return (
      <ListItemIcon
        style={{ backgroundColor: '#b3bcb1' }}
        className={classes.itemIcon}
      >
        <Typography className={classes.status}>{number}</Typography>
      </ListItemIcon>
    );
  };

  const pendding = (number) => {
    return (
      <ListItemIcon
        className={classes.itemIcon}
        style={{ backgroundColor: '#39c41d' }}
      >
        <Typography className={classes.status}>{number}</Typography>
      </ListItemIcon>
    );
  };

  const complete = () => {
    return (
      <ListItemIcon
        style={{ backgroundColor: '#39c41d', borderRadius: '20px' }}
      >
        <DoneIcon />
      </ListItemIcon>
    );
  };

  const cancel = () => {
    return (
      <ListItemIcon
        style={{ backgroundColor: '#ba82ba', borderRadius: '20px' }}
      >
        <ClearIcon />
      </ListItemIcon>
    );
  };

  const newHostRegistrationStatus = (status) => {
    if (status === 'PENDING') return pendding(1);
    else if (status === 'COMPLETE') return complete();
    else if (status === 'COMPLETE') return cancel();
    else return normal(1);
  };

  const rewardStatus = (status) => {
    if (status === 'PENDING') return pendding(2);
    else if (status === 'COMPLETE') return complete();
    else if (status === 'COMPLETE') return cancel();
    else return complete();
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={24}>
        <Typography className={classes.title}>Your referrals</Typography>
        <Typography style={{ marginBottom: '15px' }}>
          Congratulation! You are a great friend, you saved your friends X Kč
          and at least 21 hours a week to each of them. You were rewarded with
          Kč for your help.{' '}
        </Typography>
        {props.listInvite &&
          props.listInvite.length > 0 &&
          props.listInvite.map((v, k) => {
            return (
              <Grid key={k} item lg={4} xs={12} md={6}>
                <div className={classes.grid}>
                  <Typography className={classes.username}>
                    {v.firstName + ' ' + v.lastName}
                  </Typography>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                      {newHostRegistrationStatus(v.onboardingStatus)}
                      <ListItemText primary="Registration" />
                    </ListItem>
                    <ListItem button>
                      {rewardStatus(v.rewardStatus)}
                      <ListItemText primary="Trial period" />
                    </ListItem>
                    <ListItem button>
                      {rewardStatus(v.rewardStatus)}
                      <ListItemText primary="Pay out" />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ReferralProgress);
