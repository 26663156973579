import React from 'react';
import {
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

const VirtualTableCustom = ({ dataCount = 0, ...props }) => {
  return (
    dataCount > 10 && <VirtualTable {...props}/>
  );
};

export default VirtualTableCustom;
