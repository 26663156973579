import { createSelector } from 'reselect';

const clientInfoSelector = state => state.client.clientInfo;
const residentialClientsSelector = state => state.client.residentialClients;

export const selectClientInfo = createSelector(
  clientInfoSelector,
  clientInfo => clientInfo
);

export const selectResidentialClientsList = createSelector(
  residentialClientsSelector,
  residentialClients => residentialClients
);
