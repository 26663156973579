/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Button, Grid, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';
import { previousStep } from '../../../actions/onboading';
import './style.css';
import { conntectAccountStyles } from './style';
import Airbnb from '../../../styles/onboarding/connect_account/Airbnb_logo.png';
import Booking from '../../../styles/onboarding/connect_account/Booking_logo.png';
import AirbnbDialog from '../components/AirbnbDialog';
import BookingDialog from '../components/BookingDialog';
import ContactUsDialog from '../components/ContactUsDialog';
import CusTooltip from '../../../components/CusTooltip';
import { useTranslation } from 'react-i18next';

export const AIRBNB = 'airbnb';
export const BOOKING = 'booking';
export const CONTACT_US = 'contact_us';
export const HASAIRBNB = 'hasAirbnb';
export const HASBOOKING = 'hasBooking';
const styles = {
  continueButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px 0',
  },
};

const checkIsConnect = (_integration, type) => {
  return (
    _integration &&
    _integration[type] &&
    _integration[type].connected === 1 &&
    _integration[type].valid === 1
  );
};

const ConnectAccount = ({
  handleSubmit,
  formData,
  stepKey,
  classes,
  previousStep,
  account,
  handleBack,
  clientInfo,
}) => {
  const { t } = useTranslation();

  const [connectData, setConnectData] = useState({
    [AIRBNB]: null,
    [BOOKING]: null,
    [CONTACT_US]: null,
    [HASAIRBNB]: false,
    [HASBOOKING]: false,
  });

  useEffect(() => {
    setConnectData({
      [AIRBNB]:
        formData && formData[stepKey] ? formData[stepKey][AIRBNB] : null,
      [BOOKING]:
        formData && formData[stepKey] ? formData[stepKey][BOOKING] : null,
      [CONTACT_US]:
        formData && formData[stepKey] ? formData[stepKey][CONTACT_US] : null,
    });
  }, []);

  const [openAirbnbDialog, setOpenAirbnbDialog] = useState(false);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);

  const handleAddData = (values) => {
    connectData[values.type] = values;
    setConnectData(connectData);
    setOpenAirbnbDialog(false);
    setOpenBookingDialog(false);
    setOpenContactUsDialog(false);
  };

  const handleConnectAccountSubmit = () => {
    // console.log(connectData)
    // console.log(hasAirbnb, hasBooking)
    connectData[HASBOOKING] = hasBooking;
    connectData[HASAIRBNB] = hasAirbnb;
    setConnectData(connectData);
    return handleSubmit(connectData);
  };

  const renderDialog = () => {
    return (
      <div>
        {openAirbnbDialog && (
          <AirbnbDialog
            isOpen={openAirbnbDialog}
            initialData={connectData && connectData[AIRBNB]}
            handleClose={() => setOpenAirbnbDialog(false)}
            handleSubmit={handleAddData}
            objectKey={AIRBNB}
          />
        )}
        {openBookingDialog && (
          <BookingDialog
            isOpen={openBookingDialog}
            initialData={connectData && connectData[BOOKING]}
            handleClose={() => setOpenBookingDialog(false)}
            handleSubmit={handleAddData}
            objectKey={BOOKING}
          />
        )}
        {openContactUsDialog && (
          <ContactUsDialog
            isOpen={openContactUsDialog}
            initialData={connectData && connectData[CONTACT_US]}
            handleClose={() => setOpenContactUsDialog(false)}
            handleSubmit={handleAddData}
            objectKey={CONTACT_US}
          />
        )}
      </div>
    );
  };

  const _integration = clientInfo.integration;

  const hasAirbnb =
    (connectData && connectData[AIRBNB]) ||
    checkIsConnect(_integration, AIRBNB);
  const hasBooking =
    (connectData && connectData[BOOKING]) ||
    checkIsConnect(_integration, BOOKING);
  const hasContactUs = connectData && connectData[CONTACT_US];

  return (
    <div>
      <Typography variant="subtitle1">
        {t(
          `Connect your Airbnb and Booking account with Best Guest to unlock these features`,
        )}
        :
      </Typography>
      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
        {t(
          `Reservations import, Calendar synchronisation, Listings import, Automatic messages and Reviews`,
        )}
      </Typography>
      <div className={classes.chartRoot}>
        <Grid container spacing={24} direction="row" className={classes.chart}>
          <Grid item md={4} className="logo_img">
            <Button
              variant="outlined"
              color="secondary"
              className="logo_img__airbnb"
              disabled
            >
              <img className="logo_img__button" src={Airbnb} alt="" />
            </Button>
          </Grid>
          <Grid item md={4} className="connect">
            <Button
              variant={hasAirbnb ? 'contained' : 'outlined'}
              className={hasAirbnb ? 'airbnbBtnActive' : null}
              disabled={hasAirbnb}
              color="secondary"
              onClick={() => setOpenAirbnbDialog(true)}
            >
              {t(hasAirbnb ? `Connected` : `Connect`)}
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={24}
          direction="row"
          className={`${classes.chart} ${classes.best_guest_center}`}
          justify="flex-end"
          alignItems="center"
        >
          <div className="gradient-border airbnb">
            <div className="arrow"></div>
          </div>
          <Grid item className="best_guest">
            <Button
              variant="outlined"
              color="secondary"
              className="best_guest__button"
              disabled
            >
              BEST GUEST
            </Button>
          </Grid>
          <div className="gradient-border booking">
            <div className="arrow"></div>
          </div>
        </Grid>

        <Grid container spacing={24} direction="row" className={classes.chart}>
          <Grid item md={4} className="logo_img">
            <Button
              variant="outlined"
              color="secondary"
              className="logo_img__booking"
              disabled
            >
              <img className="logo_img__button" src={Booking} alt="" />
            </Button>
          </Grid>
          <Grid item md={4} className="connect">
            <Button
              variant={hasBooking ? 'contained' : 'outlined'}
              className={hasBooking ? 'airbnbBtnActive' : null}
              color="secondary"
              onClick={() => setOpenBookingDialog(true)}
            >
              {t(hasBooking ? `Connected` : `Connect`)}
            </Button>
          </Grid>
        </Grid>
      </div>

      <Hidden xsDown implementation="css">
        <Typography
          variant="subtitle1"
          align="center"
          style={{ display: 'flex', placeContent: 'center' }}
        >
          {t(`Don‘t you have an Airbnb or a Booking account`)}?
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setOpenContactUsDialog(true);
            }}
          >
            {t(`Contact us`)}.
          </a>
          <CusTooltip
            title={`${t(
              'Do you use some channel that you would like to connect with Best Guest',
            )}?`}
          />
        </Typography>
      </Hidden>
      <Hidden smUp implementation="css">
        <Typography
          variant="subtitle1"
          align="center"
          style={{ placeContent: 'center' }}
        >
          {t(`Don‘t you have an Airbnb or a Booking account`)}?
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ display: 'flex', placeContent: 'center' }}
        >
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setOpenContactUsDialog(true);
            }}
          >
            {t(`Contact us`)}.
          </a>
          <CusTooltip
            title={`${t(
              'Do you use some channel that you would like to connect with Best Guest',
            )}?`}
          />
        </Typography>
      </Hidden>

      <div style={styles.continueButton}>
        <Button
          type="button"
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 15 }}
        >
          {t(`Back`)}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!hasAirbnb && !hasBooking /*&& !hasContactUs*/}
          onClick={handleConnectAccountSubmit}
        >
          {t(`Continue`)}
        </Button>
      </div>

      {/* <Typography variant="subtitle1" align="center" style={{ color: red[300] }}>
        {t(`Add your Airbnb or Booking account`)}.
        </Typography> */}

      {renderDialog()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
  clientInfo: state.client.clientInfo.data,
});

const mapActionToProps = {
  previousStep,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(conntectAccountStyles)(ConnectAccount));
