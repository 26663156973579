import React, { useState } from 'react';
import { connect } from 'react-redux';
import TooltipCustom from '../components/TooltipCustom';
import { Grid, CircularProgress, Typography, Button } from '@mui/material';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { generateInvoiceDetailsFields } from '../components/generateFields';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { previousStep } from '../../../actions/onboading';
import { updateClientInfo } from '../../../actions/account';

const cusStyles = {
  middle_item: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomText: {
    background: '#c6eeff',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    padding: 2,
  },
  continuesButton: {
    textAlign: 'center',
    marginTop: 30,
  },
  forgotPasswordBtn: {
    textAlign: 'center',
    margin: '30px 0',
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '8px 0 4px',
  },
  iconTooltip: {
    marginTop: 20,
    marginRight: 20,
  },
};

const contentTooltip = (title) => {
  return <div>{title}</div>;
};

const validation = Yup.object().shape({
  clientName: Yup.string().required('Required'),
  residentialAddress: Yup.string().required('Required'),
  businessId: Yup.string()
    .test(
      'len',
      'Must be 8 digit characters',
      // val => `${val}`.length === 8,
      (val) => val && val.toString().length === 8,
    )
    .required('Required'),
  // vatId: Yup.string()
  //   .test(
  //     'len',
  //     'Must be 8-10 digit characters',
  //     // val => `${val}`.length >= 8 && `${val}`.length <= 10,
  //     val => val && val.toString().length >= 8 && val.toString().length <= 10,
  //   )
  //   .required('Required'),
});

const InvoiceDetails = ({
  handleSubmit,
  handleBack,
  formData,
  stepKey,
  updateClientInfo,
  account,
  clientInfo,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  let initialValues =
    formData && formData[stepKey]
      ? formData[stepKey]
      : {
          clientName: clientInfo.clientName || '',
          businessId: clientInfo.businessId || '',
          vatId: clientInfo.vatId || '',
          residentialAddress: clientInfo.postalAddress || '',
        };

  const formFields = generateInvoiceDetailsFields(t);

  const handleInvoiceDetailsSubmit = (values, { setErrors }) => {
    let params = omit(values, ['clientName_obj', 'residentialAddress']);
    params.postalAddress = values.residentialAddress;
    params.isOnboardingProcess = true;
    //console.log(other);
    setLoading(true);

    updateClientInfo(params).then((res) => {
      setLoading(false);
      res.data && res.result && handleSubmit(values);
      res.errors.length > 0 && setErrors(res.errors[0].note);
      //console.log(res);
    });
  };

  return (
    <div>
      <Grid container style={{ marginBottom: 15 }} spacing={24}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t(
              `Dear user, please, fill in your payment details that will be on your invoices from Best Guest`,
            )}
            . {t(`You will receive the invoices by email`)}.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t(`You have 14 days free trial`)}.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justify="center">
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleInvoiceDetailsSubmit}
            render={({ dirty, values, setFieldValue, ...props }) => {
              return (
                <Form>
                  <Grid container spacing={24}>
                    <Grid item md={6} xs={12}>
                      {formFields.map(
                        (v, k) =>
                          !v.disabled && (
                            <Grid
                              item
                              xs
                              style={cusStyles.inputTooltip}
                              key={k}
                            >
                              <FastField {...omit(v, 'showTooltip')} key={k} />
                              {v.showTooltip && (
                                <TooltipCustom
                                  title={v.label}
                                  iconStyle={cusStyles.iconTooltip}
                                  body={contentTooltip(v.title)}
                                />
                              )}
                            </Grid>
                          ),
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {formFields.map(
                        (v, k) =>
                          v.disabled && (
                            <Grid
                              item
                              xs
                              style={cusStyles.inputTooltip}
                              key={k}
                            >
                              {v.showTooltip && (
                                <TooltipCustom
                                  title={v.label}
                                  iconStyle={cusStyles.iconTooltip}
                                  body={contentTooltip(v.title)}
                                />
                              )}
                              <FastField {...omit(v, 'showTooltip')} key={k} />
                            </Grid>
                          ),
                      )}
                    </Grid>
                  </Grid>
                  <div style={cusStyles.continuesButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="default"
                      onClick={handleBack}
                      style={{ marginRight: 15 }}
                    >
                      {t(`Back`)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!dirty && loading}
                    >
                      {t(`Continue`)}
                      {loading && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
  clientInfo: state.client.clientInfo.data,
});

const mapActionToProps = {
  previousStep,
  updateClientInfo,
};

export default connect(mapStateToProps, mapActionToProps)(InvoiceDetails);
