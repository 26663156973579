import React, { useState, useContext } from 'react';
import { Formik, Form, FastField, FieldArray } from 'formik';
import {
  addNotificationsContacts,
  editNotificationsContacts,
  getNotificationsSettings,
} from '../../../actions/reminders';
import * as Yup from 'yup';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid,
  FormHelperText,
  Button,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import SelectMui from '../../../common/SelectMui';
import { GlobalDrawerContext } from '../../../contexts';
import { CLOSE_DRAWER } from '../actionTypes';
import { media } from '../../../styles/commonStyles';
import { useTranslation } from 'react-i18next';
import { remindersTypeName } from '../containers/Reminders';

const BoxStyled = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const FormWrapper = styled.div`
  padding: 20px;
  ${media.largeDesktop`
    width: 480px;
  `}
  ${media.phone`
    width: 90%;
  `}
`;

const styles = {
  btnAdd: {
    margin: '15px 0',
  },
  listingStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 10,
  },
  btnDelete: {
    padding: 8,
  },
};

const validation = Yup.object().shape({
  memberId: Yup.string().required('Required'),
  types: Yup.array().of(Yup.string().required('Required')),
  smsOn: Yup.boolean().test(
    'match',
    'Either one must be selected',
    function (value) {
      return !(!this.parent.emailOn && !value);
    },
  ),
});

const updatedValidation = Yup.object().shape({
  memberId: Yup.string().required('Required'),
  types: Yup.array().of(Yup.string().required('Required')),
  // smsOn: Yup.boolean().test(
  //   "match",
  //   "Either one must be selected",
  //   function (value) {
  //     return !(!this.parent.emailOn && !value);
  //   }
  // ),
});

const defaultReminders = [''];

const customMemberOptions = (lists = []) => {
  return lists.map((v) => {
    return {
      value: v.id,
      label: v.welcomerName,
    };
  });
};

const customRemindersOptions = (lists = [], t) => {
  return lists.map((v) => {
    return {
      value: v,
      label: t(remindersTypeName[v]),
    };
  });
};

const RemindersForm = ({
  isUpdating,
  reminderSettings,
  getNotificationsSettings,
  addNotificationsContacts,
  editNotificationsContacts,
  defaultData,
}) => {
  const [loader, setLoadder] = useState(false);
  const [error] = useState('');
  const [actionStatus, setActionStatus] = useState(false);
  const { dispatch } = useContext(GlobalDrawerContext);

  const { t } = useTranslation();

  function handleSubmit(values, { setErrors }) {
    // values.emailOn = values.emailOn ? '1' : '0';
    // values.smsOn = '0';
    // values.smsOn = values.smsOn ? '1' : '0';
    values.emailOn = '1';
    setLoadder(true);
    if (isUpdating) {
      editNotificationsContacts(values).then((res) => {
        setLoadder(false);
        if (res.data && res.result) {
          setActionStatus(true);
          dispatch({ type: CLOSE_DRAWER });
          getNotificationsSettings();
        } else {
          res.errors &&
            res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    } else {
      addNotificationsContacts(values).then((res) => {
        setLoadder(false);
        if (res.data) {
          setActionStatus(true);
          dispatch({ type: CLOSE_DRAWER });
          getNotificationsSettings();
        } else {
          res.errors &&
            res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    }
  }

  function renderListingSelectBox(values) {
    let listingOption =
      reminderSettings.data &&
      customRemindersOptions(reminderSettings.data.type, t);
    listingOption = listingOption.map((v) =>
      Object.assign(
        { ...(values.types.includes(v.value) && (v.disabled = true)) },
        v,
      ),
    );
    return (
      <Grid item xs={12} key={'133'}>
        <Typography variant="h5" color="primary">
          {t('Reminders')}
        </Typography>
        <FieldArray
          name="types"
          render={(arrayHelpers) => (
            <div>
              {values.types.map((value, index) => (
                <div key={index} style={styles.listingStyle}>
                  <FastField
                    name={`types[${index}]`}
                    label={`${t('Reminder')} ${index + 1}`}
                    options={listingOption}
                    placeholder={`${t('Select reminder')} ${index + 1}`}
                    component={SelectMui}
                  />
                  {index > 0 && (
                    <IconButton
                      color="default"
                      aria-label="Delete"
                      onClick={() => arrayHelpers.remove(index)}
                      style={styles.btnDelete}
                    >
                      <DeleteIcon fontSize="small" className="icon-left" />
                    </IconButton>
                  )}
                </div>
              ))}
              {listingOption.length > values.types.length &&
                values.types[values.types.length - 1] !== '' && (
                  <IconButton
                    color="primary"
                    style={styles.btnAdd}
                    onClick={() => arrayHelpers.push('')}
                  >
                    <AddCircleOutline className="icon-left" />
                  </IconButton>
                )}
            </div>
          )}
        />
      </Grid>
    );
  }

  const initialValues = isUpdating
    ? {
        contactId: defaultData.contactId,
        types: defaultData.types,
        memberId: defaultData.memberId,
        smsOn: defaultData.smsOn === 1,
        emailOn: defaultData.emailOn === 1,
      }
    : {
        types: defaultReminders,
        memberId: '',
        emailOn: true,
        smsOn: false,
      };

  return (
    <FormWrapper>
      <Typography variant="h6" color="default" align="center">
        {t('Member setting')}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={isUpdating ? updatedValidation : validation}
        onSubmit={handleSubmit}
        render={({ dirty, values }) => {
          return (
            <Form>
              <Grid container spacing={24} alignItems={'center'}>
                <Grid item xs={12}>
                  <FastField
                    name="memberId"
                    label={t('Member')}
                    placeholder={t('Member')}
                    component={SelectMui}
                    options={
                      reminderSettings.data &&
                      customMemberOptions(reminderSettings.data.members)
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FastField
                    name="smsOn"
                    label={t("SMS")}
                    placeholder={t('SMS')}
                    component={SwitchesCustom}
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <FastField
                    name="emailOn"
                    label={t("Email")}
                    placeholder={t('Email')}
                    component={SwitchesCustom}
                  />
                </Grid> */}
                {renderListingSelectBox(values)}
              </Grid>
              <BoxStyled>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || loader}
                >
                  {isUpdating ? t('Update') : t('Save')}
                  {loader && <CircularProgress size={20} />}
                </Button>
              </BoxStyled>
              {actionStatus && (
                <Typography color="secondary">{`${
                  isUpdating ? t('Updated') : t('Saved')
                } ${t('successfully')}!`}</Typography>
              )}
              {!!error && (
                <FormHelperText error={!!error}>{error}</FormHelperText>
              )}
            </Form>
          );
        }}
      />
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  reminderSettings: state.reminders.settings,
});

export default connect(mapStateToProps, {
  getNotificationsSettings,
  addNotificationsContacts,
  editNotificationsContacts,
})(RemindersForm);
