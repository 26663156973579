import { makeRequest } from '../redux/apiCall';
import {
  URL_GET_WELCOMERS,
  URL_DELETE_WELCOMERS,
  URL_CREATE_WELCOMERS,
  URL_UPDATE_WELCOMERS,
  URL_GET_WELCOMERS_SELECT,
  URL_GET_WELCOMERS_ROLES,
} from '../shared/urls';
// import { uploadFile } from '../custom-hooks/apiHook';
// import { handleError } from '../redux/apiCall';

export const GET_WELCOMERS = 'get_welcomers';
export const GET_WELCOMERS_SUCCESS = 'get_welcomers_success';
export const GET_WELCOMERS_FAILURE = 'get_welcomers_failure';

export const GET_WELCOMERS_SELECT = 'get_welcomers_select';
export const GET_WELCOMERS_SELECT_SUCCESS = 'get_welcomers_select_success';
export const GET_WELCOMERS_SELECT_FAILURE = 'get_welcomers_select_failure';

export const GET_WELCOMERS_ROLES = 'get_welcomers_roles';
export const GET_WELCOMERS_ROLES_SUCCESS = 'get_welcomers_roles_success';
export const GET_WELCOMERS_ROLES_FAILURE = 'get_welcomers_roles_failure';

export const DELETE_WELCOMERS = 'delete_welcomers';
export const DELETE_WELCOMERS_SUCCESS = 'delete_welcomers_success';
export const DELETE_WELCOMERS_FAILURE = 'delete_welcomers_failure';

export const CREATE_WELCOMERS = 'create_welcomers';
export const CREATE_WELCOMERS_SUCCESS = 'create_welcomers_success';
export const CREATE_WELCOMERS_FAILURE = 'create_welcomers_failure';

export const UPDATE_WELCOMERS = 'update_welcomers';
export const UPDATE_WELCOMERS_SUCCESS = 'update_welcomers_success';
export const UPDATE_WELCOMERS_FAILURE = 'update_welcomers_failure';

export const getWelcomers = params =>
  makeRequest(
    GET_WELCOMERS,
    'get',
    URL_GET_WELCOMERS,
    params,
    getWelcomers,
  );

export const getWelcomersSelect = params =>
  makeRequest(
    GET_WELCOMERS_SELECT,
    'get',
    URL_GET_WELCOMERS_SELECT,
    params,
    getWelcomersSelect,
  );

export const getWelcomersRoles = params =>
  makeRequest(
    GET_WELCOMERS_ROLES,
    'get',
    URL_GET_WELCOMERS_ROLES,
    params,
    getWelcomersRoles,
  );

export const deleteWelcomer = (params) =>
  makeRequest(
    DELETE_WELCOMERS,
    'post',
    URL_DELETE_WELCOMERS,
    params,
    deleteWelcomer,
    true
  );

export const createWelcomer = params =>
  makeRequest(
    CREATE_WELCOMERS,
    'post',
    URL_CREATE_WELCOMERS,
    params,
    createWelcomer,
  );

export const updateWelcomer = params =>
  makeRequest(
    UPDATE_WELCOMERS,
    'post',
    URL_UPDATE_WELCOMERS,
    params,
    updateWelcomer,
  );

// export const createWelcomer = (params, file) => async dispatch => {
//   let data = new FormData();
//   data.append('coverImage', file);
//   data.append('data', JSON.stringify(params));
//   dispatch({ type: CREATE_WELCOMERS });
//   try {
//     await uploadFile(URL_CREATE_WELCOMERS, data);
//     dispatch({ type: CREATE_WELCOMERS_SUCCESS, res: params });
//   } catch (e) {
//     handleError(e, dispatch, createWelcomer, data);
//   }
// };

// export const updateWelcomer = (params, file) => async dispatch => {
//   let data = new FormData();
//   data.append('coverImage', file);
//   data.append('data', JSON.stringify(params));
//   dispatch({ type: UPDATE_WELCOMERS });
//   try {
//     await uploadFile(URL_UPDATE_WELCOMERS, data);
//     dispatch({ type: UPDATE_WELCOMERS_SUCCESS, res: params });
//   } catch (e) {
//     handleError(e, dispatch, updateWelcomer, data);
//   }
// };
