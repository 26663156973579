import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  Typography,
} from '@mui/material';
import { closeModal } from '../shared/actions';
import { grey, red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit * 2,
  },
  DialogContentText: {
    color: grey[500],
  },
  NoButton: {
    border: 'none !important',
    backgroundColor: grey[300],
    padding: '8px 30px',
  },
  YesButton: {
    border: 'none !important',
    backgroundColor: red[50],
    padding: '8px 30px',
  },
});

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const DialogConfirm = ({
  title,
  message,
  isOpen = false,
  isLoadding = false,
  handleClose,
  handleAccess,
  classes,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography variant="h5" component="div" color="primary" align="center">
          {title || 'Action'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography
            variant="subtitle2"
            component="span"
            color="default"
            align="center"
            className={classes.DialogContentText}
          >
            {message || 'Do you want?'}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: 30 }}>
        <Button
          variant="text"
          disabled={isLoadding}
          onClick={handleClose}
          style={{ marginRight: 15 }}
          className={classes.NoButton}
        >
          {t('No')}
        </Button>
        <Button
          variant="text"
          onClick={handleAccess && handleAccess}
          disabled={isLoadding}
          className={classes.YesButton}
        >
          {t('Yes')}{' '}
          {!!isLoadding && (
            <CircularProgress size={24} style={{ marginLeft: 15 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { closeModal })(
  withStyles(styles)(DialogConfirm),
);
