import createImmerReducer from '../../redux/immer-reducer';

import {
  AIRBNB_GET_LISTINGS,
  AIRBNB_GET_LISTINGS_SUCCESS,
  AIRBNB_GET_LISTINGS_FAILURE,
} from '../../actions/property';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [AIRBNB_GET_LISTINGS]: state => {
    state.loading = true;
  },
  [AIRBNB_GET_LISTINGS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [AIRBNB_GET_LISTINGS_FAILURE]: (state, action) => {
    state.loading = false;
    state.data = initialState;
  },
});
