import {
  FETCH_ACCOMODATIONS,
  FETCH_ACCOMODATIONS_SUCCESS,
  FETCH_ACCOMODATION_DETAIL,
  FETCH_ACCOMODATION_DETAIL_SUCCESS,
  RESET_ACCOMODATION_DETAIL,
  ADD_ACCOMODATION,
  ADD_ACCOMODATION_SUCCESS,
  ADD_NEW_LISTING,
  ADD_NEW_LISTING_SUCCESS,
  GET_LISTING_BY_ACCOMMODATION,
  GET_LISTING_BY_ACCOMMODATION_SUCCESS,
  UPDATE_ACCOMMODATION,
  UPDATE_ACCOMMODATION_SUCCESS,
} from './actionTypes';

export const getAccommodations = () => {
  return {
    type: FETCH_ACCOMODATIONS,
  };
};

export const getAccommodationsSuccess = (res) => {
  return {
    type: FETCH_ACCOMODATIONS_SUCCESS,
    res,
  };
};

export const getAccommodationDetail = (id) => {
  return {
    type: FETCH_ACCOMODATION_DETAIL,
    id,
  };
};

export const getAccomodationDetailSuccess = (data) => {
  return {
    type: FETCH_ACCOMODATION_DETAIL_SUCCESS,
    detail: data,
  };
};

export const resetAccomodationDetail = () => {
  return {
    type: RESET_ACCOMODATION_DETAIL,
  };
};

export const addAccommodation = (params) => {
  return {
    type: ADD_ACCOMODATION,
    params,
  };
};

export const addAccommodationSuccess = (res) => {
  return {
    type: ADD_ACCOMODATION_SUCCESS,
    res,
  };
};

export const addNewListing = (params) => ({
  type: ADD_NEW_LISTING,
  params,
});

export const addNewListingSuccess = (res) => ({
  type: ADD_NEW_LISTING_SUCCESS,
  res,
});

export const getListingByAccommodation = (accommodationId) => ({
  type: GET_LISTING_BY_ACCOMMODATION,
  params: { accommodationId },
});

export const getListingByAccommodationSuccess = (res) => ({
  type: GET_LISTING_BY_ACCOMMODATION_SUCCESS,
  res,
});

export const updateAccommodation = (params) => ({
  type: UPDATE_ACCOMMODATION,
  params,
});

export const updateAccommodationSuccess = (res) => ({
  type: UPDATE_ACCOMMODATION_SUCCESS,
  res,
});
