/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import {
  CircularProgress,
  Typography,
  Button,
  Grid,
  Paper,
  Hidden,
} from '@mui/material';
import { loginStyles } from './style';
import { Form, Formik, Field } from 'formik';
import FormInput from '../../../common/input/index';
import * as Yup from 'yup';
import { resetPassword } from '../actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import { verifyPasswordOtp } from '../../../actions/onboading';

const url = new URL(window.location.href);
const hash = url.searchParams.get('hash');
const validation = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(6, 'Password is too short should be 6 chars minimum')
    .matches(/[0-9]/, 'Password must include at least one numeric character')
    .matches(/[a-z]/, 'Password must contain lowercase characters')
    .matches(/[A-Z]/, 'Password must contain uppercase characters')
    .nullable(),
});
const validationVerifyCode = Yup.object().shape({
  otp: Yup.string()
    .required('Required')
    .matches(/^[0-9]{6}$/, 'Must be exactly 6 digits')
    .nullable(),
});
class ResetPassword extends Component {
  state = {
    email: '',
    loading: false,
    password: '',
    message: '',
    typeInput: false,
    isOpen: false,
    isVerifycode: false,
  };

  componentDidMount() {
    !this.props.location.search && this.props.history('/login');
  }

  handleSubmit = (data) => {
    const { t } = this.props;

    if (!hash) {
      toast.error(t('Missing hash!'));
      return false;
    }
    if (data.password !== data.passwordConfirm) {
      alert(t('Password confirm not match'));
      return false;
    }
    data.hash = hash;
    this.setState({ loading: true });
    this.props.resetPassword(data);
  };

  handleSubmitVerifyCode = (data) => {
    const { t } = this.props;
    if (!hash) {
      toast.error(t('Missing hash!'));
      return false;
    }
    data.hash = hash;
    this.setState({ loading: true });
    this.props.verifyPasswordOtp(data).then((res) => {
      if (res.result && res.data) {
        this.setState({
          isVerifycode: !this.state.isVerifycode,
          loading: false,
        });
      } else {
        res.errors &&
          res.errors.length > 0 &&
          toast.error(t(res.errors[0].note));
        this.setState({ loading: false });
      }
    });
  };

  renderVerifyCode = () => {
    const { classes, t } = this.props;
    const { loading, message } = this.state;
    return (
      <div>
        <Typography variant="body1" align="center">
          {t('Please,Check your SMS and verify you phone number')}.
        </Typography>
        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={validationVerifyCode}
          onSubmit={this.handleSubmitVerifyCode}
          render={({ dirty, values }) => (
            <Form>
              <Field
                name="otp"
                label={t('Verify Code')}
                placeholder={t('Verify Code')}
                type={'text'}
                component={FormInput}
              />
              <Typography
                className={classes.title}
                variant="body1"
                gutterBottom
              >
                {message}
              </Typography>
              <div align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.margin}
                  disabled={!dirty || loading}
                >
                  {t('VERIFY')}
                  {loading && (
                    <CircularProgress
                      size={20}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  renderForm = () => {
    const { classes, t } = this.props;
    const { loading, message } = this.state;
    return (
      <div>
        <Typography
          variant="body1"
          color="secondary"
          align="center"
          style={{ marginBottom: 30 }}
        >
          {t('Choose a new password')}
        </Typography>
        <Typography variant="body1" align="center">
          {t(
            'A strong password is a combination of letters and punctuation marks',
          )}
          .
        </Typography>
        <Typography variant="body1" align="center">
          {t('It must be at least 6 characters long')}.
        </Typography>
        <Formik
          initialValues={{
            password: '',
            passwordComfirm: '',
          }}
          validationSchema={validation}
          onSubmit={this.handleSubmit}
          render={({ dirty, values }) => (
            <Form>
              <Field
                name="password"
                label={t('Password')}
                placeholder={t('Password')}
                type={'password'}
                component={FormInput}
              />
              <Typography
                className={classes.title}
                variant="body1"
                gutterBottom
              >
                {message}
              </Typography>

              <Field
                name="passwordConfirm"
                label={t('Password Again')}
                placeholder={t('Password Confirm')}
                type={'password'}
                component={FormInput}
              />
              <Typography
                className={classes.title}
                variant="body1"
                gutterBottom
              >
                {message}
              </Typography>

              <div align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.margin}
                  disabled={!dirty || loading}
                >
                  {t('Continues')}
                  {loading && (
                    <CircularProgress
                      size={20}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;
    return (
      <div align="center">
        <Typography variant="h5" color="secondary" style={{ margin: '40px 0' }}>
          {t('Your password was saved successfully')}!
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.props.history('/login')}
        >
          {t('Log In')}
        </Button>
      </div>
    );
  };

  render() {
    const { classes, account } = this.props;
    return (
      <div>
        <Hidden xsDown>
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            className={classes.formLayout}
          >
            <Grid item xs={12} md={3}>
              <Paper className={classes.formWrapper}>
                <div className={classes.formGroup}>
                  <Grid
                    container
                    spacing={24}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={logo}
                        className={classes.logo}
                        style={window.innerWidth < 500 ? { width: '67%' } : {}}
                      />
                    </Grid>
                  </Grid>
                  {!this.state.isVerifycode
                    ? this.renderVerifyCode()
                    : !account.detail.isResetPassSuccess
                    ? this.renderForm()
                    : this.renderSuccess()}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Grid
            container
            style={{ backgroundColor: 'white' }}
            spacing={0}
            justify="center"
            alignItems="center"
            className={classes.formLayout}
          >
            <Grid item xs={12} md={3}>
              <Paper
                style={{ boxShadow: 'none' }}
                className={classes.formWrapper}
              >
                <div className={classes.formGroup}>
                  <Grid
                    container
                    spacing={24}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={logo}
                        className={classes.logo}
                        style={window.innerWidth < 500 ? { width: '67%' } : {}}
                      />
                    </Grid>
                  </Grid>
                  {!this.state.isVerifycode
                    ? this.renderVerifyCode()
                    : !account.detail.isResetPassSuccess
                    ? this.renderForm()
                    : this.renderSuccess()}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(
  connect(mapStateToProps, {
    resetPassword,
    verifyPasswordOtp,
  })(withStyles(loginStyles)(ResetPassword)),
);
