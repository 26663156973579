import React from 'react'
import ContentEditable from 'react-contenteditable';
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next';
import { grey, blue } from '@mui/material/colors';
import { getCaretPosition } from '../helpers/index';
const styles = theme => ({
  root: {
    height: 200,
    width: '100%',
    overflowY: 'auto',
    padding: '15px 7px',
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'text',
    alignItems: 'center',
    border: `1px solid ${grey[400]}`,
    borderRadius: 4,
    marginTop: 5,
    marginBottom: 15,
    transition: '.3s',
    '&:hover': {
      borderColor: grey[900]
    },
    '&:focus': {
      borderColor: blue[500],
      borderWidth: 2
    },
    '& .suggestItem': {
      color: blue[500],
      padding: '0 5px',
    },
    '& article': {
      height: '100% !important',
    },
  },
});

class HtmlInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.onHandle = this.onHandle.bind(this);
  }

  handleChangeInput(evt) {
    return this.props.handleChange(evt);
  }

  onHandle(e) {
    return this.props.handleKey(getCaretPosition());
  }

  render() {
    const { classes, value, inputRef, style } = this.props;

    return (
      <div className={classes.root}>
        <ContentEditable
          innerRef={inputRef}
          html={value || ''} // innerHTML of the editable div
          disabled={false}       // use true to disable editing
          onChange={this.handleChangeInput} // handle innerHTML change
          tagName='article' // Use a custom HTML tag (uses a div by default)
          style={style}
          // onFocus={this.onHandle}
          onKeyUp={this.onHandle}
          onMouseUp={this.onHandle}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(HtmlInput));
