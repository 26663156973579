export const OPEN_MODAL = 'open_modal';
export const CLOSE_MODAL = 'close_modal';

export const openModal = (modalName) => ({
  type: OPEN_MODAL,
  modalName,
});

export const closeModal = (modalName) => ({
  type: CLOSE_MODAL,
  modalName,
});
