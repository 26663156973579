import React from 'react';
import {
  Paper,
  Grid,
  CircularProgress
} from '@mui/material';
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { generateAutomationFields } from '../components/generateFields';
import AutomationForm from '../components/AutomationForm';
import { connect } from 'react-redux';
import { getSuggestions } from '../../../actions/global';
import {
  getMessage,
  saveMessage
} from '../../../actions/automation';

const styles = theme => ({
  paperRoot: {
    padding: 20,
  },
  formField: {
    marginTop: 30,
  }
});

class Automation extends React.Component {
  // componentWillMount(){
  //   this.props.getMessage()
  // }
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      result: false
    }
  }
  componentDidMount() {
    this.setState({
      isLoading: true
    }, async () => {
      await this.props.getSuggestions({ type: 'listing' });
      await this.props.getMessage().then(res => {
        res.result && this.setState({
          isloading: false,
          result: true
        })
      })

    })
  }

  render() {
    const { classes, t, suggestions } = this.props;
    const { isloading, result } = this.state;
    const formField = suggestions.data.length > 0 ? generateAutomationFields(t, suggestions.data) : [];
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs>
            <Paper className={classes.paperRoot}>
              {/* <TitleHasUnderLine title={t('Automated messages')} size="h4" color="default" borderWidth={3} /> */}
              <TitleHasIcon variant="h5" title={t('Automation')} label={t('Automate communication with your Airbnb and Booking guests')} icon={'autorenew'} />
              {isloading && <CircularProgress />}
              <div className={classes.formField}>
                {result && formField.map((v, k) =>
                  <AutomationForm key={k} {...v} />)}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};
const mapStateToProps = state => ({
  automations: state.automations.settings,
  suggestions: state.global.suggestions,
});

export default connect(
  mapStateToProps,
  {
    getMessage,
    saveMessage,
    getSuggestions
  },
)(withStyles(styles)(withTranslation()(Automation)));
