import React from 'react';
import { Paper, Hidden, Typography, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import MaterialGrid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { tableStyles } from '../../../styles/styles';
import Placeholder from '../../../common/Placeholder';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import VirtualTableCustom from '../../../components/VirtualTableCustom';
import { withTranslation } from 'react-i18next';
import { truncate } from '../../../helpers';
import { remindersTypeName } from '../containers/Reminders';

const TableRow = ({ row, ...restProps }) => <Table.Row {...restProps} />;

const comparePriority = (a, b) => {
  return a < b ? -1 : 1;
};
const MemberList = ({
  datas,
  classes,
  loading,
  t,
  setOpenDeleteMember,
  openUpdateMember,
}) => {
  const columns = [
    {
      name: 'welcomerName',
      title: t('Name'),
    },
    {
      name: 'reminders',
      title: t('Reminders'),
      getCellValue: (row) =>
        row.types &&
        row.types.length > 0 &&
        row.types.map((v) => <div key={v}>- {t(remindersTypeName[v])} </div>),
    },
    {
      name: 'sendBy',
      title: t('Send by'),
      getCellValue: (row) => (
        <div>
          <div>{row.emailOn === 1 && t('Email')}</div>
          <div>{row.smsOn === 1 && t('SMS')}</div>
        </div>
      ),
    },
    {
      name: '',
      title: '',
      getCellValue: (row) => (
        <div>
          <IconButton
            className={classes.IconButton}
            aria-label="Delete"
            onClick={() => openUpdateMember(row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            className={classes.IconButton}
            aria-label="Delete"
            onClick={() => setOpenDeleteMember(row)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  let integratedSortingColumnExtensions = [
    { columnName: 'name', compare: comparePriority },
  ];
  return (
    <div>
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <Paper className={classes.root}>
          <Placeholder loading={loading} type={'table'}>
            <Grid rows={datas || []} columns={columns}>
              <SortingState
                defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
                columnExtensions={[
                  { columnName: 'name', sortingEnabled: true },
                  { columnName: 'reminders', sortingEnabled: true },
                  { columnName: 'sendBy', sortingEnabled: true },
                ]}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              {/* <DateTypeProvider for={['name']} /> */}
              <Table
                rowComponent={TableRow}
                messages={{ noData: t('No Data') }}
              />
              <VirtualTableCustom
                dataCount={datas ? datas.length : 0}
                rowComponent={TableRow}
              />
              <TableHeaderRow showSortingControls />
            </Grid>
          </Placeholder>
        </Paper>
      </Hidden>

      {/* Mobile */}
      <Hidden smUp implementation="css" className={classes.smUp}>
        {datas &&
          datas.length > 0 &&
          datas.map((v, k) => (
            <Paper className={classes.root} key={k}>
              <MaterialGrid container spacing={0}>
                <MaterialGrid item xs={6}>
                  <Typography variant="caption">{t('Name')}</Typography>
                  <Typography variant="subtitle1" title={v.name}>
                    {truncate(v.welcomerName, 25)}
                  </Typography>
                </MaterialGrid>
                <MaterialGrid item xs={6}>
                  <Typography variant="caption">{t('Reminders')}</Typography>
                  <Typography variant="subtitle1">
                    {v.types &&
                      v.types.length > 0 &&
                      v.types.map((v) => (
                        <div key={v}>- {t(remindersTypeName[v])} </div>
                      ))}
                  </Typography>
                </MaterialGrid>
              </MaterialGrid>
              <MaterialGrid container spacing={0} style={{ marginTop: 10 }}>
                <MaterialGrid item xs={6}>
                  <Typography variant="caption">{t('Send by')}</Typography>
                  <Typography variant="subtitle1">
                    {v.emailOn === 1
                      ? t('Email')
                      : v.smsOn === 1
                      ? t('SMS')
                      : ''}
                  </Typography>
                </MaterialGrid>
                <MaterialGrid item xs={6}>
                  <Typography variant="caption">{t('Action')}</Typography>
                  <div>
                    <IconButton
                      className={classes.IconButton}
                      aria-label="Delete"
                      onClick={() => openUpdateMember(v)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={classes.IconButton}
                      aria-label="Delete"
                      onClick={() => setOpenDeleteMember(v)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </MaterialGrid>
              </MaterialGrid>
            </Paper>
          ))}
      </Hidden>
    </div>
  );
};

export default withTranslation()(withStyles(tableStyles)(MemberList));
