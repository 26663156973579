import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  SWITCH_COMPANY_TYPE,
  GET_CLIENT_SIGNATURE_URL_SUCCESS,
  REMOVE_CLIENT_SIGNATURE,
} from '../../actions/account';

const initialState = {
  data: {},
  loading: false,
  error: ''
};
export default createImmerReducer(initialState, {
  [GET_CLIENT]: state => {
    state.loading = true;
    state.data = {};
  },
  [SWITCH_COMPANY_TYPE]: (state, action) => {
    state.data.propertyManager = action.propertyManager;
  },
  [GET_CLIENT_SUCCESS]: (state, action, dispatch) => {
    state.loading = false;
    state.data = action.res;
  },
  [GET_CLIENT_SIGNATURE_URL_SUCCESS]: (state, action, dispatch) => {
    state.data = {
      ...state.data,
      signatureUrl: null,
      signatureId: null,
      signature: action.res,
    }
  },
  [REMOVE_CLIENT_SIGNATURE]: (state, action) => {
    state.data = {
      ...state.data,
      signatureUrl: null,
      signatureId: null,
      signature: null,
    }
  },
});
