import React from 'react';
import {
  Icon,
  Hidden,
  Grid,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material';
import EmailAutoComplete from '../EmailAutoComplete';
import { useTranslation } from 'react-i18next';
import CusTooltip from '../../components/CusTooltip';
import { omit } from 'lodash';
import { withStyles } from '@mui/styles';

const styles = {
  formControl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  labelRoot: {
    fontSize: 11,
  },
};

const FormInput = ({
  field, // { name, value, onChange, onBlur }
  classes,
  iconName,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Hidden xsDown>
        <div style={styles.formControl}>
          {props.showInfo && props.helpPosition !== 'right' && (
            <CusTooltip title={t(props.helpContent)} />
          )}
          <FormControl fullWidth margin={'dense'}>
            <TextField
              {...field}
              {...omit(props, [
                'showInfo',
                'useYear',
                'helpContent',
                'helpPosition',
              ])}
              label={t(props.label)}
              placeholder={t(props.placeholder)}
              autoFocus={props.autoFocus ? props.autoFocus : false}
              variant={props.variant ? props.variant : 'standard'}
              value={field.value || ''}
              InputProps={{
                endAdornment: iconName && (
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility">
                      <Icon>{iconName}</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
                ...(props &&
                  props.type === 'email' && {
                    inputComponent: EmailAutoComplete,
                  }),
              }}
              onChange={(evt) => {
                setFieldValue(field.name, evt.target.value);
              }}
            />
            {touched[field.name] && errors[field.name] && (
              <FormHelperText error>{t(errors[field.name])}</FormHelperText>
            )}
          </FormControl>
          {props.showInfo && props.helpPosition === 'right' && (
            <CusTooltip title={t(props.helpContent)} />
          )}
        </div>
      </Hidden>
      <Hidden smUp>
        <div style={styles.formControl}>
          {props.showInfo && props.helpPosition !== 'right' && (
            <CusTooltip title={t(props.helpContent)} />
          )}
          {props.inputLabel ? (
            <FormControl fullWidth margin={'dense'}>
              <TextField
                {...field}
                {...omit(props, [
                  'showInfo',
                  'useYear',
                  'helpContent',
                  'helpPosition',
                ])}
                label={t(props.label)}
                placeholder={t(props.placeholder)}
                autoFocus={props.autoFocus ? props.autoFocus : false}
                variant={props.variant ? props.variant : 'standard'}
                value={field.value || ''}
                InputProps={{
                  endAdornment: iconName && (
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility">
                        <Icon>{iconName}</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                  ...(props &&
                    props.type === 'email' && {
                      inputComponent: EmailAutoComplete,
                    }),
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                onChange={(evt) => {
                  setFieldValue(field.name, evt.target.value);
                }}
              />
              {touched[field.name] && errors[field.name] && (
                <FormHelperText error>{t(errors[field.name])}</FormHelperText>
              )}
            </FormControl>
          ) : (
            <FormControl fullWidth margin={'dense'}>
              <TextField
                {...field}
                {...omit(props, [
                  'showInfo',
                  'useYear',
                  'helpContent',
                  'helpPosition',
                ])}
                label={t(props.label)}
                placeholder={t(props.placeholder)}
                autoFocus={props.autoFocus ? props.autoFocus : false}
                variant={props.variant ? props.variant : 'standard'}
                value={field.value || ''}
                InputProps={{
                  endAdornment: iconName && (
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility">
                        <Icon>{iconName}</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                  ...(props &&
                    props.type === 'email' && {
                      inputComponent: EmailAutoComplete,
                    }),
                }}
                onChange={(evt) => {
                  setFieldValue(field.name, evt.target.value);
                }}
              />
              {touched[field.name] && errors[field.name] && (
                <FormHelperText error>{t(errors[field.name])}</FormHelperText>
              )}
            </FormControl>
          )}
          {props.showInfo && props.helpPosition === 'right' && (
            <CusTooltip title={t(props.helpContent)} />
          )}
        </div>
      </Hidden>
    </Grid>
  );
};

export default withStyles(styles)(FormInput);
