/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import Placeholder from '../../../common/Placeholder';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { Email, Phone } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import { truncate } from '../../../helpers';
import ImgDefault from '../../../styles/image/Icon_white.jpg';
import IMessage from '../../../styles/onboarding/iMessage.png';
import WhatsApp from '../../../styles/onboarding/WhatsApp.png';
import { convertPhoneToInput } from '../../../helpers';

const styles = (theme) => ({
  root: {},
  Items: {
    padding: 0,
    marginTop: 10,
  },
  cardItem: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionItem: {
    paddingBottom: 20,
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      marginRight: 5,
    },
  },
  avatar: {
    height: 100,
    width: 100,
    border: '1px solid #ddd',
    margin: 'auto',
    marginBottom: 15,
  },
  centerIcon: {
    display: 'flex',
    alignTtems: 'center',

    marginTop: 8,
    '& .icon': {
      fontSize: 20,
      marginRight: 15,
    },
  },
});

const MembersList = ({
  classes,
  members,
  loading,
  t,
  openUpdateMember,
  openDeleteConfirm,
  isWelcome,
}) => {
  const MemberItem = (item) => {
    return (
      <Grid item xs={12} md={isWelcome ? 6 : 3}>
        <Card className={classes.cardItem}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt={t('welcomer')}
              src={item.haveImage === 1 ? item.welcomerUrl : ImgDefault}
              className={classes.avatar}
            />
            <Typography variant="h6">{item.welcomerName}</Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {t(item.roleName)}
            </Typography>
            <div>
              <div style={{ marginTop: 30, marginLeft: 37 }}>
                <Typography component="p" className={classes.centerIcon}>
                  <Phone className="icon" />{' '}
                  {convertPhoneToInput(item.phoneNumber) || '___'}
                </Typography>
                <Typography
                  component="p"
                  className={classes.centerIcon}
                  title={item.email}
                >
                  <Email className="icon" />{' '}
                  {item.email ? truncate(item.email, 20) : '___'}
                </Typography>
              </div>
              <CardActions style={{ padding: 0, paddingTop: 15 }}>
                <Button size="small" style={{ width: 120 }} disabled={true}>
                  <img src={IMessage} style={{ width: 19, marginRight: 17 }} />
                  {item.iMessage ? t('Yes') : t('No')}
                </Button>
                <Button size="small" style={{ width: 120 }} disabled={true}>
                  <img src={WhatsApp} style={{ width: 19, marginRight: 17 }} />
                  {item.whatsApp ? t('Yes') : t('No')}
                </Button>
              </CardActions>
            </div>
          </CardContent>
          <CardActions className={classes.actionItem}>
            <Button
              size="small"
              style={{ width: 120 }}
              color="primary"
              onClick={() => openUpdateMember(item)}
            >
              <EditIcon /> {t('Edit')}
            </Button>
            <ButtonWithTooltip
              show={!item.canDelete}
              title={t(
                `You can't delete this member because this member is connected with some property`,
              )}
            >
              <Button
                size="small"
                style={{ width: 120 }}
                color="primary"
                onClick={() => openDeleteConfirm(true, item)}
                disabled={!item.canDelete}
              >
                <DeleteIcon /> {t('Delete')}
              </Button>
            </ButtonWithTooltip>
          </CardActions>
        </Card>
      </Grid>
    );
  };
  return (
    <Placeholder loading={loading} type={'table'}>
      <Grid container spacing={24} className={classes.Items}>
        {members &&
          members.length > 0 &&
          members.map((v, k) => <MemberItem key={k} {...v} />)}
      </Grid>
    </Placeholder>
  );
};

const mapStateToProps = (state) => ({
  // members: state.member.memberList,
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, null)(MembersList)),
);
