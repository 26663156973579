/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCheckInform } from '../../../actions/reservation';
import format from 'date-fns/format';
import { isString } from 'lodash';
import {
  selectReservationDetail,
  selectCheckinFormDetail,
} from '../../../selectors/reservation-selectors';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import styled from 'styled-components';
import { media } from '../../../styles/commonStyles';
import { useTranslation } from 'react-i18next';

import birthDay from '../../../styles/reservation-detail/Date_of_birth.png';
import nationality from '../../../styles/reservation-detail/Nationality.png';
import fullName from '../../../styles/reservation-detail/Full_name.png';
import identNumber from '../../../styles/reservation-detail/Document.png';
import signature from '../../../styles/reservation-detail/Signature.png';
import address from '../../../styles/reservation-detail/Address.png';
import { callApi } from '../../../custom-hooks/apiHook';
import {
  URL_GET_CHECK_IN_FORM,
  URL_GET_CLIENT_SIGNATURE,
} from '../../../shared/urls';

const width = window.innerWidth;

const FormWrapper = styled.div`
  position: relative;
  overflow: hidden;
  ${media.largeDesktop`
    width: 1024px;
  `};
  ${media.tablet`
    width: 90%;
    padding: 20px;
  `}
  ${media.phone`
    width: 100%;
    padding: 5px;
  `}
  border-radius: 5px;
  box-shadow: 1px 1px 1px #ddd;
  border: 1px solid #ddd;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  padding: 0 20px;
  ${media.phone`
      width: ${width < 500 ? width + 'px' : '100%'};
      padding: 0 1px 0 6px;
  `};
`;

const styles = {
  gridContainer: {
    margin: '5px 0',
    padding: '20px 0 10px',
  },
  icons: {
    maxHeight: 13,
  },
  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  formHeader: {
    marginTop: 25,
    marginLeft: 25,
    color: '#9e9e9e',
    fontSize: 15,
  },
  colorDate: {
    color: '#f16d36',
  },
  roundedIcon: {
    border: '1px solid #ccc',
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  colorGrey: {
    color: '#9e9e9e',
    fontSize: 15,
  },
  colorBlack: {
    color: 'black',
    fontSize: 17,
  },
};

const OverViewCheckInForm = ({
  reservationDetail,
  getCheckInform,
  checkinFormDetail,
  visaCounttryList,
  countriesList,
  guestList = [],
}) => {
  let [checkInFormList, setCheckInFormList] = useState(guestList);
  const { t } = useTranslation();

  useEffect(() => {
    // reservationDetail && getCheckInform({ checkinFormId: reservationDetail.reservationId })
    if (checkInFormList.length > 0) {
      checkInFormList.map((v, k) => {
        if (v.dataId || v.formDataId) {
          callApi('get', URL_GET_CHECK_IN_FORM, {
            checkinFormId: v.dataId || v.formDataId,
          }).then((res) => {
            let _data = res.data.data;
            if (_data) {
              checkInFormList[k] = { ...v, ..._data };
              setCheckInFormList(checkInFormList);
              _data.fileId && getSignature(checkInFormList[k], k);
            }
          });
        }
      });
    }
  }, []);

  const getSignature = (value, k) => {
    if ((value.dataId || value.formDataId) && value.fileId) {
      callApi(
        'get',
        URL_GET_CLIENT_SIGNATURE,
        { fileId: value.fileId },
        true,
      ).then((resSignature) => {
        if (resSignature.data) {
          checkInFormList[k] = { ...value, signature: resSignature.data };
          setCheckInFormList(checkInFormList);
        }
      });
    }
  };

  const _lists = (value) => [
    {
      text: (
        <Grid container spacing={8}>
          <Grid item md={6} xs={6} style={styles.colorGrey}>
            {t('Full Name')}:
          </Grid>
          <Grid item md={6} xs={6} style={styles.colorBlack}>
            {value.firstName} {value.lastName}
          </Grid>
        </Grid>
      ),
      icon: fullName,
    },
    // {
    //   text: <Text>{ t('Stay from to')}: <TextTitle>{value.checkIn && format(value.checkIn, dateFormat)} - {value.checkOut && format(value.checkOut, dateFormat)}</TextTitle></Text>,
    //   icon: checkIn,
    // },
    {
      text: (
        <Grid container spacing={8}>
          <Grid item md={6} xs={6} style={styles.colorGrey}>
            {t('Nationality')}:
          </Grid>
          <Grid item md={6} xs={6} style={styles.colorBlack}>
            {countriesList &&
              countriesList.length > 0 &&
              value.nationality &&
              ((countriesList.find((v) => v.value === value.nationality) &&
                countriesList.find((v) => v.value === value.nationality)
                  .name) ||
                value.nationality)}
          </Grid>
        </Grid>
      ),
      icon: nationality,
    },
    {
      text: (
        <Grid container spacing={8}>
          <Grid item md={6} xs={6} style={styles.colorGrey}>
            {t('Date of birth')}:
          </Grid>
          <Grid item md={6} xs={6} style={styles.colorBlack}>
            {value.birthdate
              ? isString(value.birthdate) && value.birthdate.includes('-00-00')
                ? value.birthdate.replace('-00-00', '')
                : format(value.birthdate, 'dd/MM/YYYY')
              : ''}
          </Grid>
        </Grid>
      ),
      icon: birthDay,
    },
    {
      text: (
        <Grid container spacing={8}>
          <Grid item md={6} xs={6} style={styles.colorGrey}>
            {t('Travel document number')}:<br />
            {visaCounttryList.includes(value.nationality) &&
              `${t('Visa number')}:`}
          </Grid>
          <Grid item md={6} xs={6} style={styles.colorBlack}>
            {value.identNumber || ' '}
            <br />
            {visaCounttryList.includes(value.nationality) && value.visaNumber}
          </Grid>
        </Grid>
      ),
      icon: identNumber,
    },
    // {
    //   text: <Text>{ t('Purpose of travel:') } <TextTitle>{ t('Relaxation') }</TextTitle></Text>,
    //   icon: purpose,
    // },
    {
      text: (
        <Grid container spacing={8}>
          <Grid item md={6} xs={6} style={styles.colorGrey}>
            {t('Address')}:{' '}
          </Grid>
          <Grid item md={6} xs={6} style={styles.colorBlack}>
            {value.street} {value.houseNumber} {value.district}
            {value.street || value.houseNumber || (value.district && ', ')}
            {value.city}
            {value.city && ', '}
            {value.state}
            {value.state && ', '}
            {value.postalCode}
          </Grid>
        </Grid>
      ),
      icon: address,
    },
    {
      text: (
        <Grid container spacing={8}>
          <Grid item md={6} xs={12} style={styles.colorGrey}>
            {' '}
            {t('Signature')}:
          </Grid>
          <Grid item md={6} xs={12}>
            {value.signature && (
              <img
                className="signature-preview"
                src={URL.createObjectURL(value.signature)}
                height={80}
                alt="signature"
              />
            )}
          </Grid>
        </Grid>
      ),
      icon: signature,
    },
  ];

  return (
    <Wrapper>
      {guestList.length > 0 ? (
        guestList.map((item, key) => (
          <div key={key}>
            <FormWrapper>
              <p style={styles.formHeader}>
                {t(`Stay from`)}{' '}
                <span style={styles.colorDate}>
                  {item.checkIn
                    ? format(item.checkIn, 'dd.MMMM, YYYY')
                    : reservationDetail.fromDate
                    ? format(reservationDetail.fromDate, 'dd.MMMM, YYYY')
                    : 'N/A'}{' '}
                </span>
                {t(`to`)}{' '}
                <span style={styles.colorDate}>
                  {item.checkOut
                    ? format(item.checkOut, 'dd.MMMM, YYYY')
                    : reservationDetail.toDate
                    ? format(reservationDetail.toDate, 'dd.MMMM, YYYY')
                    : 'N/A'}{' '}
                </span>
                {t(`for the purpose of relaxation`)}
              </p>
              <Grid container spacing={8} style={{ ...styles.gridContainer }}>
                {_lists(item).map((v, k) => (
                  <Grid item md={6} xs={12} style={styles.gridItem} key={k}>
                    <List dense={false} style={styles.gridItem}>
                      <ListItem style={k > 3 ? { alignItems: 'start' } : {}}>
                        <ListItemIcon style={styles.roundedIcon}>
                          <img src={v.icon} style={styles.icons} />
                        </ListItemIcon>
                        <ListItemText primary={v.text} />
                      </ListItem>
                    </List>
                  </Grid>
                ))}
              </Grid>
            </FormWrapper>
          </div>
        ))
      ) : (
        <div>{t('No Check-in Form')}</div>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  reservationDetail: selectReservationDetail(state).data,
  checkinFormDetail: selectCheckinFormDetail(state).data,
  visaCounttryList: state.visaCountry.data,
  countriesList: state.countries.data,
});

export default connect(mapStateToProps, { getCheckInform })(
  OverViewCheckInForm,
);
