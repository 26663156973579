import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MembersList from '../components/MembersList';
// import AddIcon from '@mui/icons-material/Add';
import {
  // Typography,
  Paper,
  // Button
} from '@mui/material';
// import StyledHeadLine from '../../../styles/StyledHeadLine';
// import StyledButtonLink from '../../../styles/StyledButtonLink';
import MemberForm from '../components/MemberForm';
import { GlobalDrawerContext } from '../../../contexts';
import { OPEN_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import GlobalDrawer from '../../../components/GlobalDrawer';
import { withTranslation } from 'react-i18next';
import DialogConfirm from '../../../common/DialogConfirm';
import { getWelcomers, deleteWelcomer } from '../../../actions/member';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { globalStyles } from '../../../styles/styles';
import { withStyles } from '@mui/styles'

const Members = ({
  t,
  getWelcomers,
  deleteWelcomer,
  members,
  classes,
}) => {

  useEffect(() => {
    getWelcomers();
  }, []);

  const { state, dispatch } = useContext(GlobalDrawerContext);

  const [openDeleteMember, setOpenDeleteMember] = useState({
    open: false,
    data: null,
    isLoadding: false,
  }); // open delete Reservation

  const deleteMember = () => {
    const data = openDeleteMember.data;
    if (data && data.canDelete && data.id) {
      setOpenDeleteMember({ ...openDeleteMember, isLoadding: true });
      deleteWelcomer({ id: data.id }).then(() => {
        setOpenDeleteMember({ isOpen: false, data: null, isLoadding: false })
        getWelcomers();
      });
    }
  }

  const openDeleteMemberConfirm = (open, item) => {
    setOpenDeleteMember({
      open: open,
      data: item,
    })
  }

  return (
    <Paper className={classes.root} >
      <TitleHasIcon variant="h5" title={t('Members')}
        label={''} icon={'group'}
        actionButton={t('Add new member')}
        handleAction={() => dispatch({ type: OPEN_DRAWER, drawerName: 'add_new_member' })}
      />
      {/*
      <StyledHeadLine>
        <Typography variant={'h6'}>{t('Members')}</Typography>
        <StyledButtonLink bold onClick={() => dispatch({ type: OPEN_DRAWER, drawerName: 'add_new_member' })}>
          <AddIcon className="icon-left" />
          <span className="text">{t('Add new member')}</span>
        </StyledButtonLink>
      </StyledHeadLine> */}

      {
        members.data && members.data.length > 0 &&
        <MembersList members={members.data}
          loading={members.loading}
          openUpdateMember={(data) => dispatch({ type: OPEN_DRAWER, drawerName: 'update_member', data })}
          openDeleteConfirm={openDeleteMemberConfirm}
        />
      }

      <GlobalDrawer isOpen={state.isOpen} >
        {
          (state.drawerName === 'add_new_member' || state.drawerName === 'update_member') &&
          <MemberForm defaultData={state.data || null} isUpdating={state.drawerName === 'update_member'} />
        }
      </GlobalDrawer>

      {/* open Delete Member */}
      {
        openDeleteMember.open &&
        <DialogConfirm isOpen={openDeleteMember.open}
          title={t('Deletion')} message={t('Are you sure that you want to delete this member?')}
          handleClose={() => openDeleteMemberConfirm(false, null)}
          handleAccess={deleteMember}
          isLoadding={openDeleteMember.isLoadding}
        />
      }
    </Paper>
  );
};

const mapStateToProps = state => ({
  members: state.member.memberList,
});

const mapActionToProps = {
  getWelcomers,
  deleteWelcomer,
};

export default withTranslation()(connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(globalStyles)(Members)));
