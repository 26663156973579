import React from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import Dashboard from '../features/dashboard';
import LoginForm from '../features/account/containers/Login';
import LoginWithPassword from '../features/account/containers/LoginWithPassword';
import ForgotPassword from '../features/account/containers/ForgotPassword';
import Register from '../features/account/containers/Register';
import VerifyEmail from '../features/account/containers/VerifyEmail';
import EmailVerify from '../features/account/containers/EmailVerify';
import ResetPassword from '../features/account/containers/ResetPassword';
import Onboarding from '../features/onboarding/containers/Onboarding';

const NotFound = () => <p>Sorry, nothing here</p>;

const AppRoutes = () => {
  const history = useNavigate();

  return (
    <Routes>
      <Route path="dashboard/*" element={<Dashboard />} />
      <Route path="login" history={history} element={<LoginForm />} />
      <Route path="register" element={<Register history={history} />} />
      <Route path="/" element={<Dashboard />} errorElement={<NotFound />} />
      <Route
        path="login-with-password"
        element={<LoginWithPassword history={history} />}
      />
      <Route element={<ForgotPassword />} path="forgot-password" />
      <Route element={<Onboarding />} path="onboarding/*" />
      <Route
        element={<EmailVerify history={history} />}
        path="user/emailVerify"
      />
      <Route
        element={<ResetPassword history={history} />}
        path="reset-password"
      />
      <Route
        element={<VerifyEmail history={history} />}
        path="user/verifyEmail"
      />
    </Routes>
  );
};

export default AppRoutes;
