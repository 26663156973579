import produce from 'immer';
import createReducer from '../../../redux/createReducer';
import { GET_COUNTRIES, GET_COUNTRIES_SUCCESS } from '../actionTypes';

const initialState = {
  countries: [],
  loading: false,
};

export default createReducer(initialState, {
  [GET_COUNTRIES]: (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  [GET_COUNTRIES_SUCCESS]: (state, action) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.countries = action.res;
    }),
});
