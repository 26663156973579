import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { FormControl,DialogTitle, TextField, Button, Dialog, DialogContent, FormHelperText } from '@mui/material';
import PlacesSearchBox from './PlacesSearchBox';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles'
import { omit } from 'lodash';
import CusTooltip from '../components/CusTooltip';

const StyledGoogleInput = styled.div`
  display: flex;
  width: 100%;
`;

const address_field = ['state', 'street', 'houseNumber', 'district', 'city', 'postalCode'];

const styles = theme => ({
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: 'initial',
    },
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }
})

const removeObjectKeyNull = (value) => {
  return Object.keys(value).forEach(key => value[key] == null && (value[key] = ''));
}

const GoogleInput = ({
  useMerk = true,
  field,
  form: { touched, errors, setFieldValue },
  classes,
  ...props
}) => {
  const [newValue, setNewValue] = useState(null);
  const [openMap, setOpenMap] = useState(false);
  const isMobile = window.innerWidth <= 600;

  function handleSaveClose(value, html) {
    removeObjectKeyNull(value);
    field.onBlur(field.name);
    field.onChange(html);
    setNewValue(html);
    setFieldValue(field.name, html);
    setFieldValue(`${field.name}_obj`, value);
    setOpenMap(false);
  };
  const { t } = useTranslation();
  return (
    <StyledGoogleInput>
      <FormControl fullWidth margin={'dense'}>
        <Button
          type="button"
          style={{ marginBottom: 15 }}
          variant={'outlined'}
          color={'primary'}
          onClick={() => setOpenMap(true)}
          disabled={props.disabled}
        >{t(props.label)}</Button>
        <div className={classes.formControl}>
          {props.showInfo && <CusTooltip title={props.helpContent} />}
          <TextField
            type={'text'}
            // onChange={ evt =>setFieldValue(field.name, evt.target.value ? evt.target.value : '') }
            // onBlur={field.onBlur(field.name)}
            value={newValue || field.value || ''}
            {...omit(props, ['showInfo', 'useYear', 'helpContent'])}
            label={t(props.label)}
            placeholder={t(props.placeholder)}
            variant={'standard'}
            disabled={true}
            fullWidth
          // error={touched[field.name] && !!errors[field.name]}
          // helperText={touched[field.name] ? errors[field.name] : undefined}
          />
        </div>
        {touched[field.name] && errors[field.name] && <FormHelperText error>{t(errors[field.name])}</FormHelperText>}
        {
          address_field.map((v, k) => (
            touched[v] && errors[v] && <FormHelperText error key={k}>{t(errors[v])}</FormHelperText>
          ))
        }

      </FormControl>
      <Dialog
        open={openMap}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
        style={isMobile ? { marginLeft: -45, marginRight: -45 } : {}}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle style={{ textAlign: 'center' }}>{t('Select Address')}</DialogTitle>
        <DialogContent style={isMobile ? { marginLeft: -35, marginRight: -27 } : {}}>
          <PlacesSearchBox onSaveClose={handleSaveClose} onClose={() => setOpenMap(false)} useMerk={useMerk} isMobile={isMobile} />
        </DialogContent>
      </Dialog>
    </StyledGoogleInput>
  );
};

export default withStyles(styles)(GoogleInput);
