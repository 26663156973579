import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import OnboardingMain from '../features/onboarding/containers/OnboardingMain';

export const AIRBNB_ACCOUNT = `airbnb-account`;
export const YOUR_PROPERTY = `your-property`;
export const INVOICE_DETAILS = `invoice-details`;
export const PERSON_COMPANY = `person-company`;
export const SIGNUP = `signup`;
export const LOGIN_DETAIL = `login-detail`;
export const LOGIN = `login`;
export const VERIFY = `verify`;
export const WELCOME = `welcome`;
export const BILLING = `billing`;

export const ONBOARDING_URL = `/onboarding`;
export const AIRBNB_ACCOUNT_URL = `${ONBOARDING_URL}/${AIRBNB_ACCOUNT}`;
export const YOUR_PROPERTY_URL = `${ONBOARDING_URL}/${YOUR_PROPERTY}`;
export const INVOICE_DETAILS_URL = `${ONBOARDING_URL}/${INVOICE_DETAILS}`;
export const PERSON_COMPANY_URL = `${ONBOARDING_URL}/${PERSON_COMPANY}`;
export const SIGNUP_URL = `${ONBOARDING_URL}/${SIGNUP}`;
export const LOGIN_DETAIL_URL = `${ONBOARDING_URL}/${LOGIN_DETAIL}`;
export const LOGIN_URL = `${ONBOARDING_URL}/${LOGIN}`;
export const VERIFY_URL = `${ONBOARDING_URL}/${VERIFY}`;
export const WELCOME_URL = `${ONBOARDING_URL}/${WELCOME}`;
export const BILLING_URL = `${ONBOARDING_URL}/${BILLING}`;
export const completedStepUrl = [
  ONBOARDING_URL,
  ONBOARDING_URL,
  SIGNUP_URL,
  LOGIN_DETAIL_URL,
  ...Array.from({ length: 10 }, (value, key) => PERSON_COMPANY_URL),
];

const NotFound = () => <p>Sorry, nothing here</p>;

const OnboardingRoutes = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="*" element={<OnboardingMain />} />
        <Route default element={NotFound} />
      </Routes>
    </Suspense>
  );
};

export default OnboardingRoutes;
