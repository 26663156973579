import React, { Fragment } from 'react';
import ContentLoader, {
  Facebook,
  List,
  BulletList,
} from 'react-content-loader';
import LineLoader from './ContentLoader';
import TablePlaceholder from './TablePlaceholder';

const Placeholder = ({
  children,
  loading,
  type,
  lineWidth,
  numberOfRows,
  ...props
}) => {
  switch (type) {
    case 'avatar':
      return loading ? (
        <div style={{ width: '60%', height: 'auto' }}>
          <ContentLoader
            rtl
            height={60}
            width={200}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
          >
            <rect x="70" y="15" rx="4" ry="4" width="117" height="6.4" />
            <rect x="70" y="35" rx="3" ry="3" width="85" height="6.4" />
            <circle cx="30" cy="30" r="30" />
          </ContentLoader>
        </div>
      ) : (
        children
      );
    case 'media':
      return loading ? <Facebook /> : children;
    case 'table':
      return (
        <Fragment>
          {loading ? (
            <div style={{ width: '100%', height: '100%', padding: 12 }}>
              <React.Fragment>
                {Array(!!numberOfRows ? numberOfRows : 15)
                  .fill('')
                  .map((e, i) => (
                    <TablePlaceholder
                      key={i}
                      style={{ opacity: Number(2 / i).toFixed(1) }}
                    />
                  ))}
              </React.Fragment>
            </div>
          ) : (
            children
          )}
        </Fragment>
      );
    case 'list':
      return (
        <Fragment>
          {loading ? (
            <div style={{ width: 500, height: '100%', padding: 12 }}>
              <List />
            </div>
          ) : (
            children
          )}
        </Fragment>
      );
    case 'line':
      return (
        <Fragment>
          {loading ? (
            <LineLoader {...props} width={!!lineWidth ? lineWidth : 120} />
          ) : (
            children
          )}
        </Fragment>
      );
    case 'bulletList':
      return <Fragment>{loading ? <BulletList /> : children}</Fragment>;

    case 'button':
      return (
        <Fragment>
          {loading ? (
            <LineLoader width={90} height={30} round={15} />
          ) : (
            children
          )}
        </Fragment>
      );

    default:
      return <Fragment>{loading ? <LineLoader /> : children}</Fragment>;
  }
};

export default Placeholder;
