import React, { Component } from 'react';
import UserForm from './UserForm';
import { UserFormType } from '../model';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { signUp } from '../actions';
import RegisterSuccess from './RegisterSuccess';

const SignUpSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  first_name: Yup.string().required('Required'),
  login_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
});

class Register extends Component {
  handleSignUp = (value) => {
    this.props.signUp(value);
  };

  render() {
    const initialValues = {
      login_name: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
    };
    if (this.props.account.detail.isSignUpSuccess) {
      return <RegisterSuccess history={this.props.history} />;
    }
    return (
      <UserForm
        {...this.props}
        formName={UserFormType.SIGN_UP}
        onSubmit={this.handleSignUp}
        initialValues={initialValues}
        validation={SignUpSchema}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { signUp })(Register);
