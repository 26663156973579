import React, { useState, Fragment, useEffect } from 'react';
import {
  Paper,
  Typography,
  FormControl,
  Button,
  FormHelperText,
  CircularProgress,
  Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DatePicker } from '@mui/lab';
import BackButton from '../../../common/BackButton';
import { connect } from 'react-redux';
import { format, compareAsc } from 'date-fns';
import { isEmpty } from 'lodash';
import { exportGuestBookPDF } from '../actions';
import { getReservations } from '../../../actions/reservation';
import ReservationList from '../../reservation/components/ReservationList';
import CusTooltip from '../../../components/CusTooltip';
import {
  selectProperties,
  selectPropertyById,
} from '../../../selectors/property-selectors';
import Dropdown from '../../../components/Dropdown';
import { formatPropertiesList } from '../../../components/generateFields';
import { selectReservationsList } from '../../../selectors/reservation-selectors';
import {
  URL_GUEST_BOOK_EXPORT,
  URL_REGISTRATION_BOOK_EXPORT,
} from '../../../shared/urls';
import { callApi } from '../../../custom-hooks/apiHook';
import {
  downloadFile,
  objectToQueryString,
  getAllParamsInUrl,
} from '../../../helpers';
import { withTranslation } from 'react-i18next';
import { globalStyles } from '../../../styles/styles';
import TitleHasIcon from '../../../common/TitleHasIcon';
import titleIcon from '../../../styles/title-icon/Unclosed_reservations_icon.png';
import guestBookIcon from '../../../styles/title-icon/Guestbook_icon.png';
import { getProperties, setAccommodationID } from '../../../actions/property';
import { grey, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const styles = {
  title: {
    color: grey[700],
    display: 'flex',
  },
};

const HOUSE_BOOK_NAME = 'House book';
const REGISTRATION_BOOK_NAME = 'Registration book';

const GuestBook = ({
  account,
  accommodationId,
  reservationsList,
  reservationsLoading,
  properties,
  propertyFromStore,
  isGlobalGuestBook,
  getReservations,
  classes,
  t,
  getProperties,
  setAccommodationID,
  ...props
}) => {
  const history = useNavigate();

  const [formValues, setFormValue] = useState({
    accommodationId: '',
    dateFrom: format(new Date(), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  useEffect(() => {
    let initialValues = {
      onlyClosed: 1,
    };
    if (isGlobalGuestBook) {
      initialValues = {
        ...initialValues,
        dateFrom: formValues.dateFrom,
        dateTo: formValues.dateTo,
      };
    }
    if (accommodationId) {
      initialValues = {
        ...initialValues,
        dateFrom: formValues.dateFrom,
        dateTo: formValues.dateTo,
        accommodationId: accommodationId,
      };
      setFormValue({
        ...formValues,
        accommodationId: accommodationId,
      });
    }
    if (props.location.search) {
      let get_current_params = getAllParamsInUrl(props.location.href);
      let forms = {
        ...(get_current_params.dateFrom && {
          dateFrom: get_current_params.dateFrom,
        }),
        ...(get_current_params.dateTo && { dateTo: get_current_params.dateTo }),
        ...(get_current_params.accommodationId && {
          accommodationId: get_current_params.accommodationId,
        }),
      };
      initialValues = { ...initialValues, ...forms };
      setFormValue({ ...formValues, ...forms });
    }
    getReservations(initialValues);
    getProperties();
  }, []);

  const [invalidDate, setInvalidTime] = useState(false);
  const [loadingGetReservations, setLoadingGetReservations] = useState(false);

  const [fileLoader, setFileLoader] = useState({
    done: false,
    error: null,
    houseBookPdfLoading: false,
    houseBookCsvLoading: false,
    registrationBookPdfLoading: false,
    registrationBookCsvLoading: false,
  });

  function handleChangeProperty(e) {
    setFileLoader({ ...fileLoader, error: null });
    setInvalidTime(compareAsc(formValues.dateFrom, formValues.dateTo) === 1);
    setAccommodationID(e.target.value);
    let new_params = {
      accommodationId: e.target.value,
      dateFrom: format(formValues.dateFrom, 'yyyy-MM-dd'),
      dateTo: format(formValues.dateTo, 'yyyy-MM-dd'),
    };
    setFormValue({
      ...formValues,
      ...new_params,
    });
    handleGetReservations(new_params);
    addParams(new_params);
  }

  function addParams(params) {
    let url_params = getAllParamsInUrl(props.location.href);
    url_params = { ...url_params, ...params };
    let toUrl = objectToQueryString(url_params);
    history(`${props.location.pathname}?${toUrl}`);
  }

  function handleGetReservations(params) {
    setLoadingGetReservations(true);
    getReservations({ onlyClosed: 1, ...params }).then(() => {
      setLoadingGetReservations(false);
    });
  }

  const accommodationIdPrint = isGlobalGuestBook
    ? formValues.accommodationId
    : accommodationId;

  function exportGuestBook(url, type, loading, name) {
    setFileLoader({ ...fileLoader, error: null, [loading]: true });
    const dateFrom = format(formValues.dateFrom, 'yyyy-MM-dd');
    const dateTo = format(formValues.dateTo, 'yyyy-MM-dd');
    callApi(
      'post',
      url,
      {
        accommodationId: accommodationIdPrint,
        dateFrom,
        dateTo,
        format: type,
      },
      true,
    )
      .then((res) => {
        setFileLoader({
          ...fileLoader,
          error: null,
          done: true,
          [loading]: false,
        });
        if (res.data) {
          const dateFromName = format(formValues.dateFrom, 'MMM dd, yyyy');
          const dateToName = format(formValues.dateTo, 'MMM dd, yyyy');
          const fileName = `${name}: from ${dateFromName} to ${dateToName}.${type}`;
          downloadFile(res.data, fileName, type);
        } else {
          setFileLoader({
            error: t(`There is no reservation for ${name}`),
            done: false,
            [loading]: false,
          });
        }
      })
      .catch((err) => {
        // const errorMsg = result(err, "err.response.data.errors[0].msg", `There is no reservation for ${name}`);
        setFileLoader({
          error: t(`There is no reservation for ${name}`),
          done: false,
          [loading]: false,
        });
      });
  }

  if (!isGlobalGuestBook && isEmpty(propertyFromStore)) {
    return <CircularProgress />;
  }

  const renderLoading = () => {
    return <CircularProgress size={20} style={{ marginLeft: 15 }} />;
  };

  return (
    <div>
      {!isGlobalGuestBook && (
        <BackButton label={propertyFromStore.accommodationName} />
      )}
      <Paper className={classes.root}>
        <TitleHasIcon
          variant="h5"
          title={t('Guest book')}
          label={!isGlobalGuestBook ? propertyFromStore.accommodationName : ''}
          isImage={true}
          icon={guestBookIcon}
        />

        <Grid
          container
          spacing={24}
          justify="center"
          alignItems={'flex-end'}
          style={{ padding: 30 }}
        >
          {isGlobalGuestBook && (
            <Fragment>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <FormControl
                  margin={'dense'}
                  key="dateFrom"
                  fullWidth
                  style={{ marginRight: 10 }}
                >
                  <Dropdown
                    isFullWidth
                    options={formatPropertiesList(properties.data)}
                    label={`${t('Select property')}`}
                    value={accommodationId}
                    onChange={handleChangeProperty}
                    disabled={loadingGetReservations}
                  />
                </FormControl>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControl
              margin={'dense'}
              key="dateFrom"
              fullWidth
              style={{ marginRight: 10 }}
            >
              <DatePicker
                name="dateFrom"
                variant="standard"
                format={'yyyy-MM-dd'}
                value={formValues.dateFrom}
                label={t('Date from')}
                disabled={loadingGetReservations}
                onChange={(selectedDate) => {
                  let new_params = {
                    accommodationId: formValues.accommodationId,
                    dateTo: format(formValues.dateTo, 'yyyy-MM-dd'),
                    dateFrom: format(selectedDate, 'yyyy-MM-dd'),
                  };
                  setFileLoader({ ...fileLoader, error: null });
                  // console.log(compareAsc(format(selectedDate, "yyyy-MM-dd"), formValues.dateTo))
                  // console.log(compareAsc(selectedDate, formValues.dateTo)=== 1)
                  setInvalidTime(
                    compareAsc(
                      format(selectedDate, 'yyyy-MM-dd'),
                      formValues.dateTo,
                    ) === 1,
                  );
                  setFormValue({ ...formValues, ...new_params });
                  handleGetReservations(new_params);
                  addParams(new_params);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControl margin={'dense'} key="dateTo" fullWidth>
              <DatePicker
                variant="standard"
                name="dateTo"
                format={'yyyy-MM-dd'}
                value={formValues.dateTo}
                label={t('Date to')}
                disabled={loadingGetReservations}
                onChange={(selectedDate) => {
                  let new_params = {
                    accommodationId: formValues.accommodationId,
                    dateFrom: format(formValues.dateFrom, 'yyyy-MM-dd'),
                    dateTo: format(selectedDate, 'yyyy-MM-dd'),
                  };
                  setFileLoader({ ...fileLoader, error: null });

                  // console.log(compareAsc(formValues.dateFrom, selectedDate),formValues.dateFrom,format(selectedDate, "yyyy-MM-dd"))
                  // console.log(compareAsc(formValues.dateFrom, selectedDate) === 1)
                  setInvalidTime(
                    compareAsc(formValues.dateFrom, selectedDate) === 1,
                  );
                  setFormValue({ ...formValues, ...new_params });
                  handleGetReservations(new_params);
                  addParams(new_params);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        {invalidDate && (
          <Typography
            align="center"
            style={{ color: red[500], paddingBottom: 30 }}
          >
            {t('Day to must be greater than Day from')}
          </Typography>
        )}
        {/* Export Button */}
        <Grid
          container
          spacing={24}
          justify="center"
          alignItems={'flex-end'}
          style={{ padding: '0 30px' }}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Grid
              container
              spacing={24}
              justify="center"
              alignItems={'flex-end'}
              style={{ padding: '0 30px' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3} style={styles.title}>
                <CusTooltip
                  title={`${t(
                    'The House book is kept for Foreign police purposes',
                  )}, ${t(
                    'therefore there are all guests from foreign countries and no guests from Czechia',
                  )}. ${t(
                    'The Foreign police requires these information',
                  )}: ${t('Full name of the guest')}, ${t(
                    'Date of birth',
                  )}, ${t('Nationality')}, ${t('Travel document number')}, (${t(
                    'Visa number',
                  )}), ${t('Residential address')}, ${t('Check-in')}, ${t(
                    'Check-out',
                  )}, ${t('Purpose of travel')}, ${t('Signature')}. ${t(
                    'The accommodation provider is obligated to keep the house book in paper form',
                  )}. ${t(
                    'The records in the house book must be kept at the current time, regularly, clearly and comprehensibly',
                  )}. ${t(
                    'These records must be in a chronological order',
                  )}. ${t(
                    'The accommodation provider has to keep these records for 6 years from the last record',
                  )}.`}
                />
                {t('House book')}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button
                  variant={'contained'}
                  disabled={
                    fileLoader.houseBookPdfLoading ||
                    !accommodationIdPrint ||
                    invalidDate
                  }
                  color={'primary'}
                  style={{ textTransform: 'none' }}
                  onClick={() =>
                    exportGuestBook(
                      URL_GUEST_BOOK_EXPORT,
                      'pdf',
                      'houseBookPdfLoading',
                      HOUSE_BOOK_NAME,
                    )
                  }
                >
                  {t('Export to PDF')}
                  {fileLoader.houseBookPdfLoading && renderLoading()}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button
                  variant={'outlined'}
                  color="primary"
                  disabled={
                    fileLoader.houseBookCsvLoading ||
                    !accommodationIdPrint ||
                    invalidDate
                  }
                  style={{ textTransform: 'none' }}
                  onClick={() =>
                    exportGuestBook(
                      URL_GUEST_BOOK_EXPORT,
                      'csv',
                      'houseBookCsvLoading',
                      HOUSE_BOOK_NAME,
                    )
                  }
                >
                  {t('Export to CSV')}
                  {fileLoader.houseBookCsvLoading && renderLoading()}
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={24}
              justify="center"
              alignItems={'flex-end'}
              style={{ padding: '0 30px 30px' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3} style={styles.title}>
                <CusTooltip
                  title={`${t(
                    'The Registration book is kept for the municipal offices managing local fees',
                  )}. ${t(
                    'In the book are all guests - foreigners and czech citizens',
                  )}. ${t(
                    'The municipal offices requires these information',
                  )}: ${t('Full name of the guest')}, ${t(
                    'Date of birth',
                  )}, ${t('Travel document number')}, ${t(
                    'Residential address',
                  )}, ${t('Check-in')}, ${t('Check-out')}, ${t(
                    'Purpose of travel',
                  )}, ${t('Signature')}. ${t(
                    'The accommodation provider is obligated to keep the registration book in paper form',
                  )}. ${t(
                    'The records in the house book must be kept at the current time, regularly, clearly and comprehensibly',
                  )}. ${t(
                    'These records must be in a chronological order',
                  )}. ${t(
                    'The accommodation provider has to keep these records for 6 years from the last record',
                  )}. ${t(
                    'In the registration book are written all guest, does not matter what age they are but the fees are paid only for guests between 18 and 70 years',
                  )}.`}
                />
                {t('Registration book')}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  disabled={
                    fileLoader.registrationBookPdfLoading ||
                    !accommodationIdPrint ||
                    invalidDate
                  }
                  style={{ textTransform: 'none' }}
                  onClick={() =>
                    exportGuestBook(
                      URL_REGISTRATION_BOOK_EXPORT,
                      'pdf',
                      'registrationBookPdfLoading',
                      REGISTRATION_BOOK_NAME,
                    )
                  }
                >
                  {t('Export to PDF')}
                  {fileLoader.registrationBookPdfLoading && renderLoading()}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button
                  variant={'outlined'}
                  color="primary"
                  disabled={
                    fileLoader.registrationBookCsvLoading ||
                    !accommodationIdPrint ||
                    invalidDate
                  }
                  style={{ textTransform: 'none' }}
                  onClick={() =>
                    exportGuestBook(
                      URL_REGISTRATION_BOOK_EXPORT,
                      'csv',
                      'registrationBookCsvLoading',
                      REGISTRATION_BOOK_NAME,
                    )
                  }
                >
                  {t('Export to CSV')}
                  {fileLoader.registrationBookCsvLoading && renderLoading()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!fileLoader.done && fileLoader.error && (
          <FormHelperText
            error={!!fileLoader.error}
            style={{ textAlign: 'center' }}
          >
            {fileLoader.error}
          </FormHelperText>
        )}
      </Paper>

      <Paper className={classes.root} style={{ marginTop: 30 }}>
        <TitleHasIcon
          variant="h5"
          title={t('Closed reservations')}
          label={''}
          isImage={true}
          icon={titleIcon}
        />
        {reservationsList.length > 0 && (
          <ReservationList
            reservations={reservationsList}
            loading={reservationsLoading}
          />
        )}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  account: state.account.detail,
  accommodationId: state.property.propertyDetail.accommodationId,
  properties: selectProperties(state),
  reservationsList: selectReservationsList(state),
  reservationsLoading: state.reservation.reservationsList.loading,
  propertyFromStore: selectPropertyById(state, props),
});

export default withTranslation()(
  connect(mapStateToProps, {
    exportGuestBookPDF,
    getReservations,
    getProperties,
    setAccommodationID,
  })(withStyles(globalStyles)(GuestBook)),
);
