import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Booking from '../../../styles/onboarding/connect_account/Booking_logo.png';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { generateBookingLogin } from './generateFields';
import { verifySMSCode } from '../../../actions/onboading';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import {
  addClientIntegration,
  updateClientIntegration,
} from '../../../actions/listing';

const styles = (theme) => ({
  DialogTitle: {
    textAlign: 'center',
    padding: '40px 0 30px',
    '& img': {
      height: 40,
    },
  },
  margin: {
    margin: '20px 0',
  },
  DialogContent: {
    padding: '0 50px 50px',
  },
  loginButton: {
    color: theme.palette.getContrastText(blue[900]),
    backgroundColor: blue[900],
    '&:hover': {
      backgroundColor: blue['A700'],
    },
  },
});

const validation = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const BookingDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  classes,
  objectKey,
  initialData,
  addClientIntegration,
  updateClientIntegration,
  account,
  clientInfo,
  verifySMSCode,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [needSmsCode, setNeedSmsCode] = useState({
    open: false,
    loading: false,
    data: {
      authorizationCode: '',
      SMSCode: '',
      username: '',
      password: '',
    },
  });

  let initialValues = {
    username: initialData ? initialData.username : '',
    password: initialData ? initialData.password : '',
    type: objectKey,
  };

  const formFields = generateBookingLogin();

  const handleBookingSubmit = (values) => {
    // const checkHasAirbnb = account.invalidCredentials && account.invalidCredentials.length > 0 && account.invalidCredentials.filter(v => v.type === objectKey);
    const checkHasAirbnb =
      clientInfo.integration && clientInfo.integration.booking;
    // console.log(checkHasAirbnb.id);
    if (checkHasAirbnb.connected === 1) {
      let newData = {
        username: values.username,
        password: values.password,
        id: checkHasAirbnb.id,
      };
      updateClientIntegration(newData).then((res) => {
        // console.log(res)
        setLoading(false);
        if (res.data && res.result) {
          handleSubmit(values);
        }
      });
    } else {
      setLoading(true);
      values.isOnboardingProcess = true;
      addClientIntegration(values).then((res) => {
        setLoading(false);
        if (res.data && res.result) {
          handleSubmit(values);
        } else if (
          res.errors &&
          res.errors.length > 0 &&
          res.errors[0].msg === 'BOOKING_NEED_SMS'
        ) {
          setNeedSmsCode({
            open: true,
            loading: false,
            data: {
              SMSCode: '',
              authorizationCode: res.errors[0].note,
              username: values.username,
              password: values.password,
            },
          });
        }
      });
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={handleBookingSubmit}
        enableReinitialize
        render={({ dirty, values, setFieldValue }) => {
          return (
            <Form>
              {formFields.map((v, k) => (
                <FastField {...v} key={k} />
              ))}
              <div className={classes.margin}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  className={classes.loginButton}
                  color="primary"
                  disabled={!dirty || loading}
                >
                  {t(`Login`)}
                  {loading && (
                    <CircularProgress size={20} style={{ marginLeft: 10 }} />
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  };

  const handleVerifySmsCode = () => {
    setNeedSmsCode({ ...needSmsCode, loading: true });
    verifySMSCode(needSmsCode.data).then((res) => {
      setNeedSmsCode({ ...needSmsCode, loading: false });
      console.log(res);
      if (res.data && res.result) {
        handleSubmit({ ...needSmsCode.data, type: objectKey });
      }
    });
  };

  const renderSMSCode = () => {
    return (
      <div>
        <TextField
          name="smsCode"
          value={needSmsCode.data.SMSCode}
          onChange={(e) =>
            setNeedSmsCode({
              ...needSmsCode,
              data: { ...needSmsCode.data, SMSCode: e.target.value },
            })
          }
          label="SMS Code"
          placeholder="SMS Code"
          fullWidth
        />
        <Button
          color="secondary"
          variant="contained"
          disabled={!needSmsCode.data.SMSCode || needSmsCode.loading}
          fullWidth
          style={{ marginTop: 30 }}
          onClick={handleVerifySmsCode}
        >
          {t('Send')}
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div style={{ textAlign: 'right' }}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
        <img src={Booking} alt="" />
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        {!needSmsCode.open ? renderForm() : renderSMSCode()}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
  clientInfo: state.client.clientInfo.data,
});

const mapActionToProps = {
  addClientIntegration,
  updateClientIntegration,
  verifySMSCode,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(styles)(BookingDialog));
