/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import i18n from 'i18next';
// import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import { useTranslation } from 'react-i18next';
// import TitleHasIcon from '../../../common/TitleHasIcon';
import { globalStyles } from '../../../styles/styles';
import { withStyles } from '@mui/styles'
import { Paper } from '@mui/material';
import FirstPicture from '../../../styles/uby-port/1.png';
import SecondPictureEN from '../../../styles/uby-port/2_en.png';
import SecondPictureCZ from '../../../styles/uby-port/2_cz.png';
import ThirdPicture from '../../../styles/uby-port/3.png';
import FourPicture from '../../../styles/uby-port/4.png';

const styles = {
    marginTB: {
        marginTop: 30,
        marginBottom: 15
    },
    fontBold: {
        fontWeight: 'bold'
    }
}

const UbyportHelp = ({ classes }) => {
//   const { t } = useTranslation();
  console.log("t ne", i18n.languages[0]);
  const isEn = i18n.languages[0].indexOf('en') !== -1;
  return (
    <Paper className={classes.root} style={{color: '#000'}}>
        <h2>{isEn ? 'Where do I find the Ubyport ID (IDUB) and Ubyport client name short (Zkratku Ubyport klienta)?' : 'Kde najdu IDUB a Zkratku Ubyport klienta?'}</h2>

        <p style={styles.marginTB}><span style={styles.fontBold}>1)</span> {isEn ? `First, go to 
         https://ubyport.policie.cz/ubyport_formular/Default.aspx and click on "Přihlášení do systému"` : `Nejprve jděte na stránku
         https://ubyport.policie.cz/ubyport_formular/Default.aspx klikněte na "Přihlášení do systému"` }</p>
        <img src={FirstPicture} style={{width: '100%'}}/>
        <p style={styles.marginTB}><span style={styles.fontBold}>2)</span> {isEn ? 'Enter your login information.' : 'Zadejte své přihlašovací údaje.'}</p>
        <img src={isEn ? SecondPictureEN : SecondPictureCZ} style={{width: '100%'}}/>
        <p style={styles.marginTB}><span style={styles.fontBold}>3)</span> {isEn ? `Choose property, click on "Import dat (UNL soubor)".` : `Vyberte ubytování a klikněte na "Import dat (UNL soubor)".`}</p>
        <img src={ThirdPicture} style={{width: '100%'}}/>
        <p style={styles.marginTB}><span style={styles.fontBold}>4)</span> {isEn ? `On this page you can already find all the needed information => "Ubyport ID" and "Ubyport client name short". 
            Ubyport ID is a 12-digit number (where you see an orange box) and 
            Ubyport client name short is a five-digit code that consists of capital letters (where you see a green box).`: `Na této stránce už můžete najít všechny potřebné informace = "IDUB" a "Zkratku ubytování". IDUB je 12-místné číslo (na místě, kde vidíte oranžový rámeček) a Zkratka ubytování je pětimístný kód z velkých písmen (na místě, kde vidíte zelený rámeček).`}
        </p>
        <img src={FourPicture} style={{width: '100%'}}/>
    </Paper>
  );
};

export default withStyles(globalStyles)(UbyportHelp);
