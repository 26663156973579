/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Grid,
  Paper,
  TextField,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { red, blue, green } from '@mui/material/colors';
import PlayButton from '../../../common/PlayButton';
import BookingFav from '../../../styles/icon/booking.png';
import AirBnbFav from '../../../styles/icon/airbnb.png';
import {
  getListingForMapping,
  mapListings,
  verifySMSCode,
} from '../../../actions/onboading';
import MappingListing from '../../../components/MappingListing';
import {
  addClientIntegration,
  updateClientIntegration,
} from '../../../actions/listing';
import { getClientInfo } from '../../../actions/account';
import AirbnbDialog from '../../onboarding/components/AirbnbDialog';
const styles = (theme) => ({
  root: {
    marginBottom: 15,
  },
  imgTitle: {
    // height: 30,
    width: '100%',
    maxWidth: '100%',
  },
  heading: {
    // display: 'flex',
    // fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    // flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: '80%',
    color: theme.palette.text.secondary,
  },
  ExpansionPanelSummary: {
    padding: '0 15px',
    '&:hover': {
      cursor: 'default !important',
    },
    '& button': {
      margin: 5,
    },
  },
  GridTitle: {
    paddingRight: '0 !important',
  },
  rightBtn: {
    minWidth: 100,
    padding: '5px 10px',
  },
  middleDiv: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'center',
  },
  Desynchromises: {
    color: red[400],
    borderColor: red[400],
  },
  propertyList: {
    marginBottom: 30,
    '& .propertyTitle': {
      fontWeight: 500,
      marginBottom: 15,
    },
    '& .listingsItems': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: width < 500 ? 'column' : 'row',
      borderBottom: '1px solid #ccc',
      padding: '10px 0',
      '&:first-child': {
        borderTop: '1px solid #ccc',
      },
      '& .listingName': {
        display: 'flex',
        placeItems: 'center',
        '& img': {
          marginRight: 5,
        },
      },
    },
  },
});

const width = window.innerWidth;

const loadingElement = () => {
  return <CircularProgress size={20} style={{ marginLeft: 15 }} />;
};
export const AIRBNB = 'airbnb';
class ConnectClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentExpanded: false,
      openAirbnbDialog: false,
      formValue: {
        [props.name]: {
          username: '',
          password: '',
        },
      },
      isConnected: false,
      mapDatas: [],
      isLoadingMapping: false,
      isLoadingLogin: false,
      airLock: '',
      isLoadingListingForMapping: false,
      needSmsCode: {
        open: false,
        loading: false,
        data: {
          authorizationCode: '',
          SMSCode: '',
          username: '',
          password: '',
        },
      },
    };

    this.handleIsConnect = this.handleIsConnect.bind(this);
    this.handleMapDatas = this.handleMapDatas.bind(this);
    this.setConnect = this.setConnect.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginSmsCode = this.handleLoginSmsCode.bind(this);
  }

  handleAddData = (values) => {
    //connectData[values.type] = values;
    //setConnectData(connectData);
    this.setState({ openAirbnbDialog: false });
  };

  renderDialog = () => {
    return (
      <div>
        {this.state.openAirbnbDialog && (
          <AirbnbDialog
            isOpen={this.state.openAirbnbDialog}
            initialData={null}
            handleClose={() => this.setState({ openAirbnbDialog: false })}
            handleSubmit={this.handleAddData}
            objectKey={AIRBNB}
          />
        )}
      </div>
    );
  };

  handleExpanded = (expanded) => {
    this.setState({ currentExpanded: expanded });
  };

  handleIsConnect() {
    const { clientInfo, name, getListingForMapping } = this.props;
    let _connected =
      name &&
      clientInfo &&
      clientInfo.integration &&
      clientInfo.integration[name] &&
      clientInfo.integration[name].connected === 1 &&
      clientInfo.integration[name].valid === 1;
    this.setState({ isLoadingListingForMapping: true });
    if (_connected) {
      getListingForMapping().then((res) => {
        this.setState({ isLoadingListingForMapping: false });
        if (res.data && res.result) {
          this.handleExpanded(true);
        } else {
          res.result &&
            res.data &&
            res.errors.length > 0 &&
            this.setState({ openAirbnbDialog: true });
        }
      });
    } else {
      this.handleExpanded(true);
      this.setState({ isLoadingListingForMapping: false });
    }
  }

  handleMapDatas(params) {
    let { mapDatas } = this.state;
    let index = mapDatas.findIndex(
      (e) =>
        e.accommodationId === params.accommodationId &&
        e.airbnbId === params.airbnbId,
    );
    index === -1 ? mapDatas.push(params) : (mapDatas[index] = params);
    this.setState({ mapDatas });
  }

  expandIcon = () => {
    const { classes, t, clientInfo, name } = this.props;
    const { isLoadingListingForMapping } = this.state;
    let _connected =
      name &&
      clientInfo &&
      clientInfo.integration &&
      clientInfo.integration[name] &&
      clientInfo.integration[name].connected === 1 &&
      clientInfo.integration[name].valid === 1;

    return !this.state.currentExpanded ? (
      <Button
        variant={'outlined'}
        size="small"
        color={'primary'}
        className={classes.rightBtn}
        onClick={this.handleIsConnect}
        disabled={isLoadingListingForMapping}
      >
        {_connected ? t('Manage') : t('Connect')}
        {isLoadingListingForMapping && loadingElement()}
      </Button>
    ) : (
      <IconButton
        onClick={() => this.handleExpanded(false)}
        style={{ padding: 2 }}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  onSubmit = (e) => {
    console.log('values', e);
  };

  onChangeForm = (e, key) => {
    const { name } = this.props;
    let { formValue } = this.state;
    formValue[name][key] = e.target.value;
    this.setState({ formValue });
  };

  handleLogin() {
    const { addClientIntegration, name } = this.props;
    let { formValue } = this.state;
    let params = {
      type: name,
      username: formValue[name].username,
      password: formValue[name].password,
    };
    this.setState({ isLoadingLogin: true });
    addClientIntegration(params).then((res) => {
      this.setState({ isLoadingLogin: false });
      if (
        res.errors &&
        res.errors.length > 0 &&
        res.errors[0].msg === 'BOOKING_NEED_SMS'
      ) {
        this.setState({
          needSmsCode: {
            open: true,
            loading: false,
            data: {
              SMSCode: '',
              authorizationCode: res.errors[0].note,
              username: params.username,
              password: params.password,
            },
          },
        });
      }
      if (
        res.errors &&
        res.errors.length > 0 &&
        res.errors[0].msg === 'AIR_LOCK_ERROR'
      ) {
        this.setState({
          airLock: res.errors[0].note,
        });
      }
    });
  }

  handleLoginSmsCode() {
    let { needSmsCode } = this.state;
    let { verifySMSCode, getClientInfo } = this.props;
    this.setState({ needSmsCode: { ...needSmsCode, loading: true } });
    verifySMSCode(needSmsCode.data).then((res) => {
      this.setState({ needSmsCode: { ...needSmsCode, loading: false } });
      res.data && res.result && getClientInfo();
    });
  }

  setConnect() {
    const { mapDatas } = this.state;
    this.setState({ isLoadingMapping: true });
    if (mapDatas.length > 0) {
      this.props
        .mapListings({ ids: mapDatas, isOnboardingProcess: true })
        .then((res) => {
          this.setState({ isLoadingMapping: false });
          res.data &&
            res.result &&
            this.setState({
              currentExpanded: false,
            });
        });
    }
  }

  loginForm = () => {
    const {
      name,
      // loadingConnectForm, handleAddClientIntegration,
      initialValues,
      clientInfo,
      t,
    } = this.props;
    const { formValue, isLoadingLogin, needSmsCode, airLock } = this.state;
    return (
      <Grid container spacing={24} justify="center">
        <Grid item xs={12} md={6}>
          <div style={{ color: '#999', textAlign: 'justify' }}>
            {t('Dear')}{' '}
            <Typography
              variant="subtitle2"
              component="span"
              style={{ display: 'inline' }}
            >
              {clientInfo && clientInfo.clientName}
            </Typography>
            ,{t('your client')}{' '}
            <Typography
              variant="subtitle2"
              component="span"
              style={{ display: 'inline' }}
            >
              {(initialValues && initialValues.clientName) || '__'}
            </Typography>{' '}
            {t('might have changed the')}{' '}
            {name === 'airbnb' ? 'Airbnb' : 'Booking.com'}{' '}
            {t('login details recently')}. {t('Please')},{' '}
            {t('provide the current username and password below')}.
          </div>
          {!needSmsCode.open ? (
            <div>
              <TextField
                fullWidth
                name={`username_${name}`}
                id={`username_${name}`}
                label={t('Username')}
                placeholder={t('Username')}
                onChange={(e) => this.onChangeForm(e, 'username')}
                value={formValue[name]['username']}
              />
              <TextField
                fullWidth
                type="password"
                name={`password_${name}`}
                id={`password_${name}`}
                label={t('Password')}
                placeholder={t('Password')}
                onChange={(e) => this.onChangeForm(e, 'password')}
                value={formValue[name]['password']}
              />

              {airLock && (
                <Paper
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: green[500],
                  }}
                >
                  <Typography
                    align="center"
                    variant="subtitle1"
                    style={{ padding: '15px 0', color: '#fff' }}
                  >
                    {t(`Go to `)}{' '}
                    <a href={airLock} target="_blank">
                      {' '}
                      {t(`Link`)}
                    </a>{' '}
                    {t(`and checked`)} "I'm not a robot" {t(`then login again`)}
                  </Typography>
                </Paper>
              )}

              <Button
                style={{
                  marginTop: 15,
                  color: '#fff',
                  background: name === 'airbnb' ? red[400] : blue[400],
                }}
                variant={'contained'}
                color={'primary'}
                fullWidth
                disabled={
                  isLoadingLogin ||
                  !formValue[name]['username'] ||
                  !formValue[name]['password']
                }
                onClick={this.handleLogin}
              >
                {t('Login')}
                {isLoadingLogin && loadingElement()}
              </Button>
            </div>
          ) : (
            <div>
              <TextField
                fullWidth
                type="text"
                name={`sms_code_${name}`}
                id={`sms_code_${name}`}
                label={t('SMS Code')}
                placeholder={t('SMS Code')}
                onChange={(e) => {
                  needSmsCode.data.SMSCode = e.target.value;
                  this.setState({ needSmsCode });
                }}
                value={needSmsCode.data.SMSCode}
              />
              <Button
                style={{
                  marginTop: 15,
                  color: '#fff',
                  background: name === 'airbnb' ? red[400] : blue[400],
                }}
                variant={'contained'}
                color={'primary'}
                fullWidth
                disabled={needSmsCode.loading || !needSmsCode.data.SMSCode}
                onClick={this.handleLoginSmsCode}
              >
                {t('Send')}
                {needSmsCode.loading && loadingElement()}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  renderListing = () => {
    const { t, listingForMapping, classes } = this.props;
    const { isLoadingMapping } = this.state;
    return (
      <Grid container spacing={24} justify="center">
        <Grid
          item
          xs={12}
          md={12}
          style={{ borderBottom: '1px solid #ccc', fontWeight: 600 }}
        >
          {listingForMapping && listingForMapping.length === 0 && (
            <Typography variant="subtitle1" align="center">
              {t(`Empty listing`)}
            </Typography>
          )}
          {listingForMapping &&
            listingForMapping.length > 0 &&
            listingForMapping.map((v, k) => (
              <div className={classes.propertyList} key={k}>
                <Typography variant="h6" className="propertyTitle">
                  {v.accommodationName}
                </Typography>
                <div>
                  {v.listings &&
                    v.listings.airbnb &&
                    v.listings.airbnb.map((item, idx) => {
                      return (
                        <Grid
                          container
                          alignItems="center"
                          key={idx}
                          className={'listingsItems'}
                        >
                          <Grid item xs={12} md={6} className={'listingName'}>
                            <img src={AirBnbFav} height={20} />
                            <div>{item.listingName}</div>
                          </Grid>
                          <Grid item xs={12} md={2} align="center">
                            {t(`is the same as`)}
                          </Grid>
                          <Grid item xs={12} md={4} align="right">
                            <MappingListing
                              favIcon={BookingFav}
                              datas={v.listings && v.listings.booking}
                              accommodationId={v.accommodationId}
                              airbnbId={item.listingId}
                              handleMapDatas={this.handleMapDatas}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                </div>
              </div>
            ))}
        </Grid>
        {listingForMapping && listingForMapping.length > 0 && (
          <Button
            style={{ marginTop: 15, color: '#fff', width: 100 }}
            variant={'contained'}
            color={'primary'}
            fullWidth
            onClick={() => this.setConnect()}
            disabled={isLoadingMapping}
          >
            {t('Save')}
            {isLoadingMapping && loadingElement()}
          </Button>
        )}
      </Grid>
    );
  };

  render() {
    const {
      // connectType,label,  set, link,
      img,
      name,
      classes,
      t,
      clientInfo,
    } = this.props;
    let _connected =
      name &&
      clientInfo &&
      clientInfo.integration &&
      clientInfo.integration[name] &&
      clientInfo.integration[name].connected === 1 &&
      clientInfo.integration[name].valid === 1;

    return (
      <Accordion
        square
        expanded={this.state.currentExpanded}
        className={classes.root}
      >
        <AccordionSummary className={classes.ExpansionPanelSummary}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.GridTitle}
          >
            <Grid item xs={12} md={4}>
              <Grid container spacing={0} justify="center">
                <Grid
                  item
                  xs={12}
                  md={8}
                  className={classes.heading}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={img} style={{ width: 90 }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="caption"
                className={classes.secondaryHeading}
                headlineMapping={{ caption: 'div' }}
              >
                {_connected && (
                  <div className={classes.middleDiv}>
                    <PlayButton />
                    {t('Connected and being synchronised')}
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant={'outlined'}
                    size="small"
                    color={'default'}
                    disabled
                  >
                    {t('Reservations')}
                  </Button>
                  <Button
                    variant={'outlined'}
                    size="small"
                    color={'default'}
                    disabled
                  >
                    {t('Messages')}
                  </Button>
                </div>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {this.expandIcon()}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {!_connected ? (
            <Grid container spacing={24}>
              <Grid item xs={12}>
                {name && this.loginForm()}
              </Grid>
            </Grid>
          ) : (
            this.renderListing()
          )}
        </AccordionDetails>
        {this.renderDialog()}
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => ({
  clientInfo: state.client.clientInfo.data,
  listingForMapping: state.onboarding.listingForMapping.data,
});

export default connect(mapStateToProps, {
  getListingForMapping,
  mapListings,
  addClientIntegration,
  updateClientIntegration,
  verifySMSCode,
  getClientInfo,
})(withStyles(styles)(withTranslation()(ConnectClient)));
