import React from 'react';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const DownloadText = styled.span`
  font-size: 14px;
`;

const Download = ({ onClick, t }) => (
  <IconButton color={'primary'} onClick={onClick}>
    <DownloadText>{t('Download')}</DownloadText>
    <ArrowDownward fontSize={'small'} />
  </IconButton>
);

export default withTranslation()(Download);
