import createReducer from '../../../redux/createReducer';
import produce from 'immer';
import {
  FETCH_ACCOMODATIONS,
  FETCH_ACCOMODATIONS_SUCCESS,
  FETCH_ACCOMODATIONS_FAILURE,
  ADD_ACCOMODATION_SUCCESS,
  ADD_ACCOMODATION,
} from '../actionTypes';

const initalState = {
  list: [],
  loading: false,
};

export default createReducer(initalState, {
  [FETCH_ACCOMODATIONS]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_ACCOMODATIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    list: action.res,
  }),
  [ADD_ACCOMODATION]: (state) => ({
    ...state,
    loading: true,
  }),
  [ADD_ACCOMODATION_SUCCESS]: (state, action) =>
    produce(state, (draft) => {
      draft.list.push(action.res);
      draft.loading = false;
    }),
  [FETCH_ACCOMODATIONS_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
});
