import React, { Component } from 'react';
import GuestBook from './GuestBook';

class GlobalGuestBook extends Component {
  render() {
    return <GuestBook {...this.props} isGlobalGuestBook />;
  }
}

export default GlobalGuestBook;
