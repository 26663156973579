import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import { withStyles } from '@mui/styles'
import ReferralImg from "../../../styles/image/referral_img1.jpg";
import ImgFooter from "../../../styles/image/success_picture.jpg";
import One from "../../../styles/icon/1.png";
import Two from "../../../styles/icon/2.png";
import Three from "../../../styles/icon/3.png";
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../../common/input';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import ReferralProgress from '../components/referralProgress'
import { getLinkReferral, sentInvite, getListOfInviteUser } from '../../../actions/referral';
import { connect } from 'react-redux'

const styles = theme => ({
  header: {
  },
  img_common: {
    margin: "0 10px"
  },
  linkCopy: {
    alignItems: "center",
    border: "1px solid",
    display: "inline-flex",
    borderRadius: "20px",
    padding: "4px"
  },
  btn_sendInvite: {
    backgroundColor: "rgb(245, 246, 247)",
    border: "2px solid sandybrown",
    borderRadius: "20px"
  },
  body: {
    margin: "50px 0"
  },
  footer: {
    width: "100%",
    background: `url(${ImgFooter})`,
    height: "100%",
    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }
});

const Referral = ({ classes, referral, ...props }) => {

  useEffect(() => {
    props.getLinkReferral();
    props.getListOfInviteUser();
  }, [])

  const { t } = useTranslation();

  const [state, setState] = useState({
    loading: false,
    done: false
  });
  const [copied, setCopied] = useState({
    isCopied: false,
    index: null,
  })

  const onCopyEmail = (value) => {
    setCopied({
      isCopied: true,
      index: value,
    });
    setTimeout(() => {
      setCopied({
        isCopied: false,
        index: null,
      });
    }, 1200);
  }

  const handleSubmit = (values, actions) => {
    setState({ ...state, loading: true, done: false })
    try {
      props.sentInvite(values).then(res => {
        if (res.result && res.data) {
          props.getListOfInviteUser();
          setState({ ...state, done: true })
          actions.resetForm({ email: '' })
        }
        else
          actions.setErrors(res.errors)
      })
    } catch (error) {
      setState({ ...state, loading: false })
    }
  }

  const validationSchema = Yup.object().shape({
  })
  const initialValues = { email: null }
  // console.log(referral)
  const link = referral.link ? referral.link : { ref_link: null }

  return (
    <div style={{ margin: '0' }}>
      <Grid container spacing={24}>
        <div className={classes.header}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{
                fontSize: "45px",
                fontWeight: "550",
                margin: "20px 0"
              }}>
                {t("Advise your friends and get both rewarded")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography style={{ fontSize: "16px" }}>{t('For every new user that creates an' +
                ' account by using your invitation link and uses Best Guest for at least a month,'
                + ' you will get 500 Kč for yourself and 300 Kč for them in credit')}. <a href="">{t('Terms')}</a>
              </Typography>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                render={({ dirty }) => (
                  <Form>
                    <Grid container spacing={24} direction="column" style={{ marginTop: "25px", marginBottom: "20px" }}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item md={9} xs={12}>
                            <Field
                              type='email'
                              name="email"
                              disabled={!!state.loading}
                              component={FormInput}
                              placeholder={t("Enter email address")}
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <Button className={classes.btn_sendInvite} style={{ backgroundColor: "rgb(245, 246, 247)" }} type="submit" variant="contained" color="secondary" disabled={!dirty} >
                              <Typography style={{ color: "sandybrown" }}>{t("Send invite")}</Typography>
                              {state.loading && <CircularProgress size={20} className={classes.buttonProgress} />}
                            </Button>
                            {state.done && <Typography color="secondary">{t('Send Success')}</Typography>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              />
              <div style={{ position: "relative" }}>
                <Typography variant={"h6"}>{t('Share your invitation link')}</Typography>
                <Grid container spacing={24}>
                  <Grid item xs={12} lg={7}>
                    <div className={classes.linkCopy}>
                      <Typography style={{ marginLeft: "10px" }}>{link.ref_link}</Typography>
                      <CopyToClipboard text={link.ref_link} onCopy={() => onCopyEmail(link.ref_link)}>
                        <Button style={{ color: "cadetblue" }}>{t('Copy')}</Button>
                      </CopyToClipboard>
                      {copied.isCopied && <span style={{ position: "absolute", fontSize: 11, left: "315px", top: '50px', background: "white" }}>{t("Copied")}</span>}
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} lg={5}>
                    <img className={classes.img_common} src={Messenger} alt="" width={50} />
                    <img className={classes.img_common} src={Phone} alt="" width={46} />
                    <img className={classes.img_common} src={Bacham} alt="" width={46} />
                  </Grid>
                */}
                </Grid>
              </div>

            </Grid>
            <Grid item xs={12} sm={4}>
              <img width="100%" src={ReferralImg} alt="" />
            </Grid>
          </Grid>
        </div>

        <div className={classes.body}>
          <Grid container spacing={24}>

            <Grid item lg={4} xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={3}>
                  <img alt="" src={One} width={60} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h5" style={{ fontWeight: "500" }}> {t('Invite your friends')}</Typography>
                  <Typography variant="subtitle1"> {t('Would you like to make the life easier for your friends who host as well')}?</Typography>
                  <Typography variant="subtitle1"> {t('Save them time and remove worries with the host’s duties by inviting them to join Best Guest, just send them your unique link')}.</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={3}>
                  <img alt="" src={Two} width={60} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h5" style={{ fontWeight: "500" }}> {t('Be attentive during their trial')}</Typography>
                  <Typography variant="subtitle1"> {t('By sharing your link you give each of your friends 300 Kč in credit It’s a gift for our novices')}.</Typography>
                  <Typography variant="subtitle1"> {t('Support your friends along the way It will be very nice from you to ask them time to time how is their trial going in case they need help and your sincere interest will certainly make them happy')}.</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={3}>
                  <img alt="" src={Three} width={60} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h5" style={{ fontWeight: "500" }}> {t('You get rewarded for your help')}</Typography>
                  <Typography variant="subtitle1"> {t('You get from us 500 Kč in subscription for each host you refer')}.</Typography>
                  <Typography variant="subtitle1"> {t('Do you have many hosts that joined Best Guest thanks to you? We have a special gift for you')}!</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.referralprogress}>
          <ReferralProgress listInvite={referral.listUser} />
        </div>

        <Grid container className={classes.footer}>
          <Grid item xs={12} sm={6}>
            <Typography style={{ margin: "120px 40px" }} variant="h4">{t('Tell your friends they could save about')} <span style={{ color: 'darkorange' }} >{t('21 hours a week')}</span> {t('by using Best Guest')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
const mapStateToProps = (state) => ({
  referral: state.referral.ref.data,
});

export default connect(mapStateToProps, { getLinkReferral, sentInvite, getListOfInviteUser })(withStyles(styles)(Referral));
