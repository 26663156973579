/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { List, Divider } from '@mui/material';
import { MENUS, SETTINGS_MENUS } from './main-layout/menus';
import NavLink from './main-layout/NavLink';
import LogoutButton from '../features/account/containers/LogoutButton';
import { useTranslation } from 'react-i18next';
import logoImage from '../styles/image/logo_without_background.png';
import LanguagePicker from '../common/LanguagePicker';
import { AccountBox } from '@mui/icons-material';

const CustomDrawer = ({ handleDrawerToggle, classes, isUpdateInfo }) => {
  const { t } = useTranslation();
  const checkMobile = window.innerWidth < 500;
  return (
    <div>
      <div className={classes.brand}>
        <img src={logoImage} style={{ height: 25 }} />
      </div>
      {isUpdateInfo ? (
        <div>
          <List component="nav">
            {MENUS.map(({ name, iconName, isImage, path }) => (
              <NavLink
                to={path}
                onClick={handleDrawerToggle}
                key={path}
                name={t(name)}
                iconName={iconName}
                isImage={isImage}
              />
            ))}
          </List>
          {/* <NavItem iconName={''} name={''} hasBackground disabled={true} /> */}
          <Divider className={classes.divider} />
          <List component="nav">
            {SETTINGS_MENUS.map(
              ({ name, iconName, isImage, path, description }) => (
                <NavLink
                  to={path}
                  onClick={handleDrawerToggle}
                  key={path}
                  name={t(name)}
                  description={t(description)}
                  iconName={iconName}
                  isImage={isImage}
                />
              ),
            )}
            {/* <NavItem iconName={''} name={''} hasBackground disabled={true} /> */}
            <Divider className={classes.divider} />
            <LogoutButton />
            {checkMobile ? <LanguagePicker isMobile={checkMobile} /> : ''}
          </List>
        </div>
      ) : (
        <NavLink
          to={'account'}
          onClick={handleDrawerToggle}
          key={'account'}
          name={'Account'}
          iconName={AccountBox}
        />
      )}
    </div>
  );
};

export default CustomDrawer;
