import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getWelcomersSelect } from '../../../actions/member';

const PropertyHome = ({ children, getWelcomersSelect, memberListRoles }) => {
  useEffect(() => {
    let getWelcomerRole =
      memberListRoles.length > 0 &&
      memberListRoles.filter((v) => v.roleName === 'Welcomer');
    if (getWelcomerRole.length > 0) {
      getWelcomersSelect({ role: getWelcomerRole[0].id });
    }
  }, [memberListRoles]);

  return <div>{children}</div>;
};

const mapStateToProps = (state) => ({
  memberListRoles: state.member.memberListRoles.data,
});

export default connect(mapStateToProps, { getWelcomersSelect })(PropertyHome);
