export const tableStyles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

export const detailPageStyles = theme => ({
  info: {
    margin: theme.spacing.unit * 2,
  },
  actions: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

export const listingStyle = theme => ({
  containers: {
    border: `1px solid #e0e0e0`,
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    borderRadius: 5,
  },
  slider: {
    margin: theme.spacing.unit * 0.5,
  },
});

export const accomodationDetailStyle = theme => ({
  navBar: { marginBottom: '24px' },
  divider: { marginTop: '10px', marginBottom: '10px' },
  paper: { padding: '16px', margin: '20px 0' },
  button: { margin: theme.spacing.unit },
  box: { position: 'relative' },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing.unit}px 0`,
  },
  rightIcon: { marginLeft: theme.spacing.unit },
});
