import React from 'react';
import { tableStyles } from '../../../styles/styles';
import { Paper, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import Placeholder from '../../../common/Placeholder';
import { format } from 'date-fns';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  // VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import VirtualTableCustom from '../../../components/VirtualTableCustom';
import { useTranslation } from 'react-i18next';
import { SEND_INVITATION, DONT_SEND_INVITATION } from './UpcomingReservation';

const DeadlineFormatter = ({ value }) => {
  const valueFormat = value ? parseInt(value) : 0;
  if (valueFormat === 0) {
    return `today`;
  }
  if (valueFormat === -1) {
    return `yesterday`;
  }
  if (valueFormat === 1) {
    return `tomorrow`;
  }
  if (valueFormat < -1) {
    return `${Math.abs(valueFormat)} days ago`;
  }

  if (valueFormat > 1) {
    return `in ${valueFormat} days`;
  }
  return '';
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DeadlineFormatter} {...props} />
);

const styles = {
  styleHeader: {
    color: 'rgb(16, 172, 243)',
    border: '1px solid rgb(16, 172, 243)',
    padding: '5px 15px',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
  },
};

const comparePriority = (a, b) => {
  return a < b ? -1 : 1;
};
const UpcomingReservationList = ({
  datas,
  classes,
  loading,
  onSetSendInvitation,
  onSetSendAllInvitation,
  invitationList,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: 'accommodationName', title: t('Property') },
    { name: 'bookedBy', title: t('Master Guest') },
    {
      name: 'checkInOut',
      title: `${t('Check-in')}/-${t('out')}')`,
      getCellValue: (row) =>
        // `${format(row.fromDate, 'd.MMM')} - ${format(row.toDate, 'd.MMM')}`,
        `${format(row.fromDate, 'dd/MM')} - ${format(row.toDate, 'dd/MM')}`,
    },
    {
      name: 'notSendAll',
      // title: <span onClick={() => console.log('ok')} style={styles.styleHeader}>Don't send all</span>,
      getCellValue: (row) => (
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          className={classes.buttonDisableSuccess}
          onClick={() => onSetSendInvitation(row, DONT_SEND_INVITATION)}
          disabled={
            invitationList &&
            invitationList[row.reservationId] === DONT_SEND_INVITATION
          }
        >
          {t(`Don't send`)}
        </Button>
      ),
    },
    {
      name: 'sendAll',
      // title: <span onClick={() => console.log('ok')} style={styles.styleHeader}>SEND ALL</span>,
      getCellValue: (row) => (
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          className={classes.buttonDisableSuccess}
          onClick={() => onSetSendInvitation(row, SEND_INVITATION)}
          disabled={
            invitationList &&
            invitationList[row.reservationId] === SEND_INVITATION
          }
        >
          {t(`Send`)}
        </Button>
      ),
    },
  ];

  const tableColumnExtensions = [
    { columnName: 'property', width: 60, align: 'center' },
    { columnName: 'master_guest', width: 60, align: 'center' },
    { columnName: 'checkin_checkout', width: 60, align: 'center' },
    { columnName: 'notSend', width: 100, align: 'center' },
    { columnName: 'send', width: 100, align: 'center' },
  ];

  let integratedSortingColumnExtensions = [
    { columnName: 'deadline', compare: comparePriority },
  ];

  let exampleHeaderCell = (props) => {
    const { column } = props;
    if (column.name === 'notSendAll') {
      return (
        <TableHeaderRow.Cell
          {...props}
          children={
            <Button
              size="small"
              onClick={() => onSetSendAllInvitation(DONT_SEND_INVITATION)}
              style={styles.styleHeader}
            >
              Don't send all
            </Button>
          }
        />
      );
    }
    if (column.name === 'sendAll') {
      return (
        <TableHeaderRow.Cell
          {...props}
          children={
            <Button
              size="small"
              onClick={() => onSetSendAllInvitation(SEND_INVITATION)}
              style={styles.styleHeader}
            >
              Send all
            </Button>
          }
        />
      );
    }
    return (
      <TableHeaderRow.Cell
        {...props}
        key={column.name}
        getMessage={() => column.title}
      />
    );
  };

  return (
    <Paper className={classes.root}>
      <Placeholder loading={loading} type={'table'}>
        <Grid rows={datas} columns={columns}>
          <SortingState
            defaultSorting={[{ columnName: 'deadline', direction: 'asc' }]}
            columnExtensions={[
              { columnName: 'property', sortingEnabled: true },
              { columnName: 'master_guest', sortingEnabled: true },
              { columnName: 'checkin_checkout', sortingEnabled: true },
              { columnName: 'notSend', sortingEnabled: false },
              { columnName: 'send', sortingEnabled: false },
            ]}
          />
          <IntegratedSorting
            columnExtensions={integratedSortingColumnExtensions}
          />
          <DateTypeProvider for={['deadline']} />
          <Table />
          {/* <TableColumnResizing defaultColumnWidths={tableColumnExtensions} /> */}
          <VirtualTableCustom
            dataCount={datas.length}
            columnExtensions={tableColumnExtensions}
          />
          <TableHeaderRow
            showSortingControls
            cellComponent={exampleHeaderCell}
          />
        </Grid>
      </Placeholder>
    </Paper>
  );
};

export default withStyles(tableStyles)(UpcomingReservationList);
