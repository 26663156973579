export const emailServicesDomains = [
  'yahoo.com',
  'hotmail.com',
  'gmail.com',
  'me.com',
  'aol.com',
  'mac.com',
  'live.com',
  'googlemail.com',
  'msn.com',
  'yahoo.com',
  'facebook.com',
  'verizon.net',
  'outlook.com',
  'icloud.com'
]

export const protectedKeyCodes = [9, 17, 18, 35, 36, 37, 38, 39, 40, 45]
