import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, LinearProgress } from '@mui/material';
import {
  completedStepUrl,
  LOGIN_DETAIL,
  PERSON_COMPANY,
  SIGNUP,
  VERIFY,
} from '../../../routes/onboarding-routes';
import { loginRedirect } from '../../../helpers';
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';
import Signup from './Signup';
import Verify from './Verify';
import LoginDetail from './LoginDetail';
import PersonCompany from './PersonCompany';

const LoadingPage = () => (
  <div>
    <LinearProgress />
  </div>
);

const styles = {
  rootLayout: {
    backgroundColor: '#f5f6f7',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  rootLayoutMobile: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  item: {
    margin: '50px 0',
  },
  page: {
    padding: 50,
  },
  pageMobile: {
    padding: 25,
    boxShadow: 'none',
  },
};

const OnboardingMain = (props) => {
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { account } = props;
    const isAuthenticated = account.detail.tokens.authToken;
    const clientInfoFilled = account.detail.clientInfoFilled;

    !isAuthenticated &&
      history(
        loginRedirect(
          location && location.pathname,
          location && location.search,
        ),
      );
    clientInfoFilled && isAuthenticated && history('/dashboard');

    let completedStep = account.detail && account.detail.completedStep + 1;
    if (
      completedStep &&
      completedStep <= 11 &&
      completedStepUrl[completedStep]
    ) {
      if (location.pathname !== completedStepUrl[completedStep]) {
        history(completedStepUrl[account.detail && completedStep]);
      }
    }
  }, []);

  const { children, account } = props;
  const completedStep = account.detail.completedStep || 0;
  const md = completedStep > 0 && completedStep < 3 ? 3 : 6;

  return (
    <div>
      <Grid container style={styles.rootLayoutMobile}>
        <Grid item md={md} sm={6} xs={12} style={styles.item}>
          <Paper style={styles.pageMobile}>
            <Routes>
              <Route path={`${SIGNUP}`} element={<Signup md={3} />} />
              <Route path={`${VERIFY}`} element={<Verify />} />
              <Route path={`${LOGIN_DETAIL}`} element={<LoginDetail />} />
              <Route
                path={`${PERSON_COMPANY}`}
                element={<PersonCompany history={history} />}
              />
              <Route default element={<LoadingPage />} />
            </Routes>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {})(OnboardingMain);
