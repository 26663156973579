import React, { Fragment } from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import 'react-virtualized-select/styles.css';
import { FormHelperText, FormControl } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import CusTooltip from '../components/CusTooltip';

const styles = (theme) => ({
  optionItems: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      background: '#ddd',
    },
  },
  optionImg: {
    height: 20,
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});

const CustomSelect = ({
  field,
  options,
  form: { touched, errors, setFieldValue },
  classes,
  ...props
}) => {
  const { t } = useTranslation();

  function CountryOptionRenderer({
    focusedOption,
    focusedOptionIndex,
    focusOption,
    key,
    labelKey,
    option,
    options,
    selectValue,
    style,
    valueArray,
    valueKey,
  }) {
    return (
      <a
        key={key}
        style={style}
        onClick={() => selectValue(option)}
        className={classes.optionItems}
      >
        {option.label}
        <img src={option.flag} className={classes.optionImg} />
      </a>
      // <div
      //   key={key}
      //   onClick={() => selectValue(option)}
      //   onMouseEnter={() => focusOption(option)}
      //   className={classes.optionItems}
      // >
      //   <label>{option.label}</label>
      //   <img src={option.flag} className={classes.optionImg} />
      // </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.formControl}>
        {props.showInfo && <CusTooltip title={props.helpContent} />}
        <FormControl fullWidth>
          <VirtualizedSelect
            label={t(props.label)}
            placeholder={t(props.placeholder)}
            onChange={(op) => setFieldValue(field.name, op ? op.value : '')}
            clearValue={() => setFieldValue(field.name, '')}
            value={
              !isEmpty(options)
                ? find(options, (op) => {
                    if (field.value) {
                      return op.value === field.value;
                    } else if (props.initialValue) {
                      return op.value === props.initialValue;
                    }
                  })
                : { value: '', label: t(props.placeholder) }
            }
            options={options}
            optionRenderer={CountryOptionRenderer}
          />
          {touched[field.name] && errors[field.name] && (
            <FormHelperText error>{t(errors[field.name])}</FormHelperText>
          )}
        </FormControl>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(CustomSelect);
