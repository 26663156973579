import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import 'nprogress/nprogress.css';
import App from './App';
import { Provider } from 'react-redux';
import { initStore, history } from './redux/configureStore';
import {
  getItemFromStorage,
  BESTGUEST_STORE_NAME,
} from './helpers/localStorage';
import * as serviceWorker from './serviceWorker';
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import themeConfig from './themeConfig';
import './i18n';
import moment from 'moment-timezone';
import Firebase, { FirebaseContext } from './components/Firebase';
import { BrowserRouter } from 'react-router-dom';

moment.tz.setDefault('UTC');

const existingUser = getItemFromStorage(BESTGUEST_STORE_NAME);
let initialState;
if (existingUser) {
  initialState = {
    account: {
      detail: existingUser,
    },
  };
} else {
  initialState = {};
}

const store = initStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themeConfig}>
      <Suspense fallback={<CircularProgress size={60} />}>
        <FirebaseContext.Provider value={new Firebase()}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </FirebaseContext.Provider>
      </Suspense>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
