import React, { useContext } from 'react';
import { Drawer } from '@mui/material';
import { GlobalDrawerContext } from '../contexts';
import { CLOSE_DRAWER } from '../custom-hooks/useGlobalDrawer';
import DrawerAppBar from '../shared/drawer-app-bar';

const styles = {
  drawerContainer: {
    overflowY: 'auto',
    padding: '15px 0',
    marginTop: 40,
  },
};

const GlobalDrawer = ({ children, isOpen, hideBackButton, globalDrawer }) => {
  const { dispatch } = useContext(GlobalDrawerContext);
  return (
    <Drawer onClose={() => {}} onOpen={() => {}} open={isOpen} anchor="right">
      <div>
        {!!hideBackButton ? null : (
          <DrawerAppBar
            onClick={() => {
              globalDrawer
                ? globalDrawer.dispatch({ type: CLOSE_DRAWER })
                : dispatch({ type: CLOSE_DRAWER });
            }}
          />
        )}
      </div>
      <div style={styles.drawerContainer} id="globalDrawerId">
        {children}
      </div>
    </Drawer>
  );
};

export default GlobalDrawer;
