import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { green, grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  buttonStyle: {
    border: `1px solid ${green[500]}`,
    color: green[500],
  },
  buttonStyleActive: {
    background: green[500],
    color: grey[100],
    '&:hover': {
      background: green[300],
      color: grey[100],
    },
  },
  DialogContent: {
    padding: 0,
  },
  Checkbox: {
    padding: 30,
    textAlign: 'center',
  },
  disabled: {
    background: grey[100],
    borderColor: grey[300],
  },
});

const AlertComfirm = ({
  classes,
  open,
  handleClose,
  handleConfirm,
  content,
  content1,
  confirmContent,
  loading,
}) => {
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState(false);

  return (
    <Dialog fullWidth maxWidth="md" disableBackdropClick open={open}>
      <DialogContent className={classes.DialogContent}>
        <div>{content}</div>
        {confirmContent && (
          <div className={classes.Checkbox}>
            <FormControlLabel
              control={
                <Checkbox
                  value="checkedC"
                  onClick={(e) => setConfirm(e.target.checked)}
                />
              }
              label={confirmContent}
            />
          </div>
        )}
        <div>{content1}</div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          className={classes.buttonStyle}
          variant="outlined"
          onClick={handleClose}
        >
          {t('Close')}
        </Button>
        <Button
          disabled={(confirmContent && !confirm) || loading || false}
          className={[classes.buttonStyle, classes.buttonStyleActive].join(' ')}
          classes={{ disabled: classes.disabled }}
          onClick={handleConfirm}
          variant="outlined"
        >
          {t('Confirm')}
          {loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(AlertComfirm);
