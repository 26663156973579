import styled from 'styled-components';

const StyledButtonLink = styled.div`
  color: ${props => (!!props.color ? props.color : '#2196f3')};
  background: transparent;
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  font-size: 16px;
  font-weight: ${props => (!!props.bold ? 'bold' : undefined)};
  align-items: center;
  &:disabled,
  &[disabled]{
    background-color: #cccccc;
  }
  .icon-left {
    font-size: 16px;
  }
  .font-20 {
    font-size: 20px;
  }
  .text {
    padding-left: 3px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export default StyledButtonLink;
