import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import accReducers from '../features/account/reducers';
import modalReducer from '../shared/reducers';
import accomodationReducer from '../features/accomodation/reducers/reducers';
import checkInForm from '../features/reservation/reducers/checkInForm';
import countriesReducer from '../features/reservation/reducers/countriesReducer';
import countries from '../reducers/countryReducer';
import visaCountry from '../reducers/visaCountryReducer';
import propertyReducer from '../reducers/property';
import remindersReducer from '../reducers/reminders';
import automationsReducer from '../reducers/automation';
import referral from '../reducers/referral';
import clientReducer from '../reducers/account';
import reservation from '../reducers/reservation';
import member from '../reducers/member';
import onboarding from '../reducers/onboarding';
import global from '../reducers/globalReducer';
import listingsReducer from '../reducers/listingsReducer';
import accomodationDetailReducer from '../features/accomodation/reducers/reducersDetail';
import listingReducer from '../features/accomodation/reducers/listingReducer';
import exportGuestBook from '../features/guest-book/reducers/exportGuestBook';
import invoiceReducer from '../reducers/invoiceReducer';
import accountEpic from '../features/account/epics';
import guestBookEpics from '../features/guest-book/epics';
import accomodationEpic from '../features/accomodation/epics';
import reservationEpic from '../features/reservation/epics';
import settingsReducer from '../features/settings/reducers';
import clientDetailsEpic from '../features/settings/epics';
import { connectRouter } from 'connected-react-router';

export const rootEpic = combineEpics(
  accountEpic,
  accomodationEpic,
  reservationEpic,
  guestBookEpics,
  clientDetailsEpic,
);

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    account: accReducers,
    accomodation: accomodationReducer,
    accomodationDetail: accomodationDetailReducer,
    modal: modalReducer,
    reservation,
    member,
    referral,
    onboarding,
    checkInForm,
    country: countriesReducer,
    exportGuestBook,
    listingState: listingReducer,
    settings: settingsReducer,
    invoice: invoiceReducer,
    global,
    countries,
    visaCountry,
    automations: automationsReducer,
    property: propertyReducer,
    reminders: remindersReducer,
    client: clientReducer,
    listing: listingsReducer,
  });
