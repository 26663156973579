import React from 'react';
import styled from 'styled-components';
import { media } from '../styles/commonStyles';

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: ${(props) => (!props.noShadow ? `0 10px 21px #e7eeef` : null)};
  padding: ${(props) =>
    props.noShadow || props.noPadding
      ? !!props.padding
        ? `${props.padding}px`
        : null
      : `30px 45px`};
  ${media.phone`padding: 3px 15px`}
`;

const BoxShadow = ({ children, noShadow, noPadding, padding }) => {
  return (
    <Container noShadow={noShadow} noPadding={noPadding} padding={padding}>
      {children}
    </Container>
  );
};

export default BoxShadow;
