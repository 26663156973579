import React, { useReducer, useEffect } from 'react';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import styled from 'styled-components';

export function countReducer(state, action) {
  switch (action.type) {
    case 'increment':
      return state ? state + 1 : 1;
    case 'decrement':
      return state >= 1 ? state - 1 : 0;
    case 'change':
      return action.value;
    default:
      return state;
  }
}

function CounterHooks({ value, onChange }) {
  const [count, dispatch] = useReducer(countReducer, value);
  useEffect(
    () => {
      onChange(count);
    },
    [count]
  );
  return (
    <StyledWrapper>
      <Button
        style={btnStyle}
        aria-current={true}
        onClick={() => dispatch({ type: 'decrement' })}
      >
        <Remove style={{ color: 'rgba(0, 0, 0, 0.42)' }} />
      </Button>
      <StyledInput
        value={count}
        type="number"
        onChange={e =>
          dispatch({
            type: 'change',
            value: e.target.value ? parseFloat(e.target.value) : ''
          })
        }
      />
      <Button style={btnStyle} onClick={() => dispatch({ type: 'increment' })}>
        <AddIcon style={{ color: 'rgba(0, 0, 0, 0.42)' }} />
      </Button>
    </StyledWrapper>
  );
}

export default CounterHooks;

const StyledInput = styled.input`
  height: 50px;
  width: 100%;
  background-color: transparent;
  text-align: center;
  /* border-radius: 10px; */
  /* padding-left: 15px; */
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  /* box-shadow: 0 4px 8px 0 rgba(42, 55, 71, 0.15); */
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.64);
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 170px;
`;

const btnStyle = {
  minWidth: 50,
  height: 50,
  width: 50,
  borderRadius: 25,
  backgroundColor: '#ffffff',
  border: '1px solid rgba(0, 0, 0, 0.20)',
  boxShadow: '0 4px 8px 0 rgba(42, 55, 71, 0.15)'
};
