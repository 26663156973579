import React from 'react';
import { FormControl, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import EmailAutoComplete from '../../../common/EmailAutoComplete';
import { useTranslation } from 'react-i18next';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="CZ"
      // format={"CZ##########"}
    />
  );
}

const StyledInput = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth margin={'dense'}>
      {props.isTextMask ? (
        <TextField
          // type={'text'}
          onChange={(evt) =>
            setFieldValue(field.name, evt.target.value ? evt.target.value : '')
          }
          onBlur={field.onBlur(field.name)}
          value={field.value || ''}
          {...props}
          label={t(props.label)}
          placeholder={t(props.placeholder)}
          variant={'standard'}
          error={touched[field.name] && !!errors[field.name]}
          helperText={touched[field.name] ? errors[field.name] : undefined}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      ) : (
        <TextField
          type={props.type || 'text'}
          onChange={(evt) => {
            // setFieldValue(field.name, evt.target && evt.target.value ? evt.target.value : '')
            const new_value =
              props && props.type === 'email'
                ? evt
                : evt.target.value
                ? evt.target.value
                : '';
            setFieldValue(field.name, new_value);
          }}
          onBlur={field.onBlur(field.name)}
          value={field.value || ''}
          {...props}
          label={t(props.label)}
          placeholder={t(props.placeholder)}
          variant={'standard'}
          error={touched[field.name] && !!errors[field.name]}
          helperText={touched[field.name] ? errors[field.name] : undefined}
          InputProps={{
            ...(props &&
              props.type === 'email' && { inputComponent: EmailAutoComplete }),
          }}
        />
      )}
    </FormControl>
  );
};

export default StyledInput;
