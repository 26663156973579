import React from 'react';
import TitleHasUnderLine from '../common/TitleHasUnderLine';
import {
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import SwitchesCustom from '../common/SwitchesCustom';
import { FastField } from 'formik';
import CusTooltip from './CusTooltip';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

const styles = {
  middleItem: {
    display: 'flex',
    alignItems: 'center'
  },
  noMargin: {
    margin: '0'
  },
  mr10: {
    marginLeft: 0,
    marginRight: 10,
  }
}


const GroupFields = ({ fields, title, helpContent, lg, md, isUport, id }) => {
  const { t } = useTranslation();

  const localFeeHint = `${t('Is the property registered at the City office for the local fee from the accommodation capacity')}?`;

  const _fastField = (field) => <FastField {...omit(field, ['showHelp', 'helpContent', 'helpPosition', 'disableReason', 'disableTooltipType'])} />;

  return (
    <div style={{ marginBottom: 30 }}>
      {
        title &&
        <TitleHasUnderLine
          marginTop={30}
          marginBottom={25}
          title={t(title)}
          size="h6"
          helpContent={helpContent}
          isUport={isUport}
        />
      }
      {
        id === 'detail_local_fee' &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FastField
            id={`Switch_LocalFee`}
            name={`Switch_LocalFee`}
            color={'secondary'}
            component={SwitchesCustom}
          />
          <CusTooltip title={localFeeHint} iconStyle={styles.mr10} />
        </div>
      }
      <Grid container spacing={24}>
        {
          fields.map((field, key) => field && (
            <Grid key={key} item xs={12} sm={6} lg={lg || 4} md={md || 6} style={styles.middleItem}>
              <Grid container spacing={0}>
                {
                  !field.disableTooltipType && field.disableReason &&
                  <Grid item xs={12}>
                    <Typography variant="body2" color="error">{field.disableReason}</Typography>
                  </Grid>
                }
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    field.helpPosition === 'left' && field.showHelp && field.helpContent &&
                    <CusTooltip title={field.helpContent} iconStyle={styles.mr10} />
                  }
                  {
                    field.disableTooltipType && field.disableReason ?
                      <Tooltip title={field.disableReason} id="tooltip_custom">
                        <div style={{width: '100%'}}>{_fastField(field)}</div>
                      </Tooltip> : _fastField(field)
                  }

                  {
                    field.helpPosition !== 'left' && field.showHelp && field.helpContent &&
                    <CusTooltip title={field.helpContent} iconStyle={styles.noMargin} />
                  }
                </Grid>
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

export default GroupFields;
