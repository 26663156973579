import React, { memo, useContext, useState } from 'react';
import PropertyForm from '../components/PropertyForm';
import {
  // forEach,
  isEmpty,
} from 'lodash';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { updateProperty, getPropertyDetail } from '../../../actions/property';
// import { format } from 'date-fns';
import StyledFormWrapper from '../../../components/property/StyledFormWrapper';
import { selectPropertyDetail } from '../../../selectors/property-selectors';
import { CLOSE_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import { GlobalDrawerContext } from '../../../contexts';
import { addressToValue } from '../../../helpers';

const UpdateProperty = memo(
  ({ property, propertyFromStore, updateProperty, getPropertyDetail }) => {
    const { dispatch } = useContext(GlobalDrawerContext);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    function handleUpdate(values, { setErrors }) {
      const address_obj = values.address_obj;
      let changedParams = {
        ...omit(values, [
          'coverImage',
          'listings',
          'address',
          'address_obj',
          'rentingFrom',
          'haveImage',
          'welcomerUrl',
          'welcomerName',
          'hasReservation',
          'Switch_LocalFee',
        ]),
        accommodationId: property.accommodationId,
      };

      // remove key null
      Object.keys(changedParams).map((key) => {
        !changedParams[key] && delete changedParams[key];
      });

      if (!isEmpty(changedParams)) {
        setLoading(true);
        if (!isEmpty(address_obj)) {
          changedParams = {
            ...changedParams,
            ...(address_obj && addressToValue(values.address_obj, false)),
          };
        }

        if (values.hasReservation) {
          delete changedParams.street;
          delete changedParams.city;
          delete changedParams.district;
          delete changedParams.cityDistrict;
          delete changedParams.country;
          delete changedParams.postalNumber;
          delete changedParams.registerNumber;
          delete changedParams.orientationNumber;
        }

        changedParams.payingCapacityFee = values.Switch_LocalFee ? '1' : '0';

        updateProperty(changedParams).then((res) => {
          setLoading(false);
          if (res.data && res.result) {
            setIsSuccess(true);
            setTimeout(() => {
              dispatch({ type: CLOSE_DRAWER, drawerName: 'update_property' });
              getPropertyDetail({ accommodationId: property.accommodationId });
            }, 1000);
          } else {
            res.errors.length > 0 &&
              res.errors[0].note &&
              setErrors(res.errors[0].note);
          }
        });
      }
    }

    // let accommodationDetail = {};
    // forEach(property, (value, key) => {
    //   if (!property[key]) {
    //     if (key === 'rentingFrom') {
    //       const current = new Date();
    //       accommodationDetail[key] = current.toISOString();
    //     } else {
    //       accommodationDetail[key] = '';
    //     }
    //   } else {
    //     accommodationDetail[key] = value;
    //   }
    // });
    // accommodationDetail.address = `${property.street} ${property.registerNumber}${property.orientationNumber && '/'}${property.orientationNumber}, ${property.cityDistrict}, ${property.district}`
    property.address = `${property.street} ${property.registerNumber}${
      property.orientationNumber && '/'
    }${property.orientationNumber}, ${property.cityDistrict}, ${
      property.district
    }`;
    const hasReservation = property.hasReservation;
    return (
      <StyledFormWrapper>
        <PropertyForm
          isUpdating={true}
          hasReservation={hasReservation}
          loading={loading}
          isSuccess={isSuccess}
          initialValues={property}
          handleSubmit={handleUpdate}
        />
      </StyledFormWrapper>
    );
  },
);

const mapStateToProps = (state) => ({
  propertyFromStore: selectPropertyDetail(state),
});

export default connect(mapStateToProps, { updateProperty, getPropertyDetail })(
  UpdateProperty,
);
