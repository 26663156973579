/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import FormInput from '../../../common/input';
import { Formik, Form, FastField } from 'formik';
import { withTranslation } from 'react-i18next';
import { grey, red, green } from '@mui/material/colors';
import AirbnbImg from '../../../styles/onboarding/connect_account/Airbnb_logo.png';
import * as Yup from 'yup';

const styles = (theme) => ({
  Dialog: {
    padding: 0,
    borderRadius: 10,
  },
  DialogTitle: {
    backgroundColor: grey[300],
    padding: '10px 30px',
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  LogoImg: {
    height: 30,
    marginRight: 10,
  },
  DialogContent: {
    padding: 30,
  },
  LoginSection: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    '& button': {
      padding: 15,
      backgroundColor: red[400],
    },
  },
});

const validation = Yup.object().shape({
  username: Yup.string().required('Required').nullable(),
  password: Yup.string().required('Required').nullable(),
});

class AirbnbLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValues: {
        username: '',
        password: '',
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {}

  onSubmit(values) {
    const { data, handleSubmit } = this.props;
    let _new_values = {
      ...values,
      id: data.id,
    };
    return handleSubmit(_new_values);
  }

  render() {
    let { account, isOpen, t, classes, loading, airLock } = this.props;
    const { currentValues } = this.state;
    const { firstName } = account.detail;

    return (
      <Dialog open={isOpen} classes={{ paper: classes.Dialog }}>
        <DialogTitle className={classes.DialogTitle}>
          <img src={AirbnbImg} className={classes.LogoImg} alt="" /> login
          details update
        </DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <DialogContentText>
            {t('Dear')}{' '}
            <Typography
              variant="subtitle2"
              component="span"
              style={{ display: 'inline' }}
            >
              {firstName}
            </Typography>
            ,{' '}
            {t(
              'your Airbnb login details might have been changed recently or Airbnb just needs to verify that you have given Best Guest access to your account',
            )}
            . {t('Please')},{' '}
            {t('provide your current username and password below')}.
          </DialogContentText>
          <Formik
            enableReinitialize
            initialValues={currentValues}
            validationSchema={validation}
            onSubmit={this.onSubmit}
            render={({ dirty, values, ...other }) => {
              return (
                <Form style={{ marginTop: 20 }}>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <FastField
                        variant="outlined"
                        iconName={'person'}
                        // type="text"
                        name="username"
                        label={t('UserName')}
                        placeholder={t('UserName')}
                        component={FormInput}
                        disabled={!!loading}
                        autoFocus
                      />
                      <FastField
                        variant="outlined"
                        iconName={'lock'}
                        type="password"
                        name="password"
                        label={t('Password')}
                        placeholder={t('Password')}
                        component={FormInput}
                        disabled={!!loading}
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.LoginSection}>
                    <Button
                      fullWidth
                      variant={'contained'}
                      color={'primary'}
                      type="submit"
                      disabled={!dirty || !!loading}
                    >
                      {t('Login')}{' '}
                      {!!loading && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15 }}
                        />
                      )}
                    </Button>
                  </div>
                  {airLock && (
                    <Paper
                      style={{ marginBottom: 15, backgroundColor: green[500] }}
                    >
                      <Typography
                        align="center"
                        variant="subtitle1"
                        style={{ padding: '15px 0', color: '#fff' }}
                      >
                        {t(`Go to `)}{' '}
                        <a href={airLock} target="_blank">
                          {' '}
                          {t(`Link`)}
                        </a>{' '}
                        {t(`and checked`)} "I'm not a robot"{' '}
                        {t(`then login again`)}
                      </Typography>
                    </Paper>
                  )}
                </Form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(
  mapStateToProps,
  {},
)(withTranslation()(withStyles(styles)(AirbnbLoginForm)));
