import { makeRequest } from '../redux/apiCall';
import {
  URL_REFERRAL_GET_LINK,
  URL_REFERRAL_LIST_INVITE_USER,
  URL_REFERRAL_SEND_INVITE
} from '../shared/urls';

export const GET_LINK_REFERRAL = 'get_link_referral';
export const GET_LINK_REFERRAL_SUCCESS = 'get_link_referral_success';
export const GET_LIST_INVITE_USER_REFERRAL = 'list_invite_user_referral';
export const GET_LIST_INVITE_USER_REFERRAL_SUCCESS = 'list_invite_user_referral_success';
export const SEND_INVITE_REFERRAL = 'send_invite_referral';
export const SEND_INVITE_REFERRAL_SUCCESS = 'send_invite_referral_success';

export const getLinkReferral = (params) =>
  makeRequest(
    GET_LINK_REFERRAL,
    'get',
    URL_REFERRAL_GET_LINK,
    params,
    getLinkReferral
  );

export const sentInvite = (params) =>
  makeRequest(
    SEND_INVITE_REFERRAL,
    'post',
    URL_REFERRAL_SEND_INVITE,
    params,
    sentInvite,
    true
  );

export const getListOfInviteUser = () =>
  makeRequest(
    GET_LIST_INVITE_USER_REFERRAL,
    'get',
    URL_REFERRAL_LIST_INVITE_USER,
    null,
    getListOfInviteUser
  );
