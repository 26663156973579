import React from 'react';
import { Button } from '@mui/material';
import Placeholder from './Placeholder';

const CustomLink = ({ onClick, label, loading, color }) => (
  <Button
    variant={'text'}
    style={{ textTransform: 'capitalize', color: color }}
    fullWidth
    onClick={onClick}
  >
    <Placeholder loading={!!loading} type={'button'}>
      {label}
    </Placeholder>
  </Button>
);

export default CustomLink;
