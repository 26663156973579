/* eslint-disable no-mixed-operators */
import { find, isEmpty, replace, includes } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import { format, differenceInDays, addDays } from 'date-fns';

export function getCountryName(countriesList, countryCode) {
  let countryObj = find(countriesList, (ct) => ct.id === countryCode);
  return isEmpty(countryObj) ? '' : countryObj.name;
}

export function formatPhone(data) {
  if (data) {
    try {
      const { countryCallingCode, nationalNumber } = parsePhoneNumber(data);
      return `00 ${countryCallingCode} ${nationalNumber}`;
    } catch (error) {
      return `00${data.replace('+', ' ')}`;
    }
  }
  return '';
}

export function convertPhoneToInput(phone) {
  return phone ? replace(`${phone.replace(/\s/g, '')}`, '00', '+') : '';
}

export function truncate(str = '', max, sep) {
  // Default to 10 characters
  max = max || 10;

  var len = str.length;
  if (len > max) {
    // Default to elipsis
    sep = sep || '...';

    var seplen = sep.length;

    // If seperator is larger than character limit,
    // well then we don't want to just show the seperator,
    // so just show right hand side of the string.
    if (seplen > max) {
      return str.substr(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    var n = -0.5 * (max - len - seplen);

    // This gives us the centerline.
    var center = len / 2;

    var front = str.substr(0, center - n);
    var back = str.substr(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;
  }

  return str;
}

export function addressView(address) {
  console.log(address);
  let street = `${address.street} ${address.registerNumber}${
    address.registerNumber && address.orientationNumber && '/'
  }${address.orientationNumber}`;
  return `
    ${street}, 
    ${address.cityDistrict}${address.cityDistrict && ', '}
    ${address.city}${address.city && ', '} 
    ${address.district}${address.district && ', '} 
    ${address.postalNumber}${address.postalNumber && ', '}
    ${address.country}
  `;
}

export function getCaretCharacterOffsetWithin(element) {
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== 'Control') {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export function getCaretPosition() {
  if (window.getSelection && window.getSelection().getRangeAt) {
    var range = window.getSelection().getRangeAt(0);
    var selectedObj = window.getSelection();
    var rangeCount = 0;
    var childNodes = selectedObj.anchorNode.parentNode.childNodes;
    for (var i = 0; i < childNodes.length; i++) {
      if (childNodes[i] === selectedObj.anchorNode) {
        break;
      }
      if (childNodes[i].outerHTML) {
        rangeCount += childNodes[i].outerHTML.length;
      } else if (childNodes[i].nodeType === 3) {
        rangeCount += childNodes[i].textContent.length;
      }
    }
    return range.startOffset + rangeCount;
  }
  return -1;
}

export const suggestFomat = (name) => {
  return `<a class="suggestItem" contenteditable="false">{ ${name} }</a> `;
};

export function replaceHtmlEntites(text) {
  let translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  let translate = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };

  return text.replace(translate_re, function (match, entity) {
    return translate[entity];
  });
}

export function stripHtml(html) {
  // Create a new div element
  var temporalDivElement = document.createElement('div');
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

export function objectByString(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export function downloadFile(data, filename, mime) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

export const RETURN_URl = 'returnUrl';

export function getRedirectUrl(href, param = RETURN_URl) {
  return (href && new URL(href).searchParams.get(param)) || '';
}

export function loginRedirect(pathName, search) {
  return pathName && pathName !== '/'
    ? `/login?${RETURN_URl}=${pathName}${search}`
    : '/login';
}

export function addressPickerValue(address) {
  if (
    address &&
    (address.street ||
      address.houseNumber ||
      address.district ||
      address.city ||
      address.state ||
      address.postalCode)
  ) {
    return `${address.street || ''} ${address.houseNumber || ''}, ${
      address.district || ''
    }, ${address.city || ''}, ${address.state || ''} ${
      address.postalCode || ''
    }`;
  }
  return '';
}

export function addressToValue(address_obj, isGoogleAddress = true) {
  if (isGoogleAddress) {
    return {
      state: address_obj.country || '',
      street: address_obj.route || address_obj.neighborhood || '',
      houseNumber: address_obj.street_number || '0',
      district:
        address_obj.sublocality ||
        address_obj.sublocality_level_1 ||
        address_obj.locality ||
        '',
      city:
        address_obj.locality || address_obj.administrative_area_level_2 || '',
      postalCode: address_obj.postal_code
        ? address_obj.postal_code.replace(/ /g, '')
        : address_obj.zip,
    };
  } else {
    return {
      street: address_obj.streetName,
      city: address_obj.municipalityName,
      district: address_obj.district || address_obj.regionName,
      cityDistrict: address_obj.cityDistrict || address_obj.streetName,
      country: address_obj.country,
      postalNumber: address_obj.postal_code
        ? address_obj.postal_code.replace(/ /g, '')
        : address_obj.zip,
      registerNumber: address_obj.cp,
      orientationNumber: address_obj.co || '0',
    };
  }
}

export function objectToQueryString(obj) {
  return obj
    ? Object.entries(obj)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    : '';
}

export function getAllParamsInUrl(url) {
  var params = {};
  var parser = document.createElement('a');
  parser.href = url;
  var query = parser.search.substring(1);
  if (query) {
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
  }
  return params;
}

export function getDiffDate(checkInDate) {
  return differenceInDays(
    addDays(checkInDate, 3),
    format(new Date(), 'yyyy-MM-dd'),
  );
}

export function deadlineCustom(checkInDate, t) {
  // const { t } = useTranslation();
  const dateOfDeadline = getDiffDate(checkInDate);
  let _time;
  if (dateOfDeadline === 0) {
    _time = `${t('today')}`;
  }
  if (dateOfDeadline === -1) {
    _time = `${t('yesterday')}`;
  }
  if (dateOfDeadline === 1) {
    _time = `${t('tomorrow')}`;
  }
  if (dateOfDeadline < -1) {
    _time = `${Math.abs(dateOfDeadline)} ${t('days ago')}`;
  }
  if (dateOfDeadline > 1) {
    _time = `${t('in')} ${dateOfDeadline} ${t('days')}`;
  }
  return _time;
}

export function comparePriority(a, b) {
  const _props_a = a.props.children;
  const _props_b = b.props.children;
  if (
    _props_a &&
    _props_a.length > 0 &&
    _props_a[0] &&
    _props_b &&
    _props_b.length > 0 &&
    _props_b[0]
  ) {
    if (
      _props_a[0].props &&
      includes(_props_a[0].props.className, 'SORT') &&
      _props_a[0].props.children &&
      _props_b[0].props &&
      includes(_props_b[0].props.className, 'SORT') &&
      _props_b[0].props.children
    ) {
      return _props_a[0].props.children < _props_b[0].props.children ? -1 : 1;
    }
  }
  return a < b ? -1 : 1;
}

export function compareChannel(a, b) {
  // console.log(a,b)
  return a.key.localeCompare(b.key);
}
