import { EXPORT_GUEST_BOOK, EXPORT_GUEST_BOOK_SUCCESS } from './actionTypes';

export const exportGuestBookPDF = (params) => ({
  type: EXPORT_GUEST_BOOK,
  params,
});

export const exportGuestBookPDFSuccess = (res) => ({
  type: EXPORT_GUEST_BOOK_SUCCESS,
  res,
});
