import React from 'react';
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import StyledButtonLink from '../styles/StyledButtonLink';
import { useTranslation } from 'react-i18next';

const BackButton = ({ onClick, label }) => {
  const { t } = useTranslation();
  return (
    <StyledButtonLink onClick={() => window.history.back()}>
      <ArrowBackIosOutlined fontSize={'inherit'} className="icon-left" />
      <span className="text">{t(label || 'Back')}</span>
    </StyledButtonLink>
  );
};

export default BackButton;
