/* eslint-disable no-mixed-operators */
import React from 'react';
import PropTypes from 'prop-types';
// import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {TextField, Paper, MenuItem, CircularProgress, FormHelperText} from '@mui/material';
import { withStyles } from '@mui/styles'
import { callApi } from '../custom-hooks/apiHook';
import { withTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';

const merkSuggestUrl = 'client/getCompanySuggest';

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 8,
    marginBottom: 4,
    position: 'relative',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  cusMenu: {
    display: 'block',
    height: 42,
    '& .secondary': {
      fontWeight: 300,
      fontSize: '90%',
      color: grey[500],
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  },
  loadding: {
    animationDuration: '550ms',
    position: 'absolute',
    right: 0,
    bottom: 5,
    zIndex: 9999,
    width: '15px !important',
    height: '15px !important',
  },
});

const getResidentialAddress = (suggestion) => {
  const address = suggestion.address;
  return address && `${address.street} ${address.number}, ${address.county && address.county.text} ${address.municipality} ${address.region && address.region.text} ${address.postal_code}` || '';
}

class MerkCompanyPicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      suggestions: [],
      typing: false,
    };
  }

  componentDidMount() {
    //console.log(this.props,"123")
    setTimeout(() => {
      this.props && this.props.field && this.props.field.value && this.setState({ inputValue: this.props.field.value })
    }, 2000);
    //console.log(this.state.inputValue,"345");
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({ typing: true });
    setTimeout(() => {
      let params = {
        name: value,
      };

      callApi('get', merkSuggestUrl, params).then((res) => {
        if (res.data.result) {
          this.setState({
            suggestions: res.data.data,
            typing: false
          });
        }
      });

    }, 300);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    //console.log(this.state.inputValue);
    this.setState({
      [name]: newValue,
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { field, form } = this.props;
    let new_suggestion = suggestion;
    const businessId = new_suggestion && new_suggestion.regno && new_suggestion.regno.toString().length === 7 ? `0${new_suggestion.regno.toString()}` : new_suggestion.regno || '';
    new_suggestion.regno = businessId;
    form.setFieldValue(field.name, new_suggestion.name);
    form.setFieldValue(`${field.name}_obj`, new_suggestion);

    const residentialAddress = getResidentialAddress(new_suggestion);

    form.setFieldValue(`residentialAddress`, residentialAddress);
    form.setFieldValue(`invoiceAddress`, residentialAddress);
    form.setFieldValue(`vatId`, new_suggestion.vatno || '');
    form.setFieldValue(`businessId`, new_suggestion.regno || '');
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);
    const { classes } = this.props;

    const residentialAddress = getResidentialAddress(suggestion);

    return (
      <MenuItem selected={isHighlighted} component="div" className={classes.cusMenu}>
        <div className="primary">
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
                <strong key={String(index)} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              ),
          )}
        </div>
        <div className="secondary">{residentialAddress}</div>
      </MenuItem>
    );
  }

  renderInputComponent = (inputProps, props) => {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;
    //console.log(this.state.inputValue,"456");
    inputProps.value = this.state.inputValue
    return (
      <TextField
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
        value={inputProps.value}
        //value={inputProps.value  || (props && props.field && props.field.value || '')}
      />
    );
  }

  render() {
    const { classes, label, placeholder, t, field, form: { touched, errors } } = this.props;
    const { inputValue } = this.state;
    const autosuggestProps = {
      renderInputComponent: (inputProps) => this.renderInputComponent(inputProps, this.props),
      suggestions: this.state.suggestions || [],
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
    };

    // console.log(this.state.inputValue)
    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: t(label),
            placeholder: t(placeholder),
            value: inputValue,
            onChange: this.handleChange('inputValue'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        {
          this.state.typing && <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.loadding}
          />
        }
        {touched[field.name] && errors[field.name] && (
          <FormHelperText error>{t(errors[field.name])}</FormHelperText>
        )}
      </div>
    );
  }
}

MerkCompanyPicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(MerkCompanyPicker));
