import React, { useState, useEffect } from 'react';
import {
  Paper,
  ListItemSecondaryAction,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Grid,
} from '@mui/material';
import BackButton from '../../../common/BackButton';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Download from '../../../common/Download';
import {
  selectPropertyById,
  selectProperties,
} from '../../../selectors/property-selectors';
import { callApi } from '../../../custom-hooks/apiHook';
import {
  URL_LOCAL_FEE_GET_TIMES,
  URL_GET_LOCAL_FEES,
  URL_CAPACITY_FEE_GET_TIMES,
  URL_GET_CAPACITY_FEES,
} from '../../../shared/urls';
import { format } from 'date-fns';
import Placeholder from '../../../common/Placeholder';
import Dropdown from '../../../components/Dropdown';
import { formatPropertiesList } from '../../../components/generateFields';
import { StyledDivider } from '../../../styles/commonStyles';
import { useTranslation } from 'react-i18next';
import { globalStyles } from '../../../styles/styles';
import { withStyles } from '@mui/styles';
import TitleHasIcon from '../../../common/TitleHasIcon';
import LocalFeesIcon from '../../../styles/title-icon/Local_fees_icon.png';
import { downloadFile } from '../../../helpers';
import CusTooltip from '../../../components/CusTooltip';

const ordinal_suffix_of = (n) =>
  n + ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || n + 'th';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const quarterMonth = (n) => {
  switch (n) {
    case 1:
      return [1, 2, 3].map((m) => ` ${monthNames[m - 1]}`);
    case 2:
      return [4, 5, 6].map((m) => ` ${monthNames[m - 1]}`);
    case 3:
      return [7, 8, 9].map((m) => ` ${monthNames[m - 1]}`);
    default:
      return [10, 11, 12].map((m) => ` ${monthNames[m - 1]}`);
  }
};

const Row = ({ time, isLastOne, onClick, loader, error }) => {
  const { t } = useTranslation();

  return (
    <ListItem dense divider={!isLastOne}>
      <ListItemText
        primary={format(time.display, 'MMMM YYYY')}
        secondary={
          !!error ? <span style={{ color: 'red' }}>{t(error)}</span> : ''
        }
      />
      <ListItemSecondaryAction onClick={onClick}>
        {loader ? <CircularProgress size={'16px'} /> : <Download />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const RowCapacity = ({ time, isLastOne, onClick, loader, error }) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 500;
  return (
    <ListItem dense divider={!isLastOne}>
      <ListItemText
        style={isMobile ? { display: 'flex', flex: '0 0 120px' } : {}}
        primary={`${ordinal_suffix_of(time.quarter)} ${t(
          'quarter',
        )} - ${quarterMonth(time.quarter)} ${time.year}`}
        secondary={
          !!error ? <span style={{ color: 'red' }}>{t(error)}</span> : ''
        }
      />
      <ListItemSecondaryAction onClick={onClick}>
        {loader ? <CircularProgress size={'16px'} /> : <Download />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const LocalFees = ({
  propertyFromStore,
  accommodationId,
  isGlobal,
  properties,
  classes,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    times: [],
    loading: false,
    capacityTimes: [],
    capacityloading: false,
    error: '',
  });

  const [loader, setLoader] = useState({
    loading: false,
    capacityloading: false,
    index: null,
    error: '',
  });

  const [propertyId, setPropertyId] = useState(accommodationId);

  useEffect(() => {
    if (propertyId) {
      setState({
        ...state,
        loading: true,
        capacityloading: true,
      });

      // Spa or recreation stay fee
      const getLocalFee = callApi('get', URL_LOCAL_FEE_GET_TIMES, {
        accommodationId: propertyId,
      });

      // Accommodation capacity fee
      const getCapacityFee = callApi('get', URL_CAPACITY_FEE_GET_TIMES, {
        accommodationId: propertyId,
      });

      Promise.all([getLocalFee, getCapacityFee])
        .then(function (values) {
          setState({
            ...state,
            // local fee
            loading: false,
            times: values[0].data.data,
            // capacity
            capacityloading: false,
            capacityTimes: values[1].data.data,
          });
        })
        .catch((e) => {
          setState({
            ...state,
            capacityloading: false,
            loading: false,
            error: 'error',
          });
        });
    }
  }, [propertyId]);

  if (!isGlobal && isEmpty(propertyFromStore)) {
    return <CircularProgress />;
  }

  function checkCapacityFee() {
    if (propertyId) {
      // console.log(properties)
      const findProperty = properties.data.find(
        (item) => item.accommodationId === propertyId,
      );
      // console.log(findProperty)
      return findProperty /*&& findProperty.payingCapacityFee === 1*/;
    }
    return false;
  }

  function dowloadFile(url, data, i, loadingType) {
    setLoader({ [loadingType]: true, index: i });
    callApi(
      'post',
      url,
      {
        accommodationId: propertyId,
        format: 'pdf',
        ...data,
      },
      true,
    )
      .then((res) => {
        setLoader({ ...loader, [loadingType]: false, error: '' });
        if (res.data) {
          downloadFile(res.data, `localFee.pdf`, 'pdf');
        } else {
          setLoader({
            ...loader,
            [loadingType]: false,
            error: `${t('Sorry. PDF file is not ready to download')}!`,
            index: i,
          });
        }
      })
      .catch((err) => {
        setLoader({
          ...loader,
          [loadingType]: false,
          error: `${t('Sorry. PDF file is not ready to download')}!`,
          index: i,
        });
      });
  }

  return (
    <div>
      {!isGlobal && <BackButton label={propertyFromStore.accommodationName} />}
      <Paper className={classes.root}>
        <TitleHasIcon
          variant="h5"
          title={t('Local Fees')}
          label={t('Acknowledgements')}
          isImage={true}
          icon={LocalFeesIcon}
        />
        {/* {isGlobal ? 'Global' : propertyFromStore.accommodationName} */}
        {isGlobal && (
          <Grid container spacing={24} justify="center" style={{ padding: 30 }}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div style={{ display: 'flex', placeItems: 'flex-end' }}>
                <Dropdown
                  isFullWidth
                  options={formatPropertiesList(properties.data)}
                  label={t('Select property')}
                  value={propertyId || ''}
                  onChange={(e) => {
                    setLoader({ ...loader, error: '' });
                    setPropertyId(e.target.value);
                  }}
                />
                <CusTooltip
                  title={`${t(
                    'The 7th day of every month is a deadline to add all reservations to the previous month before',
                  )}, ${t(
                    'then the period closes and we generate a new acknowledgement for the closed period',
                  )}. ${t(
                    'The local fee acknowledgement and payment will be automatically sent to the government the 10th day of a month by Best Guest',
                  )}, ${t(
                    'the deadline to send it is according to the law the 15th day of a month',
                  )}.`}
                />
              </div>
            </Grid>
          </Grid>
        )}

        {state.times && state.times.length > 0 && (
          <div>
            <Typography
              variant={'h6'}
              align={'left'}
              style={{ display: 'flex', placeItems: 'center' }}
            >
              {t('Spa or recreation stay fee')}
              <CusTooltip
                title={`${t(
                  'Local fee for spa or recreation stay is',
                )} 15, - CZK ${t('per person per night')}. ${t(
                  'Except for those under 18 and over 70',
                )}. ${t(
                  'The acknowledgement and fee should be sent every month',
                )} (${t('15th day of the following month')}). ${t(
                  'Act No',
                )}. 565/1990 ${t('Coll')}. §3`}
              />
            </Typography>
            <Paper style={{ marginBottom: 20 }}>
              <StyledDivider marginTop={2} marginBottom={10} />
              <Placeholder
                loading={state.loading}
                type="table"
                numberOfRows={10}
              >
                <List>
                  {state.times.map((time, index) => (
                    <Row
                      time={time}
                      error={
                        loader.index === index && !!loader.error
                          ? loader.error
                          : ''
                      }
                      key={index}
                      loader={loader.loading && loader.index === index}
                      onClick={() =>
                        dowloadFile(
                          URL_GET_LOCAL_FEES,
                          { month: time.month, year: time.year },
                          index,
                          'loading',
                        )
                      }
                      isLastOne={index === state.times.length - 1}
                    />
                  ))}
                </List>
              </Placeholder>
            </Paper>
          </div>
        )}
        {checkCapacityFee() &&
          state.capacityTimes &&
          state.capacityTimes.length > 0 && (
            <div>
              <Typography
                variant={'h6'}
                align={'left'}
                style={{ display: 'flex', placeItems: 'center' }}
              >
                {t('Accommodation capacity fee')}
                <CusTooltip
                  title={`${t(
                    'The accommodation capacity fee is 6',
                  )}, - CZK ${t('per person per night')}. ${t(
                    'The acknowledgement and fee should be sent every 3 months',
                  )} i.e. ${t('every quarter')} (${t(
                    '15th day of the following month',
                  )}). ${t('Act No')}. 565/1990 ${t('Coll')}. §7`}
                />
              </Typography>
              <Paper>
                <StyledDivider marginTop={2} marginBottom={10} />
                <Placeholder
                  loading={state.capacityloading}
                  type="table"
                  numberOfRows={10}
                >
                  <List>
                    {state.capacityTimes.map((time, index) => (
                      <RowCapacity
                        time={time}
                        error={
                          loader.index === index && !!loader.error
                            ? loader.error
                            : ''
                        }
                        key={index}
                        loader={
                          loader.capacityloading && loader.index === index
                        }
                        onClick={() =>
                          dowloadFile(
                            URL_GET_CAPACITY_FEES,
                            { quarter: time.quarter, year: time.year },
                            index,
                            'capacityloading',
                          )
                        }
                        isLastOne={index === state.capacityTimes.length - 1}
                      />
                    ))}
                  </List>
                </Placeholder>
              </Paper>
            </div>
          )}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  propertyFromStore: selectPropertyById(state, props),
  properties: selectProperties(state),
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(globalStyles)(LocalFees));
