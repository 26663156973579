import React, { useEffect, useState } from 'react';
import MainLayout from '../../shared/main-layout';
import { connect } from 'react-redux';
import { getClientInfo, getResidentialClient } from '../../actions/account';
import { getCountries, getVisaCountry } from '../../actions/country';
import { getWelcomersRoles } from '../../actions/member';
import { getProperties } from '../../actions/property';
import { GlobalDrawerContext } from '../../contexts';
import useGlobalDrawer from '../../custom-hooks/useGlobalDrawer';
import {
  NATIVE_NAME,
  ALPHA_3_CODE,
  FLAG,
  ENGLISH_NAME,
} from '../../reducers/countryReducer';
import AirbnbLoginForm from './containers/AirbnbLoginForm';
import BookingLoginForm from './containers/BookingLoginForm';
import { verifySMSCode } from '../../actions/onboading';
import {
  updateClientIntegration,
  addClientIntegration,
} from '../../actions/listing';

const BOOKING_TYPE = 'booking';
const AIRBNB_TYPE = 'airbnb';
const INTEGRATION_VALID = 'valid';
const INTEGRATION_CONNECTED = 'connected';
const AIR_LOCK_ERROR = 'AIR_LOCK_ERROR';
const BOOKING_NEED_SMS = 'BOOKING_NEED_SMS';

const checkIsIntegration = (_integration, type, connect_type) => {
  return (
    _integration &&
    _integration[type] &&
    _integration[type][INTEGRATION_CONNECTED] === 1 &&
    _integration[type][connect_type] === 0
  );
};

const checkIntegrationUpdateOrCreate = (_integration, type, isUpdate) => {
  return (
    _integration &&
    _integration[type] &&
    _integration[type] &&
    _integration[type][INTEGRATION_CONNECTED] === isUpdate &&
    _integration[type][INTEGRATION_VALID] === 0
  );
};

const Dashboard = ({
  getCountries,
  getVisaCountry,
  getClientInfo,
  getProperties,
  getWelcomersRoles,
  getResidentialClient,
  account,
  clientInfo,
  updateClientIntegration,
  addClientIntegration,
  verifySMSCode,
  ...props
}) => {
  const { state, dispatch } = useGlobalDrawer();

  const [openAirbnbLogin, setOpenAirbnbLogin] = useState({
    open: false,
    data: null,
    loading: false,
  });
  const [openBookingLogin, setOpenBookingLogin] = useState({
    open: false,
    data: null,
    loading: false,
  });
  const [airLock, setAirLock] = useState();
  const [needSmsCode, setNeedSmsCode] = useState({
    open: false,
    loading: false,
    data: {
      authorizationCode: '',
      SMSCode: '',
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    console.log(account);
    if (account.detail.userId) {
      let routeName = props['*'];
      routeName !== 'account' && getClientInfo();

      getCountries({
        fields: `${ENGLISH_NAME};${NATIVE_NAME};${ALPHA_3_CODE};${FLAG}`,
      });
      getVisaCountry();
      getProperties();
      getResidentialClient();
      getWelcomersRoles();
    }
  }, [account.detail.userId]);

  useEffect(() => {
    if (clientInfo && clientInfo.clientId) {
      const _integration = clientInfo.integration;
      setOpenAirbnbLogin({
        ...openAirbnbLogin,
        open: checkIsIntegration(_integration, AIRBNB_TYPE, INTEGRATION_VALID),
        data: _integration ? _integration[AIRBNB_TYPE] : false,
      });

      // booking show
      setOpenBookingLogin({
        ...openBookingLogin,
        open: checkIsIntegration(_integration, BOOKING_TYPE, INTEGRATION_VALID),
        data: _integration ? _integration[BOOKING_TYPE] : false,
      });
    }
  }, [clientInfo]);

  const handleAirbnbSubmit = (values) => {
    setOpenAirbnbLogin({ ...openAirbnbLogin, loading: true });
    setAirLock(null);
    const _integration = clientInfo.integration;
    let _clientIntegrationFnc = checkIntegrationUpdateOrCreate(
      _integration,
      AIRBNB_TYPE,
      0,
    )
      ? addClientIntegration
      : checkIntegrationUpdateOrCreate(_integration, AIRBNB_TYPE, 1) &&
        updateClientIntegration;

    if (_clientIntegrationFnc) {
      values.type = AIRBNB_TYPE;
      _clientIntegrationFnc(values).then((res) => {
        if (res.data && res.result) {
          setOpenAirbnbLogin({
            ...openAirbnbLogin,
            loading: false,
            open: false,
          });
        } else {
          setOpenAirbnbLogin({ ...openAirbnbLogin, loading: false });
          res.errors.length > 0 &&
            res.errors.map((v) => {
              v.msg === AIR_LOCK_ERROR && setAirLock(v.note);
              return true;
            });
        }
      });
    }
  };

  const handleBookingSubmit = (values) => {
    setOpenBookingLogin({ ...openBookingLogin, loading: true });
    const _integration = clientInfo.integration;
    let _clientIntegrationFnc = checkIntegrationUpdateOrCreate(
      _integration,
      BOOKING_TYPE,
      0,
    )
      ? addClientIntegration
      : checkIntegrationUpdateOrCreate(_integration, BOOKING_TYPE, 1) &&
        updateClientIntegration;

    if (_clientIntegrationFnc) {
      values.type = BOOKING_TYPE;
      _clientIntegrationFnc(values).then((res) => {
        if (res.data && res.result) {
          setOpenBookingLogin({
            ...openBookingLogin,
            loading: false,
            open: false,
          });
        } else {
          setOpenBookingLogin({ ...openBookingLogin, loading: false });

          res.errors &&
            res.errors.length > 0 &&
            res.errors[0].msg === BOOKING_NEED_SMS &&
            setNeedSmsCode({
              open: true,
              loading: false,
              data: {
                SMSCode: '',
                authorizationCode: res.errors[0].note,
                username: values.username,
                password: values.password,
              },
            });
        }
      });
    }
  };

  const handleVerifySmsCode = () => {
    setNeedSmsCode({ ...needSmsCode, loading: true });
    verifySMSCode(needSmsCode.data).then((res) => {
      setNeedSmsCode({ ...needSmsCode, loading: false });
      res.data &&
        res.result &&
        setOpenBookingLogin({
          ...openBookingLogin,
          loading: false,
          open: false,
        });
    });
  };

  return (
    <GlobalDrawerContext.Provider value={{ state, dispatch }}>
      <MainLayout {...props} />
      <AirbnbLoginForm
        isOpen={openAirbnbLogin.open}
        data={openAirbnbLogin.data}
        handleSubmit={handleAirbnbSubmit}
        loading={openAirbnbLogin.loading}
        airLock={airLock}
      />
      <BookingLoginForm
        isOpen={openBookingLogin.open}
        data={openBookingLogin.data}
        handleSubmit={handleBookingSubmit}
        loading={openBookingLogin.loading}
        needSmsCode={needSmsCode}
        setNeedSmsCode={setNeedSmsCode}
        handleVerifySmsCode={handleVerifySmsCode}
      />
    </GlobalDrawerContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  clientInfo: state.client.clientInfo.data,
});

export default connect(mapStateToProps, {
  getCountries,
  getVisaCountry,
  getClientInfo,
  getProperties,
  getResidentialClient,
  getWelcomersRoles,
  updateClientIntegration,
  addClientIntegration,
  verifySMSCode,
})(Dashboard);
