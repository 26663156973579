import React from 'react';

import { Button, Hidden } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { nextStep, updateUserInfo } from '../../../actions/onboading';
import { connect } from 'react-redux';
import Styles from '../../../styles/StyleBilling';
import { Form, Field } from 'react-final-form';
import Card from '../components/Card';
import PackageBilling from '../components/packageBilling';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../utils';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = async (values) => {
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

const styles = {
  outlineButton: {
    textAlign: 'center',
    margin: '50px 0',
  },
  continueButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const Billing = ({ nextStep, handleSubmit, handleBack }) => {
  const { t } = useTranslation();

  // const [loading, setLoading] = useState(false);

  return (
    <div>
      <div style={{ border: `1px solid ${grey[300]}`, padding: 15 }}>
        <PackageBilling></PackageBilling>

        <Styles>
          <Form
            onSubmit={onSubmit}
            render={({ form, submitting, pristine, values, active }) => {
              return (
                <div>
                  <Hidden xsDown implementation="css">
                    <form>
                      <Card
                        number={values.number || ''}
                        name={values.name || ''}
                        expiry={values.expiry || ''}
                        cvc={values.cvc || ''}
                        focused={active}
                      />
                      <div>
                        <Field
                          name="number"
                          component="input"
                          type="text"
                          required
                          pattern="[\d| ]{16,22}"
                          placeholder="Card Number"
                          format={formatCreditCardNumber}
                        />
                      </div>
                      <div>
                        <Field
                          name="name"
                          required
                          component="input"
                          type="text"
                          placeholder="Name"
                        />
                      </div>
                      <div>
                        <Field
                          name="expiry"
                          required
                          component="input"
                          type="text"
                          pattern="\d\d/\d\d"
                          placeholder="Valid Thru"
                          format={formatExpirationDate}
                        />
                        <Field
                          name="cvc"
                          required
                          component="input"
                          type="text"
                          pattern="\d{3,4}"
                          placeholder="CVC"
                          format={formatCVC}
                        />
                      </div>
                      <div className="buttons">
                        <button type="submit" disabled={submitting}>
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={form.reset}
                          disabled={submitting || pristine}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Hidden>
                  <Hidden smUp implementation="css">
                    <form>
                      <div>
                        <Field
                          name="number"
                          component="input"
                          type="text"
                          required
                          variant={'standard'}
                          pattern="[\d| ]{16,22}"
                          placeholder="Card Number"
                          format={formatCreditCardNumber}
                        />
                      </div>
                      <div>
                        <Field
                          name="name"
                          required
                          component="input"
                          type="text"
                          placeholder="Name"
                        />
                      </div>
                      <div>
                        <Field
                          name="expiry"
                          required
                          component="input"
                          type="text"
                          pattern="\d\d/\d\d"
                          placeholder="Valid Thru"
                          format={formatExpirationDate}
                        />
                      </div>
                      <div>
                        <Field
                          name="cvc"
                          required
                          component="input"
                          type="text"
                          pattern="\d{3,4}"
                          placeholder="CVC"
                          format={formatCVC}
                        />
                      </div>
                      <div className="buttons">
                        <button type="submit" disabled={submitting}>
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={form.reset}
                          disabled={submitting || pristine}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Hidden>
                </div>
              );
            }}
          />
        </Styles>
        <div style={styles.continueButton}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={handleBack}
            style={{ marginRight: 15 }}
          >
            {t(`Back`)}
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            {t(`Continue`)}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { nextStep, updateUserInfo })(Billing);
