import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import FormInput from '../../../common/input';
import { Formik, Form, FastField } from 'formik';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { grey, blue } from '@mui/material/colors';
import BookingImg from '../../../styles/onboarding/connect_account/Booking_logo.png';
import * as Yup from 'yup';

const styles = (theme) => ({
  Dialog: {
    padding: 0,
    borderRadius: 10,
  },
  DialogTitle: {
    backgroundColor: grey[50],
    padding: '10px 30px',
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  LogoImg: {
    height: 25,
    marginRight: 10,
  },
  DialogContent: {
    padding: 30,
  },
  LoginSection: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    '& button': {
      padding: 15,
      backgroundColor: blue[400],
      color: grey[50],
    },
  },
});

const validation = Yup.object().shape({
  username: Yup.string().required('Required').nullable(),
  password: Yup.string().required('Required').nullable(),
});

class BookingLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValues: {
        username: '',
        password: '',
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {}

  onSubmit(values) {
    const { data, handleSubmit } = this.props;
    let _new_values = {
      ...values,
      id: data.id,
    };
    return handleSubmit(_new_values);
  }

  renderForm() {
    let { t, classes, loading } = this.props;
    const { currentValues } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={currentValues}
        validationSchema={validation}
        onSubmit={this.onSubmit}
        render={({ dirty, values, ...other }) => {
          return (
            <Form style={{ marginTop: 20 }}>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <FastField
                    variant="outlined"
                    iconName={'person'}
                    type="text"
                    name="username"
                    label={t('User Name')}
                    placeholder={t('User Name')}
                    component={FormInput}
                    disabled={!!loading}
                    autoFocus
                  />
                  <FastField
                    variant="outlined"
                    iconName={'lock'}
                    type="password"
                    name="password"
                    label={t('Password')}
                    placeholder={t('Password')}
                    component={FormInput}
                    disabled={!!loading}
                  />
                </Grid>
              </Grid>
              <div className={classes.LoginSection}>
                <Button
                  fullWidth
                  variant={'contained'}
                  color={'default'}
                  type="submit"
                  disabled={!dirty || !!loading}
                >
                  {t('Login')}{' '}
                  {!!loading && (
                    <CircularProgress size={24} style={{ marginLeft: 15 }} />
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }

  renderSMSCode() {
    let { handleVerifySmsCode, t, needSmsCode, setNeedSmsCode } = this.props;
    return (
      <div>
        <TextField
          name="smsCode"
          value={needSmsCode.data.SMSCode}
          onChange={(e) =>
            setNeedSmsCode({
              ...needSmsCode,
              data: { ...needSmsCode.data, SMSCode: e.target.value },
            })
          }
          label="SMS Code"
          placeholder="SMS Code"
          fullWidth
        />
        <Button
          color="secondary"
          variant="contained"
          disabled={!needSmsCode.data.SMSCode || needSmsCode.loading}
          fullWidth
          style={{ marginTop: 30 }}
          onClick={handleVerifySmsCode}
        >
          {t('Send')}
        </Button>
      </div>
    );
  }

  render() {
    let { account, isOpen, t, classes, needSmsCode } = this.props;
    const { firstName } = account.detail;

    return (
      <Dialog open={isOpen} classes={{ paper: classes.Dialog }}>
        <DialogTitle className={classes.DialogTitle}>
          <img src={BookingImg} className={classes.LogoImg} alt="" />{' '}
          {t('login details update')}
        </DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <DialogContentText>
            {t('Dear')}{' '}
            <Typography
              variant="subtitle2"
              component="span"
              style={{ display: 'inline' }}
            >
              {firstName}
            </Typography>
            ,{' '}
            {t(
              'your Booking login details might have been changed recently or Booking just needs to verify that you have given Best Guest access to your account',
            )}
            . {t('Please')},{' '}
            {t('provide your current username and password below')}.
          </DialogContentText>
          {!needSmsCode.open ? this.renderForm() : this.renderSMSCode()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(
  mapStateToProps,
  {},
)(withTranslation()(withStyles(styles)(BookingLoginForm)));
