import React from 'react';
import { withStyles } from '@mui/styles';
import circleImg from '../styles/image/loading.png';
import PlayArrow from '@mui/icons-material/PlayArrow';

const styles = (theme) => ({
  playIcon: {
    marginRight: 10,
    marginLeft: 1,
    display: 'flex',
    position: 'relative',
    flex: '0 0 20px',
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '-webkit-animation': 'spin 4s linear infinite',
      '-moz-animation': 'spin 4s linear infinite',
      animation: 'spin 4s linear infinite',
    },
    '& .IconStyle': {
      fontSize: 16,
    },
  },
});

const PlayButton = ({ classes }) => (
  <span className={classes.playIcon}>
    <img src={circleImg} />
    <PlayArrow color="secondary" className={'IconStyle'} />
  </span>
);

export default withStyles(styles)(PlayButton);
