/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import {
  Typography,
  Grid,
  Paper,
  Hidden,
  Button,
  Switch,
  CircularProgress,
} from '@mui/material';
import { PauseCircleOutline } from '@mui/icons-material';
import {
  getNotificationsSettings,
  updateNotificationSettings,
  deleteNotificationsContacts,
} from '../../../actions/reminders';
import { getClientInfo, updateClientInfo } from '../../../actions/account';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { paymentsStyles } from '../../../styles/styles';
import { withStyles } from '@mui/styles';
import SubscribedIcon from '../../../styles/icon/ikona-fajfka.png';
import PlayButton from '../../../common/PlayButton';
import SwitchesCustom from '../../../common/SwitchesCustom';
import CusTooltip from '../../../components/CusTooltip';

export const remindersTypeName = {
  localFees: 'Local fees payment',
  checkinFromsMissing: 'Missing check-in form',
  checkinFromsToSend: 'Close reservation ( & send to Ubyport )',
};

const Payments = ({ classes, getClientInfo, updateClientInfo, clientInfo }) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log('here');
    getClientInfo();
  }, []);

  const [paymentsList, setPaymentsList] = useState({
    name: 'sms',
    label: t('Turn On Off SMSs'),
    placeholder: 'that are sent to the guests to fill the check-in form',
    // infoIcon: `${t("You will receive the reminder every 12th day of a month")}, ${t("because the deadline according to the law is 15th of every month for Spa of recreation stay fee and every three months for Accommodation capacity fee")}.`,
    component: SwitchesCustom,
    loading: false,
    labelPosition: 'right',
    enableSms: false,
  });

  useEffect(() => {
    let _newData = paymentsList;
    clientInfo
      ? (_newData.enableSms = clientInfo.enable_sms)
      : (_newData.enableSms = false);
    setPaymentsList(_newData);
  }, [clientInfo]);

  let handleChangeSwitch = (e) => {
    let _newValue = e.target.checked;

    setPaymentsList({ ...paymentsList, loading: true });

    const params = {
      enableSms: _newValue,
    };
    updateClientInfo(params)
      .then((response) => {
        setPaymentsList({ ...paymentsList, loading: false });
        if (response.data) {
          setPaymentsList({ ...paymentsList, enableSms: _newValue });
        }
      })
      .catch((error) => {
        setPaymentsList({
          ...paymentsList,
          enableSms: !_newValue,
          loading: false,
        });
      });
  };

  let initialValues = {
    localFees: false,
    checkinFromsMissing: false,
    checkinFromsToSend: false,
  };

  let renderPayments = (v) => {
    return (
      <Grid container key={v.name} className={classes.payment}>
        <Grid item lg={8} xs={12} className={'payment__item'}>
          <Typography
            variant="subtitle1"
            component="div"
            className={classes.heading}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {v.loading && (
              <CircularProgress size={20} style={{ marginLeft: 15 }} />
            )}
            <Switch
              checked={v.enableSms}
              value={v.placeholder}
              onChange={(e) => {
                handleChangeSwitch(e);
              }}
              color="secondary"
            />
            {v.label}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            className={'payment__item__placeholder'}
            style={{ display: 'flex', placeItems: 'center' }}
          >
            {t(v.placeholder)}
            {v.infoIcon && <CusTooltip title={v.infoIcon} />}
          </Typography>

          <Typography
            variant="caption"
            component="p"
            className={'payment__item__caption'}
          >
            {v.enableSms ? (
              <span>
                <span>
                  <PlayButton color="secondary" />
                </span>
                {t('The SMSs are being sent to the guests')}.
              </span>
            ) : (
              <span>
                <PauseCircleOutline
                  style={{ marginRight: 10 }}
                  color="inherit"
                />{' '}
                {t(
                  'The SMSs are not being sent to the guests but they still receive the emails, Airbnb & Booking messages',
                )}
                .
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item lg={2} xs={12} sm={6} className={classes.balance}>
          <Typography className={'balance__text'}>
            {t('SMS balance')}:
          </Typography>
          <Typography className={'balance__value'}>120,-czk</Typography>
        </Grid>
        <Grid item lg={2} xs={12} sm={6} className={classes.topup}>
          <Button
            variant={'outlined'}
            color={'primary'}
            className={'topup__btn'}
          >
            {t('TOP UP 500 CZK')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  let renderPaymentsTitle = () => {
    return (
      <TitleHasIcon
        variant="h5"
        title={t('Payments')}
        label={''}
        icon={'payment'}
      />
    );
  };

  return (
    <div>
      {/* Desktop */}
      <Hidden implementation="css">
        <Paper className={classes.root}>
          {renderPaymentsTitle()}
          <Paper className={classes.root}>
            <Grid container>
              <img src={SubscribedIcon} alt="" width={35} />
              <Typography variant="h6" style={{ marginLeft: '15px' }}>
                {t('You are subscribed')}
              </Typography>
            </Grid>
            <Typography style={{ fontSize: '15px', marginTop: '15px' }}>
              {t('Update your payment details')} <a href="#">{t('here')}</a>.
            </Typography>
          </Paper>
          <Paper className={classes.root}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              render={({ dirty, ...formProps }) => {
                return (
                  <Form style={{ width: '100%' }}>
                    {renderPayments(paymentsList)}
                  </Form>
                );
              }}
            />
          </Paper>
        </Paper>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reminderSettings: state.reminders.settings,
  clientInfo: state.client.clientInfo.data,
});

export default connect(mapStateToProps, {
  getNotificationsSettings,
  updateNotificationSettings,
  deleteNotificationsContacts,
  getClientInfo,
  updateClientInfo,
})(withStyles(paymentsStyles)(Payments));
