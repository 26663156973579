import produce from 'immer';
import {
  INVOICE_GET_TIMES,
  INVOICE_GET_TIMES_SUCCESS,
  INVOICE_GET_TIMES_FAILURE,
} from '../actions/invoice';

export const initialState = {
  data: [],
  loading: false,
  error: ''
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INVOICE_GET_TIMES:
        draft.loading = true;
        draft.error = '';
        return;
      case INVOICE_GET_TIMES_SUCCESS:
        draft.loading = false;
        draft.data = action.res;
        draft.error = '';
        return;
      case INVOICE_GET_TIMES_FAILURE:
        draft.loading = false;
        draft.data = [];
        draft.error = 'Server error';
        return;
      default:
        return state;
    }
  });
