import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { FormControl, Button, Typography } from '@mui/material';
import { truncate } from '../helpers';
import { useTranslation } from 'react-i18next';

const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const FileUpload = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const [errorMes, setErrorMes] = useState();
  const [file, setFile] = useState();
  const { t } = useTranslation();

  function changeFile(e) {
    let _file = e.currentTarget.files[0];
    setFile(_file);
    if (props.maxSize && _file.size > props.maxSize) {
      setErrorMes(`${t('Please choose maximum')} ${bytesToSize(props.maxSize)}`)
      return false;
    } else {
      setErrorMes(null);
      setFieldValue(field.name, _file);
    }
  }
  return (
    <FormControl fullWidth margin={'dense'}>
      {/* <TextField
        type="file"
        name={field.name}
        label={t(props.label)}
        placeholder={t(props.placeholder)}
        onChange={changeFile}
        error={touched[field.name] && !!errors[field.name]}
        helperText={errors[field.name]}
        id="outlined-button-file"
        style={{display: 'none'}}
        accept={props.accept || "*"}
      /> */}
      <input
        type="file"
        name={field.name}
        label={t(props.label)}
        placeholder={t(props.placeholder)}
        onChange={changeFile}
        // error={touched[field.name] && !!errors[field.name]}
        // helperText={errors[field.name]}
        id="outlined-button-file"
        style={{ display: 'none' }}
        accept={props.accept || "*"}
      />
      <label htmlFor="outlined-button-file">
        <Button variant="outlined" color={props.color || 'default'} component="span" fullWidth style={{ marginTop: 10 }}>
          {t(props.placeholder)}
        </Button>
      </label>
      {file && file.name && <Typography variant="caption" gutterBottom color={'primary'}>{truncate(file.name, 20)}</Typography>}
      {props.maxSize && <Typography variant="caption" gutterBottom style={{ 'color': '#999' }}>* {t('Maximum size')} {bytesToSize(props.maxSize)}</Typography>}
      {errorMes && <Typography variant="caption" gutterBottom color='error'>{t(errorMes)}</Typography>}
      {touched[field.name] && !!errors[field.name] && <Typography variant="caption" gutterBottom color='error'>{t(errors[field.name])}</Typography>}
      <div>
        {props.showReview && file && <img src={URL.createObjectURL(file)} style={{height: 300}} />}
      </div>
    </FormControl>
  );
};

export default FileUpload;
