import { makeRequest } from '../redux/apiCall';
import {
  URL_UPDATE_LISTING,
  URL_LISTINGS_CREATE,
  URL_DELETE_LISTING,
  URL_GET_LISTING_DETAIL,
  URL_ADD_ICAL_LINK,
  URL_UPDATE_ICAL_LINK,
  URL_CLIENT_INTEGRATON,
  URL_UPDATE_CLIENT_INTEGRATION,
} from '../shared/urls';

export const CREATE_LISTING = 'create_listing';
export const CREATE_LISTING_SUCCESS = 'create_listing_success';
export const CREATE_LISTING_FAILURE = 'create_listing_failure';

export const UPDATE_LISTING = 'update_listing';
export const UPDATE_LISTING_SUCCESS = 'update_listing_success';
export const UPDATE_LISTING_FAILURE = 'update_listing_failure';

export const UPDATE_CLIENT_INTEGRATION = 'update_client_integration';
export const UPDATE_CLIENT_INTEGRATION_SUCCESS = 'update_client_integration_success';
export const UPDATE_CLIENT_INTEGRATION_FAILURE = 'update_client_integration_failure';

export const DELETE_LISTING = 'delete_listing';
export const DELETE_LISTING_SUCCESS = 'delete_listing_success';
export const DELETE_LISTING_FAILURE = 'delete_listing_failure';

export const GET_LISTING_DETAIL = 'get_listing_detail';
export const GET_LISTING_DETAIL_SUCCESS = 'get_listing_detail_success';
export const GET_LISTING_DETAIL_FAILURE = 'get_listing_detail_failure';

export const ADD_ICAL_LINK = 'add_ical_link';
export const ADD_ICAL_LINK_SUCCESS = 'add_ical_link_success';
export const ADD_ICAL_LINK_FAILURE = 'add_ical_link_failure';

export const CREATE_CLIENT_INTEGRATION = 'create_client_integration';
export const CREATE_CLIENT_INTEGRATION_SUCCESS = 'create_client_integration_success';
export const CREATE_CLIENT_INTEGRATION_FAILURE = 'create_client_integration_failure';

export const UPDATE_ICAL_LINK = 'update_ical_link';
export const UPDATE_ICAL_LINK_SUCCESS = 'update_ical_link_success';
export const UPDATE_ICAL_LINK_FAILURE = 'update_ical_link_failure';

export const updateListing = params =>
  makeRequest(
    UPDATE_LISTING,
    'post',
    URL_UPDATE_LISTING,
    params,
    updateListing,
    true,
  );

export const createListing = params =>
  makeRequest(
    CREATE_LISTING,
    'post',
    URL_LISTINGS_CREATE,
    params,
    createListing,
  );

export const deleteListing = params =>
  makeRequest(
    DELETE_LISTING,
    'post',
    URL_DELETE_LISTING,
    params,
    deleteListing,
  );

export const getListingDetail = params =>
  makeRequest(
    GET_LISTING_DETAIL,
    'get',
    URL_GET_LISTING_DETAIL,
    params,
    getListingDetail,
  );

export const addIcalLink = params =>
  makeRequest(
    ADD_ICAL_LINK,
    'post',
    URL_ADD_ICAL_LINK,
    params,
    addIcalLink,
  );

export const updateIcalLink = params =>
  makeRequest(
    UPDATE_ICAL_LINK,
    'post',
    URL_UPDATE_ICAL_LINK,
    params,
    updateIcalLink,
  );

export const addClientIntegration = params =>
  makeRequest(
    CREATE_CLIENT_INTEGRATION,
    'post',
    URL_CLIENT_INTEGRATON,
    params,
    addClientIntegration,
  );

export const updateClientIntegration = params =>
  makeRequest(
    UPDATE_CLIENT_INTEGRATION,
    'post',
    URL_UPDATE_CLIENT_INTEGRATION,
    params,
    updateClientIntegration,
    true,
  );