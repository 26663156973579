import React from 'react';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { getDate, setDate, setMonth } from 'date-fns';
import FormInput from '../common/input';
import SelectMui from '../common/SelectMui';
import CustomDatePicker from '../common/CustomDatePicker';
import FieldCounter from '../features/reservation/components/FieldCounter';
import PhoneInput from '../common/PhoneInput';
import GoogleInput from '../common/GoogleInput';
import ImgDefault from '../styles/image/Icon_white.jpg';

export function formatList(list) {
  if (!isEmpty(list)) {
    return list.map((item) => ({
      value: item.residentialClientId,
      label: item.clientName,
    }));
  }
  return [];
}

export function formatMemberList(list) {
  if (!isEmpty(list)) {
    return list.map((item) => ({
      value: item.id,
      label: item.welcomerName,
      img: item.welcomerUrl || ImgDefault,
    }));
  }
  return [];
}

export function formatPropertiesList(list) {
  return list.map((acc) => ({
    value: acc.accommodationId,
    label: acc.accommodationName || 'Unknown',
  }));
}

export function formatReservationsList(list) {
  return list.map((acc) => ({
    value: acc.reservationId,
    label: acc.reservationId,
  }));
}

export function formatListingsList(list) {
  return list.map((listing) => ({
    value: listing.listingId,
    label: listing.listingName,
  }));
}

const masterGuestField = {
  title: 'Master guest',
  id: 'master-guest',
  fields: [
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'First name',
      label: 'First name',
      component: FormInput,
    },
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Last name',
      label: 'Last name',
      component: FormInput,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'E-mail for master guest',
      label: 'E-mail for master guest',
      component: FormInput,
    },
    {
      name: 'phoneNumber',
      type: 'text',
      placeholder: 'Phone number',
      label: 'Phone number',
      component: PhoneInput,
    },
  ],
};

export function generateReservationFormFields(
  property,
  isUpdating,
  properties,
) {
  const fiels = [
    {
      title: '',
      id: 'properties-1',
      fields: [
        {
          name: 'fromDate',
          label: 'Check in',
          format: 'MM-dd-yyyy',
          placeholder: 'MM-dd-yyyy',
          component: CustomDatePicker,
          minDate:
            getDate(new Date()) >= 7
              ? setDate(new Date(), 1)
              : setDate(setMonth(new Date(), new Date().getMonth() - 1), 1),
          // maxDate: getDate(new Date()) >= 7 ? setDate(setMonth(new Date(), new Date().getMonth() + 1), 7) : setDate(new Date(), 7),
        },
        {
          name: 'toDate',
          label: 'Check out',
          format: 'MM-dd-yyyy',
          placeholder: 'MM-dd-yyyy',
          component: CustomDatePicker,
          minDate:
            getDate(new Date()) >= 7
              ? setDate(new Date(), 1)
              : setDate(setMonth(new Date(), new Date().getMonth() - 1), 1),
          // maxDate: getDate(new Date()) >= 7 ? setDate(setMonth(new Date(), new Date().getMonth() + 1), 7) : setDate(new Date(), 7),
        },
      ],
    },
  ];
  const numberOfGuest = {
    title: '',
    id: 'guests',
    fields: [
      {
        label: 'Number of guests',
        name: 'personsCount',
        type: 'number',
        component: FieldCounter,
      },
    ],
  };
  if (isUpdating) {
    return fiels;
  }
  return [...fiels, numberOfGuest, masterGuestField];
}

export const residentialClientIdTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('Is your client that owns the property that your company manages')}.
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(
          `Is registered at the Business Office, the City Office and the Foreign Police as a person doing business in accommodation services and the person responsible for the proper fulfilment of the host's duties`,
        )}
        .
      </Typography>
    </div>
  );
};

export const welcomerNameTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('Is person that communicates with your guests')}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('It can be for example you, your co-host, receptionist or manager')}
      </Typography>
    </div>
  );
};

export const localFeeIdTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(
          'This is the symbol you received from the City office when you registered your property to the local fee(s)',
        )}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(
          'This symbol is used to pay the local fee(s) through a bank account, it serves as identification of your property',
        )}
      </Typography>
    </div>
  );
};

export const numberOfBedsTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t(
          'This is the maximum capacity of guests you can accommodate in this property in total',
        )}
      </Typography>
    </div>
  );
};

export const addressTooltip = (t) => {
  return (
    <div>
      <Typography
        variant="body2"
        gutterBottom
        headlineMapping={{ body2: 'p' }}
        className="body"
      >
        {t('Address')}
      </Typography>
    </div>
  );
};

export function generatePropertyFields(
  t,
  memberListSelect,
  isOn,
  isUpdating,
  residentialClients,
  countriesList,
  propertyManager,
  hasReservation,
) {
  // const firstRowFields = [
  //   {
  //     name: 'accommodationName',
  //     type: 'text',
  //     placeholder: 'Property name',
  //     label: 'Property name',
  //     component: FormInput,
  //   },
  //   {
  //     name: 'welcomerId',
  //     type: 'text',
  //     placeholder: 'Welcomer',
  //     label: "Welcomer's first name",
  //     options: formatMemberList(memberListSelect),
  //     component: SelectMui,
  //     showHelp: true,
  //     helpContent: welcomerNameTooltip(t),
  //     showImg: true,
  //   },
  // ];

  const detail_local_fee = [
    {
      name: 'localFeeId',
      type: 'string',
      inputLabel: true,
      placeholder: 'Variable symbol (Spa fee)',
      label: 'Variable symbol (Spa fee)',
      component: FormInput,
      showHelp: true,
      helpContent: localFeeIdTooltip(t),
    },
    {
      name: 'numberOfBeds',
      type: 'number',
      placeholder: 'Maximum capacity',
      label: 'Maximum capacity',
      component: FormInput,
      showHelp: true,
      helpContent: numberOfBedsTooltip(t),
    },
  ];

  const fieldsArr = [
    {
      title: 'Property',
      id: 'use-form-fields-gr1',
      fields: [
        {
          name: 'accommodationName',
          type: 'text',
          placeholder: 'Property name',
          label: 'Property name',
          component: FormInput,
        },
        !(isEmpty(residentialClients) || propertyManager === 0) && {
          name: 'residentialClientId',
          type: 'text',
          placeholder: 'Accommodation provider',
          label: 'Accommodation provider',
          options: formatList(residentialClients),
          component: SelectMui,
          showHelp: true,
          helpContent: residentialClientIdTooltip(t),
          disabled: isUpdating && hasReservation,
          disableReason:
            isUpdating &&
            hasReservation &&
            t(
              'You can not edit the accommodation provider because there are some reservations connected with this property',
            ),
          disableTooltipType: true,
        },
        {
          name: 'welcomerId',
          type: 'text',
          placeholder: 'Welcomer',
          label: "Welcomer's first name",
          options: formatMemberList(memberListSelect),
          component: SelectMui,
          showHelp: true,
          helpContent: welcomerNameTooltip(t),
          showImg: true,
        },
        {
          name: 'specialCode',
          type: 'text',
          placeholder: 'Property special code',
          label: 'Property special code',
          component: FormInput,
          showHelp: true,
          helpContent: `${t(
            'You can create you own special code for this property to create messages for your guests',
          )} - ${t('for example Check-in instructions')}`,
        },
      ],
    },
    {
      title: 'Address',
      id: 'address_gr',
      fields: [
        {
          name: 'address',
          type: 'text',
          placeholder: 'Address',
          label: 'Address',
          component: GoogleInput,
          showHelp: true,
          helpContent: addressTooltip(t),
          disabled: isUpdating && hasReservation,
          disableReason:
            isUpdating &&
            hasReservation &&
            `${t(
              'You cannot change the address of the property because there exists already some reservations connected with this property',
            )}. ${t('But you can add a new property')}.`,
        },
      ],
    },
    {
      title: 'Local fees',
      id: 'detail_local_fee',
      fields: [
        isOn && {
          name: 'accommodationFeeId',
          type: 'string',
          inputLabel: true,
          placeholder: 'Variable symbol (Capacity fee)',
          label: 'Variable symbol (Capacity fee)',
          component: FormInput,
          showHelp: true,
          helpContent: localFeeIdTooltip(t),
        },
        ...detail_local_fee,
      ],
    },
    {
      title: 'Ubyport data',
      id: 'detail_ubyport_data',
      fields: [
        {
          name: 'ubyUsername',
          label: 'Login name UbyPort',
          placeholder: 'Login name UbyPort',
          type: 'string',
          component: FormInput,
        },
        {
          name: 'ubyPassword',
          label: 'Password to UbyPort',
          placeholder: 'Password to UbyPort',
          type: 'string',
          component: FormInput,
        },
        {
          name: 'ubyIDUB',
          label: 'UbyPort ID',
          placeholder: 'UbyPort ID',
          type: 'number',
          component: FormInput,
        },
        // {
        //   name: 'ubyClientNameShort',
        //   label: 'UbyPort Client Name Short',
        //   placeholder: 'UbyPort Client Name Short',
        //   type: 'string',
        //   component: FormInput,
        // },
      ],
    },
  ];
  return fieldsArr;
}
