import createReducer from '../../../redux/createReducer';
import {
  GET_CLIENT_INFO,
  GET_CLIENT_INFO_SUCCESS,
  SWITCH_KIND_OF_COMPANY,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_SUCCESS,
  UPDATE_CLIENT_INFO_FAILURE,
} from '../actionTypes';
import { produce } from 'immer';

const initialState = {
  data: {
    clientName: '',
    vatId: '',
    PropertyManager: 0,
    businessId: '',
    bankAccount: '',
    invoiceAddress: '',
    // postalAddress: '',
    email: '',
    phoneNumber: '',
    contactName: '',
    ubyClientContact: '',
    ubyClientName: '',
    ubyClientNameShort: '',
    ubyUsername: '',
    ubyIDUB: '',
  },
  isUpdated: false,
  isUpdatedErrorMsg: null,
  loading: false,
};

export default createReducer(initialState, {
  [GET_CLIENT_INFO]: (state) =>
    produce(state, (draftState) => {
      draftState.loading = true;
      draftState.isUpdatedErrorMsg = null;
      draftState.isUpdated = false;
    }),
  [SWITCH_KIND_OF_COMPANY]: (state, action) =>
    produce(state, (draftState) => {
      draftState.data.propertyManager = action.propertyManager;
    }),
  [GET_CLIENT_INFO_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.data = action.res;
      draftState.isUpdatedErrorMsg = null;
      draftState.isUpdated = false;
    }),

  [UPDATE_CLIENT_INFO]: (state) =>
    produce(state, (draftState) => {
      draftState.loading = true;
      draftState.isUpdatedErrorMsg = null;
      draftState.isUpdated = false;
    }),
  [UPDATE_CLIENT_INFO_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.isUpdated = action.res;
      draftState.isUpdatedErrorMsg = null;
    }),
  [UPDATE_CLIENT_INFO_FAILURE]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.isUpdated = false;
      draftState.isUpdatedErrorMsg = action.res.msg;
    }),

  // [UPDATE_CLIENT_INFO_ACTION]: (state: ClientInfoState) =>
  //   produce(state, draftState => {
  //     draftState.loading = true;
  //     draftState.isUpdatedErrorMsg = null;
  //     draftState.isUpdated = false;
  //   }),
  // [UPDATE_CLIENT_INFO_ACTION_SUCCESS]: (state: ClientInfoState, action) =>
  //   produce(state, draftState => {
  //     draftState.loading = false;
  //     draftState.isUpdated = action.res;
  //     draftState.isUpdatedErrorMsg = null;
  //   }),
  // [UPDATE_CLIENT_INFO_ACTION_FAILURE]: (state: ClientInfoState, action) =>
  //   produce(state, draftState => {
  //     draftState.loading = false;
  //     draftState.isUpdated = false;
  //     draftState.isUpdatedErrorMsg = action.res && action.res.msg;
  //   }),
});
