import React from 'react';
import { Link } from 'react-router-dom';
import NavItem from './NavItem';
import { withStyles } from '@mui/styles';
const styles = (theme) => ({
  menuActive: {
    '&>div, &:hover>div': {
      background: theme.palette.dark.light,
    },
    '& span': {
      color: '#f5f5f5 !important',
    },
    '& img': {
      filter: 'brightness(100%)',
      '-webkit-filter': 'brightness(100%)',
    },
  },
});

const NavLink = ({ name, iconName, isImage, classes, ...props }) => {
  console.log(props, name);
  return (
    <Link
      {...props}
      /* TODO getprops={({ isCurrent, href, location }) => {
        return location.pathname.includes(href) ||
          (href === '/dashboard' && location.pathname === '/')
          ? { className: classes.menuActive }
          : null;
      }}*/
    >
      <NavItem iconName={iconName} isImage={isImage} name={name} {...props} />
    </Link>
  );
};

export default withStyles(styles)(NavLink);
