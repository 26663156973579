import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import TooltipCustom from '../components/TooltipCustom';
import { connect } from 'react-redux';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import {
  generateHostDuties,
  generateHostDutiesYesButton,
  generateHostDutiesLocalNoButton,
  generateHostDutiesCapacityNoButton,
  generateHostDutiesNoReportButton,
  generateHostDutiesYesReportButtonWebSevice,
} from '../components/generateFields';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import { useTranslation } from 'react-i18next';
import { getWelcomers } from '../../../actions/member';
import {
  editAccommodation,
  getAccommodationHostDuties,
} from '../../../actions/onboading';
import { isEmpty, omit } from 'lodash';
import format from 'date-fns/format';

const cusStyles = {
  paperItem: {
    padding: 20,
    marginTop: 30,
  },
  middle_item: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomText: {
    background: '#c6eeff',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    padding: 2,
  },
  continuesButton: {
    textAlign: 'center',
    marginTop: 30,
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '8px 0 4px',
  },
  iconTooltip: {
    marginTop: 20,
  },
  PropertyName: {
    margin: '30px 0',
  },
  HostDutiesItem: {
    marginTop: 10,
    marginBottom: 100,
  },
  buttonStyle: {
    marginTop: 30,
    marginRight: 15,
    minWidth: 200,
  },
};

const contentTooltip = (title) => {
  return <div>{title}</div>;
};

const itemTooltip = (name, t) => {
  const content = () => {
    switch (name) {
      case 'stay_fee':
        return (
          <div>
            {t('Local fee for spa or recreation stay is')} 15, - CZK{' '}
            {t('per person per night')}.
            {t('Except for those under 18 and over 70')}.
            {t('The acknowledgement and fee should be sent every month')} (
            {t('15th day of the following month')}).
            {t('Act No')}. 565/1990 Coll. §3
          </div>
        );
      case 'capicity_fee':
        return (
          <div>
            {t('The accommodation capacity fee is')} 6, - CZK{' '}
            {t('per person per night')}.
            {t(
              'The acknowledgement and fee should be sent every 3 months i.e. every quarter',
            )}
            (15th {t('day of the following month')}).
            {t('Act No')}. 565/1990 Coll. §7
          </div>
        );
      case 'reporting':
        return (
          <div>
            <p>http://zakony-online.cz/?s9&q9=all</p>
            <p>Zákon č. 326/1999 Sb.</p>
            <p>§ 99 - 102</p>
          </div>
        );
      default:
        return null;
    }
  };
  return content();
};

const validation = Yup.object().shape({
  welcomerId: Yup.string().required('Required').nullable(),
  numberOfBeds: Yup.number().required('Required').nullable(),
  // propertyAddress: Yup.string().required('Required'),
});

export const SPA_OR_RECREATION_STAY = 'spa_or_recreation_stay';
export const ACCOMMODATION_CAPACITY = 'accommodation_capacity';
export const REPORTING_FOREIGNERS = 'reporting_foreigners';

let initialValues = {
  accommodationId: null,
  welcomerId: null,
  numberOfBeds: 1,
  rentingFromLocal: new Date(),
  rentingFromCapacity: new Date(),
  localFeeAddress: '',
  capacityFeeAddress: '',
  localFeeId: '',
  accommodationFeeId: '',
  databoxId: '',
  ubyUsername: '',
  ubyPassword: '',
  ubyIDUB: '',
  ubyClientNameShort: '',
};

const HostDuties = ({
  handleSubmit,
  handleBack,
  formData,
  stepKey,
  getWelcomers,
  members,
  propertiesList,
  accommodationHostDuties,
  editAccommodation,
  getAccommodationHostDuties,
  accommodationHostDutiesLoading,
  setTitle,
}) => {
  useEffect(() => {
    getAccommodationHostDuties();
    getWelcomers();
  }, []);

  const [activeProperty, setActiveProperty] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accommodationHostDuties.length > 0) {
      setTitle(` - ${activeProperty + 1}/${accommodationHostDuties.length}`);
    }
  }, [accommodationHostDuties.length > 0]);

  const [YesButton, SetYesButton] = useState({
    [SPA_OR_RECREATION_STAY]: false,
    [ACCOMMODATION_CAPACITY]: false,
    [REPORTING_FOREIGNERS]: false,
  });

  const [NoButton, SetNoButton] = useState({
    [SPA_OR_RECREATION_STAY]: false,
    [ACCOMMODATION_CAPACITY]: false,
    [REPORTING_FOREIGNERS]: false,
  });

  const [DoesNotApplyButton, SetDoesNotApplyButton] = useState({
    [SPA_OR_RECREATION_STAY]: false,
    [ACCOMMODATION_CAPACITY]: false,
    [REPORTING_FOREIGNERS]: false,
  });

  const [ReportForeigners, setReportForeigners] = useState({
    FromAnotherWebService: false,
    FromTheForm: false,
  });

  const [fillLater, setFillLater] = useState({
    [SPA_OR_RECREATION_STAY]: false,
    [ACCOMMODATION_CAPACITY]: false,
    [REPORTING_FOREIGNERS]: false,
  });

  const { t } = useTranslation();

  initialValues = {
    ...initialValues,
    ...(formData && formData[stepKey]),
    accommodationId:
      accommodationHostDuties.length > 0 &&
      accommodationHostDuties[activeProperty]
        ? accommodationHostDuties[activeProperty].accommodationId
        : null,
  };

  const welcomersList = !isEmpty(members)
    ? members.data.map((item) => ({
        value: item.id,
        label: item.welcomerName,
      }))
    : [];

  const formFields = generateHostDuties(welcomersList);

  const setChangeButton = (func, currentState, name) => {
    func({ ...currentState, [name]: true });
  };

  const onSubmit = async (values, { resetForm }) => {
    let params = omit(values, [
      'localFeeAddress_obj',
      'capacityFeeAddress_obj',
    ]);
    params.rentingFromLocal &&
      (params.rentingFromLocal = format(values.rentingFromLocal, 'yyyy-MM-dd'));
    params.rentingFromCapacity &&
      (params.rentingFromCapacity = format(
        values.rentingFromCapacity,
        'yyyy-MM-dd',
      ));
    params.isOnboardingProcess = true;
    setLoading(true);
    editAccommodation(params).then((res) => {
      setLoading(false);
      if (res.data && res.result) {
        if (activeProperty === accommodationHostDuties.length - 1) {
          handleSubmit(values);
        } else {
          resetForm({});

          SetYesButton({
            [SPA_OR_RECREATION_STAY]: false,
            [ACCOMMODATION_CAPACITY]: false,
            [REPORTING_FOREIGNERS]: false,
          });

          SetNoButton({
            [SPA_OR_RECREATION_STAY]: false,
            [ACCOMMODATION_CAPACITY]: false,
            [REPORTING_FOREIGNERS]: false,
          });

          SetDoesNotApplyButton({
            [SPA_OR_RECREATION_STAY]: false,
            [ACCOMMODATION_CAPACITY]: false,
            [REPORTING_FOREIGNERS]: false,
          });

          setReportForeigners({
            FromAnotherWebService: false,
            FromTheForm: false,
          });

          setFillLater({
            [SPA_OR_RECREATION_STAY]: false,
            [ACCOMMODATION_CAPACITY]: false,
            [REPORTING_FOREIGNERS]: false,
          });

          let nextActiveProperty = activeProperty + 1;
          setActiveProperty(nextActiveProperty);
          setTitle(
            ` - ${nextActiveProperty + 1}/${accommodationHostDuties.length}`,
          );
        }
      }
    });
  };

  const renderYesButton = (name) => {
    return (
      <div>
        {(name === SPA_OR_RECREATION_STAY ||
          name === ACCOMMODATION_CAPACITY) && (
          <Paper style={cusStyles.paperItem}>
            <Grid container spacing={24}>
              <Grid item md={12}>
                <Typography variant="subtitle1" align="left">
                  {t('We need this to generate the local fee reports for you')}.
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={fillLater[name]}
                    onClick={() => setFillLater({ ...fillLater, [name]: true })}
                  >
                    {t('Fill later')}
                  </Button>
                </Typography>
              </Grid>
              {!fillLater[name] &&
                generateHostDutiesYesButton(name).map((field, k) => (
                  <Grid
                    item
                    md={k % 2 === 0 ? 5 : 7}
                    xs={12}
                    key={k}
                    style={cusStyles.inputTooltip}
                  >
                    <FastField {...omit(field, 'showTooltip')} />
                    {field.showTooltip && (
                      <TooltipCustom
                        title={t(field.label)}
                        iconStyle={cusStyles.iconTooltip}
                        body={contentTooltip(field.title)}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
          </Paper>
        )}

        {name === REPORTING_FOREIGNERS && (
          <div>
            <Paper style={cusStyles.paperItem}>
              <Grid container spacing={24}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" align="left">
                    {t('Please select one of these options')}:
                  </Typography>
                  <Typography variant="subtitle1" align="left">
                    1. {t('Are you switching')}{' '}
                    <b style={{ fontWeight: 'bold' }}>
                      {t('from another web service provider')}
                    </b>{' '}
                    {t('to us')}?
                  </Typography>
                  <Typography variant="subtitle1" align="left">
                    2. {t('Are you switch')}{' '}
                    <b style={{ fontWeight: 'bold' }}>
                      {t('from the Form')} / {t('Upload module')}
                    </b>{' '}
                    (
                    {t(
                      'that means that you used to upload the check-in forms manually',
                    )}
                    ) {t('to the Web Service module')} (
                    {t(
                      'the check-in forms are uploaded automatically by software',
                    )}
                    )?
                  </Typography>
                  {!ReportForeigners.FromAnotherWebService &&
                    !ReportForeigners.FromTheForm && (
                      <div>
                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          style={cusStyles.buttonStyle}
                          onClick={() =>
                            setReportForeigners({
                              ...ReportForeigners,
                              FromAnotherWebService: true,
                            })
                          }
                        >
                          {t(`From another web service provider`)}
                        </Button>
                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          style={cusStyles.buttonStyle}
                          onClick={() =>
                            setReportForeigners({
                              ...ReportForeigners,
                              FromTheForm: true,
                            })
                          }
                        >
                          {t(`From the Form / Upload module`)}
                        </Button>
                      </div>
                    )}
                </Grid>
              </Grid>
            </Paper>
            {ReportForeigners.FromAnotherWebService && (
              <Paper style={cusStyles.paperItem}>
                <Grid container spacing={24}>
                  <Grid item md={12}>
                    <Typography variant="subtitle1" align="left">
                      {t(
                        'Please fill in the details below to report the foreigners directly through Ubyport to the Foreign police',
                      )}
                      .
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1" align="left">
                        {t('Every property has its unique Ubyport details')}.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        disabled={fillLater[name]}
                        onClick={() =>
                          setFillLater({ ...fillLater, [name]: true })
                        }
                      >
                        {t('Fill later')}
                      </Button>
                    </div>
                  </Grid>
                  {!fillLater[name] &&
                    generateHostDutiesYesReportButtonWebSevice(true).map(
                      (field, k) => (
                        <Grid
                          item
                          md={4}
                          xs={12}
                          key={k}
                          style={cusStyles.inputTooltip}
                        >
                          <FastField {...omit(field, 'showTooltip')} />
                          {field.showTooltip && (
                            <TooltipCustom
                              title={t(field.label)}
                              iconStyle={cusStyles.iconTooltip}
                              body={contentTooltip(t(field.title))}
                            />
                          )}
                        </Grid>
                      ),
                    )}
                </Grid>
              </Paper>
            )}

            {ReportForeigners.FromTheForm && (
              <Paper style={cusStyles.paperItem}>
                <Grid container spacing={24}>
                  <Grid item md={12}>
                    <Typography variant="subtitle1" align="left">
                      {t(
                        'Please fill in the details below to report the foreigners directly through Ubyport to the Foreign police',
                      )}
                      .
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1" align="left">
                        {t('Every property has its unique Ubyport details')}.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        disabled={fillLater[name]}
                        onClick={() =>
                          setFillLater({ ...fillLater, [name]: true })
                        }
                      >
                        {t('Fill later')}
                      </Button>
                    </div>
                  </Grid>
                  {!fillLater[name] &&
                    generateHostDutiesYesReportButtonWebSevice(false).map(
                      (field, k) => (
                        <Grid
                          item
                          md={4}
                          xs={12}
                          key={k}
                          style={cusStyles.inputTooltip}
                        >
                          <FastField {...omit(field, 'showTooltip')} />
                          {field.showTooltip && (
                            <TooltipCustom
                              title={t(field.label)}
                              iconStyle={cusStyles.iconTooltip}
                              body={contentTooltip(t(field.title))}
                            />
                          )}
                        </Grid>
                      ),
                    )}
                </Grid>
              </Paper>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderNoButton = (name) => {
    return (
      <Paper style={cusStyles.paperItem}>
        {(name === SPA_OR_RECREATION_STAY ||
          name === ACCOMMODATION_CAPACITY) && (
          <Grid container spacing={24}>
            <Grid item md={12}>
              <Typography variant="subtitle1" align="left">
                {t(
                  'Please fill the details that are needed to generate the sign up form for the local fees and the local fee reports',
                )}
                .
              </Typography>
            </Grid>
            {[
              ...(name === SPA_OR_RECREATION_STAY
                ? generateHostDutiesLocalNoButton()
                : generateHostDutiesCapacityNoButton()),
            ].map((field, k) => (
              <Grid
                item
                md={k % 2 === 0 ? 5 : 7}
                xs={12}
                key={k}
                style={cusStyles.inputTooltip}
              >
                <FastField {...omit(field, 'showTooltip')} />
                {field.showTooltip && (
                  <TooltipCustom
                    title={t(field.label)}
                    iconStyle={cusStyles.iconTooltip}
                    body={contentTooltip(t(field.title))}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
        {name === REPORTING_FOREIGNERS && (
          <Grid container spacing={24}>
            <Grid item md={12}>
              <Typography variant="subtitle1" align="left">
                {t(
                  'Please fill in the details below to generate the sign up to the Foreign police',
                )}
                .{' '}
                {t(
                  'You will receive the log in details to /contact email/ within a few days',
                )}
                .
              </Typography>
            </Grid>
            {generateHostDutiesNoReportButton().map((field, k) => (
              <Grid
                item
                md={k % 2 === 0 ? 5 : 7}
                xs={12}
                key={k}
                style={cusStyles.inputTooltip}
              >
                <FastField {...omit(field, 'showTooltip')} />
                {field.showTooltip && (
                  <TooltipCustom
                    title={t(field.label)}
                    iconStyle={cusStyles.iconTooltip}
                    body={contentTooltip(t(field.title))}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
    );
  };

  const renderDoesNotApplyButton = (name) => {
    return (
      <Paper style={cusStyles.paperItem}>
        <Typography variant="subtitle1" align="left">
          {t('Does Not Apply')}
        </Typography>
      </Paper>
    );
  };

  const checkBeforeContinue = (val) => {
    if (!val.welcomerId || !val.numberOfBeds) {
      // console.log("false")
      return false;
    }

    if (
      (YesButton[SPA_OR_RECREATION_STAY] || NoButton[SPA_OR_RECREATION_STAY]) &&
      (YesButton[ACCOMMODATION_CAPACITY] || NoButton[ACCOMMODATION_CAPACITY]) &&
      (YesButton[REPORTING_FOREIGNERS] || NoButton[REPORTING_FOREIGNERS])
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderPropertyDetail = (item) => {
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align={'center'}
            style={cusStyles.PropertyName}
          >
            {activeProperty + 1}# {item.accommodationName}
          </Typography>
          <Typography variant="subtitle1">
            {t(
              `Dear user, please fill in the details below to automate the fulfilment of your host duties`,
            )}
            .{' '}
            {t(
              `If you are not registered at the city office or Ubyport yet we will help you with that as well`,
            )}
            .
          </Typography>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
            render={({ dirty, values }) => {
              return (
                <Form>
                  <Grid container spacing={24} style={{ marginBottom: 15 }}>
                    {welcomersList.length > 0 &&
                      formFields.map((field, k) => (
                        <Grid
                          item
                          md={field.name !== 'propertyAddress' ? 6 : 12}
                          xs={12}
                          key={k}
                          style={cusStyles.inputTooltip}
                        >
                          <FastField {...omit(field, 'showTooltip')} />
                          {field.showTooltip && (
                            <TooltipCustom
                              title={t(field.label)}
                              iconStyle={cusStyles.iconTooltip}
                              body={contentTooltip(t(field.title))}
                            />
                          )}
                        </Grid>
                      ))}
                  </Grid>
                  <div style={cusStyles.HostDutiesItem}>
                    <TitleHasUnderLine
                      marginTop={20}
                      marginBottom={20}
                      title={
                        <div>
                          {t(`Spa or recreation stay fee`)} +{' '}
                          {t(`Registration book`)}{' '}
                          <TooltipCustom
                            title={`${t(`Spa or recreation stay fee`)} + ${t(
                              `Registration book`,
                            )}`}
                            iconStyle={cusStyles.iconTooltip}
                            body={itemTooltip('stay_fe, te, t', t)}
                          />
                        </div>
                      }
                      size="h6"
                    />
                    <Typography variant="subtitle1">
                      {t(
                        `Is the property registered at the City office for the local fee from the spa or recreation stay`,
                      )}
                      ?
                    </Typography>
                    <Grid
                      container
                      spacing={24}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ textAlign: 'center' }}
                    >
                      <Grid item xs>
                        {!YesButton[SPA_OR_RECREATION_STAY] &&
                          !NoButton[SPA_OR_RECREATION_STAY] && (
                            <div>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetYesButton,
                                    YesButton,
                                    SPA_OR_RECREATION_STAY,
                                  )
                                }
                              >
                                {t(`Yes`)}
                              </Button>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetNoButton,
                                    NoButton,
                                    SPA_OR_RECREATION_STAY,
                                  )
                                }
                              >
                                {t(`Not yet`)}
                              </Button>
                            </div>
                          )}
                        {YesButton[SPA_OR_RECREATION_STAY] &&
                          renderYesButton(SPA_OR_RECREATION_STAY)}
                        {NoButton[SPA_OR_RECREATION_STAY] &&
                          renderNoButton(SPA_OR_RECREATION_STAY)}
                      </Grid>
                    </Grid>
                  </div>

                  <div style={cusStyles.HostDutiesItem}>
                    <TitleHasUnderLine
                      marginTop={20}
                      marginBottom={20}
                      title={
                        <div>
                          {t(`Accommodation capacity fee`)} +{' '}
                          {t(`Registration book`)}{' '}
                          <TooltipCustom
                            title={`${t(
                              `Accommodation capacity fee`,
                            )} + ${`Registration book`}`}
                            iconStyle={cusStyles.iconTooltip}
                            body={itemTooltip('capicity_fee, t', t)}
                          />
                        </div>
                      }
                      size="h6"
                    />
                    <Typography variant="subtitle1">
                      {t(
                        `Is the property registered at the City office for the local fee from the accommodation capacity`,
                      )}
                      ?
                    </Typography>
                    <Grid
                      container
                      spacing={24}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ textAlign: 'center' }}
                    >
                      <Grid item xs>
                        {!YesButton[ACCOMMODATION_CAPACITY] &&
                          !NoButton[ACCOMMODATION_CAPACITY] &&
                          !DoesNotApplyButton[ACCOMMODATION_CAPACITY] && (
                            <div>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetYesButton,
                                    YesButton,
                                    ACCOMMODATION_CAPACITY,
                                  )
                                }
                              >
                                {t(`Yes`)}
                              </Button>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetNoButton,
                                    NoButton,
                                    ACCOMMODATION_CAPACITY,
                                  )
                                }
                              >
                                {t(`Not yet`)}
                              </Button>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetDoesNotApplyButton,
                                    DoesNotApplyButton,
                                    ACCOMMODATION_CAPACITY,
                                  )
                                }
                              >
                                {t(`Obligation doesn‘t apply`)}
                              </Button>
                            </div>
                          )}
                        {YesButton[ACCOMMODATION_CAPACITY] &&
                          renderYesButton(ACCOMMODATION_CAPACITY)}
                        {NoButton[ACCOMMODATION_CAPACITY] &&
                          renderNoButton(ACCOMMODATION_CAPACITY)}
                        {DoesNotApplyButton[ACCOMMODATION_CAPACITY] &&
                          renderDoesNotApplyButton(ACCOMMODATION_CAPACITY)}
                      </Grid>
                    </Grid>
                  </div>

                  <div style={cusStyles.HostDutiesItem}>
                    <TitleHasUnderLine
                      marginTop={20}
                      marginBottom={20}
                      title={
                        <div>
                          {t(`Reporting foreigners`)} + {t(`House book`)}{' '}
                          <TooltipCustom
                            title={`${t(`Reporting foreigners`)} + ${t(
                              `House book`,
                            )}`}
                            iconStyle={cusStyles.iconTooltip}
                            body={itemTooltip('reporting', t)}
                          />
                        </div>
                      }
                      size="h6"
                    />
                    <Typography variant="subtitle1">
                      {t(
                        `Is the property registered at the Foreign police to report your guests from foreign countries through Ubyport`,
                      )}
                      ?
                    </Typography>
                    <Typography variant="body2">
                      *{' '}
                      {t(
                        `If you are not registered or the owner changes or the address of the accommodation changes, please, select`,
                      )}{' '}
                      "{t(`Not yet`)}".
                    </Typography>
                    <Grid
                      container
                      spacing={24}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ textAlign: 'center' }}
                    >
                      <Grid item xs>
                        {!YesButton[REPORTING_FOREIGNERS] &&
                          !NoButton[REPORTING_FOREIGNERS] && (
                            <div>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetYesButton,
                                    YesButton,
                                    REPORTING_FOREIGNERS,
                                  )
                                }
                              >
                                {t(`Yes`)}
                              </Button>
                              <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                style={cusStyles.buttonStyle}
                                onClick={() =>
                                  setChangeButton(
                                    SetNoButton,
                                    NoButton,
                                    REPORTING_FOREIGNERS,
                                  )
                                }
                              >
                                {t(`Not yet`)}
                              </Button>
                            </div>
                          )}
                      </Grid>
                      {NoButton[REPORTING_FOREIGNERS] &&
                        renderNoButton(REPORTING_FOREIGNERS)}
                      {YesButton[REPORTING_FOREIGNERS] &&
                        renderYesButton(REPORTING_FOREIGNERS)}
                    </Grid>
                  </div>

                  <div style={cusStyles.continuesButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="default"
                      onClick={handleBack}
                      style={{ marginRight: 15 }}
                    >
                      {t(`Back`)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!checkBeforeContinue(values)}
                    >
                      {t(`Continue`)}
                      {loading && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Button>
                    {!checkBeforeContinue(values) && (
                      <Typography style={{ color: 'red' }}>
                        {t('Please fill all the information')}
                      </Typography>
                    )}
                  </div>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      {accommodationHostDutiesLoading ? (
        <CircularProgress />
      ) : accommodationHostDuties && accommodationHostDuties.length > 0 ? (
        renderPropertyDetail(accommodationHostDuties[activeProperty])
      ) : (
        <div style={{ ...cusStyles.continuesButton, marginTop: 300 }}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={handleBack}
            style={{ marginRight: 15 }}
          >
            {t(`Back`)}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            {t(`Continue`)}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  members: state.member.memberList,
  propertiesList: state.property.propertiesList.data,
  accommodationHostDuties: state.onboarding.accommodationHostDuties.data,
  accommodationHostDutiesLoading:
    state.onboarding.accommodationHostDuties.loading,
});

const mapActionToProps = {
  getWelcomers,
  editAccommodation,
  getAccommodationHostDuties,
};

export default connect(mapStateToProps, mapActionToProps)(HostDuties);
