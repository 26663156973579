import React from 'react';
import {FormControl, TextField, FormHelperText} from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomTimePicker = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth margin={'dense'}>
      <TextField
        {...field}
        {...props}
        type="time"
        label={t(props.label)}
        placeholder={t(props.placeholder)}
        autoFocus={props.autoFocus ? props.autoFocus : false}
        variant={props.variant ? props.variant : 'standard'}
        value={field.value || ''}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        onChange={evt => {
          setFieldValue(field.name, evt.target.value)
        }}
      />
      {touched[field.name] && errors[field.name] && (
        <FormHelperText error>{t(errors[field.name])}</FormHelperText>
      )}
    </FormControl>
  )
};

export default CustomTimePicker;
