import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  withLatestFrom,
  flatMap,
} from 'rxjs/operators';
import {
  FETCH_ACCOMODATIONS,
  FETCH_ACCOMODATION_DETAIL,
  FETCH_ACCOMODATION_DETAIL_FAILURE,
  ADD_ACCOMODATION,
  ADD_NEW_LISTING,
  GET_LISTING_BY_ACCOMMODATION,
  UPDATE_ACCOMMODATION,
} from './actionTypes';
import {
  getAccommodationsSuccess,
  addAccommodationSuccess,
  addNewListingSuccess,
  getListingByAccommodationSuccess,
  updateAccommodationSuccess,
} from './actions';
import { requestFailure } from '../../redux/handleError';
import { ajax } from 'rxjs/ajax';
import { getAccomodationDetailSuccess } from './actions';
import { closeModal } from '../../shared/actions';
import qs from 'qs';
import modalName from '../../shared/const/modalName';
import requestEpic, { getJSONEpic } from '../../shared/requestEpic';
import { RequestMethod } from '../../shared/const/requestMethod';
import { AccommodationUrl } from '../../shared/urls';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BEST_GUEST_API_BASE_URL || '';

const fetchAccomodationsEpic = getJSONEpic(
  FETCH_ACCOMODATIONS,
  '/accommodations/getAccommodations',
  map((res) => getAccommodationsSuccess(res.data)),
);

const addAccomodationEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_ACCOMODATION),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const token = state.account.detail.tokens.authToken;
      return ajax
        .post(
          `${BASE_URL}/accommodations/addAccommodation`,
          qs.stringify({
            data: JSON.stringify({
              ...action.params,
            }),
          }),
          {
            Authorization: `${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        )
        .pipe(
          flatMap((res) => {
            return [
              addAccommodationSuccess(res.response.data),
              closeModal(modalName.CREATE_RESERVATION_MODAL),
            ];
          }),
          catchError((error) =>
            of(requestFailure(error.xhr.response, error.status)),
          ),
        );
    }),
  );

const fetchAccomodationDetailEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ACCOMODATION_DETAIL),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const token = state.account.detail.tokens.authToken;
      return ajax
        .post(
          `${BASE_URL}/accommodations/getAccommodation`,
          {
            data: JSON.stringify({
              accommodationId: action.id,
            }),
          },
          {
            Authorization: `${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        )
        .pipe(
          map((res) => getAccomodationDetailSuccess(res.response.data)),
          catchError((error) =>
            of({
              type: FETCH_ACCOMODATION_DETAIL_FAILURE,
              error: error.xhr.response,
            }),
          ),
        );
    }),
  );

const addNewListingEpic = requestEpic(
  ADD_NEW_LISTING,
  RequestMethod.POST,
  AccommodationUrl.CREATE_NEW_LISTING,
  flatMap((res) => {
    return [
      addNewListingSuccess(res.response.data),
      closeModal(modalName.CREATE_LISTING),
    ];
  }),
);

const getListingByAccommodationEpic = getJSONEpic(
  GET_LISTING_BY_ACCOMMODATION,
  AccommodationUrl.GET_LISTING_BY_ACCOMMODATION,
  map((res) => {
    return getListingByAccommodationSuccess(res.data);
  }),
);

const updateAccommodationEpic = requestEpic(
  UPDATE_ACCOMMODATION,
  RequestMethod.POST,
  AccommodationUrl.UPDATE_ACCOMMODATION_URL,
  map((res) => {
    toast.success('Update succeed!');
    return updateAccommodationSuccess(res.response.data);
  }),
);

export default combineEpics(
  fetchAccomodationsEpic,
  fetchAccomodationDetailEpic,
  addAccomodationEpic,
  addNewListingEpic,
  getListingByAccommodationEpic,
  updateAccommodationEpic,
);
