import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { rootStyles } from './style';
import OnboardingRoutes from '../../../routes/onboarding-routes';
import { GlobalDrawerContext } from '../../../contexts';
import useGlobalDrawer from '../../../custom-hooks/useGlobalDrawer';
import { getClientInfo, getResidentialClient } from '../../../actions/account';
import { getCountries, getVisaCountry } from '../../../actions/country';
import { getWelcomersRoles } from '../../../actions/member';
import { getProperties } from '../../../actions/property';
import {
  NATIVE_NAME,
  ALPHA_3_CODE,
  FLAG,
  ENGLISH_NAME,
} from '../../../reducers/countryReducer';

const Onboarding = ({
  getCountries,
  getVisaCountry,
  getClientInfo,
  getProperties,
  getResidentialClient,
  getWelcomersRoles,
  account,
}) => {
  const { state, dispatch } = useGlobalDrawer();

  useEffect(() => {
    console.log(account);
    if (account.detail.userId) {
      getCountries({
        fields: `${ENGLISH_NAME};${NATIVE_NAME};${ALPHA_3_CODE};${FLAG}`,
      });
      getVisaCountry();
      getClientInfo();
      getProperties();
      getResidentialClient();
      getWelcomersRoles();
    }
  }, [account.detail.userId]);

  return (
    <GlobalDrawerContext.Provider value={{ state, dispatch }}>
      <OnboardingRoutes />
    </GlobalDrawerContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {
  getCountries,
  getVisaCountry,
  getClientInfo,
  getProperties,
  getResidentialClient,
  getWelcomersRoles,
})(withStyles(rootStyles)(Onboarding));
