/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import HourGlassIcon from '@mui/icons-material/HourglassFull';
import ExclamationIcon from '@mui/icons-material/PriorityHigh';
import DoneIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { Hidden, Paper, Typography, IconButton } from '@mui/material';
import {
  ST_WAITING,
  ST_PROGRESS,
  ST_SEND,
  ST_FAILED,
  ST_SEND_SUCCESS,
  ST_SENT_ERROR,
} from '../../../shared/const/checkinForm';
import VirtualTableCustom from '../../../components/VirtualTableCustom';
import AlertIcon from '../../../styles/reservation-detail/Alert.png';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import { withState } from 'recompose';


const styles = {
  flexRowTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px 0 15px'
  },
  flexRowBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 7px 0 15px'
  },
  statusContent: {
    display: 'flex',
    placeItems: 'center'
  },
  statusIcon: {
    marginRight: 10
  }
}


const CheckInFormList = ({
  forms,
  openToEditCheckInForm,
  setOpenDeleteGuest,
  hasClosed,
  reservationDetail,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState({
    isCopied: false,
    index: null,
  })
  const onCopyEmail = (row) => {
    setCopied({
      isCopied: true,
      index: row.sendToId.tempEmail,
    });
    setTimeout(() => {
      setCopied({
        isCopied: false,
        index: null,
      });
    }, 1200);
  }
  const columns = [
    {
      name: 'guestName',
      title: t('Guest Name'),
      getCellValue: row => <div style={{ textTransform: 'capitalize' }}>
        <div>{row.firstName + ' ' + row.lastName}</div>
        {row.masterGuest && <Typography variant="caption" color="primary" styles={{ fontSize: '80%' }}>{t("Master Guest")}</Typography>}
      </div>,
    },
    {
      name: 'alerts',
      title: <span data-tip={t("How many times was the guest alerted to fill the check-in form")} data-place="bottom"
        data-for={"readyToSend"} className="mgLeft2">
        <img src={AlertIcon} style={{ width: 18 }} alt={'alert'} />
        <ReactTooltip id="readyToSend" place="right" type="info" effect="float" multiline={true} />
      </span>,
      getCellValue: row => {
        return <span>{row.masterGuest ? reservationDetail.notificationsCount : row.alerts || row.notificationsCount || 0}</span>
      }
    },
    !hasClosed && {
      name: 'tempEmail',
      title: t('Email'),
      getCellValue: row => {
        return (

          <div style={{position: 'relative'}}>
          <CopyToClipboard onCopy={()=>onCopyEmail(row)} text={row.sendToId && row.sendToId.tempEmail}>
              <a href="javascript:void(0)">{row.sendToId && row.sendToId.tempEmail}</a>
            </CopyToClipboard>
            {copied.isCopied && copied.index === row.sendToId.tempEmail && <span style={{ fontSize: 11, position: 'absolute', right: 0,background:"white" }}>{t("Copied")}</span>}
            </div>
        )
      }
    },
    {
      name: 'status',
      title: t('Status'),
      getCellValue: row => renderStatus(row.status)
    },
    // {
    //   name: 'editInfo',
    //   title: 'Edit info',
    //   getCellValue: row => (
    //     <IconButton color="primary" onClick={() => openToEditInfo(row)}>
    //       <EditIcon fontSize={'small'} />
    //       <Typography color="primary" style={{ paddingLeft: 4 }}>
    //         Info
    //       </Typography>
    //     </IconButton>
    //   ),
    // },
    {
      name: 'editCheckInForm',
      title: t('Check-in form'),
      getCellValue: row => (
        <ButtonWithTooltip show={hasClosed} title={t(`You can't edit this check-in form because the reservation was already closed`)}>
          <IconButton color="primary" disabled={hasClosed} onClick={() => openToEditCheckInForm(row)}>
            <EditIcon fontSize={'small'} />
            <Typography style={{ paddingLeft: 4 }} >
              {t('Edit')}
            </Typography>
          </IconButton>
        </ButtonWithTooltip>
      ),
    },
    {
      name: ' ',
      title: '',
      getCellValue: row => (
        <ButtonWithTooltip show={hasClosed} title={t(`You can't delete this check-in form because the reservation was already closed`)}>
          <IconButton onClick={() => setOpenDeleteGuest(row)} disabled={(!row.canDelete && !row.invitationId) || hasClosed}>
            <DeleteIcon fontSize={'small'} />
          </IconButton>
        </ButtonWithTooltip>
      ),
    },
  ];

  const tableColumnExtensions = [
    { columnName: 'alerts', width: 150, align: 'center' },
  ]

  function renderStatus(status) {
    switch (status) {
      case ST_SENT_ERROR:
      case ST_FAILED:
        return <CancelIcon fontSize={'small'} color="error" />;
      case ST_PROGRESS:
        return <HourGlassIcon fontSize={'small'} color="primary" />;
      case ST_SEND_SUCCESS:
        return (
          <Typography style={styles.statusContent} color={'secondary'}>
            <DoneIcon fontSize={'small'} color="secondary" style={styles.statusIcon} />
            {t('Sent')}
          </Typography>
        )
      case ST_WAITING:
        // case ST_FORM_FILLED:
        return (
          <Typography style={styles.statusContent} color={'secondary'}>
            <EmailIcon color={'secondary'} fontSize={'small'} style={styles.statusIcon} />
            {t('Ready to send')}
          </Typography>
        )
      case ST_SEND:
        return (
          <Typography style={styles.statusContent} color={'error'}>
            <ExclamationIcon fontSize={'small'} color="error" style={styles.statusIcon} />
            {t('Missing')}
          </Typography>
        )
      default:
        return (
          <Typography style={styles.statusContent} color={'error'}>
            <ExclamationIcon fontSize={'small'} color="error" style={styles.statusIcon} />
            {t('Missing')}
          </Typography>
        )
    }
  }

  return (
    <div>
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <Grid rows={forms} columns={columns}>
          <Table columnExtensions={tableColumnExtensions} />
          <VirtualTableCustom dataCount={forms.length} />
          <TableHeaderRow />
        </Grid>
      </Hidden>
      {/* Mobile */}
      <Hidden smUp implementation="css">
        {
          forms.length > 0 && forms.map((v, k) => (
            <Paper style={{ marginTop: 15 }} key={k}>
              <div style={styles.flexRowTop}>
                <div style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                  {v.firstName + ' ' + v.lastName}
                </div>
                {renderStatus(v.status)}
              </div>
              <div style={styles.flexRowBottom}>
                <div style={{ display: 'flex', alignItems: 'center' }}><img src={AlertIcon} style={{ width: 18, marginRight: 10 }} alt="" />{v.notificationsCount}</div>
                <ButtonWithTooltip show={hasClosed} title={t(`You can't edit this check-in form because the reservation was already closed`)}>
                  <IconButton color="primary" onClick={() => openToEditCheckInForm(v)} disabled={hasClosed}>
                    <EditIcon fontSize={'small'} />
                    <Typography style={{ paddingLeft: 4 }} >
                      {t('Edit')}
                    </Typography>
                  </IconButton>
                </ButtonWithTooltip>
                <ButtonWithTooltip show={hasClosed} title={t(`You can't delete this check-in form because the reservation was already closed`)}>
                  <IconButton onClick={() => setOpenDeleteGuest(v)} disabled={(!v.canDelete && !v.invitationId) || hasClosed}>
                    <DeleteIcon fontSize={'small'} />
                  </IconButton>
                </ButtonWithTooltip>
              </div>
            </Paper>
          ))
        }
      </Hidden>
    </div>
  );
};

CheckInFormList.propTypes = {
  forms: PropTypes.array,
  openToEditInfo: PropTypes.func,
  openToEditCheckInForm: PropTypes.func,
};

CheckInFormList.defaultProps = {
  forms: [],
};

export default withState('copied')(CheckInFormList);
