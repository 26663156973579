const drawerWidth = 240;

export const loginStyles = theme => ({
  formLayout: {
    backgroundColor: '#f5f6f7',
    minHeight: '90vh',
  },
  formWrapper: {
    // width: 480,
    // height: 'auto',
    // borderRadius: 6,
    // padding: '30px 45px',
  },
  formGroup: {
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: '50px 15px',
    textAlign: 'left',
  },
  header: {
    marginTop: 20,
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  margin: {
    marginTop:20,
  },
  title: {
    fontWeight: 500,
  },
  wrapper: {
    position: 'relative',
  },
  link: {
    textAlign: 'center',
    color: theme.palette.common,
    marginTop: 20,
  },
  buttonProgress: {
    marginLeft: 10,
    // color: green[500],
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    // marginLeft: -12,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  logo: {
    width: '100%',
    margin: '15px 0 30px',
  }
});

export const forgotStyle = theme => ({
  formLayout: {
    backgroundColor: '#f5f6f7',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  formWrapper: {
    width: 480,
    height: 'auto',
    borderRadius: 6,
    padding: '30px 45px',
  },
  formGroup: {
    backgroundColor: '#fff',
    borderRadius: 6,
    boxShadow: '0 10px 21px #e7eeef',
    padding: '30px 45px',
    textAlign: 'left',
  },
  header: {
    marginTop: 20,
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  margin: {
    marginTop: theme.spacing.unit * 2,
  },
  title: {
    fontWeight: 500,
  },
  wrapper: {
    position: 'relative',
  },
});

export const profileStyle = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 1.5,
  },
  content: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 1.5,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 1.5,
  },
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  box: {
    margin: theme.spacing.unit * 2,
    padding: `10 0`,
  },
});
