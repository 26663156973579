import React from 'react';
import { Stepper, Step, StepLabel, Grid, StepConnector } from '@mui/material';
import { withStyles } from '@mui/styles';
const styles = (theme) => ({
  root: {
    marginTop: 30,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorDisabled: {
    // '& $connectorLine': {
    //   borderColor: theme.palette.grey[100],
    // },
  },
  connectorLine: {
    // transition: theme.transitions.create('border-color'),
  },
  StepLabel: {
    // padding: 0,
    '& span': {
      padding: 0,
    },
  },
});

const StepperCustom = ({ activeStep, steps, classes }) => {
  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (
    <Grid container spacing={24} justify="center" className={classes.root}>
      <Grid item md={6} xs={12}>
        <Stepper activeStep={activeStep} connector={connector}>
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props} className={classes.StepLabel}>
                <StepLabel {...labelProps} className={classes.StepLabel} />
              </Step>
            );
          })}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(StepperCustom);
