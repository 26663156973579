import { THROW_ERROR } from '../redux/handleError';
import { removeItemFromStorage, BESTGUEST_STORE_NAME } from '../helpers/localStorage';
import { push } from 'connected-react-router';
import { result } from 'lodash';
import { toast } from 'react-toastify';
import getErrorName from '../shared/const/errorCode';
import { getRedirectUrl, loginRedirect } from '../helpers';

export const requiredAuthen = store => next => action => {
  if (action.type === THROW_ERROR && action.statusCode === 401) {
    if (action.errors && action.errors.length > 0 && action.errors[0].msg === 'MASTER_TOKEN_EXPIRED') {
      removeItemFromStorage(BESTGUEST_STORE_NAME);
      let loginUrl = loginRedirect(getRedirectUrl(window.location.href), window.location.search);
      window.location.href = loginUrl;
      // store.dispatch(push('/login'));
      return false;
    }
    if (action.errors && typeof (action.errors) === 'object' && action.errors.errors && action.errors.errors.length > 0) {
      const errorMsg = result(action.errors, 'errors[0].msg', 'Server error');
      toast.error(getErrorName(errorMsg));
      return false;
    }
    return next(action);
  }
  return next(action);
};
