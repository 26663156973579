import createImmerReducer from '../../redux/immer-reducer';

import {
  GET_LISTING_DETAIL,
  GET_LISTING_DETAIL_SUCCESS,
  GET_LISTING_DETAIL_FAILURE,
} from '../../actions/listing';

const initialState = {
  data: {
    checkIn: null,
    checkOut: null,
    clientId: null,
    icals: {},
    integrations: {},
    listingHash: null,
    listingId: null,
    listingName: null,
    specialCode1: null,
    specialCode2: null,
    wifiName: null,
    wifiPassword: null,
  },
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_LISTING_DETAIL]: state => {
    state.loading = true;
  },
  [GET_LISTING_DETAIL_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [GET_LISTING_DETAIL_FAILURE]: (state, action) => {
    state.loading = false;
    state.data = initialState;
  },
});
