import React, { useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  DialogTitle: {
    padding: '50px 50px 0',
  },
  margin: {
    margin: '20px 0',
    paddingLeft: '15px',
    textAlign: 'center',
    '& button': {
      marginRight: 15,
    },
  },
  DialogContent: {
    padding: '0 50px 50px',
  },
  textField: {
    minHeight: 100,
  },
});

const ContactUsDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  classes,
  objectKey,
  initialData,
}) => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState(
    `Hello, I'd like to start using Best Guest but I have no account on Airbnb or Booking yet. Please, let me know what I can do. Thanks.`,
  );

  const handleSubmitContact = () =>
    handleSubmit({ ...initialValues, type: objectKey });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Hidden xsDown>
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          <TitleHasUnderLine
            title={'Contact Us'}
            size="h4"
            color="default"
            borderWidth={3}
            marginBottom={30}
          />
        </DialogTitle>

        <DialogContent className={classes.DialogContent}>
          <Typography variant="subtitle1">
            {t(
              `If you don‘t have account an Airbnb or Booking account, please write us`,
            )}
            .
          </Typography>
          <TextField
            multiline
            rowsMax="4"
            value={initialValues}
            onChange={(e) => setInitialValues(e.target.value)}
            margin="normal"
            className={classes.textField}
            variant="outlined"
            fullWidth
          />
          <div className={classes.margin}>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              {t(`Cancel`)}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!initialValues}
              onClick={handleSubmitContact}
            >
              {t(`Send`)}
            </Button>
          </div>
        </DialogContent>
      </Hidden>
      <Hidden smUp>
        <DialogTitle id="form-dialog-title" style={{ padding: '50px 0px 0' }}>
          <TitleHasUnderLine
            title={'Contact Us'}
            size="h5"
            color="default"
            borderWidth={3}
            marginBottom={30}
          />
        </DialogTitle>

        <DialogContent style={{ padding: '0' }}>
          <Typography variant="subtitle1">
            {t(
              `If you don‘t have account an Airbnb or Booking account, please write us`,
            )}
            .
          </Typography>
          <TextField
            multiline
            rowsMax="4"
            value={initialValues}
            onChange={(e) => setInitialValues(e.target.value)}
            margin="normal"
            className={classes.textField}
            variant="outlined"
            fullWidth
          />
          <div className={classes.margin}>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              {t(`Cancel`)}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!initialValues}
              onClick={handleSubmitContact}
            >
              {t(`Send`)}
            </Button>
          </div>
        </DialogContent>
      </Hidden>
    </Dialog>
  );
};
export default withStyles(styles)(ContactUsDialog);
