import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';
import { Button, Typography, CircularProgress } from '@mui/material';
import AdminForm from '../components/AdminForm';
import {
  yourClientFields,
  yourClientLocalFeesFields,
} from '../components/adminFields';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import DrawerAppBar from '../../../shared/drawer-app-bar';
import { withTranslation } from 'react-i18next';
import ConnectClient from '../components/ConnectClient';
import Airbnb from '../../../styles/onboarding/connect_account/Airbnb_logo.png';
import Booking from '../../../styles/onboarding/connect_account/Booking_logo.png';
import styled from 'styled-components';
import CusTooltip from '../../../components/CusTooltip';

const StyledSignatureImg = styled.div`
  width: 100%;
  margin: 10px 0;
  .gr-signature-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    .signature-preview {
      border: 1px dashed #e0e0e0;
    }
  }
`;

const width = window.innerWidth;
const styles = {
  buttonProgress: {
    color: green[500],
    marginLeft: 15,
  },
};

class YourClientForm extends PureComponent {
  render() {
    const {
      initialValues,
      validationSchema,
      onClose,
      isUpdating,
      handleCreate,
      handleUpdate,
      // handleDeleteResidentClient,
      loading,
      // deleteLoading,
      updateErrorMsg,
      clientInfo,
      t,
      openSignature,
      signature,
      removeSignature,
      requiredSignature,
    } = this.props;
    return (
      <div style={{ marginBottom: 30, width: width < 500 ? width : 'auto' }}>
        <DrawerAppBar onClick={() => onClose()} />
        <Typography variant={'h4'} align={'center'}>
          {t('Your client')}
        </Typography>
        <Formik
          onSubmit={isUpdating ? handleUpdate : handleCreate}
          validationSchema={validationSchema}
          initialValues={initialValues}
          render={({ dirty, submitForm, isValid }) => (
            <Form>
              <div style={{ padding: `0 30px` }}>
                <AdminForm
                  fields={yourClientFields(t)}
                  title={'Client'}
                  noBoxShadow
                  hasBorder
                  updateErrorMsg={updateErrorMsg}
                />
                <AdminForm
                  fields={yourClientLocalFeesFields(t)}
                  title={'Local fees'}
                  noBoxShadow
                  hasBorder
                />
                <div
                  style={{
                    marginBottom: 30,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {signature ? (
                    <StyledSignatureImg>
                      <div className="gr-signature-icon">
                        <img
                          className="signature-preview"
                          src={signature}
                          height={80}
                          alt="signature"
                        />

                        <Button onClick={removeSignature}>
                          <CloseIcon fontSize={'small'} />
                          <Typography
                            variant="caption"
                            style={{ paddingLeft: 5 }}
                          >
                            {t('Remove signature')}
                          </Typography>
                        </Button>
                      </div>
                    </StyledSignatureImg>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <Button
                        style={{
                          textTransform: 'uppercase',
                          border: '1px solid #f16d36',
                          color: '#f16d36',
                          backgroundColor: '#fff',
                          width: '100%',
                          ...requiredSignature,
                        }}
                        onClick={openSignature}
                        fullWidth
                      >
                        {t('Click to Add Signature')}
                      </Button>
                      {requiredSignature && (
                        <div
                          style={{
                            color: '#f44336',
                            marginTop: 10,
                            textAlign: 'center',
                            fontSize: '80%',
                          }}
                        >
                          Required
                        </div>
                      )}
                    </div>
                  )}
                  <CusTooltip
                    title={`${t(
                      'This is a signature of the accommodation provider',
                    )}. ${t(
                      'Best Guest will use it for generating local fee acknowledgments',
                    )}.`}
                  />
                </div>

                <ConnectClient
                  img={Airbnb}
                  connectType={'AIRBNB'}
                  name={'airbnb'}
                  initialValues={initialValues}
                  clientInfo={clientInfo}
                />
                <ConnectClient
                  img={Booking}
                  connectType={'BOOKING'}
                  name={'booking'}
                  initialValues={initialValues}
                  clientInfo={clientInfo}
                />
                <Button
                  style={{ marginTop: 20 }}
                  fullWidth
                  onClick={submitForm}
                  disabled={!dirty || !signature || loading}
                  variant={'contained'}
                  color={'primary'}
                >
                  {!!isUpdating ? t('Update') : t('Save')}
                  {!!loading && (
                    <CircularProgress size={24} style={styles.buttonProgress} />
                  )}
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withTranslation()(YourClientForm);
