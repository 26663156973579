/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { Paper, Hidden } from '@mui/material';
import Placeholder from '../../../common/Placeholder';
import { size } from 'lodash';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import VirtualTableCustom from '../../../components/VirtualTableCustom';
import { withTranslation } from 'react-i18next';
import ListingIcon from '../../../styles/reservation-detail/Listings.png';

const PropertiesList = ({ classes, propertyReducer, t }) => {
  const history = useNavigate();
  const columns = [
    {
      name: 'accommodationName',
      title: t('Property name'),
    },
    {
      name: 'street',
      title: t('Street'),
      getCellValue: (row) =>
        `${row.street} ${row.registerNumber}${
          row.registerNumber && row.orientationNumber && '/'
        }${row.orientationNumber}`,
    },
    {
      name: 'cityDistrict',
      title: t('City quarter'),
    },
    {
      name: 'city',
      title: t('Municipality'),
    },
    {
      name: 'listingsCount',
      title: t('Listings'),
      getCellValue: (row) => row.listingsCount || size(row.listings),
    },
    // {
    //   name: 'icon',
    //   title: t('Detail'),
    //   getCellValue: row => <Icon>keyboard_arrow_right</Icon>,
    // },
  ];

  const tableColumnExtensions = [
    { columnName: 'personsCount', width: 60, align: 'center' },
    { columnName: 'filledForms', width: 60, align: 'center' },
    { columnName: 'missingForms', width: 60, align: 'center' },
    { columnName: 'checkInOut', align: 'center' },
    { columnName: 'readyToSend', width: 100, align: 'center' },
    { columnName: 'deadline', align: 'center' },
  ];

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => history(`${row.accommodationId}`)}
      style={{
        cursor: 'pointer',
      }}
    />
  );

  const styles = {
    flexTop: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6px 10px',
    },
    padBottom: {
      padding: 10,
      fontSize: '0.875rem',
    },
  };

  const properties = propertyReducer.propertiesList;
  const { data, loading } = properties;
  return (
    <div>
      <Hidden xsDown implementation="css">
        <Paper>
          <Placeholder loading={loading} type={'table'}>
            <Grid rows={data} columns={columns}>
              <Table
                rowComponent={TableRow}
                messages={{ noData: t('No Data') }}
              />
              <VirtualTableCustom
                dataCount={data.length}
                columnExtensions={tableColumnExtensions}
                rowComponent={TableRow}
              />
              <TableHeaderRow />
            </Grid>
          </Placeholder>
        </Paper>
      </Hidden>
      <Hidden smUp implementation="css">
        {data.length > 0 &&
          data.map((v, k) => (
            <Paper
              key={k}
              onClick={() => history(`${v.accommodationId}`)}
              style={{ marginBottom: 15 }}
            >
              <div style={styles.flexTop}>
                <div style={{ fontWeight: 'bold' }}>{v.accommodationName}</div>
                <div>
                  {v.listingsCount}{' '}
                  <img src={ListingIcon} style={{ width: 15, marginLeft: 5 }} />
                </div>
              </div>
              <div style={styles.padBottom}>
                {v.street}, {v.district}, {v.city}
              </div>
            </Paper>
          ))}
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state) => ({
  propertyReducer: state.property,
});

export default withTranslation()(
  connect(mapStateToProps, null)(PropertiesList),
);
