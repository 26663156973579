export const THROW_ERROR = 'THROW_ERROR';

export const requestFailure = (errors, statusCode) => {
  return {
    type: THROW_ERROR,
    errors,
    statusCode,
    hasError: true,
  };
};
