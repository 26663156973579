import React, { useState, useEffect } from 'react';
import { tableStyles } from '../../../styles/styles';
import Placeholder from '../../../common/Placeholder';
import { Grid, Paper, Typography, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { format, differenceInDays, addDays } from 'date-fns';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Table,
  TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { yellow } from '@mui/material/colors';
import { toString } from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  getRedirectUrl,
  getAllParamsInUrl,
  objectToQueryString,
  deadlineCustom,
  comparePriority,
  compareChannel,
} from '../../../helpers';
import { useNavigate } from 'react-router-dom';

const PAGE_SEARCH_URL = 'page';

const getChangeImg = (name = 'own') => {
  try {
    let channel = name;
    name == 'own' || (name == 'guest' && (channel = 'own'));
    return require(`../../../styles/channel_icon/${channel}.png`);
  } catch (error) {
    return require(`../../../styles/channel_icon/own.png`);
  }
};

function getDiffDate(checkInDate) {
  return differenceInDays(
    addDays(checkInDate, 3),
    format(new Date(), 'yyyy-MM-dd'),
  );
}

export function getDeadline(checkInDate, trans) {
  const dateOfDeadline = getDiffDate(checkInDate);
  if (dateOfDeadline === 0) {
    return `${trans('is today')}`;
  }
  if (dateOfDeadline === -1) {
    return `${trans('was yesterday')}`;
  }
  if (dateOfDeadline === 1) {
    return `${trans('is tomorrow')}`;
  }
  if (dateOfDeadline < -1) {
    return `${trans('was')} ${Math.abs(dateOfDeadline)} ${trans('days ago')}`;
  }

  if (dateOfDeadline > 1) {
    return `${trans('is in')} ${dateOfDeadline} ${trans('days')}`;
  }
}

const TableRow = ({ row, ...restProps }) => {
  const history = useNavigate();
  return (
    <Table.Row
      {...restProps}
      onClick={() => history(`/reservations/${row.reservationId}`)}
      style={{
        cursor: 'pointer',
      }}
    />
  );
};

const defaultPageSize = 10;

const ReservationTable = ({
  reservations,
  classes,
  loading,
  showReadytosend,
  ...props
}) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const _get_current_page = getRedirectUrl(
    window.location.href,
    PAGE_SEARCH_URL,
  );

  useEffect(() => {
    _get_current_page && setCurrentPage(parseInt(_get_current_page));
  }, [_get_current_page]);

  const columns = [
    {
      name: 'channel',
      title: t('Channel'),
      getCellValue: (row) => (
        <div key={row.type}>
          <img
            src={getChangeImg(row.type)}
            style={{ width: 20 }}
            alt={row.type}
          />
        </div>
      ),
    },
    {
      name: 'accommodationName',
      title: t('Property'),
    },
    {
      name: 'listingName',
      title: t('Listing(s)'),
      getCellValue: (row) =>
        row.listings &&
        row.listings.length > 0 &&
        row.listings.map((v, k) => v.listingName).join(', '),
    },
    { name: 'bookedBy', title: t('Booked by') },
    {
      name: 'personsCount',
      title: t('Guests'),
    },
    {
      name: 'checkInOut',
      title: t('From - To'),
      getCellValue: (row) =>
        `${format(row.fromDate, 'dd/MM')} - ${format(row.toDate, 'dd/MM')}`,
    },
    {
      name: 'filledMissingForms',
      title: `${t('Filled')} / ${t('Missing')}`,
      getCellValue: (row) => (
        <div>
          <span className="no-display SORT">{toString(row.filledForms)}</span>
          <span className={`${classes.filledMissing} filled`}>
            {row.filledForms}
          </span>
          <span className={`${classes.filledMissing} missing`}>
            {row.missingForms}
          </span>
        </div>
      ),
    },
    {
      name: 'deadline',
      title: t('Deadline'),
      getCellValue: (row) => (
        <div>
          <span className="no-display SORT">{row.fromDate}</span>
          {deadlineCustom(row.fromDate, t)}
        </div>
      ),
    },
    ...(showReadytosend
      ? [
          {
            name: 'readyToSend',
            title: t('Ready to send'),
            getCellValue: (row) => (
              <div>
                <span className="no-display SORT">
                  {row.readyToSend ? '1' : '0'}
                </span>
                {row.readyToSend && (
                  <EmailIcon color={'secondary'} fontSize={'small'} />
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  const integratedSortingColumnExtensions = [
    { columnName: 'channel', compare: compareChannel },
    { columnName: 'deadline', compare: comparePriority },
    { columnName: 'filledMissingForms', compare: comparePriority },
    { columnName: 'readyToSend', compare: comparePriority },
  ];

  const onCurrentPageChange = (currentPage) => {
    let url_params = getAllParamsInUrl(window.location.href);
    url_params = { ...url_params, [PAGE_SEARCH_URL]: currentPage };
    let toUrl = objectToQueryString(url_params);
    history(`${window.location.pathname}?${toUrl}`);
  };

  return (
    <div>
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <Paper className={classes.root}>
          <Placeholder loading={loading} type={'table'}>
            <Grid rows={reservations} columns={columns}>
              <SortingState
                defaultSorting={[{ columnName: 'deadline', direction: 'asc' }]}
                columnExtensions={[
                  { columnName: 'channel', sortingEnabled: true },
                  { columnName: 'deadline', sortingEnabled: true },
                  { columnName: 'filledForms', sortingEnabled: true },
                  { columnName: 'missingForms', sortingEnabled: true },
                  { columnName: 'personsCount', sortingEnabled: true },
                  { columnName: 'readyToSend', sortingEnabled: true },
                  { columnName: 'listingName', sortingEnabled: false },
                  { columnName: 'accommodationName', sortingEnabled: false },
                  { columnName: 'bookedBy', sortingEnabled: false },
                  { columnName: 'checkInOut', sortingEnabled: false },
                ]}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />

              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={onCurrentPageChange}
                pageSize={pageSize}
                onPageSizeChange={(pageSize) => setPageSize(pageSize)}
              />

              <IntegratedPaging />

              <Table
                rowComponent={TableRow}
                messages={{ noData: t('No Data') }}
              />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={[5, 10, 15]}
                messages={{
                  rowsPerPage: `${t('Rows per page')}:`,
                  info: ({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count}`,
                }}
              />
            </Grid>
          </Placeholder>
        </Paper>
      </Hidden>

      {/* Mobile */}
      <Hidden smUp implementation="css" className={classes.smUp}>
        {reservations.length > 0 &&
          reservations.map((v, k) => (
            <Paper
              className={classes.root}
              key={k}
              onClick={() => history(`/reservations/${v.reservationId}`)}
            >
              <Grid container spacing={0} className={classes.mbMedium}>
                <Grid item xs={11}>
                  <Typography
                    variant="body2"
                    component="div"
                    title={v.bookedBy}
                    className={classes.title}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div style={{ marginRight: 15, display: 'flex' }}>
                      <img
                        src={getChangeImg()}
                        style={{ width: '100%' }}
                        alt=""
                      />
                    </div>
                    {v.bookedBy !== ' ' ? v.bookedBy : 'unknown'}
                  </Typography>
                </Grid>
                <Grid item xs={1} align="right">
                  {v.readyToSend && (
                    <EmailIcon color={'secondary'} fontSize={'small'} />
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={0} className={classes.mbSmall}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {v.accommodationName}
                    {v.listings && v.listings.length > 0 && ' - '}
                    {v.listings &&
                      v.listings.length > 0 &&
                      v.listings.map((v, k) => v.listingName).join(', ')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                justify="center"
                alignItems="center"
                className={classes.mbSmall}
              >
                <Grid item xs={10}>
                  <Typography variant="caption">
                    {format(v.fromDate, 'EEE, MMM dd')} -{' '}
                    {format(v.toDate, 'EEE, MMM dd')}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right" className={classes.displayEnd}>
                  <span style={{ marginRight: 5 }}>{v.personsCount}</span>
                  <PersonOutlined fontSize={'small'} />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                justify="center"
                alignItems="center"
                className={classes.mbSmall}
              >
                <Grid item xs={10}>
                  <Typography variant="caption" style={{ color: yellow[800] }}>
                    {t('Deadline')} {getDeadline(v.fromDate, t)}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right" className={classes.displayEnd}>
                  <span
                    className={`${classes.filledMissing} filled `}
                    style={{ margin: 0 }}
                  >
                    {' '}
                    {v.filledForms}{' '}
                  </span>
                  <span style={{ margin: '0 5px' }}>/</span>
                  <span
                    className={`${classes.filledMissing} missing`}
                    style={{ margin: 0 }}
                  >
                    {' '}
                    {v.missingForms}{' '}
                  </span>
                </Grid>
              </Grid>
            </Paper>
          ))}
      </Hidden>
    </div>
  );
};

export default withTranslation()(withStyles(tableStyles)(ReservationTable));
