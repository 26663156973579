import React from 'react';
import { Toolbar, IconButton, Typography, AppBar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { connect } from 'react-redux';
import LanguagePicker from '../../common/LanguagePicker';
import { withTranslation } from 'react-i18next';

const MyAppBar = ({ handleDrawerToggle, classes, account, globalTitle, t }) => {
  return (
    <AppBar className={classes.appBar} position="fixed" color="primary">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div>
          <Typography variant="h6" color="inherit" noWrap>
            {globalTitle && t(globalTitle.title)}
          </Typography>
          <Typography variant="caption" color="inherit" noWrap>
            {globalTitle && t(globalTitle.label)}
          </Typography>
        </div>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          {/* <div>ID: {account.clientId}</div> */}
          <div style={{ marginLeft: 15 }}>
            <LanguagePicker />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
  globalTitle: state.global.globalTitle,
});

export default connect(mapStateToProps, null)(withTranslation()(MyAppBar));
