import {
  URL_SIGN_UP_WITH_EMAIL,
  URL_EMAIL_VERIFY,
  URL_ADD_USER_INFO,
  URL_VERIFY_OTP,
  URL_ADD_AUTH_INFO,
  URL_BOOKING_VERIFY_SMS_CODE,
  URL_UPDATE_PROPERTY,
  URL_RESET_PASSWORD_EMAIL,
  URL_PROPERTIES_HOST_DUTIES,
  URL_UPDATE_USER_INFO,
  URL_COMPLETE_ONBOARDING,
  URL_GET_LISTING_FOR_MAPPING,
  URL_MAP_LISTINGS,
  URL_ONBOARDING_SEND_ALERTS_TO_UPCOMING_RESERVATION,
  URL_VERIFY_PASSWORD_OTP
} from '../shared/urls';
import { makeRequest } from '../redux/apiCall';
import {
  storeItem,
  BESTGUEST_STORE_NAME,
} from '../helpers/localStorage';


export const ONBOADING_NEXT_STEP = 'onboarding_next_step';


export const SIGN_UP_WITH_EMAIL = 'sign_up_with_email';

export const SIGN_UP_WITH_EMAIL_SUCCESS = 'sign_up_with_email_success';
export const SIGN_UP_WITH_EMAIL_FAILURE = 'sign_up_with_email_failure';

export const EMAIL_VERIFY = 'email_verify';
export const EMAIL_VERIFY_SUCCESS = 'email_verify_success';
export const EMAIL_VERIFY_FAILURE = 'email_verify_failure';

export const ADD_USER_INFO = 'add_user_info';
export const ADD_USER_INFO_SUCCESS = 'add_user_info_success';
export const ADD_USER_INFO_FAILURE = 'add_user_info_failure';

export const VERIFY_OTP = 'verify_otp';
export const VERIFY_PASSWORD_OTP = 'verify_password_otp';
export const VERIFY_OTP_SUCCESS = 'verify_otp_success';
export const VERIFY_OTP_FAILURE = 'verify_otp_failure';

export const ADD_AUTH_INFO = 'add_auth_info';
export const ADD_AUTH_INFO_SUCCESS = 'add_auth_info_success';
export const ADD_AUTH_INFO_FAILURE = 'add_auth_info_failure';

export const ONBOARDING_GET_PROPERTIES_HOST_DUTIES = 'onboarding_get_properties_host_duties';
export const ONBOARDING_GET_PROPERTIES_HOST_DUTIES_SUCCESS = 'onboarding_get_properties_host_duties_success';
export const ONBOARDING_GET_PROPERTIES_HOST_DUTIES_FAILURE = 'onboarding_get_properties_host_duties_failure';

export const GET_LISTING_FOR_MAPPING = 'get_listing_for_mapping';
export const GET_LISTING_FOR_MAPPING_SUCCESS = 'get_listing_for_mapping_success';
export const GET_LISTING_FOR_MAPPING_FAILURE = 'get_listing_for_mapping_failure';

export const BOOKING_VERIFY_SMS_CODE = 'booking_verify_sms_code';

export const ONBOARDING_UPDATE_PROPERTY = 'onboarding_update_property';

export const COMMUNICATION_WITH_AUTHORITIES = 'communication_with_authorities';

export const RESET_PASSWORD_EMAIL = 'reset_password_email';

export const ONBOARDING_UPDATE_USER_INFO = 'onboading_update_user_info';

export const ONBOARDING_SEND_ALERTS_TO_UPCOMING_RESERVATION = 'send_alerts_to_upcoming_reservation';

export const COMPLETE_ONBOARDING = 'complete_onboarding';

export const MAP_LISTINGS = 'map_listings';

export const nextStep = (step = 1) =>
  async (dispatch, getState) => {
    const state = getState();
    let newNes = state.account.detail;
    newNes.completedStep = newNes.completedStep + step;
    storeItem(BESTGUEST_STORE_NAME, newNes);
    dispatch({ type: ONBOADING_NEXT_STEP, res: newNes });
  };

export const previousStep = (step = 1) =>
  async (dispatch, getState) => {
    const state = getState();
    let newNes = state.account.detail;
    newNes.completedStep = newNes.completedStep - step;
    storeItem(BESTGUEST_STORE_NAME, newNes);
    dispatch({ type: ONBOADING_NEXT_STEP, res: newNes });
  };

export const getListingForMapping = params =>
  makeRequest(
    GET_LISTING_FOR_MAPPING,
    'get',
    URL_GET_LISTING_FOR_MAPPING,
    params,
    getListingForMapping
  );

export const mapListings = params =>
  makeRequest(
    MAP_LISTINGS,
    'post',
    URL_MAP_LISTINGS,
    params,
    mapListings
  );

export const signupWithEmail = params =>
  makeRequest(
    SIGN_UP_WITH_EMAIL,
    'post',
    URL_SIGN_UP_WITH_EMAIL,
    params,
    signupWithEmail
  );

  export const verifyPasswordOtp = params =>
  makeRequest(
    VERIFY_PASSWORD_OTP,
    'post',
    URL_VERIFY_PASSWORD_OTP,
    params,
    verifyPasswordOtp
  );

export const confirmEmail = params =>
  makeRequest(
    RESET_PASSWORD_EMAIL,
    'post',
    URL_RESET_PASSWORD_EMAIL,
    params,
    confirmEmail
  );

export const emailVerify = params =>
  makeRequest(
    EMAIL_VERIFY,
    'post',
    URL_EMAIL_VERIFY,
    params,
    emailVerify,
  );

export const addUserInfo = params =>
  makeRequest(
    ADD_USER_INFO,
    'post',
    URL_ADD_USER_INFO,
    params,
    addUserInfo,
  );

export const verifyOtp = params =>
  makeRequest(
    ADD_USER_INFO,
    'post',
    URL_VERIFY_OTP,
    params,
    verifyOtp,
  );

export const addAuthInfo = params =>
  makeRequest(
    ADD_AUTH_INFO,
    'post',
    URL_ADD_AUTH_INFO,
    params,
    addAuthInfo,
  );

export const verifySMSCode = params =>
  makeRequest(
    BOOKING_VERIFY_SMS_CODE,
    'post',
    URL_BOOKING_VERIFY_SMS_CODE,
    params,
    verifySMSCode,
  );

export const getAccommodationHostDuties = params =>
  makeRequest(
    ONBOARDING_GET_PROPERTIES_HOST_DUTIES,
    'get',
    URL_PROPERTIES_HOST_DUTIES,
    params,
    getAccommodationHostDuties,
  );

export const editAccommodation = params =>
  makeRequest(
    ONBOARDING_UPDATE_PROPERTY,
    'post',
    URL_UPDATE_PROPERTY,
    params,
    editAccommodation,
  );

export const communicationWithAuthorities = (params, url) =>
  makeRequest(
    COMMUNICATION_WITH_AUTHORITIES,
    'post',
    url,
    params,
    communicationWithAuthorities,
  );

export const updateUserInfo = (params, url) =>
  makeRequest(
    ONBOARDING_UPDATE_USER_INFO,
    'post',
    URL_UPDATE_USER_INFO,
    params,
    updateUserInfo,
  );

export const sendAlertsToUpcomingReservation = params =>
  makeRequest(
    ONBOARDING_SEND_ALERTS_TO_UPCOMING_RESERVATION,
    'post',
    URL_ONBOARDING_SEND_ALERTS_TO_UPCOMING_RESERVATION,
    params,
    sendAlertsToUpcomingReservation,
  );

export const completeOnboarding = params =>
  makeRequest(
    COMPLETE_ONBOARDING,
    'get',
    URL_COMPLETE_ONBOARDING,
    params,
    completeOnboarding,
  );
