import React from 'react';
import Routes from './routes';
import { ErrorBoundary } from './ErrorBoundary';
import { GlobalStyle } from './styles/reset-css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';

class App extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <div className="App">
          <GlobalStyle />
          <ToastContainer />
          <Routes />
        </div>
      </ErrorBoundary>
    );
  }
}

export default withTranslation()(App);
