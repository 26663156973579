import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  LOG_OUT,
  LOGIN,
  REFRESH_TOKEN,
  SIGN_UP,
  CONFIRM_EMAIL,
  RESET_PASSWORD,
  VERIFY_EMAIL,
} from './actionTypes';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import {
  login,
  loginSuccess,
  logoutSuccess,
  refreshTokenSuccess,
  signUpSuccess,
  confirmEmailSuccess,
  resetPasswordSuccess,
  verifyEmailSuccess,
} from './actions';
import {
  storeItem,
  removeItemFromStorage,
  getItemFromStorage,
  BESTGUEST_STORE_NAME,
} from '../../helpers/localStorage';
import { requestFailure } from '../../redux/handleError';
import { getJSONEpic } from '../../shared/requestEpic';
import { AccountUrls } from '../../shared/urls';
import { getDeviceInfo } from '../../helpers/deviceInfo';
import { toast } from 'react-toastify';
// import getErrorName from '../../shared/const/errorCode';
import { showToast } from '../../redux/apiCall';
import { isArray, isObject } from 'lodash';
import { getRedirectUrl, loginRedirect } from '../../helpers';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BEST_GUEST_API_BASE_URL || '';
const DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND';

const showErrorMsg = (msgArr, type = 'error') => {
  if (isArray(msgArr)) {
    msgArr.map((v) => {
      showToast(v.msg);
    });
  } else if (isObject(msgArr)) {
    showToast(msgArr.msg);
  }
};

const loginEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    mergeMap((action) => {
      const deviceToken = getItemFromStorage('deviceToken');
      const deviceInfo = getDeviceInfo();
      // const params = deviceToken ? { deviceToken } : deviceInfo;
      const params = deviceInfo;
      return ajax
        .post(
          `${BASE_URL}/user/signIn`,
          {
            data: JSON.stringify({
              ...params,
              loginName: action.payload.user_or_email,
              password: action.payload.password,
            }),
          },
          {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        )
        .pipe(
          map((res) => {
            let newNes = res.response.data;
            // newNes.isOnboarding = !newNes.clientInfoFilled;
            storeItem(BESTGUEST_STORE_NAME, newNes);
            if (!getItemFromStorage('deviceToken')) {
              storeItem('deviceToken', newNes.tokens.deviceToken);
            }
            const history = useNavigate();
            history('/dashboard');
            return loginSuccess(newNes);
          }),
          catchError((error) => {
            if (
              error &&
              error.response &&
              error.response.errors.length > 0 &&
              error.response.errors[0].msg === DEVICE_NOT_FOUND
            ) {
              removeItemFromStorage('deviceToken');
              let params = {
                user_or_email: action.payload.user_or_email,
                password: action.payload.password,
              };
              return of(login(params));
            } else {
              showErrorMsg(error.xhr.response.errors);
              return of(requestFailure(error.xhr.response, error.status));
            }
          }),
        );
    }),
  );

const logoutEpic = getJSONEpic(
  LOG_OUT,
  AccountUrls.LOG_OUT,
  map((res) => {
    removeItemFromStorage(BESTGUEST_STORE_NAME);
    logoutSuccess(res.data);
    window.location.href = loginRedirect(
      getRedirectUrl(window.location.href),
      window.location.search,
    );
    return;
  }),
);

const signUpEpic = (action$) =>
  action$.pipe(
    ofType(SIGN_UP),
    mergeMap((action) =>
      ajax
        .post(
          `${BASE_URL}/user/signUp`,
          {
            data: JSON.stringify({
              firstName: action.params.first_name,
              lastName: action.params.first_name,
              loginName: action.params.login_name,
              email: action.params.email,
              password: action.params.password,
              userType: '2',
            }),
          },
          {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        )
        .pipe(
          map((res) => {
            return signUpSuccess(res.response.data);
          }),
          catchError((error) => {
            showErrorMsg(error.xhr.response.errors);
            return of(requestFailure(error.xhr.response, error.status));
          }),
        ),
    ),
  );

// const updateProfileEpic = (action$, state$) =>
//   action$.pipe(
//     ofType(UPDATE_PROFILE),
//     withLatestFrom(state$),
//     mergeMap(([action, state]) => {
//       const token = state.account.detail.tokens.authToken;
//       return ajax
//         .post(
//           `${BASE_URL}/client/insertUbyportLoginData`,
//           {
//             data: JSON.stringify({
//               ubyportUsername: 'andrejko',
//               ubyportPassword: 'password',
//               ubyportIDUB: 'oafomfso',
//               accommodationsId: 2,
//               clientContact: 'andrej',
//               clientName: 'dezo',
//               clientNameShort: 'dez',
//             }),
//           },
//           {
//             Authorization: `${token}`,
//             'Content-Type': 'application/x-www-form-urlencoded',
//           }
//         )
//         .pipe(
//           map(res => {
//             return updateProfileSuccess(res.data);
//           }),
//           catchError(error => of(requestFailure(error.xhr.response)))
//         );
//     })
//   );

const confirmEmail = (action$) => {
  return action$.pipe(
    ofType(CONFIRM_EMAIL),
    mergeMap((action) => {
      return ajax
        .post(
          `${BASE_URL}/user/resetPasswordEmail`,
          {
            data: JSON.stringify({
              loginName: action.params.loginName,
            }),
          },
          {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        )
        .pipe(
          map((res) => {
            toast.success('Please check your mail to change password!');
            return confirmEmailSuccess(res.response.data);
          }),
          catchError((error) => {
            showErrorMsg(error.xhr.response.errors);
            return of(requestFailure(error.xhr.response, error.status));
          }),
          // catchError(error =>
          //   of(requestFailure(error.xhr.response, error.status))
          // )
        );
    }),
  );
};

const resetPassword = (action$) => {
  return action$.pipe(
    ofType(RESET_PASSWORD),
    mergeMap((action) => {
      return ajax
        .post(
          `${BASE_URL}/user/resetPassword`,
          {
            data: JSON.stringify({
              hash: action.params.hash,
              password: action.params.password,
            }),
          },
          {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        )
        .pipe(
          map((res) => {
            // toast.success('Update succeed!');
            return resetPasswordSuccess(res.response.result);
          }),
          catchError((error) => {
            showErrorMsg(error.xhr.response.errors);
            return of(requestFailure(error.xhr.response, error.status));
          }),
          // catchError(error =>
          //   of(requestFailure(error.xhr.response, error.status))
          // )
        );
    }),
  );
};

const refreshToken = (action$, state$, ...other) =>
  action$.pipe(
    ofType(REFRESH_TOKEN),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const masterToken = state.account.detail.tokens.masterToken;
      return ajax
        .post(`${BASE_URL}/user/refreshAuthToken`, null, {
          Authorization: `${masterToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        })
        .pipe(
          map((res) => {
            const newTokens = Object.assign(
              {},
              state.account.detail.tokens,
              res.response.data,
            );
            let _get_bestguest = getItemFromStorage(BESTGUEST_STORE_NAME);
            storeItem(
              BESTGUEST_STORE_NAME,
              Object.assign({}, _get_bestguest, { tokens: newTokens }),
              // Object.assign({}, state.account.detail, { tokens: newTokens })
            );
            // action && action.resumeAction && action.resumeAction(action.params);
            return refreshTokenSuccess(res.response.data);
          }),
          catchError((error) => {
            if (
              error.xhr.status === 401 &&
              isArray(error.xhr.response.errors)
            ) {
              error.xhr.response.errors.map((v) => {
                if (v.msg === 'MASTER_TOKEN_EXPIRED') {
                  showErrorMsg(error.xhr.response.errors);
                  removeItemFromStorage(BESTGUEST_STORE_NAME);
                  window.location.href = loginRedirect(
                    getRedirectUrl(window.location.href),
                    window.location.search,
                  );
                  return;
                }
              });
              return;
            } else {
              showErrorMsg(error.xhr.response.errors);
              return of(requestFailure(error.xhr.response, error.status));
            }
          }),
        );
    }),
  );

const verifyEmailEpic = (action$) =>
  action$.pipe(
    ofType(VERIFY_EMAIL),
    mergeMap((action) =>
      ajax.get(`${BASE_URL}/user/verifyEmail${action.params}`).pipe(
        map((res) => {
          return verifyEmailSuccess(res.response.data === 1);
        }),
        catchError((error) => {
          showErrorMsg(error.xhr.response.errors);
          return of(requestFailure(error.xhr.response, error.status));
        }),
        // catchError(error =>
        //   of(requestFailure(error.xhr.response, error.status))
        // )
      ),
    ),
  );

export default combineEpics(
  loginEpic,
  logoutEpic,
  signUpEpic,
  refreshToken,
  confirmEmail,
  resetPassword,
  verifyEmailEpic,
);
