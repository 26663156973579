import React from 'react';
import { Typography, Hidden } from '@mui/material';
import styled from 'styled-components';
import CusTooltip from '../components/CusTooltip';
import { useTranslation } from 'react-i18next';

const StyledWapper = styled.div`
  border-bottom: ${(props) => (!!props.borderWidth ? props.borderWidth : 1)}px
    solid #2196f3;
  padding-top: 4px;
  padding-bottom: ${(props) =>
    !!props.paddingBottom ? `${props.paddingBottom}px` : 0};
  margin-top: ${(props) => (!!props.marginTop ? `${props.marginTop}px` : 0)};
  margin-bottom: ${(props) =>
    !!props.marginBottom ? `${props.marginBottom}px` : 0};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const styles = {
  mr10: {
    marginRight: 10,
    marginLeft: 0,
  },
};

const TitleHasUnderLine = ({
  title,
  helpContent,
  size,
  marginTop,
  marginBottom,
  paddingBottom,
  borderWidth,
  color,
  align,
  isUport,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      paddingBottom={paddingBottom}
      borderWidth={borderWidth}
    >
      <Typography variant={size} align={align} color={color}>
        {helpContent && (
          <CusTooltip title={helpContent} iconStyle={styles.mr10} />
        )}
        {title}
      </Typography>
      <Hidden xsDown implementation="css">
        {isUport && (
          <a
            href="/uby-info"
            target="_blank"
            style={{ color: '#2196F3', marginLeft: 15, marginTop: 5 }}
          >
            {t('Where to find Ubyport information')}?
          </a>
        )}
      </Hidden>
    </StyledWapper>
  );
};

export default TitleHasUnderLine;
