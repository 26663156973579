import {
  LOG_OUT,
  LOGIN,
  LOGIN_SUCCESS,
  LOG_OUT_FAILURE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  LOG_OUT_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
} from './actionTypes';

export const login = (userData) => ({
  type: LOGIN,
  payload: userData,
});

export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  response,
});

export const loginFailure = (error) => ({
  type: LOG_OUT_FAILURE,
  error,
});

export const logout = () => ({
  type: LOG_OUT,
});

export const logoutSuccess = (res) => ({
  type: LOG_OUT_SUCCESS,
  res,
});

export const signUp = (params) => ({
  type: SIGN_UP,
  params,
});

export const signUpSuccess = (response) => ({
  type: SIGN_UP_SUCCESS,
  response,
});

export const signUpFailure = (error) => ({
  type: SIGN_UP_FAILURE,
  error,
});

export const updateProfile = (params) => ({
  type: UPDATE_PROFILE,
  params,
});

export const updateProfileSuccess = (res) => ({
  type: UPDATE_PROFILE_SUCCESS,
  res,
});

export const confirmEmail = (params) => ({
  type: CONFIRM_EMAIL,
  params,
});

export const resetPassword = (params) => ({
  type: RESET_PASSWORD,
  params,
});

export const resetPasswordSuccess = (response) => ({
  type: RESET_PASSWORD_SUCCESS,
  response,
});

export const confirmEmailSuccess = (res) => ({
  type: CONFIRM_EMAIL_SUCCESS,
  res,
});

export const refreshToken = () => ({
  type: REFRESH_TOKEN,
});

export const refreshTokenSuccess = (res) => ({
  type: REFRESH_TOKEN_SUCCESS,
  res,
});

export const verifyEmail = (params) => ({
  type: VERIFY_EMAIL,
  params,
});

export const verifyEmailSuccess = (response) => ({
  type: VERIFY_EMAIL_SUCCESS,
  response,
});
