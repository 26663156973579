import {
  URL_GET_NOTIFICATION_SETTING,
  URL_ADD_NOTIFY_CONTACT,
  URL_EDIT_NOTIFY_CONTACT,
  URL_UPDATE_NOTIFY_SETTING,
  URL_DELETE_NOTIFICATION_CONTACT,
} from '../shared/urls';
import { makeRequest } from '../redux/apiCall';

export const GET_NOTIFICATION_SETTING = 'get_notification_setting';
export const GET_NOTIFICATION_SETTING_SUCCESS = 'get_notification_setting_success';

export const ADD_NOTIFICATION_CONTACT = 'add_notification_contact';
export const ADD_NOTIFICATION_CONTACT_SUCCESS = 'add_notification_contact_success';

export const EDIT_NOTIFICATION_CONTACT = 'edit_notification_contact';
export const EDIT_NOTIFICATION_CONTACT_SUCCESS = 'edit_notification_contact_success';

export const UPDATE_NOTIFY_SETTING = 'update_notification_setting';
export const UPDATE_NOTIFY_SETTING_SUCCESS = 'update_notification_setting_success';

export const DELETE_NOTIFY_CONTACT = 'delete_notification_contact';
export const DELETE_NOTIFY_CONTACT_SUCCESS = 'delete_notification_contact_success';

export const getNotificationsSettings = params =>
  makeRequest(
    GET_NOTIFICATION_SETTING,
    'get',
    URL_GET_NOTIFICATION_SETTING,
    params,
    getNotificationsSettings,
  );

export const addNotificationsContacts = params => {
  return makeRequest(
    ADD_NOTIFICATION_CONTACT,
    'post',
    URL_ADD_NOTIFY_CONTACT,
    params,
    addNotificationsContacts,
  );
}

export const editNotificationsContacts = params => {
  return makeRequest(
    EDIT_NOTIFICATION_CONTACT,
    'post',
    URL_EDIT_NOTIFY_CONTACT,
    params,
    editNotificationsContacts,
  );
}

export const updateNotificationSettings = params => {
  return makeRequest(
    UPDATE_NOTIFY_SETTING,
    'post',
    URL_UPDATE_NOTIFY_SETTING,
    params,
    updateNotificationSettings,
  );
}

export const deleteNotificationsContacts = params => {
  return makeRequest(
    DELETE_NOTIFY_CONTACT,
    'post',
    URL_DELETE_NOTIFICATION_CONTACT,
    params,
    deleteNotificationsContacts,
  );
}
