import React, { useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormControl,
  Typography,
  Button,
} from '@mui/material';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import { GlobalDrawerContext } from '../contexts';
import { CLOSE_DRAWER } from '../custom-hooks/useGlobalDrawer';
import styled from 'styled-components';

import { media } from '../styles/commonStyles';

const Wrapper = styled.div`
  padding: 0 20px;
  width: 480px;
  ${media.phone`
        width: 100%;
    `};
`;

function formatListing(list, currentListingId) {
  if (isEmpty(list) && !currentListingId) return [];
  return list
    .filter(listing => listing.listingId !== parseInt(currentListingId))
    .map(lt => ({
      label: lt.listingName,
      value: lt.listingId,
    }));
}
const AddListingToReservation = memo(
  ({
    getListingsByPropertyId,
    listings,
    propertyId,
    currentListingId,
    addListings,
    onChange,
  }) => {
    const { dispatch } = useContext(GlobalDrawerContext);

    useEffect(() => {
      return () => {
        dispatch({ type: CLOSE_DRAWER, drawerName: 'add_listing' });
      };
    }, []);

    if (listings.loading) {
      return (
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      );
    }
    const formatedListings = formatListing(listings.data, currentListingId);
    return (
      <Wrapper>
        <Typography variant="h6" align="center">
          Add listing
        </Typography>
        <FormControl fullWidth>
          <Select
            defaultValue={[]}
            onChange={onChange}
            // isMulti
            name="colors"
            options={formatedListings}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </FormControl>
        <Button
          onClick={addListings}
          variant="contained"
          color="secondary"
          fullWidth
          style={{ marginTop: 20 }}
        >
          Add
        </Button>
      </Wrapper>
    );
  },
);

AddListingToReservation.propTypes = {
  listings: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
  }),
  getListingsByPropertyId: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  currentListingId: PropTypes.string.isRequired,
  addListings: PropTypes.func,
  onChange: PropTypes.func,
};

AddListingToReservation.defaultProps = {
  addListings: null,
  onChange: null,
};

export default AddListingToReservation;
