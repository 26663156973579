import { createSelector } from 'reselect';
import { find, isEmpty } from 'lodash';

const reservationsListSelector = state => state.reservation.reservationsList;
const reservationsDetailSelector = state => state.reservation.detail;
const checkinFormDetailSelector = state => state.reservation.checkinFormDetail;
const propertiesSelector = state => state.property.propertiesList;

export const selectReservationsList = createSelector(
  reservationsListSelector,
  ({ data, loading, error }) => data,
);

export const selectReservationsLoader = createSelector(
  reservationsListSelector,
  ({ data, loading, error }) => loading,
);

export const selectReservationDetail = createSelector(
  reservationsDetailSelector,
  detail => detail,
);

export const selectCheckinFormDetail = createSelector(
  checkinFormDetailSelector,
  checkinFormDetail => checkinFormDetail,
);

export const selectPropertyByReservation = createSelector(
  reservationsDetailSelector,
  propertiesSelector,
  (reservationDetail, properties) => {
    const data = find(
      properties.data,
      property =>
        property.accommodationId === reservationDetail.data.accommodationId,
    );
    return data;
  },
);

export const selectReservationDetailForms = createSelector(
  reservationsDetailSelector,
  detail => (isEmpty(detail.data.forms) ? [] : detail.data.forms),
);
