import createImmerReducer from '../../redux/immer-reducer';
import {
  ONBOARDING_GET_PROPERTIES_HOST_DUTIES,
  ONBOARDING_GET_PROPERTIES_HOST_DUTIES_SUCCESS,
} from '../../actions/onboading';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [ONBOARDING_GET_PROPERTIES_HOST_DUTIES]: state => {
    state.data = [];
    state.loading = true;
  },
  [ONBOARDING_GET_PROPERTIES_HOST_DUTIES_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
});
