import React from 'react';
import { Toolbar, Typography, AppBar, Button } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
  account: state.account.detail,
});

const toolbarHeight = 40;

const styles = {
  drawerContainer: {
    overflowY: 'auto',
    padding: '15px 0',
  },
  fixTopBackButton: {
    postion: 'fixed',
    display: 'block',
    left: 0,
    right: 0,
  },
  toolbarStyle: {
    minHeight: toolbarHeight,
    padding: 0,
  },
  appBarStyle: {
    padding: 0,
  },
};

export default withTranslation()(
  connect(
    mapStateToProps,
    null,
  )(({ onClick, ...props }) => (
    <AppBar position="fixed" color="inherit" style={styles.appBarStyle}>
      <Toolbar style={styles.toolbarStyle}>
        <Typography variant="h6" color="inherit">
          <Button onClick={onClick}>
            <BackIcon />
            {props.t('Back')}
          </Button>
        </Typography>
      </Toolbar>
    </AppBar>
  )),
);
