/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  TextField,
  IconButton,
  Grid,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { red, blue } from '@mui/material/colors';
import { INTEGRATIONS, ICALS } from './ListingForm';
import PlayButton from '../../../common/PlayButton';
import BookingFav from '../../../styles/icon/booking.png';
import AirBnbFav from '../../../styles/icon/airbnb.png';
import SpecialInput from '../../settings/components/SpecialInput';
import {
  airbnbGetReservations,
  airbnbGetListings,
} from '../../../actions/property';

const styles = (theme) => ({
  root: {
    marginBottom: 15,
  },
  imgTitle: {
    // height: 30,
    width: '100%',
    maxWidth: 150,
  },
  heading: {
    // display: 'flex',
    // fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    // flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: '80%',
    color: theme.palette.text.secondary,
  },
  ExpansionPanelSummary: {
    padding: '0 15px',
    '&:hover': {
      cursor: 'default !important',
    },
    '& button': {
      margin: 5,
    },
  },
  GridTitle: {
    paddingRight: '0 !important',
  },
  rightBtn: {
    width: 100,
    padding: '5px 10px',
  },
  middleDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center !important',
    },
  },
  Desynchromises: {
    color: red[400],
    borderColor: red[400],
  },
  centerPhone: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center !important',
    },
  },
});

const width = window.innerWidth;

const listListing = [
  {
    name: '/ListingName#1/',
  },
  {
    name: '/ListingName#2/',
  },
  {
    name: '/ListingName#3/',
  },
];

const isConnected = (set, link) => {
  return set === 1 && link !== '';
};

const loadingElement = () => {
  return <CircularProgress size={24} style={{ marginLeft: 15 }} />;
};

const AIRBNB_TYPE = 'airbnb';

class ConnectProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentExpanded: false,
      formValue: {
        [props.name]: {
          ...(props.type === ICALS
            ? {
                link: '',
              }
            : {
                username: '',
                password: '',
                connected: false,
              }),
        },
      },
    };
  }

  handleExpanded = (expanded) => {
    const {
      name,
      set,
      credentialsValid,
      airbnbGetReservations,
      airbnbGetListings,
    } = this.props;
    this.setState({ currentExpanded: expanded });
    if (name === AIRBNB_TYPE && set === 1 && credentialsValid) {
      airbnbGetReservations().then((res) => {
        res.result && res.data && res.data.length > 0 && airbnbGetListings();
      });
    }
  };

  expandIcon = () => {
    const { classes, set, link, t } = this.props;
    const connected = isConnected(set, link);

    return !this.state.currentExpanded ? (
      <Button
        variant={'outlined'}
        size="small"
        color={connected ? 'primary' : 'secondary'}
        className={classes.rightBtn}
        onClick={() => this.handleExpanded(true)}
      >
        {connected ? t('Detail') : t('Connect')}
      </Button>
    ) : (
      <IconButton
        onClick={() => this.handleExpanded(false)}
        style={{ padding: 2 }}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  onChangeForm = (e, key) => {
    const { name } = this.props;
    let { formValue } = this.state;
    formValue[name][key] = e.target.value;
    this.setState({ formValue });
  };

  loginForm = () => {
    const { name, loadingConnectForm, handleAddClientIntegration, t } =
      this.props;
    const { formValue } = this.state;
    return (
      <Grid container spacing={24} justify="center">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name={`username_${name}`}
            id={`username_${name}`}
            label={t('Username')}
            placeholder={t('Username')}
            onChange={(e) => this.onChangeForm(e, 'username')}
            value={formValue[name]['username']}
          />
          <TextField
            fullWidth
            type="password"
            name={`password_${name}`}
            id={`password_${name}`}
            label={t('Password')}
            placeholder={t('Password')}
            onChange={(e) => this.onChangeForm(e, 'password')}
            value={formValue[name]['password']}
          />
          <Button
            style={{
              marginTop: 15,
              color: '#fff',
              background: name === 'airbnb' ? red[400] : blue[400],
            }}
            variant={'contained'}
            color={'primary'}
            fullWidth
            disabled={
              loadingConnectForm ||
              !formValue[name]['username'] ||
              !formValue[name]['password']
            }
            onClick={() =>
              handleAddClientIntegration({
                type: name,
                username: formValue[name]['username'],
                password: formValue[name]['password'],
              })
            }
          >
            {t('Login')} {loadingConnectForm && loadingElement()}
          </Button>
        </Grid>
      </Grid>
    );
  };

  addCalsLink = () => {
    const { name, handleAddIcalLink, loadingConnectForm, t } = this.props;
    const { formValue } = this.state;
    return (
      <Grid container spacing={24} justify="center">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name={`link_${name}`}
            id={`link_${name}`}
            label={t('Add Link')}
            placeholder={t('Add Link')}
            onChange={(e) => this.onChangeForm(e, 'link')}
            value={formValue[name]['link']}
          />
          <Button
            style={{ marginTop: 15 }}
            variant={'contained'}
            color={'secondary'}
            fullWidth
            disabled={loadingConnectForm || !formValue[name]['link']}
            onClick={() =>
              handleAddIcalLink({ type: name, link: formValue[name]['link'] })
            }
          >
            {t('Connect')} {loadingConnectForm && loadingElement()}
          </Button>
        </Grid>
      </Grid>
    );
  };

  renderListing = () => {
    const { type, name, t, airbnbReservation } = this.props;
    const { formValue } = this.state;
    return (
      <Grid container spacing={24} justify="center">
        <Grid item xs={12} md={12}>
          {airbnbReservation.loading ? (
            <CircularProgress />
          ) : airbnbReservation.data && airbnbReservation.length > 0 ? (
            airbnbReservation.map((v, k) => (
              <div>
                <Typography
                  variant="h5"
                  style={{ borderBottom: '1px solid #ccc', fontWeight: 600 }}
                >
                  /{v.name}
                </Typography>
                {listListing.map((item, idx) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: width < 500 ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #ccc',
                      }}
                      key={`lst${idx}`}
                    >
                      <span>{item.name}</span> <span>{t(`is same as`)}</span>{' '}
                      <SpecialInput
                        favIcon={type === 'airbnb' ? AirBnbFav : BookingFav}
                      />
                    </Grid>
                  );
                })}
              </div>
            ))
          ) : (
            t('No reservation')
          )}
        </Grid>
        <Grid item xs={12} md={12} align="center">
          {airbnbReservation.data && airbnbReservation.length > 0 && (
            <Button
              type="button"
              style={{ marginTop: 15, color: '#fff', width: 100 }}
              variant={'contained'}
              color={'primary'}
              fullWidth
              onClick={() => {
                formValue[name].connected = true;
                this.setState({ formValue });
              }}
            >
              {t('Save')}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  contentConnected = () => {
    const { label, link, name, t } = this.props;
    const { formValue } = this.state;
    return (
      <div>
        {formValue[name].connected ? (
          <div align="center">
            <Typography variant="subtitle1">
              {t('Listing name on')} {label}
            </Typography>
            <Typography variant="h6">
              {t('Spacious apartment with an amazing sea view')}
            </Typography>
            {link && (
              <Button color="primary" href={link} target="_blank">
                {t('Preview')}
              </Button>
            )}
          </div>
        ) : (
          this.renderListing()
        )}
      </div>
    );
  };

  contentCalsConnected = () => {
    const {
      link,
      name,
      classes,
      handleDesynchromiseIcalLink,
      id,
      loadingConnectForm,
      t,
    } = this.props;
    return (
      <Grid container spacing={24} justify="center" alignItems="center">
        <Grid item xs={12} md={5}>
          <Button
            style={{ marginTop: 15 }}
            variant={'outlined'}
            color={'default'}
            className={classes.Desynchromises}
            disabled={loadingConnectForm}
            onClick={() => handleDesynchromiseIcalLink({ id: id, link: '' })}
          >
            {t('Desynchromise')} {loadingConnectForm && loadingElement()}
          </Button>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            fullWidth
            name={`link_${name}_default`}
            id={`link_${name}_default`}
            label="Link"
            placeholder="Link"
            value={link}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { connectType, img, classes, t, set, link } = this.props;
    const connected = isConnected(set, link);
    return (
      <Accordion
        square
        expanded={this.state.currentExpanded}
        className={classes.root}
      >
        <AccordionSummary className={classes.ExpansionPanelSummary}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            className={classes.GridTitle}
          >
            <Grid item xs={12} md={4} className={classes.centerPhone}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} md={8} className={classes.heading}>
                  <img src={img} className={classes.imgTitle} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.centerPhone}>
              <Typography
                variant="caption"
                className={classes.secondaryHeading}
                headlineMapping={{ caption: 'div' }}
              >
                {connected && (
                  <div className={classes.middleDiv}>
                    <PlayButton />
                    {t('Connected and being synchronised')}
                  </div>
                )}
                <div>
                  <Button
                    variant={'outlined'}
                    size="small"
                    color={'default'}
                    disabled
                  >
                    {t('Reservations')}
                  </Button>
                  {/* {connected && <Button variant={"outlined"} size="small" color={"default"} disabled >Messages</Button>} */}
                  {connectType === INTEGRATIONS && (
                    <Button
                      variant={'outlined'}
                      size="small"
                      color={'default'}
                      disabled
                    >
                      {t('Messages')}
                    </Button>
                  )}
                </div>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={classes.centerPhone}
              style={{ textAlign: 'right' }}
            >
              {this.expandIcon()}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              {connected
                ? connectType === ICALS
                  ? this.contentCalsConnected()
                  : this.contentConnected()
                : connectType === ICALS
                ? this.addCalsLink()
                : this.loginForm()}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => ({
  airbnbReservation: state.property.airbnbReservation,
});

const mapActionToProps = {
  airbnbGetReservations,
  airbnbGetListings,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(styles)(withTranslation()(ConnectProperty)));
