import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_WELCOMERS_SELECT,
  GET_WELCOMERS_SELECT_SUCCESS,
} from '../../actions/member';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_WELCOMERS_SELECT]: state => {
    state.loading = true;
  },
  [GET_WELCOMERS_SELECT_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
});
