import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
} from '@mui/material';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { withStyles } from '@mui/styles';
import { closeModal } from '../shared/actions';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit * 2,
  },
});

const Modal = ({ children, title, modalName, modal, classes, closeModal }) => {
  return (
    <Dialog
      disableBackdropClick
      maxWidth={'sm'}
      open={modal.open && modal.modalName === modalName}
      aria-labelledby="form-dialog-title"
    >
      <div className={classes.header}>
        <DialogTitle>{title}</DialogTitle>
        <Button className={classes.close} onClick={() => closeModal(modalName)}>
          <CloseIcon /> Close
        </Button>
      </div>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { closeModal })(
  withStyles(styles)(Modal),
);
