import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReservationList from '../components/ReservationList';
import { getReservations } from '../../../actions/reservation';
import {
  selectReservationsList,
  selectReservationsLoader,
} from '../../../selectors/reservation-selectors';

const FetchReservations = ({
  getReservations,
  params,
  reservationsList,
  loading,
  showReadytosend,
}) => {
  useEffect(() => {
    // console.log(params);
    getReservations(params);
  }, []);
  return reservationsList.length > 0 ? (
    <ReservationList
      reservations={reservationsList}
      loading={loading}
      showReadytosend={showReadytosend}
    />
  ) : null;
};

const mapStateToProps = (state) => ({
  reservationsList: selectReservationsList(state),
  loading: selectReservationsLoader(state),
});

export default connect(mapStateToProps, { getReservations })(FetchReservations);
