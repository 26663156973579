import {
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  GET_RESERVATION_DETAIL_SUCCESS,
  GET_RESERVATIONS,
  GET_RESERVATION_DETAIL,
  GET_RESERVATIONS_SUCCESS,
  CREATE_CHECK_IN_FORM,
  CREATE_CHECK_IN_FORM_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
} from './actionTypes';

export const createReservation = (params) => ({
  type: CREATE_RESERVATION,
  params,
});

export const createReservationSuccess = (res) => {
  return {
    type: CREATE_RESERVATION_SUCCESS,
    res,
  };
};

export const getReservations = (params) => ({
  type: GET_RESERVATIONS,
  params,
});

export const getReservationsSuccess = (res) => ({
  type: GET_RESERVATIONS_SUCCESS,
  res,
});

export const getReservationDetail = (id) => ({
  type: GET_RESERVATION_DETAIL,
  id,
});

export const getReservationDetailSuccess = (res) => ({
  type: GET_RESERVATION_DETAIL_SUCCESS,
  res,
});

export const createCheckInForm = (formData) => ({
  type: CREATE_CHECK_IN_FORM,
  params: formData,
});

export const createCheckInFormSuccess = (res) => ({
  type: CREATE_CHECK_IN_FORM_SUCCESS,
  res,
});

export const getCountries = () => ({
  type: GET_COUNTRIES,
});

export const getCountriesSuccess = (res) => ({
  type: GET_COUNTRIES_SUCCESS,
  res,
});
