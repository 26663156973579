/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import 'react-phone-number-input/style.css';
// import { TextField } from '@mui/material';
import { FormControl, CircularProgress, Grid, Dialog, DialogContent, DialogActions, Hidden, Button, Typography } from '@mui/material';
import { truncate } from '../helpers';
import { withTranslation } from 'react-i18next';
import { callApi } from '../custom-hooks/apiHook';
import { URL_GET_PASSPORT_DATA } from '../shared/urls';
import Webcam from 'react-webcam';
// import moment from 'moment-timezone';
import format from 'date-fns/format';
import CusTooltip from '../components/CusTooltip';

const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

class PassportScanUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMes: null,
      file: null,
      screenshotImg: null,
      openWebcam: false,
      passportData: {
        loading: false,
        data: null,
      }
    }
    this.changeFile = this.changeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.screenshot = this.screenshot.bind(this);
    this.sendPassportData = this.sendPassportData.bind(this);
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  screenshot() {
    let screenshot = this.refs.webcam.getScreenshot();
    this.setState({ screenshotImg: screenshot, file: null, openWebcam: false });

    if (screenshot) {
      let params = {
        headers: { 'Content-Type': 'multipart/form-data' },
        passport: screenshot,
      };

      this.sendPassportData(params);
    }
  }

  changeFile(e) {
    const { field, form: { setFieldValue }, t, ...props } = this.props;

    let _file = e.currentTarget.files[0];
    this.setState({ file: _file, screenshotImg: null });
    if (props.maxSize && _file.size > props.maxSize) {
      this.setState({ errorMes: `${t('Please choose maximum')} ${bytesToSize(props.maxSize)}` })
      return false;
    } else {
      this.setState({ errorMes: null });
      setFieldValue(field.name, _file);
    }

    let params = {
      headers: { 'Content-Type': 'multipart/form-data' },
      passport: e.currentTarget.files[0],
    };

    this.sendPassportData(params);
  }

  sendPassportData(params) {
    const { field, form: { setFieldValue }, t, ...props } = this.props;
    const self = this;
    if (props.prefillValue) {
      self.setState({ passportData: { data: null, loading: true }, errorMes: null });
      callApi('post', URL_GET_PASSPORT_DATA, params).then((res) => {
        if (res.data.result) {
          let _result = res.data.data;
          // _result.dateOfBirth && (_result.dateOfBirth = moment.utc(new Date(_result.dateOfBirth)));
          // _result.dateOfExpiry && (_result.dateOfExpiry = moment.utc(new Date(_result.dateOfExpiry)));
          _result.dateOfBirth && (_result.dateOfBirth = format(_result.dateOfBirth, 'yyyy-MM-dd'));
          _result.dateOfExpiry && (_result.dateOfExpiry = format(_result.dateOfExpiry, 'yyyy-MM-dd'));
          self.setState({ passportData: { data: _result, loading: false } });

          props.prefillValue && Object.keys(props.prefillValue).forEach(v => {
            setFieldValue(v, _result[props.prefillValue[v]]);
          })
        } else {
          self.setState({ passportData: { data: null, loading: false } });
        }
      }).catch(function (error) {
        if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          self.setState({
            errorMes: error.response.data.errors[0].msg,
            passportData: { data: null, loading: false },
          });
        }
      });
    }
  }

  replaceErrorMes(message) {
    const { t } = this.props;
    const new_message = message.split('.');
    let translate_text = '';
    new_message.length > 0 && new_message.map(v => {
      v && (translate_text += `${t(v)}.`);
    });
    return translate_text;
  }

  render() {
    const { field, form: { touched, errors }, t, ...props } = this.props;
    const { passportData, errorMes, file, openWebcam, screenshotImg } = this.state;
    return (
      <FormControl fullWidth margin={'dense'}>
        <input
          type="file"
          name={field.name}
          label={t(props.label)}
          placeholder={t(props.placeholder)}
          onChange={this.changeFile}
          ref="fileUploader"
          id="outlined-button-file"
          style={{ display: 'none' }}
          accept={props.accept || "*"}
        // error={touched[field.name] && !!errors[field.name]}
        // helperText={errors[field.name]}
        />

        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
              <div style={{ width: '100%' }}>
                <Button variant="outlined" color={props.color || 'default'} component="span" fullWidth disabled={passportData.loading} onClick={this.handleClick}>
                  {t(props.placeholder)}
                  {passportData.loading && file && <CircularProgress size={20} style={{ marginLeft: 15 }} />}
                </Button>
              </div>
              <CusTooltip title={`${t('You can scan only your passport we don not accept other documents')}.`} />
            </div>
            {file && file.name && <Typography variant="caption" gutterBottom color={'primary'}>{truncate(file.name, 20)}</Typography>}
            {props.maxSize && <Typography variant="caption" gutterBottom style={{ 'color': '#999' }}>* {t('Maximum size')} {bytesToSize(props.maxSize)}</Typography>}
            {props.note && <Typography variant="caption" gutterBottom style={{ 'color': '#999' }}>{t(props.note)}</Typography>}
            {errorMes && <Typography variant="caption" gutterBottom color='error'>{this.replaceErrorMes(errorMes)}</Typography>}
            {touched[field.name] && !!errors[field.name] && <Typography variant="caption" gutterBottom color='error'>{t(errors[field.name])}</Typography>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Hidden xsDown implementation="css">
              <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                  <Button fullWidth variant="outlined" color={props.color || 'default'} onClick={() => this.setState({ openWebcam: true })} disabled={passportData.loading}>
                    {t('Or Pick From Your Webcam')}
                    {passportData.loading && screenshotImg && <CircularProgress size={20} style={{ marginLeft: 15 }} />}
                  </Button>
                </div>
                <CusTooltip title={`${t('You can scan only your passport we don not accept other documents')}.`} />
              </div>
            </Hidden>
          </Grid>
        </Grid>

        <div>
          {props.showReview && file && <img src={URL.createObjectURL(file)} style={{ height: 300 }} />}
          {props.showReview && screenshotImg && <img src={screenshotImg} style={{ height: 300 }} />}
        </div>

        <Dialog
          open={openWebcam}
          aria-labelledby="responsive-dialog-title"
          maxWidth="md"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogContent>
            <Webcam audio={false} ref='webcam' screenshotFormat="image/png" />
          </DialogContent>
          <DialogActions>
            <Button type="button" color="primary" onClick={() => this.setState({ openWebcam: false })} >{t('Cancel')}</Button>
            <Button type="button" color="primary" onClick={this.screenshot}>{t('take a photo')}</Button>
          </DialogActions>
        </Dialog>

      </FormControl>

    );
  }
};

export default withTranslation()(PassportScanUpload);
