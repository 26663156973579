/* eslint-disable no-mixed-operators */

import React, { useState, useContext } from 'react';
import { Formik, Form, FastField, FieldArray } from 'formik';
import {
  createReservation,
  updateReservation,
  getReservations,
  getReservationDetail,
} from '../../../actions/reservation';
import { selectReservationDetail } from '../../../selectors/reservation-selectors';
import * as Yup from 'yup';
import styled from 'styled-components';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { pick, omit, isEmpty } from 'lodash';
import { URL_LISTINGS_GET_BY_PROPERTY_ID } from '../../../shared/urls';
import {
  formatListingsList,
  formatPropertiesList,
  generateReservationFormFields,
} from '../../../components/generateFields';
import GroupFields from '../../../components/GroupFields';
import {
  selectPropertyDetail,
  selectProperties,
} from '../../../selectors/property-selectors';
import {
  Grid,
  FormHelperText,
  CircularProgress,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import SelectMui from '../../../common/SelectMui';
import { callApi } from '../../../custom-hooks/apiHook';
import { GlobalDrawerContext } from '../../../contexts';
import { CLOSE_DRAWER } from '../../settings/actionTypes';
import { validateCheckInCheckOut } from '../../../helpers/validate';
import { media } from '../../../styles/commonStyles';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import { useTranslation } from 'react-i18next';
import { formatPhone } from '../../../helpers';

const BoxStyled = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const FormWrapper = styled.div`
  padding: 20px;
  ${media.largeDesktop`
    width: 480px;
  `}
  ${media.phone`
    width: 100%;
  `}
`;

const styles = {
  btnAdd: {
    margin: '15px 0',
  },
  listingStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 10,
  },
  btnDelete: {
    padding: 8,
  },
};

const validation = Yup.object().shape({
  accommodationId: Yup.string().required('Required'),
  listingIds: Yup.array().of(Yup.string().required('Required')),
  email: Yup.string().email('Invalid email').required('Required'),
  personsCount: Yup.number().min(1, 'Number of guest must be greater than 0'),
  fromDate: Yup.date().required('Required'),
  // phoneNumber: Yup.number().required('Required'),
  toDate: Yup.date().test(
    'match',
    'Check-out date must be greater than Check-in date',
    // 'Check-out date must be greater than Check-in date at least 1 day',
    function (toDate) {
      return validateCheckInCheckOut(this.parent.fromDate, toDate);
    },
  ),
});

const updatedValidation = Yup.object().shape({
  accommodationId: Yup.string().required('Required'),
  listingIds: Yup.array().of(Yup.string().required('Required')),
  personsCount: Yup.number().min(1, 'Number of guest must be greater than 0'),
  fromDate: Yup.date().required('Required'),
  toDate: Yup.date().test(
    'match',
    'Check-out date must be greater than Check-in date',
    // 'Check-out date must be greater than Check-in date at least 1 day',
    function (toDate) {
      return validateCheckInCheckOut(this.parent.fromDate, toDate);
    },
  ),
});

const defaultListingIds = [''];

const ReservationForm = ({
  property,
  createReservation,
  properties,
  toggleDrawer,
  currentReservation,
  updateReservation,
  getReservations,
  isUpdating,
  getReservationDetail,
  reservationDetail,
}) => {
  const [selectedProperty, setProperty] = useState('');
  const [loader, setLoadder] = useState(false);
  const [error] = useState('');
  const [actionStatus, setActionStatus] = useState(false);
  const { dispatch } = useContext(GlobalDrawerContext);

  const [listingsByPropertyId, setListings] = useState({
    data: [],
    listingsLoader: false,
  });

  const { t } = useTranslation();

  function handleSubmit(values, { setErrors }) {
    values = {
      ...omit(values, 'bookedBy'),
      ...(values.listingIds.length > 0 && {
        listingIds: values.listingIds.filter((n) => n !== ''),
      }),
    };
    let params = {
      accommodationId: values.accommodationId,
      toEmail: values.email,
      fromDate: format(values.fromDate, 'yyyy-MM-dd'),
      toDate: format(values.toDate, 'yyyy-MM-dd'),
      countryOfResidence: 'Slovakia',
      recipientLanguage: 'cz',
      personsCount: values.personsCount,
      listingIds: values.listingIds,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: formatPhone(values.phoneNumber),
    };
    params.listingIds.length === 0 && delete params['listingIds'];
    setLoadder(true);
    setActionStatus(false);
    if (isUpdating) {
      let updateParams = {
        ...values,
        fromDate: format(values.fromDate, 'yyyy-MM-dd'),
        toDate: format(values.toDate, 'yyyy-MM-dd'),
      };
      delete updateParams['toEmail'];
      delete updateParams['phoneNumber'];
      delete updateParams['firstName'];
      delete updateParams['lastName'];
      // updateParams.listingIds.length === 0 && delete updateParams['listingIds'];
      // updateParams = values.listings
      //   ? { ...updateParams, listingIds: values.listingIds }
      //   : updateParams;
      updateReservation(updateParams).then((res) => {
        setLoadder(false);
        if (res.data && res.result) {
          setActionStatus(true);
          setTimeout(() => {
            dispatch({ type: CLOSE_DRAWER });
            getReservationDetail({
              reservationId: reservationDetail.reservationId,
            });
          }, 1000);
        } else {
          res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    } else {
      createReservation(params).then((res) => {
        setLoadder(false);
        if (res.data && res.result) {
          setActionStatus(true);
          dispatch({ type: CLOSE_DRAWER });
          getReservations({ onlyUnclosed: 1 });
        } else {
          res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    }
  }

  const formFields = generateReservationFormFields(
    property,
    isUpdating,
    properties.data,
  );

  function fetchListing(accommodationId) {
    setProperty(accommodationId);
    setListings({ ...listingsByPropertyId, data: [], listingsLoader: true });
    callApi('get', URL_LISTINGS_GET_BY_PROPERTY_ID, {
      accommodationId: accommodationId,
    })
      .then((res) => {
        return setListings({
          ...listingsByPropertyId,
          data: res.data.data,
          listingsLoader: false,
        });
      })
      .catch((e) => {
        setListings({ ...listingsByPropertyId, listingsLoader: false });
      });
  }

  function renderListingSelectBox(values) {
    let listingOption = formatListingsList(property ? property.listings : []);
    if (isEmpty(property)) {
      if (isEmpty(listingsByPropertyId.data)) {
        return null;
      } else {
        listingOption = formatListingsList(listingsByPropertyId.data);
      }
    } else if (property && isEmpty(property.listings)) {
      return null;
    }
    listingOption = listingOption.map((v) =>
      Object.assign(
        { ...(values.listingIds.includes(v.value) && (v.disabled = true)) },
        v,
      ),
    );

    return (
      <Grid item xs={12} key={'133'}>
        <FieldArray
          name="listingIds"
          render={(arrayHelpers) => (
            <div>
              {values.listingIds.map((value, index) => (
                <div key={index} style={styles.listingStyle}>
                  <FastField
                    name={`listingIds.${index}`}
                    label={`Listing ${index + 1}`}
                    options={listingOption}
                    placeholder={`Select listing ${index + 1}`}
                    component={SelectMui}
                  />
                  {index > 0 && (
                    <IconButton
                      color="primary"
                      aria-label="Delete"
                      onClick={() => arrayHelpers.remove(index)}
                      style={styles.btnDelete}
                    >
                      <DeleteIcon fontSize="small" className="icon-left" />
                    </IconButton>
                  )}
                </div>
              ))}
              {listingOption.length > values.listingIds.length &&
                values.listingIds[values.listingIds.length - 1] !== '' && (
                  <Button
                    color="primary"
                    style={styles.btnAdd}
                    onClick={() => arrayHelpers.push('')}
                  >
                    <AddIcon className="icon-left" />
                    {t('Add listing')}
                  </Button>
                )}
            </div>
          )}
        />
      </Grid>
    );
  }

  const currentInitalValues = currentReservation
    ? pick(currentReservation, [
        'accommodationId',
        'fromDate',
        'toDate',
        'listingIds',
        'countryOfResidence',
        'personsCount',
        'reservationId',
        'bookedBy.email',
        'bookedBy.phoneNumber',
      ])
    : {
        listingIds: defaultListingIds,
        bookedBy: {
          phoneNumber: '',
        },
      };
  const initialValues = isUpdating
    ? {
        ...currentInitalValues,
        toEmail:
          currentInitalValues.bookedBy && currentInitalValues.bookedBy.email,
        phoneNumber:
          currentInitalValues.bookedBy &&
          currentInitalValues.bookedBy.phoneNumber,
        listingIds:
          (currentReservation &&
            currentReservation.listings &&
            currentReservation.listings.map((v) => v.listingId)) ||
          defaultListingIds,
      }
    : {
        accommodationId: property ? property.accommodationId : '',
        listingIds: defaultListingIds,
        email: '',
        platform: '',
        personsCount: 1,
        fromDate: new Date(),
        toDate: new Date(),
        firstName: '',
        lastName: '',
        phoneNumber: '',
      };
  // console.log('initialValues', initialValues);

  return (
    <FormWrapper>
      <TitleHasUnderLine
        title={isUpdating ? t('Update Reservation') : t('Create Reservation')}
        size="h6"
        color="primary"
        align="center"
        marginBottom={20}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={isUpdating ? updatedValidation : validation}
        onSubmit={handleSubmit}
        render={({ dirty, values }) => {
          if (
            values.accommodationId !== selectedProperty &&
            isEmpty(property)
          ) {
            dirty && (values.listingIds = defaultListingIds);
            fetchListing(values.accommodationId);
          }
          return (
            <Form>
              <Grid container spacing={24} alignItems={'center'}>
                <Grid item xs={12} key={'1'}>
                  {/* <Grid item xs={12} lg={6} sm={6} md={6} key={'1'}> */}
                  <FastField
                    name="accommodationId"
                    label={t('Property')}
                    disabled={!!property}
                    initialValue={!!property ? property.accommodationId : ''}
                    options={formatPropertiesList(properties.data)}
                    placeholder={t('Select an Accommodation')}
                    component={SelectMui}
                  />
                </Grid>
                {listingsByPropertyId.listingsLoader && (
                  <CircularProgress size={20} />
                )}
                {renderListingSelectBox(values)}
              </Grid>
              {formFields.map(({ fields, title, id }) => (
                <GroupFields key={id} fields={fields} title={title} lg={12} />
              ))}
              <BoxStyled>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || loader}
                >
                  {isUpdating ? t('Update') : t('Create')}
                  {loader && <CircularProgress size={20} />}
                </Button>
              </BoxStyled>
              {actionStatus && (
                <Typography color="secondary">{`${
                  isUpdating ? t('Updated') : t('Saved')
                } ${t('successfully')}!`}</Typography>
              )}
              {!!error && (
                <FormHelperText error={!!error}>{error}</FormHelperText>
              )}
            </Form>
          );
        }}
      />
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  reservationDetail: selectReservationDetail(state).data,
  accommodation: selectPropertyDetail(state),
  properties: selectProperties(state),
});

export default connect(mapStateToProps, {
  createReservation,
  updateReservation,
  getReservations,
  getReservationDetail,
})(ReservationForm);
