import React, { useState } from 'react';
import NavItem from '../../../shared/main-layout/NavItem';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PowerSettingsNew } from '@mui/icons-material';

const LogoutButton = (props) => {
  const [disabledItem, setDisabledItem] = useState(false);

  function logoutClick() {
    const clearLocalstorage = () => {
      localStorage.clear();
    };
    clearLocalstorage();
    window.location.reload(() => {
      props.history('/login');
    });
  }

  const { t } = useTranslation();
  return (
    <NavItem
      iconName={PowerSettingsNew}
      name={t(`Log out`)}
      onClick={logoutClick}
      disabled={disabledItem}
      loadding={disabledItem}
    />
  );
};

export default connect()(LogoutButton);
