import { combineReducers } from 'redux';
import memberList from './memberList';
import memberListSelect from './memberListSelect';
import memberListRoles from './memberListRoles';

export default combineReducers({
  memberList,
  memberListSelect,
  memberListRoles,
});
