export function getDeviceInfo() {
  const device_info = window.navigator;
  return {
    appVersion: device_info.appVersion.substr(0, 3),
    deviceModel: device_info.platform,
    deviceName: device_info.appCodeName,
    deviceType: 'Web',
    notifyToken: '',
    osVersion: device_info.cookieEnabled,
  };
}
