import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { loginStyles } from './style';
import { emailVerify } from '../../../actions/onboading';
import { withTranslation } from 'react-i18next';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import {
  storeItem,
  removeItemFromStorage,
  getItemFromStorage,
  BESTGUEST_STORE_NAME,
} from '../../../helpers/localStorage';
import { getDeviceInfo } from '../../../helpers/deviceInfo';
import { isObject } from 'lodash';

const url = new URL(window.location.href);
const hash = url.searchParams.get('hash') || '';

class EmailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerifyEmailSuccess: false,
      isRedirect: false,
    };
  }

  componentDidMount() {
    const deviceInfo = getDeviceInfo();
    const deviceToken = getItemFromStorage('deviceToken');
    let params = {
      hash: hash,
      ...(deviceToken ? { deviceToken: deviceToken } : { ...deviceInfo }),
    };
    removeItemFromStorage(BESTGUEST_STORE_NAME);
    hash
      ? this.props.emailVerify(params).then((res) => {
          console.log(res.data && isObject(res.data) && res.result);
          if (res.data && isObject(res.data) && res.result) {
            console.log('SAVE TOKENS');
            storeItem(BESTGUEST_STORE_NAME, res.data);
            this.setState({ isVerifyEmailSuccess: true });
          }
        })
      : this.props.history('/login');
  }

  renderContent() {
    const { account, t } = this.props;
    if (!!account.loading) {
      return <CircularProgress size={24} style={{ marginLeft: 15 }} />;
    }
    const { isVerifyEmailSuccess } = this.state;
    return (
      <div>
        <Typography
          variant={'h6'}
          color={isVerifyEmailSuccess ? 'secondary' : 'error'}
        >
          {isVerifyEmailSuccess
            ? t('Verification was successful!')
            : t('Error')}
        </Typography>
        <div align="center" style={{ marginTop: 30 }}>
          {isVerifyEmailSuccess && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.props.history('/onboarding')}
            >
              {t('Login')}
            </Button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className={classes.formLayout}
      >
        <Grid item xs={12} md={3}>
          <Paper className={classes.formWrapper}>
            <div className={classes.formGroup}>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid item xs={12} md={6}>
                  <img src={logo} className={classes.logo} alt="" />
                </Grid>
              </Grid>

              <div
                className={classes.formGroup}
                style={{ textAlign: 'center' }}
              >
                {this.renderContent()}
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(
  connect(mapStateToProps, { emailVerify })(
    withStyles(loginStyles)(EmailVerify),
  ),
);
