import { makeRequest } from '../redux/apiCall';
import {
    URL_GET_SUGGESTIONS,
    URL_GET_RUIAN_ADDRESS,
} from '../shared/urls';

export const SET_TITLE_GLOBAL = 'get_title_global';


export const GET_SUGGESTIONS = 'get_suggestions';
export const GET_SUGGESTIONS_SUCCESS = 'get_suggestions_success';
export const GET_SUGGESTIONS_FAILURE = 'get_suggestions_failure';

export const GET_RUIAN_ADDRESS = 'get_ruian_address';
export const GET_RUIAN_ADDRESS_SUCCESS = 'get_ruian_address_success';
export const GET_RUIAN_ADDRESS_FAILURE = 'get_ruian_address_failure';

export const setTitleGlobal = (params: ToggleParams) => ({
    type: SET_TITLE_GLOBAL,
    params,
});

export const getSuggestions = (params) =>
    makeRequest(
        GET_SUGGESTIONS,
        'post',
        URL_GET_SUGGESTIONS,
        params,
        getSuggestions,
    );

export const getRuianAddress = (params) =>
    makeRequest(
        GET_RUIAN_ADDRESS,
        'post',
        URL_GET_RUIAN_ADDRESS,
        params,
        getRuianAddress,
    );