import createReducer from '../../../redux/createReducer';
import { OPEN_DRAWER, CLOSE_DRAWER } from '../actionTypes';

const initialState = {
  residentClient: {
    clientContact: '',
    clientName: '',
    email: '',
    businessId: '',
    residentialClientId: '',
    contactName: '',
    phoneNumber: '',
    invoiceAddress: '',
    bankAccount: '',
    ubyUsername: '',
    ubyClientContact: '',
    ubyPassword: '',
    ubyIDUB: '',
    ubyClientName: '',
    ubyClientNameShort: '',
  },
  open: false,
  isUpdating: false,
};

export default createReducer(initialState, {
  [OPEN_DRAWER]: (state, action) => ({
    ...state,
    residentClient: action.params.residentClient,
    open: true,
    isUpdating: action.params.isUpdating,
  }),
  [CLOSE_DRAWER]: (state) => ({
    ...state,
    residentClient: initialState.residentClient,
    open: false,
    isUpdating: false,
  }),
});
