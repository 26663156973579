import React from 'react';
import ContentLoader from 'react-content-loader';

const LineLoader = ({ height, width, speed, round }) => (
  <div style={{ width: width || 200, height: '100%' }}>
    <ContentLoader
      height={height || 10}
      width={width || 200}
      speed={speed || 2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect
        x="0"
        y="0"
        rx={`${round || 5}`}
        ry={`${round || 5}`}
        width={width || 200}
        height={height || 10}
      />
    </ContentLoader>
  </div>
);

export default LineLoader;
