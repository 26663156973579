import CustomDatePicker from '../../../common/CustomDatePicker';
import FormInput from '../../../common/input';
import CustomSelect from '../../../common/CustomSelect';
import GoogleInput from '../../../common/GoogleInput';
import PassportScanUpload from '../../../common/PassportScanUpload';
import { getDate, setDate, setMonth } from 'date-fns';

export function formatCountriesList(list) {
  return list.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
}

export const getFormFields = (countriesList, useYear) => [
  [
    {
      name: 'checkIn',
      label: 'Check-in',
      format: 'MM-dd-yyyy',
      placeholder: 'MM-dd-yyyy',
      component: CustomDatePicker,
      minDate: getDate(new Date()) >= 7 ? setDate(new Date(), 1) : setDate(setMonth(new Date(), new Date().getMonth() - 1), 1),
      // maxDate: getDate(new Date()) >= 7 ? setDate(setMonth(new Date(), new Date().getMonth() + 1), 7) : setDate(new Date(), 7),
    },
    {
      name: 'checkOut',
      label: 'Check-out',
      format: 'MM-dd-yyyy',
      placeholder: 'MM-dd-yyyy',
      component: CustomDatePicker,
      minDate: getDate(new Date()) >= 7 ? setDate(new Date(), 1) : setDate(setMonth(new Date(), new Date().getMonth() - 1), 1),
      // maxDate: getDate(new Date()) >= 7 ? setDate(setMonth(new Date(), new Date().getMonth() + 1), 7) : setDate(new Date(), 7),
    },
  ],
  [
    {
      name: 'passport',
      label: 'Please scan your travel document',
      placeholder: 'Please scan your travel document',
      maxSize: 10000000,
      accept: 'image/*',
      component: PassportScanUpload,
      showReview: true,
      sm: 12,
      md: 12,
      color: 'primary',
      prefillValue: {
        firstName: 'firstName',
        lastName: 'lastName',
        birthDate: 'dateOfBirth',
        birthdate: 'dateOfBirth',
        identNumber: 'documentNumber',
        nationality: 'nationality',
      }
    },
    {
      name: 'firstName',
      label: 'First name',
      component: FormInput,
      showInfo: true,
      helpContent: `First name`,
    },
    {
      name: 'lastName',
      label: 'Last name',
      component: FormInput,
      showInfo: true,
      helpContent: `Last name`,
    },
    {
      name: 'birthDate',
      label: 'Birthday',
      format: 'MM-dd-yyyy',
      placeholder: 'MM-dd-yyyy',
      component: CustomDatePicker,
      showInfo: true,
      helpContent: `Birthday`,
      useYear: true,
      useYearValue: useYear,
      maxDate: new Date(),
    },
    // {
    //   name: 'nationality',
    //   label: 'Nationality',
    //   options: formatCountriesList(countriesList),
    //   placeholder: 'Select a nationality',
    //   component: CustomSelect,
    // },
    // {
    //   name: 'street',
    //   label: 'Street name',
    //   component: FormInput,
    // },
    // {
    //   name: 'houseNumber',
    //   label: 'House number',
    //   component: FormInput,
    // },
    // {
    //   name: 'city',
    //   label: 'City',
    //   component: FormInput,
    // },
    // {
    //   name: 'postalCode',
    //   label: 'Postal code',
    //   type: 'number',
    //   component: FormInput,
    // },
    {
      name: 'nationality',
      label: 'Country',
      options: countriesList,
      placeholder: 'Select a Country',
      component: CustomSelect,
      showInfo: true,
      helpContent: `Country`,
    },
    {
      type: 'text',
      name: 'identNumber',
      label: 'Travel document number',
      component: FormInput,
      showInfo: true,
      helpContent: `Travel document number`,
    },
    {
      name: 'visaNumber',
      type: 'number',
      label: 'Visa',
      component: FormInput,
      showInfo: true,
      helpContent: `Visa`,
    },
  ],
  [
    {
      name: 'address',
      label: 'Please pick your address',
      component: GoogleInput,
      useMerk: false,
      sm: 12,
      md: 12,
      showInfo: true,
      helpContent: `Please pick your address`,
    },
  ],
];
