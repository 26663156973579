import createReducer from '../../../redux/createReducer';
import { produce } from 'immer';
import {
  ADD_NEW_LISTING,
  ADD_NEW_LISTING_SUCCESS,
  GET_LISTING_BY_ACCOMMODATION,
  GET_LISTING_BY_ACCOMMODATION_SUCCESS,
} from '../actionTypes';

const initialState = {
  listings: [],
  loading: false,
};

export default createReducer(initialState, {
  [ADD_NEW_LISTING]: (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  [ADD_NEW_LISTING_SUCCESS]: (state, action) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.listings.push(action.res);
    }),
  [GET_LISTING_BY_ACCOMMODATION]: (state) =>
    produce(state, (draftState) => {
      draftState.loading = true;
    }),

  [GET_LISTING_BY_ACCOMMODATION_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.listings = action.res;
    }),
});
