// import { isEmpty } from 'lodash';
import FormInput from '../../../common/input';
import GoogleInput from '../../../common/GoogleInput';
import SelectMui from '../../../common/SelectMui';
import CustomDatePicker from '../../../common/CustomDatePicker';
import FieldCounter from '../../reservation/components/FieldCounter';
import PhoneInput from '../../../common/PhoneInput';
import MerkCompanyPicker from '../../../common/MerkCompanyPicker';
import { SPA_OR_RECREATION_STAY } from '../containers/HostDuties';

export function generateSignup() {
  const fiels = [
    {
      name: 'name',
      placeholder: 'First and Last name',
      label: 'First and Last name',
      component: FormInput,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email address',
      label: 'Email address',
      component: FormInput,
    },
    {
      name: 'phone',
      placeholder: 'Phone number',
      label: 'Phone number',
      component: PhoneInput,
    },
  ];
  return fiels;
}

export function generateLogin() {
  const fiels = [
    {
      name: 'firstName',
      placeholder: 'First Name',
      label: 'First Name',
      component: FormInput,
    },
    {
      name: 'lastName',
      placeholder: 'Last Name',
      label: 'Last Name',
      component: FormInput,
    },
    {
      name: 'phoneNumber',
      placeholder: 'Phone number',
      label: 'Phone number',
      component: PhoneInput,
    },
  ];
  return fiels;
}

export function generateLoginDetail() {
  const fiels = [
    // {
    //   name: 'userName',
    //   placeholder: 'Username',
    //   label: 'Username',
    //   component: FormInput,
    // },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      label: 'Password',
      component: FormInput,
    },
    {
      name: 'passwordAgain',
      type: 'password',
      placeholder: 'Password Again',
      label: 'Password Again',
      component: FormInput,
    },
  ];
  return fiels;
}

export function generateAirbnbLogin() {
  const fiels = [
    {
      name: 'username',
      // type: 'email',
      placeholder: 'User Name',
      label: 'User Name',
      component: FormInput,
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      label: 'Password',
      component: FormInput,
    },
  ];
  return fiels;
}

export function generateBookingLogin() {
  const fiels = [
    {
      name: 'username',
      type: 'text',
      placeholder: 'User Name',
      label: 'User Name',
      component: FormInput,
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      label: 'Password',
      component: FormInput,
    },
  ];
  return fiels;
}

export function generateVerify() {
  const fiels = [
    {
      name: 'otp',
      placeholder: 'Verification code',
      label: 'Verification code',
      component: FormInput,
    },
  ];
  return fiels;
}

export function generateAirbnbAccountFields() {
  const fiels = [
    {
      name: 'email',
      type: 'email',
      placeholder: 'Airbnb Email',
      label: 'Airbnb Email',
      component: FormInput,
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Airbnb Password',
      label: 'Airbnb Password',
      component: FormInput,
    },
  ];
  return fiels;
}

export function generateYourPropertyFields() {
  const fiels = [
    {
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      label: 'Name',
      component: FormInput,
    },
    {
      name: 'personsCount',
      type: 'number',
      component: FieldCounter,
    },
  ];
  return fiels;
}

export function generateHostDuties(rolesList) {
  const fiels = [
    {
      name: 'welcomerId',
      placeholder: 'Welcomer',
      label: 'Welcomer',
      options: rolesList,
      component: SelectMui,
      showTooltip: true,
      title: `Welcomer is person that communicates with your guests, it can be for example you,
      your co-host, receptionist or manager. The welcomer will appear in the invitation for
      your guests with message to kindly fill in the check-in forms`,
    },
    // {
    //   name: 'coverImage',
    //   placeholder: 'Add welcomer photo',
    //   maxSize: 154000,
    //   component: FileUpload,
    // },
    {
      name: 'numberOfBeds',
      type: 'number',
      placeholder: 'Maximum capacity',
      label: 'Maximum capacity',
      component: FormInput,
      showTooltip: true,
      title:
        'The maximum capacity of guests you can accommodate in this property in total.',
    },
    // {
    //   name: 'propertyAddress',
    //   placeholder: 'Property Address',
    //   label: 'Property Address',
    //   component: GoogleInput,
    // },
  ];
  return fiels;
}

export function generateHostDutiesYesButton(symbolType) {
  const fiels = [
    {
      name:
        symbolType === SPA_OR_RECREATION_STAY
          ? 'localFeeId'
          : 'accommodationFeeId',
      type: 'number',
      placeholder: 'Variable symbol',
      label: 'Variable symbol',
      component: FormInput,
      showTooltip: true,
    },
  ];
  return fiels;
}

export function generateHostDutiesLocalNoButton() {
  const fiels = [
    {
      name: 'rentingFromLocal',
      type: 'text',
      placeholder: 'Renting From',
      label: 'Renting From',
      component: CustomDatePicker,
      showTooltip: true,
      title: 'Since when do you rent your property?',
    },
    {
      name: 'localFeeAddress',
      type: 'text',
      placeholder: 'Correspondence address',
      label: 'Correspondence address',
      component: GoogleInput,
      showTooltip: true,
      useMerk: false,
      title:
        'To which address do you want to receive letters from the city office?',
    },
  ];
  return fiels;
}

export function generateHostDutiesCapacityNoButton() {
  const fiels = [
    {
      name: 'rentingFromCapacity',
      type: 'text',
      placeholder: 'Renting From',
      label: 'Renting From',
      component: CustomDatePicker,
      showTooltip: true,
      title: 'Since when do you rent your property?',
    },
    {
      name: 'capacityFeeAddress',
      type: 'text',
      placeholder: 'Correspondence address',
      label: 'Correspondence address',
      component: GoogleInput,
      showTooltip: true,
      useMerk: false,
      title:
        'To which address do you want to receive letters from the city office?',
    },
  ];
  return fiels;
}

export function generateHostDutiesNoReportButton() {
  const fiels = [
    // {
    //   name: 'propertyName',
    //   type: 'text',
    //   placeholder: 'Property name',
    //   label: "Property name",
    //   component: FormInput,
    //   showTooltip: true,
    //   title: 'The name of your property (for example Hotel Rose)'
    // },
    {
      name: 'databoxId',
      type: 'text',
      placeholder: 'Datová schránka',
      label: 'Datová schránka',
      component: FormInput,
      showTooltip: true,
      title: `It is like mailbox for official communication with city offices. 
      The difference between mail box and datová schránka is that you are authorised/verified person when you are communicating through datová schránka. 
      If you don't have datová schránka, please, get it at your local Czechpoint.`,
    },
  ];
  return fiels;
}

export function generateHostDutiesYesReportButtonWebSevice(all) {
  const fielsMore = [
    {
      name: 'ubyUsername',
      type: 'text',
      placeholder: 'Login name',
      label: 'Login name',
      component: FormInput,
    },
    {
      name: 'ubyPassword',
      type: 'password',
      placeholder: 'Password',
      label: 'Password',
      component: FormInput,
    },
  ];
  const fiels = [
    {
      name: 'ubyIDUB',
      type: 'text',
      placeholder: 'Ubyport ID',
      label: 'Ubyport ID',
      component: FormInput,
    },
    {
      name: 'ubyClientNameShort',
      type: 'text',
      placeholder: 'Ubyport Short Name',
      label: 'Ubyport Short Name',
      component: FormInput,
    },
  ];
  return all ? [...fielsMore, ...fiels] : fiels;
}

export function generateInvoiceDetailsFields(t) {
  const fiels = [
    {
      name: 'clientName',
      placeholder: 'First and Last name/ Company name',
      label: 'First and Last name/ Company name',
      component: MerkCompanyPicker,
    },
    {
      name: 'businessId',
      // type: 'number',
      placeholder: 'Business ID',
      label: 'Business ID',
      component: FormInput,
      //error:'1234',
      showTooltip: true,
      title: `${t(
        'A unique eight-digit identification number of a legal entity',
      )}, ${t('a business entity or an organisation of the state')}. ${t(
        'In Czech it is called',
      )} „IČO“.`,
      disabled: true,
    },
    {
      name: 'vatId',
      placeholder: 'VAT ID',
      label: 'VAT ID',
      component: FormInput,
      showTooltip: true,
      title: `${t('Value added tax identification number')} = ${t(
        'an unambiguous identification of the tax entity',
      )}. ${t(
        'Each tax entity has its tax identification number assigned to it by the tax administrator after the tax entity has registered',
      )}. ${t('In Czech it is called')} „DIČ“.`,
      disabled: true,
    },
    {
      name: 'residentialAddress',
      placeholder: 'Residential address',
      label: 'Residential address',
      component: FormInput,
      // component: GoogleInput,
      showTooltip: true,
      title: `${t(
        'Residential address is the official address that is registered to your Business ID',
      )}.`,
      disabled: true,
    },
  ];
  return fiels;
}

export function bankAccountfield() {
  const fields = [
    {
      name: 'bankAccount',
      placeholder: 'Bank account for paying local fees',
      label: 'Bank account for paying local fees',
      component: FormInput,
      showTooltip: true,
      // title: 'To generate the local fees reports for you we need to know your full bank account number that is registered to your registration to the local fees.'
    },
  ];

  return fields;
}

export function generateAutomationFields() {
  const fiels = [
    {
      name: 'contactName',
      placeholder: 'First and Last name',
      label: 'First and Last name',
      component: FormInput,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email Adsress',
      label: 'Email Adsress',
      component: FormInput,
    },
    {
      name: 'phoneNumber',
      placeholder: 'Phone Number',
      label: 'Phone Number',
      component: PhoneInput,
    },
  ];
  return fiels;
}
