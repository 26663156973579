import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { withTranslation } from 'react-i18next';
import {
  IconButton,
  TextField,
  Button,
  Radio,
  FormControlLabel,
} from '@mui/material';

const styles = {
  listRoom: {
    border: '1px solid #ccc',
    padding: 10,
    textAlign: 'center',
    position: 'absolute',
    with: 250,
    zIndex: 100,
    background: '#fff',
    marginRight: 20,
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  specialInput: {
    borderBottom: '1px solid #848c90',
    marginBottom: 5,
    width: 215,
    marginLeft: 10,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

class SpecialInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showListRoom: false,
      selectedValue: '',
      selectedRoom: '',
    };
  }

  handleChange = (event) => {
    this.setState({ selectedRoom: event.target.value });
  };

  selectValue = () => {
    this.setState({
      showListRoom: false,
      selectedValue: this.state.selectedRoom,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div style={styles.inputContainer}>
          <img src={this.props.favIcon} style={{ width: 20 }} />
          {/* <div contenteditable="true" style={styles.specialInput} onClick={() => this.setState({showListRoom: true})}>{this.state.selectedValue}</div> */}
          <TextField
            value={this.state.selectedValue}
            onClick={() => this.setState({ showListRoom: true })}
            style={styles.specialInput}
          />
        </div>
        {this.state.showListRoom && (
          <div style={styles.listRoom}>
            <IconButton
              style={styles.closeIcon}
              onClick={() => this.setState({ showListRoom: false })}
            >
              <CloseIcon />
            </IconButton>
            <FormControlLabel
              label="Room 1"
              style={{ width: '100%' }}
              control={
                <Radio
                  checked={this.state.selectedRoom === 'Room 1'}
                  onChange={this.handleChange}
                  value="Room 1"
                  name="bestguest-radio"
                />
              }
            />
            <FormControlLabel
              label="Room 2"
              style={{ width: '100%' }}
              control={
                <Radio
                  checked={this.state.selectedRoom === 'Room 2'}
                  onChange={this.handleChange}
                  value="Room 2"
                  name="bestguest-radio"
                />
              }
            />
            <FormControlLabel
              label="Room 3"
              style={{ width: '100%' }}
              control={
                <Radio
                  checked={this.state.selectedRoom === 'Room 3'}
                  onChange={this.handleChange}
                  value="Room 3"
                  name="bestguest-radio"
                />
              }
            />
            <Button
              variant={'outlined'}
              size="small"
              color={'secondary'}
              onClick={this.selectValue.bind(this)}
            >
              {t('Select')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(SpecialInput);
