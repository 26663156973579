import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Typography,
  Paper,
  Hidden,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import * as Yup from 'yup';
import { compose } from 'recompose';
import { loginStyles } from './style';
import FormInput from '../../../common/input/index';
import { blue } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { systemLogin } from '../../../actions/account';
import { login, loginSuccess } from '../actions';
import { withTranslation } from 'react-i18next';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import { withFirebase } from '../../../components/Firebase';
import { signupWithEmail, confirmEmail } from '../../../actions/onboading';

import {
  storeItem,
  getItemFromStorage,
  removeItemFromStorage,
  BESTGUEST_STORE_NAME,
} from '../../../helpers/localStorage';
import { getDeviceInfo } from '../../../helpers/deviceInfo';
import { getRedirectUrl } from '../../../helpers';
import { VALIDATION_ERROR } from '../../../redux/apiCall';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

const Invalid_Login = 'Invalid login details used';
const FORM_FIELDS = [
  {
    name: 'email',
    type: 'email',
    placeholder: 'Email Address',
    label: 'Email Address',
    component: FormInput,
    autoFocus: true,
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Password',
    label: 'Password',
    component: FormInput,
  },
];

const validation = (showPassword) =>
  Yup.object().shape({
    ...(showPassword
      ? {
          password: Yup.string().required('Required'),
        }
      : {
          email: Yup.string().email('Invalid email').required('Required'),
        }),
  });

const VERIFY_EMAIL = 'VERIFY_EMAIL';
const PASSWORD = 'PASSWORD';
const DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND';

const UserForm = ({
  t,
  confirmEmail,
  referral_token,
  signupWithEmail,
  loginSuccess,
  systemLogin,
  classes,
  account,
}) => {
  const location = useLocation();
  const history = useNavigate();

  const initialValues = {
    email: '',
    password: '',
  };
  const [showPassword, setShowPassword] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const onSubmit = (values, { setErrors }) => {
    const device_info = window.navigator.appCodeName;
    setErrorMsg(null);
    if (!showPassword) {
      let params = {
        email: values.email,
        userType: '2',
        referral_token,
        deviceName: device_info,
      };

      setLoading(true);
      signupWithEmail(params).then((res) => {
        setLoading(false);
        if (res.data && res.result) {
          res.data.next === VERIFY_EMAIL && setIsVerifyEmail(true);
          res.data.next === PASSWORD && setShowPassword(true);
        }
      });
    } else {
      const deviceToken = getItemFromStorage('deviceToken');

      let params = {
        loginName: values.email,
        password: values.password,
        ...(deviceToken ? { deviceToken } : getDeviceInfo()),
      };
      setLoading(true);
      handleLogin(params, setErrors);
    }
  };

  const handleLogin = (params, setErrors) => {
    systemLogin(params).then((res) => {
      if (res.data && res.result) {
        let newNes = res.data;
        storeItem(BESTGUEST_STORE_NAME, newNes);
        if (!getItemFromStorage('deviceToken')) {
          storeItem('deviceToken', newNes.tokens.deviceToken);
        }
        loginSuccess(newNes);
        history(getRedirectUrl(location && location.href));
      } else {
        if (res.errors.length > 0 && res.errors[0].msg === DEVICE_NOT_FOUND) {
          removeItemFromStorage('deviceToken');
          let newParams = {
            loginName: params.loginName,
            password: params.password,
            ...getDeviceInfo(),
          };
          handleLogin(newParams, setErrors);
        } else {
          if (
            res.errors.length > 0 &&
            res.errors[0].msg === VALIDATION_ERROR &&
            res.errors[0].note
          ) {
            res.errors[0].note === Invalid_Login
              ? setErrors({ password: res.errors[0].note })
              : setErrors(res.errors[0].note);
          }
        }
      }
      setLoading(false);
    });
  };

  const handleForgotPassword = (values) => {
    if (!values.email) return false;
    setLoadingForgotPassword(true);
    setErrorMsg(null);
    confirmEmail({ loginName: values.email }).then((res) => {
      setLoadingForgotPassword(false);
      if (res.data && res.result) {
        setIsForgotPassword(true);
      } else {
        if (
          res.errors.length > 0 &&
          res.errors[0].msg === VALIDATION_ERROR &&
          res.errors[0].note
        ) {
          setErrorMsg(res.errors[0].note);
        }
      }
    });
  };

  const handleBack = () => {
    setShowPassword(false);
    setIsVerifyEmail(false);
    setIsForgotPassword(false);
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validation(showPassword)}
        onSubmit={onSubmit}
        render={({ dirty, values }) => (
          <Form>
            <Grid
              container
              spacing={16}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: '30px 0' }}
            >
              <Grid item xs={12} md={8}>
                {!showPassword ? (
                  <Field {...FORM_FIELDS[0]} />
                ) : (
                  <Field {...FORM_FIELDS[1]} />
                )}
              </Grid>
            </Grid>

            <div className={classes.wrapper} align="center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.margin}
                disabled={!dirty || loading}
              >
                {!showPassword ? t('Next') : t('Log In')}
                {loading && (
                  <CircularProgress
                    size={20}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>

              {showPassword && (
                <div className={classes.link}>
                  <Button
                    type="button"
                    size="small"
                    onClick={() => handleForgotPassword(values)}
                    style={{ color: blue[500], textTransform: 'initial' }}
                    disabled={loadingForgotPassword}
                  >
                    {t('Forgot Password')}?
                    {loadingForgotPassword && (
                      <CircularProgress
                        size={20}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        )}
      />
    );
  };

  const renderVerifyEmail = () => {
    return (
      <Grid
        container
        spacing={16}
        direction="row"
        justify="center"
        alignItems="center"
        style={{ padding: '30px 0' }}
      >
        <Grid item md={8} xs={12}>
          <Typography variant="body1" align="center">
            {t('Please')}, &nbsp;
            {isVerifyEmail &&
              t(
                'check your email and verify your email address to activate account',
              )}
            {isForgotPassword &&
              t(
                'check your email to verify that you want to change the password',
              )}
            .
          </Typography>
          <div align="center" style={{ marginTop: 40 }}>
            <Button variant="outlined" color="secondary" onClick={handleBack}>
              {t('Back')}
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  };

  if (account.detail.tokens.authToken) {
    return <Navigate noThrow to={'/dashboard'} />;
  }

  return (
    <Box
      sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}
    >
      <Hidden xsDown>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          className={classes.formLayout}
        >
          <Grid item xs={0} md={4} />
          <Grid item xs={12} md={4}>
            <Paper className={classes.formWrapper}>
              <div className={classes.formGroup}>
                <Grid
                  container
                  spacing={24}
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={logo}
                      className={classes.logo}
                      style={window.innerWidth < 500 ? { width: '67%' } : {}}
                    />
                  </Grid>
                </Grid>
                {isVerifyEmail || isForgotPassword
                  ? renderVerifyEmail()
                  : renderForm()}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid
          container
          style={{ backgroundColor: 'white' }}
          spacing={0}
          justify="center"
          alignItems="center"
          className={classes.formLayout}
        >
          <Grid item xs={12} md={3}>
            <Paper
              style={{ boxShadow: 'none' }}
              className={classes.formWrapper}
            >
              <div className={classes.formGroup}>
                <Grid
                  container
                  spacing={24}
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={logo}
                      className={classes.logo}
                      style={window.innerWidth < 500 ? { width: '67%' } : {}}
                    />
                  </Grid>
                </Grid>
                {isVerifyEmail || isForgotPassword
                  ? renderVerifyEmail()
                  : renderForm()}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(
  connect(mapStateToProps, {
    login,
    signupWithEmail,
    confirmEmail,
    systemLogin,
    loginSuccess,
  })(withStyles(loginStyles)(compose(withFirebase)(UserForm))),
);
