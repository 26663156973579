import produce from 'immer';
import {
  GET_COUNTRIES_LIST,
  GET_COUNTRIES_LIST_SUCCESS,
  GET_COUNTRIES_LIST_FAILURE
} from '../actions/country';

export const initialState = {
  data: [],
  loading: false,
  error: ''
};

export const ENGLISH_NAME = 'name';
export const NATIVE_NAME = 'nativeName';
export const ALPHA_3_CODE = 'alpha3Code';
export const FLAG = 'flag';

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_COUNTRIES_LIST:
        draft.loading = true;
        return;
      case GET_COUNTRIES_LIST_SUCCESS:
        draft.loading = false;
        let _new_lists = action && action.res && action.res.map((v, k) => ({ 
          label: v[ENGLISH_NAME], 
          value: v[ALPHA_3_CODE], 
          [FLAG]: v[FLAG], 
          [ENGLISH_NAME]: v[ENGLISH_NAME],
          [NATIVE_NAME]: v[NATIVE_NAME],
        }));
        draft.data = _new_lists;
        return;
      case GET_COUNTRIES_LIST_FAILURE:
        draft.loading = false;
        draft.error = 'Server error';
        return;
      default:
        return state;
    }
  });
