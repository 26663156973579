import {
  URL_GET_MESSAGE,
  URL_SAVE_MESSAGE
} from '../shared/urls';
import { makeRequest } from '../redux/apiCall';

export const GET_MESSAGE_SETTING = 'get_message_setting';
export const SAVE_MESSAGE_SETTING = 'save_message_setting';
export const GET_MESSAGE_SETTING_SUCCESS = 'get_message_setting_success';
export const SAVE_MESSAGE_SETTING_SUCCESS = 'save_message_setting_success';


export const getMessage = params =>
  makeRequest(
    GET_MESSAGE_SETTING,
    'get',
    URL_GET_MESSAGE,
    params,
    getMessage,
  );

export const saveMessage = params => {
  return makeRequest(
    SAVE_MESSAGE_SETTING,
    'post',
    URL_SAVE_MESSAGE,
    params,
    saveMessage,
  );
}
