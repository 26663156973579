import React from 'react';
import PropTypes from 'prop-types';
import {Drawer, Hidden, CssBaseline} from '@mui/material';
import { withStyles } from '@mui/styles'
import MyAppBar from '../app-bar';
import CustomDrawer from '../CustomDrawer';
import { layoutStyles } from '../../styles/styles';
import DashboardRoutes from '../../routes/dashboard-routes';

export const currentRouteVar = '*';

class MainLayout extends React.Component {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleDrawerToggleLink = () => {
    this.setState({ mobileOpen: false });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <MyAppBar
          handleDrawerToggle={this.handleDrawerToggle}
          classes={classes}
        />
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <CustomDrawer
                // isUpdateInfo={this.props.account.detail.clientInfoFilled}
                isUpdateInfo={true}
                isMobile
                classes={classes}
                handleDrawerToggle={this.handleDrawerToggleLink}
              />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <CustomDrawer
                // isUpdateInfo={this.props.account.detail.clientInfoFilled}
                isUpdateInfo={true}
                isMobile
                classes={classes}
                handleDrawerToggle={this.handleDrawerToggle}
              />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <DashboardRoutes {...this.props}/>
        </main>
      </div>
    );
  }
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(layoutStyles, { withTheme: true })(MainLayout);
