import React, { Component } from 'react';
import { Paper, Typography } from '@mui/material';
import BackButton from '../../../common/BackButton';

class ListingDetail extends Component {
  render() {
    const accommodationName =
      this.props.location && this.props.location.state
        ? this.props.location.state.accommodationName
        : '';
    const listingName =
      this.props.location && this.props.location.state
        ? this.props.location.state.listingName
        : '';
    return (
      <div>
        <BackButton label={listingName} />
        <Paper>
          <div style={{ padding: 20 }}>
            <Typography align={'center'} variant={'h6'}>
              {accommodationName}
            </Typography>
            <Typography variant={'h3'} align={'center'}>
              {listingName}
            </Typography>
          </div>
        </Paper>
      </div>
    );
  }
}

export default ListingDetail;
