import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import {
  getReservationDetail,
  getListingsByPropertyId,
  updateReservation,
  sendReservationToPolice,
  addMasterGuest,
} from '../../../actions/reservation';
import { openModal } from '../../../shared/actions';
import {
  URL_DELETE_INVITATION,
  URL_DELETE_CHECK_IN_FORM,
} from '../../../shared/urls';
import { withStyles } from '@mui/styles';
import {
  Typography,
  Paper,
  Grid,
  Hidden,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Modal from '../../../common/Modal';
import DialogConfirm from '../../../common/DialogConfirm';
import DialogAlerts from '../../../common/DialogAlerts';
import CheckInForm from './CheckInForm';
import OverViewCheckInForm from './OverViewCheckInForm';
import modalName from '../../../shared/const/modalName';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import {
  deleteReservation,
  deleteInvitation,
} from '../../../actions/reservation';
import ReservationForm from './ReservationForm';
import { selectReservationDetailForms } from '../../../selectors/reservation-selectors';
import { GlobalDrawerContext } from '../../../contexts';
import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from '../../../custom-hooks/useGlobalDrawer';
import GlobalDrawer from '../../../components/GlobalDrawer';
import CusTooltip from '../../../components/CusTooltip';
import Guest from '../../../components/Guest';
import { selectProperties } from '../../../selectors/property-selectors';
import AddListingToReservation from '../../../components/AddListingToReservation';
import { selectListingsByPropertyId } from '../../../selectors/listing-selector';
import CheckInFormList from './CheckInFormList';
import { getDeadline } from '../components/ReservationList';
import { isEmpty, get } from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { ST_WAITING, ST_SEND_SUCCESS } from '../../../shared/const/checkinForm';
import fromToIcon from '../../../styles/reservation-detail/From_To.png';
import arrowThere from '../../../styles/reservation-detail/Arrow_there.png';
import arrowBack from '../../../styles/reservation-detail/Arrow_back.png';
import nights from '../../../styles/reservation-detail/Nights.png';
import PropertyIcon from '../../../styles/reservation-detail/Property.png';
import MsGuestIcon from '../../../styles/reservation-detail/Master_guest.png';
import PhoneIcon from '../../../styles/reservation-detail/Phone.png';
import MailIcon from '../../../styles/reservation-detail/Mail.png';
import AlertIcon from '../../../styles/reservation-detail/Alert.png';
import NoGuestIcon from '../../../styles/reservation-detail/Number_of_guests.png';
import ListingIcon from '../../../styles/reservation-detail/Listings.png';
import CheckinFormIcon from '../../../styles/reservation-detail/Check-in_forms.png';
import DateCreationIcon from '../../../styles/reservation-detail/Date_of_creation.png';
import {
  getTextDay,
  getMonth,
  getYear,
  getDifferentDay,
} from '../../../common/GetTextDay';
import BoxHasIcon from '../../../common/BoxHasIcon';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import { convertPhoneToInput } from '../../../helpers';
import { useNavigate } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

const currentStyles = {
  fontBold: {
    fontWeight: 'bold',
  },
  masterGuest: {
    display: 'flex',
    fontSize: '0.875rem',
    alignItems: 'center',
    marginTop: 5,
    wordBreak: 'break-all',
  },
  colorTitle: {
    color: '#b16c05',
  },
  colorMonth: {
    color: '#F8B133',
    fontWeight: 'bold',
  },
  marginSize: {
    marginTop: 7,
    fontSize: '0.875rem',
  },
};

// const RESERVATION_LINK =  'https://bestguestapptest.surge.sh/reservation?reservationHash=';

const convertInviToForms = (invitations, forms) => {
  let _invitations = invitations.map((v) => {
    let _form = forms.find((item) => v.dataId === item.dataId);
    return {
      ...v,
      ...(v && v.sendToId),
      dataId: v.dataId,
      invitationId: v.invitationId,
      firstName: v.sendToId.firstName,
      lastName: v.sendToId.lastName,
      phoneNumber: v.sendToId.phoneNumber,
      email: v.sendToId.email,
      userId: v.sendToId.userId,

      alerts: v.alerts || (_form && _form.notificationsCount) || 0,

      ..._form,
      ...(_form && _form.status && { status: _form.status }),
    };
  });
  // return _invitations;

  let _newData = [..._invitations, ...forms];

  _newData.map((v) => {
    let getDuplicate = _newData.findIndex(
      (d) => v !== d && d.dataId && d.dataId === v.dataId,
    );
    if (getDuplicate > -1) {
      delete _newData.splice(getDuplicate, 1);
    }
    if (v.invitationId) {
      v.formDataId = v.dataId;
      v.dataId = null;
    }
  });

  return _newData;
};

const ReservationDetail = ({
  getReservationDetail,
  reservation,
  id,
  openModal,
  listings,
  forms,
  getListingsByPropertyId,
  properties,
  updateReservation,
  sendReservationToPolice,
  deleteReservation,
  deleteInvitation,
  addMasterGuest,
}) => {
  const history = useNavigate();
  const { state, dispatch } = useContext(GlobalDrawerContext);
  const [selectedListing, setSelectedListing] = useState();
  let reservationDetail =
    reservation.data && reservation.data.reservationId == id
      ? reservation.data
      : {};
  const [openDeleteReservation, setOpenDeleteReservation] = useState(false); // open delete Reservation

  const [openSendToPoliceNotFully, setOpenSendToPoliceNotFully] =
    useState(false); // open alert not fully filled
  const [openSendToPoliceNotArrive, setOpenSendToPoliceNotArrive] =
    useState(false); // open alert not arrive

  const [openDeleteGuest, setOpenDeleteGuest] = useState({
    isOpen: false,
    deleteData: null,
    isLoadding: false,
  }); // open delete Guest
  const { t } = useTranslation();
  const [sentSuccessful, setSentSuccessful] = useState(false); // show after send to police confirmation
  const [loadingSendToPolice, setLoadingSendToPolice] = useState(false); // show after send to police confirmation
  const [confirmed, setConfirmed] = useState(false); // checkbox checked all data and confirm valid

  const [loadingMasterGuest, setLoadingMasterGuest] = useState(false);

  const invitations = get(reservationDetail, 'invitations', null);
  const guestList = invitations ? convertInviToForms(invitations, forms) : [];

  const checkInTableData = guestList;

  const allCheckInFormValid = reservationDetail.readyToSend;

  const hasClosed = reservationDetail.hasClosed;

  useEffect(() => {
    id && getReservationDetail({ reservationId: id });
  }, []);

  const hasMasterGuest =
    checkInTableData.length > 0 &&
    checkInTableData.find((v) => !!v.masterGuest);

  const handleAddMasterGuest = () => {
    setLoadingMasterGuest(true);
    reservationDetail.reservationHash &&
      addMasterGuest({
        reservationHash: reservationDetail.reservationHash,
      }).then((res) => {
        setLoadingMasterGuest(false);
        res.data &&
          res.result &&
          id &&
          getReservationDetail({ reservationId: id });
      });
  };

  function addListingsToReservation() {
    if (selectedListing) {
      let params = {
        reservationId: reservationDetail.reservationId,
        listingId: selectedListing.value,
      };
      try {
        updateReservation(params).then(() => {
          setTimeout(() => {
            dispatch({
              type: CLOSE_DRAWER,
              drawerName: 'add_listing',
            });
          }, 500);
        });
      } catch (e) {}
    }
  }

  function removeReservation() {
    try {
      deleteReservation({ reservationId: id }).then(() => {
        setTimeout(() => {
          setOpenDeleteReservation(false);
          history('/dashboard');
        }, 500);
      });
    } catch (e) {}
  }

  function checkSendToPolice(e) {
    // show alert if has error
    // !allCheckInFormValid && setOpenSendToPoliceNotFully(true);
    let _check_not_fully_filled_check_in_forms_popup =
      checkInTableData &&
      checkInTableData.filter((v) => v.status !== ST_WAITING);
    if (_check_not_fully_filled_check_in_forms_popup.length > 0) {
      setOpenSendToPoliceNotFully(true);
      return false;
      // } else if (reservationDetail.forms.length > 0 && reservationDetail.personsCount > reservationDetail.forms.length) {
    } else if (reservationDetail.personsCount > reservationDetail.filledForms) {
      setOpenSendToPoliceNotArrive(true);
      return false;
    } else {
      return sendToPolice(e);
    }
  }

  function sendToPolice(e) {
    setLoadingSendToPolice(true);
    setSentSuccessful(false);
    sendReservationToPolice({
      reservationId: Number(id),
      approveForms: 1,
    }).then((res) => {
      setOpenSendToPoliceNotArrive(false);
      setLoadingSendToPolice(false);
      if (res.data && res.result) {
        getReservationDetail({ reservationId: id });
        setSentSuccessful(true);
      }
    });
  }

  function deleteCheckInForm() {
    const data = openDeleteGuest.deleteData;
    if (data && (data.canDelete || data.invitationId)) {
      setOpenDeleteGuest({ ...openDeleteGuest, isLoadding: true });
      const url = data.dataId
        ? URL_DELETE_CHECK_IN_FORM
        : URL_DELETE_INVITATION;
      const params = data.dataId
        ? { checkinFormId: data.dataId }
        : { id: data.invitationId };
      (data.invitationId || data.dataId) &&
        deleteInvitation(url, params).then((res) => {
          if (res.data && res.result) {
            if (data.dataId) {
              deleteInvitation(URL_DELETE_INVITATION, {
                id: data.invitationId,
              }).then((res) => {
                if (res.data && res.result) {
                  setOpenDeleteGuest({
                    isOpen: false,
                    deleteData: null,
                    isLoadding: false,
                  });
                  id && getReservationDetail({ reservationId: id });
                }
              });
            } else {
              setOpenDeleteGuest({
                isOpen: false,
                deleteData: null,
                isLoadding: false,
              });
              id && getReservationDetail({ reservationId: id });
            }
          }
        });
    }
  }

  function renderTopContent() {
    const fromDate =
      reservationDetail.fromDate &&
      format(reservationDetail.fromDate, 'MMM dd, YYYY');
    const toDate =
      reservationDetail.toDate &&
      format(reservationDetail.toDate, 'MMM dd, YYYY');
    return (
      // <Paper style={{ padding: 20 }}>
      <div>
        <Grid container direction="row">
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ marginBottom: 20 }}>
            <div style={currentStyles.colorTitle}>
              {t('From')} - {t('To')}
            </div>
            <BoxHasIcon width={22} image={fromToIcon}>
              <div>
                <Typography variant="body2">
                  <img
                    src={arrowThere}
                    style={{ width: 16, marginRight: 8 }}
                    alt=""
                  />
                  {fromDate ? (
                    <>
                      {getTextDay(fromDate)},{' '}
                      <span style={currentStyles.colorMonth}>
                        {getMonth(fromDate)}
                      </span>
                      , {getYear(fromDate)}
                    </>
                  ) : (
                    '___'
                  )}
                </Typography>
                <Typography variant="body2">
                  <img
                    src={arrowBack}
                    style={{ width: 16, marginRight: 8 }}
                    alt=""
                  />
                  {toDate ? (
                    <>
                      {toDate && getTextDay(toDate)},{' '}
                      <span style={currentStyles.colorMonth}>
                        {toDate && getMonth(toDate)}
                      </span>
                      , {toDate && getYear(toDate)}
                    </>
                  ) : (
                    '___'
                  )}
                </Typography>
                <div style={{ position: 'relative', marginTop: 15 }}>
                  {getDifferentDay(fromDate, toDate)}
                  <img
                    src={nights}
                    alt=""
                    style={{
                      width: 13,
                      position: 'absolute',
                      marginTop: '5px',
                      marginLeft: '5px',
                    }}
                  />
                </div>
              </div>
            </BoxHasIcon>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ marginBottom: 20 }}>
            <div style={currentStyles.colorTitle}>{t('Property')}</div>
            <BoxHasIcon width={18} image={PropertyIcon}>
              <Typography variant="body2">
                {reservationDetail.accommodationName}
              </Typography>
            </BoxHasIcon>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            {' '}
            <div style={currentStyles.colorTitle}>{t('Master Guest')}</div>
            <BoxHasIcon width={14} image={MsGuestIcon}>
              <div>
                <div style={{ ...currentStyles.fontBold, display: 'flex' }}>
                  {reservationDetail.bookedBy &&
                    (reservationDetail.bookedBy.firstName ||
                      reservationDetail.bookedBy.lastName) &&
                    reservationDetail.bookedBy.firstName +
                      ' ' +
                      reservationDetail.bookedBy.lastName}
                  <CusTooltip
                    title={`${t(
                      'The name of the Master guest may be different because it changes accordingly to the information in the guest is account that is connected with the contact details',
                    )}.`}
                  />
                </div>
                {!hasClosed && (
                  <div style={currentStyles.masterGuest}>
                    <img
                      src={PhoneIcon}
                      style={{ width: 14, height: 13, marginRight: 10 }}
                      alt=""
                    />
                    {reservationDetail.bookedBy &&
                      convertPhoneToInput(
                        reservationDetail.bookedBy.phoneNumber,
                      )}
                  </div>
                )}
                {!hasClosed && (
                  <div style={currentStyles.masterGuest}>
                    <img
                      src={MailIcon}
                      style={{ width: 14, height: 10, marginRight: 10 }}
                      alt=""
                    />
                    {reservationDetail.bookedBy &&
                      reservationDetail.bookedBy.tempEmail}
                  </div>
                )}
                <div style={currentStyles.masterGuest}>
                  <img
                    src={AlertIcon}
                    style={{ width: 12, height: 13, marginRight: 10 }}
                    alt=""
                  />
                  {t('Alterted')} {reservationDetail.notificationsCount}{' '}
                  {t('times to fill in the form')}
                </div>
              </div>
            </BoxHasIcon>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginTop: 30 }}>
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ marginBottom: 20 }}>
            {' '}
            <div style={currentStyles.colorTitle}>{t('Number of guests')}</div>
            <BoxHasIcon width={20} image={NoGuestIcon}>
              <div style={currentStyles.marginSize}>
                {reservationDetail.personsCount}
              </div>
            </BoxHasIcon>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ marginBottom: 20 }}>
            {' '}
            <div style={currentStyles.colorTitle}>{t('Listing/s')}</div>
            <BoxHasIcon width={16} image={ListingIcon}>
              <div style={currentStyles.marginSize}>
                {reservationDetail.listings
                  ? reservationDetail.listings.map((v, k) => (
                      <p key={k}>- {v.listingName}</p>
                    ))
                  : ''}
              </div>
            </BoxHasIcon>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ marginBottom: 20 }}>
            {' '}
            <div style={currentStyles.colorTitle}>{t('Date of creation')}</div>
            <BoxHasIcon width={16} image={DateCreationIcon}>
              <div style={currentStyles.marginSize}>
                {t('Received on')} {getTextDay(reservationDetail.createdUtc)},{' '}
                {getMonth(format(reservationDetail.createdUtc, 'MMM dd, YYYY'))}
                ,{getYear(format(reservationDetail.createdUtc, 'MMM dd, YYYY'))}
              </div>
            </BoxHasIcon>
          </Grid>
        </Grid>
      </div>
      // </Paper>
    );
  }

  function renderBottomContent() {
    const isClosed =
      reservationDetail && reservationDetail.status === ST_SEND_SUCCESS;
    return (
      <div>
        {!isEmpty(checkInTableData) && (
          <CheckInFormList
            forms={checkInTableData}
            hasClosed={hasClosed}
            openToEditInfo={(data) =>
              dispatch({
                type: OPEN_DRAWER,
                drawerName: 'update_guest',
                data,
              })
            }
            openToEditCheckInForm={(data) =>
              dispatch({
                type: OPEN_DRAWER,
                drawerName: 'update_check_in_form',
                data,
              })
            }
            setOpenDeleteGuest={(data) =>
              setOpenDeleteGuest({
                ...openDeleteGuest,
                isOpen: true,
                deleteData: data,
              })
            }
            reservationDetail={reservationDetail}
          />
        )}
        {/* { allCheckInFormValid && ( */}
        {/* // TODO hide form after send successful */}
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={6}
            md={12}
            lg={12}
            style={{ textAlign: 'center', marginTop: 25 }}
          >
            <Typography>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  marginBottom: 15,
                }}
                // disabled={!allCheckInFormValid}
                disabled={isEmpty(checkInTableData)}
                onClick={() =>
                  dispatch({
                    type: OPEN_DRAWER,
                    drawerName: 'overview_check_in_form',
                  })
                }
              >
                {t('Review the check-in forms')}
              </Button>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checked={confirmed}
                  // disabled={!allCheckInFormValid}
                  onChange={(event) => setConfirmed(event.target.checked)}
                  color="primary"
                  disabled={isEmpty(checkInTableData) || isClosed || hasClosed}
                />
              }
              label={t(
                "I've checked all the data and can confirm they are valid",
              )}
            />
            {/* disabled={!confirmed || !allCheckInFormValid} */}
            <div
              style={{
                display: 'flex',
                placeContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Button
                color="primary"
                // disabled={!confirmed || !allCheckInFormValid}
                variant="contained"
                size="small"
                onClick={checkSendToPolice}
                disabled={
                  isEmpty(checkInTableData) ||
                  !confirmed ||
                  loadingSendToPolice ||
                  isClosed ||
                  hasClosed
                }
                // disabled={isEmpty(checkInTableData) || !confirmed || (allCheckInFormValid && loadingSendToPolice) || isClosed}
              >
                {isClosed
                  ? t('This reservation was already closed')
                  : t('Close reservation')}
                {loadingSendToPolice && (
                  <CircularProgress size={20} style={{ marginLeft: 15 }} />
                )}
                {/* {allCheckInFormValid || loadingSendToPolice && <CircularProgress size={20} style={{ marginLeft: 15 }} />} */}
              </Button>
              <CusTooltip
                title={`${t(
                  'The check-in forms will be sent to the Foreign police only if the guest is not from Czechia',
                )}. ${t('In any case')}, ${t(
                  'the reservation will be closed for local fees purposes',
                )}.`}
              />
            </div>
            {sentSuccessful && (
              <Typography
                variant={'subtitle1'}
                color={'secondary'}
                align="center"
              >
                {t('Sent successfully')}!
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* )} */}
      </div>
      // </Paper>
    );
  }

  const userName =
    reservationDetail && reservationDetail.bookedBy
      ? !reservationDetail.bookedBy.firstName &&
        !reservationDetail.bookedBy.lastName
        ? 'Unknown'
        : reservationDetail.bookedBy.firstName +
          ' ' +
          reservationDetail.bookedBy.lastName
      : '____';
  let iconText =
    reservationDetail && reservationDetail.bookedBy
      ? reservationDetail && reservationDetail.bookedBy.firstName
        ? reservationDetail.bookedBy.firstName.substring(0, 1)
        : reservationDetail.bookedBy.lastName
        ? reservationDetail.bookedBy.lastName.substring(0, 1)
        : 'U'
      : '';
  iconText = iconText.toUpperCase();

  return (
    <div>
      {/* <BackButton /> */}
      <Hidden xsDown implementation="css">
        <Paper style={{ padding: 20 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TitleHasIcon
              variant="h5"
              title={userName}
              label={`${t('Reservation ID')}: ${
                reservationDetail.reservationId || '_'
              }`}
              isImage={false}
              isText={true}
              icon={iconText}
            />
            <div style={{ display: 'flex' }}>
              <ButtonWithTooltip
                show={hasClosed}
                title={t(
                  `You can't edit this reservation because it was already closed`,
                )}
              >
                <Button
                  color="primary"
                  onClick={() =>
                    dispatch({
                      type: OPEN_DRAWER,
                      drawerName: 'update_reservation',
                    })
                  }
                  disabled={hasClosed}
                >
                  <EditIcon className="icon-left font-20" />
                  <span className="text">{t('Edit')}</span>
                </Button>
              </ButtonWithTooltip>
              <ButtonWithTooltip
                show={hasClosed}
                title={t(
                  `You can't delete this reservation because it was already closed`,
                )}
              >
                <Button
                  color="primary"
                  onClick={() => setOpenDeleteReservation(true)}
                  disabled={hasClosed}
                >
                  <DeleteIcon className="icon-left font-20" />
                  <span className="text">{t('Delete')}</span>
                </Button>
              </ButtonWithTooltip>
            </div>
          </div>
          {renderTopContent()}
        </Paper>
        <Paper style={{ padding: 20, marginTop: 25 }}>
          <Grid container spacing={0} alignItems="center">
            <Grid item md={7}>
              <TitleHasIcon
                variant="h5"
                title={t('Check-in forms')}
                label={`Deadline ${getDeadline(reservationDetail.fromDate)}`}
                isImage={true}
                icon={CheckinFormIcon}
                disabled={hasClosed}
                disabledTitle={`${t(
                  'You can not add a new guest because the reservation was already closed',
                )}.`}
              />
            </Grid>
            <Grid item md={5} align="right">
              {!hasMasterGuest && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 15 }}
                  onClick={handleAddMasterGuest}
                  disabled={hasClosed || loadingMasterGuest}
                >
                  {t('Add Master guest')}
                  {loadingMasterGuest && (
                    <CircularProgress
                      size={20}
                      color="primary"
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  dispatch({ type: OPEN_DRAWER, drawerName: 'guest' })
                }
                disabled={hasClosed}
              >
                {t('Add guest')}
              </Button>
            </Grid>
          </Grid>
          {/* <TitleHasIcon variant="h3" title={t('Check-in forms')}
            label={`Deadline ${getDeadline(reservationDetail.fromDate)}`} isImage={true} icon={CheckinFormIcon}
            actionButton={t('Add guest')}
            handleAction={() => dispatch({ type: OPEN_DRAWER, drawerName: 'guest' })}
            disabled={hasClosed}
            disabledTitle={`${t('You can not add a new guest because the reservation was already closed')}.`}
          /> */}
          {renderBottomContent()}
        </Paper>
      </Hidden>

      {/* Mobile */}
      <Hidden smUp implementation="css">
        <TitleHasIcon
          variant="h5"
          title={userName}
          label={`Reservation ID: ${reservationDetail.reservationId || '_'}`}
          isImage={false}
          isText={true}
          icon={iconText}
        />
        <Paper style={{ padding: 20, width: '100%' }}>
          <div style={{ display: 'flex', marginBottom: 20, marginLeft: -15 }}>
            <ButtonWithTooltip
              show={hasClosed}
              title={t(
                `You can't edit this reservation because it was already closed`,
              )}
            >
              <Button
                color="primary"
                onClick={() =>
                  dispatch({
                    type: OPEN_DRAWER,
                    drawerName: 'update_reservation',
                  })
                }
                disabled={hasClosed}
              >
                <EditIcon className="icon-left font-20" />
                <span className="text">{t('Edit')}</span>
              </Button>
            </ButtonWithTooltip>
            <ButtonWithTooltip
              show={hasClosed}
              title={t(
                `You can't delete this reservation because it was already closed`,
              )}
            >
              <Button
                color="primary"
                onClick={() => setOpenDeleteReservation(true)}
                disabled={hasClosed}
              >
                <DeleteIcon className="icon-left font-20" />
                <span className="text">{t('Delete')}</span>
              </Button>
            </ButtonWithTooltip>
          </div>
          {renderTopContent()}
        </Paper>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={8}>
            <TitleHasIcon
              variant="h6"
              title={t('Check-in forms')}
              label={`Deadline ${getDeadline(reservationDetail.fromDate)}`}
              isImage={true}
              icon={CheckinFormIcon}
              disabled={hasClosed}
              disabledTitle={`${t(
                'You can not add a new guest because the reservation was already closed',
              )}.`}
            />
          </Grid>
          <Grid item xs={4} align="right">
            {!hasMasterGuest && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddMasterGuest}
                disabled={loadingMasterGuest}
              >
                {t('Add Master guest')}
                {loadingMasterGuest && (
                  <CircularProgress
                    size={20}
                    color="primary"
                    style={{ marginLeft: 10 }}
                  />
                )}
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                dispatch({ type: OPEN_DRAWER, drawerName: 'guest' })
              }
              disabled={reservationDetail.hasClosed}
            >
              {t('Add guest')}
            </Button>
          </Grid>
        </Grid>

        {/* <TitleHasIcon variant="h3" title={t('Check-in forms')}
          label={`Deadline ${getDeadline(reservationDetail.fromDate)}`} isImage={true} icon={CheckinFormIcon}
          actionButton={t('Add guest')}
          handleAction={() => dispatch({ type: OPEN_DRAWER, drawerName: 'guest' })}
          disabled={hasClosed}
          disabledTitle={`${t('You can not add a new guest because the reservation was already closed')}.`}
        /> */}
        {renderBottomContent()}
      </Hidden>

      <GlobalDrawer
        isOpen={state.isOpen}
        hideBackButton={state.drawerName === 'update_check_in_form'}
      >
        {state.drawerName === 'update_reservation' && (
          <ReservationForm
            isUpdating
            currentReservation={{
              ...reservationDetail,
              ...reservationDetail.bookedBy,
            }}
          />
        )}
        {state.drawerName === 'guest' && (
          <Guest
            reservationHash={reservationDetail.reservationHash}
            isUpdating={false}
          />
        )}
        {state.drawerName === 'update_guest' && (
          <Guest
            guest={state.data}
            reservationHash={reservationDetail.reservationHash}
            isUpdating
          />
        )}
        {state.drawerName === 'update_check_in_form' && (
          <CheckInForm
            isUpdating
            currentForm={state.data}
            allCheckInFormValid={allCheckInFormValid}
            sendToPolice={sendToPolice}
          />
        )}
        {state.drawerName === 'overview_check_in_form' && (
          <OverViewCheckInForm guestList={guestList} />
        )}
        {state.drawerName === 'add_listing' && (
          <AddListingToReservation
            listings={listings}
            onChange={(value) => setSelectedListing(value)}
            addListings={addListingsToReservation}
            currentListingId={reservationDetail.listingId}
            propertyId={reservationDetail.accommodationId}
          />
        )}
      </GlobalDrawer>

      <Modal
        modal={{ open: true }}
        modalName={modalName.CHECK_IN_FORM_MODAL}
        title={t('Check in form')}
      >
        <CheckInForm />
      </Modal>

      {/* open send to police alert */}
      {openSendToPoliceNotFully && (
        <div>
          <Hidden xsDown>
            <DialogAlerts
              isOpen={openSendToPoliceNotFully}
              style={{ textAlign: 'center', padding: '0' }}
              content={
                <div>
                  <Typography variant="h6">
                    {t('Not fully filled check-in form/s')}
                  </Typography>
                  <div style={{ margin: '20px 0' }}>
                    {t('Please review the check-in forms of your guests')}.
                    <br />
                    {t('There may be some information missing')}.
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenSendToPoliceNotFully(false)}
                    >
                      {t('Fill the check-in forms')}
                    </Button>
                  </div>
                </div>
              }
            />
          </Hidden>
          <Hidden smUp>
            <DialogAlerts
              isFullScreen={true}
              isOpen={openSendToPoliceNotFully}
              style={{ textAlign: 'center' }}
              content={
                <div>
                  <Typography variant="h6">
                    {t('Not fully filled check-in form/s')}
                  </Typography>
                  <div style={{ margin: '20px 0' }}>
                    {t('Please review the check-in forms of your guests')}.
                    <br />
                    {t('There may be some information missing')}.
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenSendToPoliceNotFully(false)}
                    >
                      {t('Fill the check-in forms')}
                    </Button>
                  </div>
                </div>
              }
            />
          </Hidden>
        </div>
      )}

      {openSendToPoliceNotArrive && (
        <div>
          <Hidden xsDown>
            <DialogAlerts
              isOpen={openSendToPoliceNotArrive}
              style={{ textAlign: 'center' }}
              content={
                <div>
                  <Typography variant="h6">
                    {t('Some guest(s) didn‘t arrive')}?
                  </Typography>
                  <div style={{ margin: '20px 0' }}>
                    {t('There are')} {reservationDetail.missingForms}{' '}
                    {t(
                      'unfilled guest(s), do you want to close the reservation and submit or do you want to add the missing guest/(s)?',
                    )}
                  </div>
                  <div>
                    <div style={{ marginBottom: 10 }}>
                      {t('Thank you')}! {t('I will check it')}
                    </div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenSendToPoliceNotArrive(false)}
                    >
                      {t('Add missing guests')}
                    </Button>
                  </div>
                  <div style={{ margin: '20px 0' }}>
                    <div style={{ marginBottom: 10 }}>
                      {t('Nobody is missing')}. {t('The reservation has only')}{' '}
                      {reservationDetail.filledForms} {t('guest(s)')}.
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={sendToPolice}
                      disabled={loadingSendToPolice}
                    >
                      {t('Close the reservation and submit')}
                      {loadingSendToPolice && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 15 }}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              }
            />
          </Hidden>
          <Hidden smUp>
            <DialogAlerts
              isFullScreen={true}
              isOpen={openSendToPoliceNotArrive}
              style={{ textAlign: 'center' }}
              content={
                <div>
                  <Typography variant="h6">
                    {t('Some guest(s) didn‘t arrive')}?
                  </Typography>
                  <div style={{ margin: '20px 0' }}>
                    {t('There are')} {reservationDetail.missingForms}{' '}
                    {t(
                      'unfilled guest(s), do you want to close the reservation and submit or do you want to add the missing guest/(s)?',
                    )}
                  </div>
                  <div>
                    <div style={{ marginBottom: 10 }}>
                      {t('Thank you')}! {t('I will check it')}
                    </div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenSendToPoliceNotArrive(false)}
                    >
                      {t('Add missing guests')}
                    </Button>
                  </div>
                  <div style={{ margin: '20px 0' }}>
                    <div style={{ marginBottom: 10 }}>
                      {t('Nobody is missing')}. {t('The reservation has only')}{' '}
                      {reservationDetail.filledForms} {t('guest(s)')}.
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={sendToPolice}
                      disabled={loadingSendToPolice}
                    >
                      {t('Close the reservation and submit')}
                      {loadingSendToPolice && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 15 }}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              }
            />
          </Hidden>
        </div>
      )}

      {/* open Delete Reservation */}
      {openDeleteReservation && (
        <DialogConfirm
          isOpen={openDeleteReservation}
          title={t('Deletion')}
          message={t('Are you sure that you want to delete this reservation?')}
          handleClose={() => setOpenDeleteReservation(false)}
          handleAccess={removeReservation}
        />
      )}

      {/* open Delete Guest */}
      {openDeleteGuest.isOpen && (
        <DialogConfirm
          isOpen={openDeleteGuest.isOpen}
          title={t('Deletion')}
          message={t('Are you sure that you want to delete this guest?')}
          handleClose={() =>
            setOpenDeleteGuest({
              ...openDeleteGuest,
              isOpen: false,
              deleteData: null,
            })
          }
          handleAccess={deleteCheckInForm}
          isLoadding={openDeleteGuest.isLoadding}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  reservation: state.reservation.detail,
  forms: selectReservationDetailForms(state),
  properties: selectProperties(state),
  listings: selectListingsByPropertyId(state),
});

export default connect(mapStateToProps, {
  getReservationDetail,
  openModal,
  updateReservation,
  getListingsByPropertyId,
  deleteReservation,
  deleteInvitation,
  sendReservationToPolice,
  addMasterGuest,
})(withStyles(styles)(ReservationDetail));
