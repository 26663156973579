import React from 'react';
import UserForm from './UserForm';
import { UserFormType } from '../model';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { login } from '../actions';

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  user_or_email: Yup.string()
    // .email('Invalid email')
    .required('Required'),
});

const url = new URL(window.location.href);
const referral_token = url.searchParams.get('referral_token') || '';
class Login extends React.Component {
  handleLogin = (value) => {
    this.props.login(value);
  };
  componentDidMount() {}

  render() {
    const initialValues = {
      user_or_email: '',
      password: '',
    };

    return (
      <UserForm
        {...this.props}
        onSubmit={this.handleLogin}
        formName={UserFormType.LOGIN}
        initialValues={initialValues}
        validation={LoginSchema}
        referral_token={referral_token}
        history={this.props.history}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { login })(Login);
