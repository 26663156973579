/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from 'react';
import {
  Grid,
  Paper,
  Button
} from '@mui/material';
import {withStyles} from '@mui/styles';
import '../../../styles/billing.css';
import { useTranslation } from 'react-i18next';

const Styles = {
  button_basic: {
    color: '#42ddf5',
    border: '2px solid',
    borderColor: '#42ddf5',
    margin: '10px auto 30px',
    padding: '10px 50px',
    display: 'table',
    borderRadius: 10,
    outline: 'none'
  }
};

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    boxShadow: 'none!important'

  }
});


const PackageBilling = (
  classes
) => {
  const { t } = useTranslation();
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [isloadBasic, setIsLoadBasic] = useState(false);
  const [package_display, setPackage_display] = useState(false);
  const [isloadPremium, setIsLoadPremium] = useState(false);
  const Basic = () => {
    setName('Package Basic');
    setPackage_display(true);
    setPrice('300 czk');

  }
  const Premium = () => {
    setName('Package Premium');
    setPackage_display(true);
    setPrice('500 czk');
  }
  const loadBasic = () => {
    setIsLoadBasic(!isloadBasic);
  }
  const loadPremium = () => {
    setIsLoadPremium(!isloadPremium);
  }
  // const { name, price, package_display, loadBasic, loadPremium } = this.state;
  return (
    <React.Fragment>
      <div>
        <div className="billing_info" >
          <p className="title-header">1.{t(`Please choose your package`)}</p><br />
          <Grid container>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} style={{boxShadow:'none'}}>
                <div className="billing-basic">
                  <Button style={Styles.button_basic} onClick={Basic}>
                    {t(`Basic`)}
                    </Button>
                  <p>{t(`We automatically upload your properties & listings & reservation in Airbnb and Booking to Best Guest`)}.</p>
                  <p className="billing_title_content">{t(`Registration book for City office and Local fees`)}</p>
                  {isloadBasic ?
                    <div>
                      <p className="billing_basic_content" >
                        - the data in Registration book are processed according to the law of Local
                          fees (Act No. 565/1990 Coll. Best Guest deals mainly with §3 and §7)<br />
                        - the data are processed according to the GDPR (we don‘t ask the guests for
                          information that the law of Local fees does not require)<br />
                        - the data are saved for 6 years (as the law requires)<br />
                        - we are processing the Local fee for Spa and recreation
                          (generating every month the acknowledgement)<br />
                        - we are able to process the Local fee for Accommodation capacity if
                          it is required for your property<br />
                        (generating every three months the acknowledgement)
                          - we send you reminders to pay the local fees in time<br />
                        - we send automatically the acknowledgements to the city office by Datová schránka<br />
                        - we will help you to register your property to the City office<br />
                        - you can download the Registration book in PDF or CSV<br />
                      </p>
                      <p className="billing_title_content">House book for Foreign Police and sending the data to Ubyport</p>
                      <p className="billing_basic_content">
                        - the data in House book are processed according to the law of the Foreign
                          police (Act No. 326/1999 Coll., Best Guest deals mainly with §93 to §102)<br />
                        - the data are processed according to the GDPR (we don‘t ask the guests for
                          information that the law of Foreign police does not require)<br />
                        - the data are saved for 6 years (as the law requires)<br />
                        - we are processing the Local fee for Spa and recreation
                          (generating every month the acknowledgement)<br />
                        - Ubyport integration that sends check-in forms to the Foreign police,
                          confirmation of receipt from the Foreign police<br />
                        - we will help you to register your property to the Foreign police<br />
                        - you can download the House book in PDF or CSV<br />
                      </p>
                      <p className="billing_title_content">It‘s simple to fill the check-in form</p>
                      <p className="billing_basic_content">
                        - the guest can easily just scan the password and it will read & fill the data<br />
                        - very easy to fill the full address which always goes through validation<br />
                        - the guest have their own app where they can manage their reservation and
                        invite the fellow travelers to fill the check-in form as well<br />
                        - our software pre-checks the information and you can review the check-in
                        forms as well<br />
                      </p>
                      <p>We automatically remind the guests by SMS and Emails to fill the check-in form until the check-in form is filled (as a human always with different text).</p>

                      <p>You have a nice overview of the process of filled and missing check-in forms, all reservations and deadlines. When a check-in form is missing we will send you a reminder a day before check-in and if all check-in forms are filled we will send you reminder as well so you can easily close the reservation and send the check-in forms to the Foreign police.</p>

                      <p>You can manage the reminders and choose which one you would like to receive.</p>

                      <p>You have also nice overview of the invoices, you can download them in PDF or XML (for accountancy).</p>

                      <p>Your app is made for both platforms so you can check your reservations comfortably from anywhere.</p>

                      <p>The guest&lsquo;s app is also made for desktop and for mobile phone. Both apps are in Czech and English.</p>

                      <p>We automatically create the reservations in Airbnb and Booking with all the needed information as the full name, contact details, number of guest therefore we can automatically contact the guest to fill the check-in form . You can automate the process also for your iCal reservations (Homeaway, Expedia etc.) by using the listing link because we are automatically creating the iCal reservations as well, so if your channel enables you to send the first message automatically according to your setting (the text contains the listing link) then you can automate all your reservations.</p>

                      <p>All your guests can see the check-in and -out instructions in their app which will save you a lot of time and your guests will be happier.</p>

                      <p>With us you never have to worry. We will take care of you.</p>
                      <a href='javascript:void(0)' onClick={loadBasic}>...Hide</a>
                    </div>
                    :
                    <a href='javascript:void(0)' onClick={loadBasic}>...Read more</a>}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} style={{boxShadow:'none'}}>
                <div className="billing-premium">
                  <Button style={Styles.button_basic} onClick={Premium}>
                    Premium
                        </Button>
                  <p>Package Premium includes all features in package Basic and also:</p>

                  <p>We pay the local fees automatically by charging your credit card and sending the money to the bank account of the city office.</p>
                  {isloadPremium ?
                    <div>
                      <p className="billing_title_content">Automated Airbnb and Booking messages according to your settings.</p>
                      <p className="billing_basic_content" >
                        - pre-fills the guest name, property name, address, ... wifi etc.<br />
                        - we help you to create the automated messages according to our
                        many years proved system
                        </p>
                      <p className="billing_title_content">Automated Airbnb reviews</p>
                      <p className="billing_basic_content" >
                        - pre-fills the guest name, property name, address, ... wifi etc.<br />
                        - we help you to create the automated messages according to our
                        many years proved system
                        </p>
                      <p className="billing_title_content">Automated Airbnb and Booking messages according to your settings.</p>
                      <p className="billing_basic_content" >
                        - pre-fills the guest name, property name, address, ... wifi etc.<br />
                        - we help you to create the automated messages according to our
                        many years proved system
                        </p>
                      <a href='javascript:void(0)' onClick={loadPremium}>...Hide</a>
                    </div>
                    : <a href='javascript:void(0)' onClick={loadPremium}>...Read more</a>}
                </div>
              </Paper>
            </Grid>
          </Grid>
          {package_display ?
            <div className="container_package_form" >
              <div className='container_package'>
                <p style={{ color: '#42ddf5', fontSize: 18 }}>{name}</p>
                <div>
                  <div className="content_package">You have 2 listings therefore you will pay
                    600 czk / month (without VAT).
                    </div>
                  <div className="content_price"><span className="big_price">{price}</span> /per list</div>
                </div>
              </div>
              <div className="package_note">First 14 days is FREE trial and you can try the Premium package.</div>
            </div>
            : null
          }
          <p className="title-header">2.Please, fill in your credit card information.</p><br />
        </div>
      </div >
    </React.Fragment >
  )
}

export default withStyles(useStyles)(PackageBilling);
