import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  IconButton,
} from '@mui/material';
import TooltipCustom from '../components/TooltipCustom';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import {
  generateAutomationFields,
  bankAccountfield,
} from '../components/generateFields';
import Signature from '../../../components/Signature';
import { omit } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Cancel';
import { previousStep } from '../../../actions/onboading';
import { updateClientInfo } from '../../../actions/account';
import { formatPhone, convertPhoneToInput } from '../../../helpers';

const cusStyles = {
  middle_item: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomText: {
    background: '#c6eeff',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    padding: 2,
  },
  continuesButton: {
    textAlign: 'center',
    marginTop: 30,
  },
  forgotPasswordBtn: {
    textAlign: 'center',
    margin: '30px 0',
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '8px 0 4px',
  },
  iconTooltip: {
    marginTop: 20,
  },
};

const contentTooltip = (title) => {
  return <div>{title}</div>;
};

const validation = Yup.object().shape({
  bankAccount: Yup.string().required('Required'),
  contactName: Yup.string().required('Required').min(3).nullable(),
  email: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
});

const StyledSignatureImg = styled.div`
  width: 100%;
  margin: 10px 0;
  .gr-signature-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    .signature-preview {
      border: 1px dashed #e0e0e0;
    }
  }
`;

const Automation = ({
  handleSubmit,
  handleBack,
  formData,
  stepKey,
  updateClientInfo,
  clientInfo,
}) => {
  const { t } = useTranslation();
  let initialValues =
    formData && formData[stepKey]
      ? formData[stepKey]
      : {
          bankAccount: clientInfo.bankAccount || '',
          contactName: clientInfo.contactName || '',
          email: clientInfo.email || '',
          phoneNumber: convertPhoneToInput(clientInfo.phoneNumber) || '',
          signature: '',
        };

  const [openSignature, setOpenSignature] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requiredSignature, setRequiredSignature] = useState();

  const formFields = generateAutomationFields();
  const bankField = bankAccountfield();

  const onSubmit = async (values) => {
    if (!values.signature) {
      return false;
    }
    let params = {
      ...values,
      phoneNumber: formatPhone(values.phoneNumber),
      headers: { 'Content-Type': 'multipart/form-data' },
      isOnboardingProcess: true,
    };
    setLoading(true);
    updateClientInfo(params).then((res) => {
      setLoading(false);
      res.data && res.result && handleSubmit(values);
    });
  };

  const signatureDialog = (values) => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => setOpenSignature(false)}
        aria-labelledby="customized-dialog-title"
        open={openSignature}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenSignature(false)}
        >
          {t(`Signature`)}
        </DialogTitle>
        <DialogContent>
          <Signature
            currentSignature={values.signature}
            onClose={() => setOpenSignature(false)}
            getSignature={(sign) => {
              setRequiredSignature(false);
              setOpenSignature(false);
              values.signature = sign;
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
            render={({ dirty, values, setFieldValue }) => {
              return (
                <Form>
                  <Typography variant="subtitle1">
                    {t(
                      `These are the details that we will be used to automatically generate the local fees acknowledgement`,
                    )}
                  </Typography>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      marginTop: 30,
                      padding: 27,
                    }}
                  >
                    <Grid container spacing={24} style={{ marginBottom: 15 }}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginLeft: 10 }}
                      >
                        {t(
                          `Contact person responsible for local fees and reporting the guests to Foreign police`,
                        )}
                      </Typography>
                      {formFields.map((field, key) => (
                        <Grid
                          item
                          md={field.md || 6}
                          xs={12}
                          key={key}
                          style={cusStyles.inputTooltip}
                        >
                          <FastField {...omit(field, 'showTooltip')} />
                          {field.showTooltip && (
                            <TooltipCustom
                              title={t(field.label)}
                              iconStyle={cusStyles.iconTooltip}
                              body={contentTooltip(t(field.title))}
                            />
                          )}
                        </Grid>
                      ))}
                      {/* </Grid>
                    <Grid container spacing={24} style={{ marginBottom: 15 }}> */}
                      {bankField.map((field, key) => (
                        <Grid
                          item
                          md={6}
                          xs={12}
                          key={key}
                          style={cusStyles.inputTooltip}
                        >
                          <FastField {...omit(field, 'showTooltip')} />
                          {field.showTooltip && (
                            <TooltipCustom
                              title={t(field.label)}
                              iconStyle={cusStyles.iconTooltip}
                              body={contentTooltip(t(field.title))}
                            />
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={12} md={12}>
                        {signatureDialog(values)}
                        {values.signature && (
                          <StyledSignatureImg>
                            <Typography>{t(`Signature`)}:</Typography>
                            <div className="gr-signature-icon">
                              <img
                                className="signature-preview"
                                src={values.signature}
                                height={80}
                                alt="signature"
                              />

                              <IconButton
                                onClick={() => setFieldValue('signature', '')}
                              >
                                <CloseIcon fontSize={'small'} />
                                <Typography
                                  variant="caption"
                                  style={{ paddingLeft: 5 }}
                                >
                                  {t('Remove signature')}
                                </Typography>
                              </IconButton>
                            </div>
                          </StyledSignatureImg>
                        )}
                        {!values.signature && (
                          <Button
                            type="button"
                            style={{
                              marginTop: 20,
                              textTransform: 'none',
                              ...(requiredSignature && {
                                borderColor: '#f44336',
                                color: '#f44336',
                              }),
                            }}
                            onClick={() => setOpenSignature(true)}
                            variant="outlined"
                            fullWidth
                          >
                            {t(`Click to Add Signature`)}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div style={cusStyles.continuesButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="default"
                      onClick={handleBack}
                      style={{ marginRight: 15 }}
                    >
                      {t(`Back`)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!values.signature || loading}
                    >
                      {t(`Continue`)}
                      {loading && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
  clientInfo: state.client.clientInfo.data,
});

const mapActionToProps = {
  previousStep,
  updateClientInfo,
};

export default connect(mapStateToProps, mapActionToProps)(Automation);
