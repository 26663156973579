import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Typography,
  ListItemIcon,
  ListItemText,
  ListItem,
  CircularProgress,
  ListItemSecondaryAction,
} from '@mui/material';

const styles = (theme) => ({
  iconStyle: {
    color: '#999',
  },
  textStyle: {
    padding: '0 5px',
    '& span': {
      color: '#999',
    },
  },
  listItem: {
    '&:hover': {
      background: theme.palette.dark.light,
    },
  },
  imageIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 20,
      marginLeft: 3,
      filter: 'brightness(60%)',
      '-webkit-filter': 'brightness(60%)',
    },
  },
});

const NavItem = ({
  iconName,
  isImage,
  description,
  name,
  onClick,
  hasBackground,
  disabled,
  loadding,
  classes,
}) => {
  let IconName = iconName;
  return (
    <div>
      <ListItem
        button
        onClick={onClick}
        disabled={disabled}
        // style={{ backgroundColor: !!hasBackground ? '#f5f5f5' : undefined }}
        className={classes.listItem}
      >
        <ListItemIcon>
          {isImage ? (
            <div className={classes.imageIcon}>
              <img alt="" src={iconName} />
            </div>
          ) : (
            IconName && <IconName className={classes.iconStyle} />
          )}
        </ListItemIcon>
        {name === 'Referral' || name === 'Doporučení' ? (
          <div className={classes.textStyle}>
            <Typography variant="inherit">{name}</Typography>
            <Typography variant="inherit" style={{ fontSize: 10 }}>
              {description}
            </Typography>
          </div>
        ) : (
          <ListItemText primary={name} className={classes.textStyle} />
        )}
        {!!loadding && (
          <ListItemSecondaryAction>
            <CircularProgress
              size={15}
              style={{ marginTop: 5, marginRight: 15 }}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </div>
  );
};

export default withStyles(styles)(NavItem);
