import React, { useState } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { grey } from '@mui/material/colors';
import {
  ClickAwayListener,
  Tooltip,
} from '@mui/material';


const styles = {
  iconDefault: {
    fontSize: '100%',
    color: grey[700],
  },
  mobileStyle: {
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: 5,
    zIndex: 20,
    padding: '10px 15px',
    width: 250,
    position: 'absolute',
    left: 30,
    top: 0,
    boxShadow: '1px 1px 1px #efe9e9'
  },
  toolTipParent: {
    position: 'relative'
  }
}

const CusTooltip = ({ title, style = {}, iconStyle = {}, withIcon = true, children }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const isMobile = window.innerWidth < 500;

  const inputProps = {
    onClose: () => setOpenTooltip(false),
    open: openTooltip || false,
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true,
  };

  return (
    // <Tooltip title={title} id="tooltip_custom" style={style} disableFocusListener>
    //   {
    //     withIcon &&
    //     <div style={styles.toolTipParent}>
    //       <InfoOutlined fontSize={'small'} className="tooltip_icon"  onClick = {() => tooltipAction()}
    //       style={{...styles.iconDefault,...iconStyle}} />
    //       {/* {state.isShowTooltip ? <div style={styles.mobileStyle}> {title} </div> : ''} */}
    //     </div>
    //   }
    //   {/* { !withIcon && children } */}
    // </Tooltip>
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <div>
        <Tooltip title={title} id="tooltip_custom" style={style} {...isMobile && inputProps}>
          {
            withIcon &&
            <div style={styles.toolTipParent}>
              <InfoOutlined
                fontSize={'small'}
                className="tooltip_icon"
                style={{ ...styles.iconDefault, ...iconStyle }}
                onClick={() => setOpenTooltip(true)}
              />
            </div>
          }
        </Tooltip>
      </div>
    </ClickAwayListener >
  );
};

export default CusTooltip;
