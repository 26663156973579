import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_LISTING_FOR_MAPPING,
  GET_LISTING_FOR_MAPPING_SUCCESS,
  GET_LISTING_FOR_MAPPING_FAILURE,
} from '../../actions/onboading';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_LISTING_FOR_MAPPING]: state => {
    state.data = [];
    state.loading = true;
  },
  [GET_LISTING_FOR_MAPPING_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [GET_LISTING_FOR_MAPPING_FAILURE]: (state, action) => {
    state.loading = false;
  },
});
