import FormInput from '../../../common/input';
import PhoneInput from '../../../common/PhoneInput';
import FileUpload from '../../../common/FileUpload';
import SelectMui from '../../../common/SelectMui';
import SwitchesCustom from '../../../common/SwitchesCustom';
import { isEmpty } from 'lodash';

export function formatList(list, t) {
  if (!isEmpty(list)) {
    return list.map(item => ({
      value: item.id,
      label: t(item.roleName),
    }));
  }
  return [];
}


export function generateReservationFormFields(rolesList, t) {

  const fiels = [
    {
      title: '',
      id: 'properties-1',
      fields: [
        {
          name: "welcomerName",
          label: "Welcomer's first name",
          placeholder: "Welcomer's first name",
          component: FormInput,
        },
        {
          name: 'role',
          placeholder: 'Role',
          label: 'Role',
          options: formatList(rolesList, t),
          component: SelectMui,
        },
        {
          name: 'coverImage',
          label: 'Add welcomer photo',
          placeholder: 'Add welcomer photo',
          maxSize: 154000,
          component: FileUpload,
        },
      ],
    },
    {
      title: 'Contacts',
      id: 'properties-2',
      helpContent: `${t('Info for members that are welcomers')} - ${t('Your guests might use these contact details that appear also in the GuestApp to contact you')}.`,
      fields: [
        {
          name: 'phoneNumber',
          type: 'text',
          placeholder: 'Phone number',
          label: 'Phone number',
          component: PhoneInput,
        },
        {
          name: "email",
          label: "Email",
          placeholder: 'Email',
          type: 'email',
          component: FormInput,
        },
        {
          name: "iMessage",
          label: "iMessage",
          placeholder: 'iMessage',
          component: SwitchesCustom,
          showHelp: true,
          helpContent: `${t('The phone number has to be the same')}!`,
          helpPosition: 'left',
        },
        {
          name: "whatsApp",
          label: "WhatsApp",
          placeholder: 'WhatsApp',
          component: SwitchesCustom,
          showHelp: true,
          helpContent: `${t('The phone number has to be the same')}!`,
          helpPosition: 'left',
        },
      ],
    },
  ];
  return fiels;
}
