import React from 'react';
import CounterHooks from '../../../common/CounterHooks';
import {FormHelperText, Grid} from '@mui/material';
import { useTranslation } from 'react-i18next';

const FieldCounter = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={24} justify="center" alignItems="center">
      {
        props.label &&
        <Grid item xs>
          <label>{t(props.label)}</label>
        </Grid>
      }
      <Grid item xs>
        <CounterHooks
          value={field.value}
          onChange={count => setFieldValue(field.name, count)}
        />
        {touched[field.name] && errors[field.name] && (
          <FormHelperText error>{errors[field.name]}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default FieldCounter;
