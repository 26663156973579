import React from 'react';
// import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import Changepassword from '../../../components/security/Changepassword';
import { useTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { globalStyles } from '../../../styles/styles';
import { Paper, Grid } from '@mui/material';
import { withStyles } from '@mui/styles'

const Security = ({ classes }) => {
  const { t } = useTranslation();
  return (
    <Paper className={classes.root} >
      <TitleHasIcon variant="h5" title={t('Security')} label={''} icon={'security'} />
      <Grid container spacing={24} alignItems="center" justify="center">
        <Grid item xs={12} md={4}>
          <Changepassword />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(globalStyles)(Security);
