import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Typography, Grid, Button } from '@mui/material';
import UpcomingReservationList from './UpcomingReservationList';
import { useTranslation } from 'react-i18next';
import { loginSuccess } from '../../account/actions';
import { getReservations } from '../../../actions/reservation';
import {
  completeOnboarding,
  sendAlertsToUpcomingReservation,
} from '../../../actions/onboading';

const cusStyles = {
  middle_item: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomText: {
    background: '#c6eeff',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    padding: 2,
  },
  continuesButton: {
    textAlign: 'center',
    marginTop: 30,
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '8px 0 4px',
  },
  iconTooltip: {
    marginTop: 20,
  },
  PropertyName: {
    margin: '30px 0',
  },
  HostDutiesItem: {
    marginTop: 10,
    marginBottom: 100,
  },
  buttonStyle: {
    marginRight: 15,
    minWidth: 200,
  },
  buttonFinish: {
    textAlign: 'center',
    marginTop: 50,
  },
};

export const SEND_INVITATION = 'SEND_INVITATION';
export const DONT_SEND_INVITATION = 'DONT_SEND_INVITATION';

const UpcomingReservation = ({
  handleSubmit,
  handleBack,
  loginSuccess,
  getReservations,
  reservationsList,
  completeOnboarding,
  sendAlertsToUpcomingReservation,
}) => {
  useEffect(() => {
    getReservations({ onlyUnclosed: 1 });
  }, []);

  const [invitationList, setInvitationList] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSetSendInvitation = (data, type) => {
    setInvitationList({
      ...invitationList,
      [data.reservationId]: type,
    });
  };

  const onSetSendAllInvitation = (type) => {
    let _invitationList = {};
    reservationsList &&
      reservationsList.length > 0 &&
      reservationsList.map((v) => (_invitationList[v.reservationId] = type));
    setInvitationList(_invitationList);
  };

  const onSubmit = () => {
    const _send_ids = Object.keys(invitationList).filter((k) => {
      return invitationList[k] === SEND_INVITATION;
    });
    setLoading(true);
    _send_ids.length > 0 &&
      sendAlertsToUpcomingReservation({ reservationIds: _send_ids }).then(
        (res) => {
          res.data &&
            res.result &&
            completeOnboarding().then((re) => {
              setLoading(false);
              re.data && re.result && handleSubmit();
            });
        },
      );
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t(`These are all the upcoming reservations from Airbnb`)}.{' '}
            {t(
              `Would you like to send an invitation to fill the check-in form to all your guests or because some of them already did so`,
            )}
            ?
          </Typography>
          <Grid
            container
            spacing={24}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            {/* <Grid item md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checked={confirmed}
                    // disabled={!allCheckInFormValid}
                    onChange={event => setConfirmed(event.target.checked)}
                    color="primary"
                  />
                }
                label={t("I confirm I just sent this message to the email address of the Foreign police")}
                style={{marginTop: 50}}
              />
            </Grid> */}
            {/* <Grid item xs style={{marginTop: 30}}>
              <Button type="button" variant="outlined" color="secondary" style={cusStyles.buttonStyle} disabled={!confirmed}>{t(`Don't send all`)}</Button>
              <Button type="button" variant="contained" color="secondary" style={cusStyles.buttonStyle} disabled={!confirmed}>{t(`Send all`)}</Button>
            </Grid> */}
          </Grid>
          <div style={{ marginTop: 50 }}>
            {reservationsList && reservationsList.length > 0 ? (
              <UpcomingReservationList
                datas={reservationsList}
                onSetSendInvitation={onSetSendInvitation}
                onSetSendAllInvitation={onSetSendAllInvitation}
                invitationList={invitationList}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <div style={cusStyles.buttonFinish}>
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={handleBack}
              style={{ marginRight: 15 }}
            >
              {t(`Back`)}
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={onSubmit}
              disabled={
                loading ||
                reservationsList.length !== Object.keys(invitationList).length
              }
            >
              {t(`Finish`)}
              {loading && (
                <CircularProgress size={20} style={{ marginLeft: 10 }} />
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  reservationsList: state.reservation.reservationsList.data,
});

export default connect(mapStateToProps, {
  loginSuccess,
  getReservations,
  completeOnboarding,
  sendAlertsToUpcomingReservation,
})(UpcomingReservation);
