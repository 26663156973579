import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import FormInput from '../../common/input';
import SuccessMessage from '../SuccessMessage';
import BoxShadow from '../../common/BoxShadow';
import { useTranslation } from 'react-i18next';
import { changePasswordUser } from '../../actions/account';

const Changepassword = (props) => {
  const [typeInput, setTypeInput] = useState(false);
  const [state, setState] = useState({
    loading: false,
    done: false,
  });
  const { t } = useTranslation();
  const required = t('Require');
  const validationSchema = Yup.object().shape({
    password: Yup.string().required(required),
    newPassword: Yup.string()
      .required(required)
      .min(6, 'Password is too short should be 6 chars minimum')
      .matches(/[0-9]/, 'Password must include at least one numeric character')
      .matches(/[a-z]/, 'Password must contain lowercase characters')
      .matches(/[A-Z]/, 'Password must contain uppercase characters')
      .nullable(),
    newPasswordAgain: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t("Passwords don't match"))
      .required(t('Confirm Password is required')),
  });

  function handleSubmit(values, actions) {
    setState({ loading: true });
    try {
      props.changePasswordUser(values).then((res) => {
        setState({ loading: false });
        if (res.data && res.result) {
          setState({ ...state, done: true });
          setTimeout(() => {
            actions.resetForm();
            setTypeInput(false);
          }, 1000);
        } else {
          res.errors.length > 0 &&
            res.errors[0].note &&
            actions.setErrors({ password: res.errors[0].note });
        }
      });
    } catch (err) {
      setState({ ...state, loading: false });
      actions.setErrors({ password: t('Password is incorrect') });
    }
  }

  return (
    <BoxShadow>
      <Typography variant="h6" align="center">
        {t('Change password')}
      </Typography>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          password: '',
          newPassword: '',
          newPasswordAgain: '',
        }}
        onSubmit={handleSubmit}
        render={({ dirty }) => (
          <Form>
            <Grid container spacing={24} direction="column">
              <Grid
                item
                xs={12}
                style={window.innerWidth < 500 ? { padding: 0 } : {}}
              >
                <Field
                  type={typeInput ? 'text' : 'password'}
                  name="password"
                  disabled={!!state.loading}
                  label={t('Current password')}
                  component={FormInput}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={window.innerWidth < 500 ? { padding: 0 } : {}}
              >
                <Field
                  disabled={!!state.loading}
                  type={typeInput ? 'text' : 'password'}
                  name="newPassword"
                  label={t('New password')}
                  component={FormInput}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={
                  window.innerWidth < 500 ? { padding: 0, marginBottom: 5 } : {}
                }
              >
                <Field
                  disabled={!!state.loading}
                  type={typeInput ? 'text' : 'password'}
                  name="newPasswordAgain"
                  label={t('New password again')}
                  component={FormInput}
                />
              </Grid>
            </Grid>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={typeInput}
                    onChange={(e) => setTypeInput(!typeInput)}
                    color="primary"
                  />
                }
                label={t('Show password')}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              type="submit"
              disabled={!dirty}
              fullWidth
            >
              {state.loading ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                t('Save')
              )}
            </Button>
            {state.done ? (
              <SuccessMessage message={`${t('Saved successfully')}!`} />
            ) : null}
          </Form>
        )}
      />
    </BoxShadow>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  changePasswordUser,
})(Changepassword);
