import React from 'react';

const styles = {
  flexMargin: {
    display: 'flex',
    marginTop: 10,
    alignItems: 'center',
  },
  contentLeft: {
    width: 36,
    border: '1px solid #ccc',
    borderRadius: '50%',
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    flex: '0 0 36px',
  },
};

const BoxHasIcon = (props) => {
  return (
    <div style={styles.flexMargin}>
      <div style={styles.contentLeft}>
        <img src={props.image} style={{ width: props.width }} />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default BoxHasIcon;
