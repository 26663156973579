/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Paper,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import { compose } from 'recompose';
import { loginStyles } from '../containers/style';
import FormInput from '../../../common/input/index';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { login, logout } from '../actions';
import { Link, Navigate } from 'react-router-dom';
import * as _ from 'lodash';
import { UserFormType } from '../model';
import { withTranslation } from 'react-i18next';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import { withFirebase } from '../../../components/Firebase';

const GOOGLE = 'google';
const FACEBOOK = 'facebook';
const TWITTER = 'twitter';

const FORM_FIELDS = [
  {
    name: 'user_or_email',
    type: 'text',
    formName: [UserFormType.LOGIN],
    placeholder: 'Username or Email',
    label: 'Email',
    component: FormInput,
  },
  {
    name: 'login_name',
    type: 'text',
    formName: [UserFormType.SIGN_UP],
    placeholder: 'Username',
    label: 'Username',
    component: FormInput,
  },
  {
    name: 'email',
    type: 'email',
    formName: [UserFormType.SIGN_UP],
    placeholder: 'Email',
    label: 'Email',
    component: FormInput,
  },
  {
    name: 'first_name',
    type: 'text',
    formName: [UserFormType.SIGN_UP],
    isSignUpForm: true,
    placeholder: 'First name',
    label: 'First name',
    component: FormInput,
  },
  {
    name: 'last_name',
    type: 'text',
    formName: [UserFormType.SIGN_UP],
    placeholder: 'Last name',
    label: 'Last name',
    component: FormInput,
  },
  {
    name: 'password',
    type: 'password',
    formName: [UserFormType.LOGIN, UserFormType.SIGN_UP],
    placeholder: 'Password',
    label: 'Password',
    component: FormInput,
  },
];

class UserFormWithPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      value: {
        email: '',
        password: '',
      },
    };

    this.onCheckBox = this.onCheckBox.bind(this);
    // this.handleLoginSocial = this.handleLoginSocial.bind(this);
  }

  onCheckBox = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleLoginSocial(e, type) {
    let _firebase;
    switch (type) {
      case GOOGLE:
        _firebase = this.props.firebase.doSignInWithGoogle();
        break;
      case FACEBOOK:
        _firebase = this.props.firebase.doSignInWithFacebook();
        break;
      case TWITTER:
        _firebase = this.props.firebase.doSignInWithTwitter();
        break;
      default:
        break;
    }

    console.log('type', type);
    _firebase
      .then((socialAuthUser) => {
        console.log('socialAuthUser', socialAuthUser);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  render() {
    const { classes, formName, initialValues, validation, onSubmit, account } =
      this.props;
    const loading = account.loading;
    const isLoginForm = formName === UserFormType.LOGIN;
    const fields = FORM_FIELDS.filter((field) =>
      _.includes(field.formName, formName),
    );

    if (this.props.account.detail.tokens.authToken) {
      return <Navigate noThrow to="/dashboard" />;
    }

    const { t } = this.props;

    return (
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className={classes.formLayout}
      >
        <Grid item xs={12} md={3}>
          <Paper className={classes.formWrapper}>
            <div className={classes.formGroup}>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={logo}
                    className={classes.logo}
                    style={window.innerWidth < 500 ? { width: '67%' } : {}}
                  />
                </Grid>
              </Grid>
              {/* <Typography variant="h6" gutterBottom>
              {isLoginForm ? t('Sign in') : t('Registration')}
            </Typography> */}
              <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
              >
                <Form style={{ marginTop: -13 }}>
                  {fields.map(
                    ({ name, type, placeholder, label, component }) => (
                      <Field
                        key={name}
                        name={name}
                        type={
                          type === 'password' && this.state.isChecked
                            ? 'text'
                            : type
                        }
                        placeholder={placeholder}
                        label={label}
                        component={component}
                      />
                    ),
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isChecked}
                        onChange={this.onCheckBox}
                        color="primary"
                      />
                    }
                    label={t('Show password')}
                  />
                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.margin}
                      fullWidth
                      disabled={loading}
                    >
                      {isLoginForm ? t('Sign in') : t('Create account')}
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              </Formik>
              {isLoginForm ? (
                <div className={classes.link}>
                  <div>
                    <Link to="/forgot-password">
                      <span className={classes.link}>
                        {t('Forgot Password')}?
                      </span>
                    </Link>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    {t("Don't have account yet?")}
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={() => this.props.history('/register')}
                  >
                    {t('Register')}
                  </Button>
                </div>
              ) : (
                <div className={classes.link}>
                  <span>{t('Already have account?')} </span>
                  <Link to="/login">
                    <span className={classes.link}>{t('Log in?')}</span>
                  </Link>
                </div>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(
  connect(mapStateToProps, { login, logout })(
    withStyles(loginStyles)(compose(withFirebase)(UserFormWithPassword)),
  ),
);
