import React, { Component } from 'react';
import { 
  // emailServicesDomains as domains,
  protectedKeyCodes, emailServicesDomains } from './constants';
import { omit } from 'lodash';

export default class Email extends Component {
  constructor(props) {
    super(props)
    let updatedEmailServicesList = emailServicesDomains
    Array.prototype.push.apply(updatedEmailServicesList, props.domains && Array.isArray(props.domains) ? props.domains : [])
    
    this.state = {
      placeholder: props.placeholder,
      class: props.className,
      value: '',
      domains: updatedEmailServicesList,
      suggestion: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.getSuggest = this.getSuggest.bind(this)
  }

  componentDidMount() {
    this.props.value && this.setState({ value: this.props.value })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.value && prevProps.value) {
      this.setState({ value: prevProps.value })
    }
  }

  handleChange(event) {
    // Catch value of the input box by every change
    let emailAddress = event.target.value
    let suggest = this.suggest(emailAddress)

    if (typeof suggest === 'undefined' || suggest.length < 1) {
      // Set value and suggestion state by every change
      this.setState({ value: emailAddress, suggestion: suggest }, () => this.selectText())
      event.target.value = emailAddress;
    } else {
      const updatedEmailAddr = `${emailAddress}${suggest}`
      // Update value state plus suggested text
      this.setState({ value: updatedEmailAddr, suggestion: suggest }, () => this.selectText())
      event.target.value = emailAddress + suggest
    }

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }
  selectText() {
    if (typeof this.state.suggestion === 'undefined' || this.state.suggestion.length < 1) {
      return false;
    } else {
      let startPos = this.state.value.indexOf(this.state.suggestion)
      let endPos = startPos + this.state.suggestion.length;
      if (this.textHandler.setSelectionRange && /text|search|password|tel|url/i.test(this.textHandler.type || '')) {
        this.textHandler.setSelectionRange(startPos, endPos)
      }
    }
  }
  getSuggest(event) {
    if (protectedKeyCodes.indexOf(event.keyCode) >= 0) {
      return;
    }
    if (event.keyCode === 8) {
      this.setState({ value: event.target.value.replace(this.state.suggestion, '') })
    }
  }
  suggest(string) {
    let str_arr = string.split('@')
    if (str_arr.length > 1) {
      string = str_arr.pop()
      if (!string.length) {
        return;
      }
    } else {
      return;
    }

    let match = this.state.domains.filter((domain) => {
      return 0 === domain.indexOf(string);
    }).shift() || '';

    return match.replace(string, '');
  }
  
  render() {
    return (
      <div className="eac-wrapper">
        <input
          autoCapitalize="none"
          type="text"
          inputMode="email"
          {...omit(this.props, ['inputRef'])}
          // id="eac-input" 
          // name={this.props.name} 
          // placeholder={this.state.placeholder} 
          onBlur={this.props.onBlur}
          className={this.state.class}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyUp={this.getSuggest}
          ref={(input) => { this.textHandler = input }}
        />
      </div>
    )
  }
}
