import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  Typography,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import {
  createWelcomer,
  getWelcomers,
  updateWelcomer,
} from '../../../actions/member';
import { selectReservationDetail } from '../../../selectors/reservation-selectors';
import * as Yup from 'yup';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { generateReservationFormFields } from '../components/generateFields';
import GroupFields from '../../../components/GroupFields';
import {
  selectPropertyDetail,
  selectProperties,
} from '../../../selectors/property-selectors';
import { GlobalDrawerContext } from '../../../contexts';
import { CLOSE_DRAWER } from '../../settings/actionTypes';
import { convertPhoneToInput, formatPhone } from '../../../helpers';
import { media } from '../../../styles/commonStyles';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import { useTranslation } from 'react-i18next';

const BoxStyled = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const FormWrapper = styled.div`
  padding: 20px;
  ${media.largeDesktop`
    width: 480px;
  `}
  ${media.phone`
    width: 90%;
  `}
`;

const validation = (isUpdating) =>
  Yup.object().shape({
    welcomerName: Yup.string().required('Required').nullable(),
    role: Yup.number().required('Required').nullable(),
    email: Yup.string().email('Invalid email').required('Required').nullable(),
    phoneNumber: Yup.string().required('Required').nullable(),
  });

const MemberForm = ({
  isUpdating,
  createWelcomer,
  updateWelcomer,
  defaultData,
  getWelcomers,
  memberListRoles,
  isOnboardingProcess,
}) => {
  const [loader, setLoadder] = useState(false);
  const [error] = useState('');
  const [actionStatus, setActionStatus] = useState(false);
  const { dispatch } = useContext(GlobalDrawerContext);
  const { t } = useTranslation();

  function handleSubmit(values, { setErrors }) {
    let params = omit(values, []);
    params.iMessage = params.iMessage ? 1 : 0;
    params.whatsApp = params.whatsApp ? 1 : 0;
    params.phoneNumber = formatPhone(params.phoneNumber);
    params.headers = { 'Content-Type': 'multipart/form-data' };
    isOnboardingProcess && (params.isOnboardingProcess = true);
    setLoadder(true);
    if (isUpdating) {
      updateWelcomer(params).then((res) => {
        setLoadder(false);
        if (res.data && res.result) {
          setActionStatus(true);
          setTimeout(() => {
            dispatch({ type: CLOSE_DRAWER });
            getWelcomers();
          }, 1000);
        } else {
          // res.errors.length > 0 && res.errors[0].note && setErrors(res.errors[0].note)
        }
      });
    } else {
      createWelcomer(params).then((res) => {
        setLoadder(false);
        if (res.data && res.result) {
          setActionStatus(true);
          setTimeout(() => {
            dispatch({ type: CLOSE_DRAWER });
            getWelcomers();
          }, 1000);
        } else {
          res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    }
  }

  const formFields = generateReservationFormFields(memberListRoles, t);

  const initialValues = isUpdating
    ? {
        ...defaultData,
        welcomerId: defaultData.id,
        phoneNumber: convertPhoneToInput(defaultData.phoneNumber),
        iMessage: defaultData.iMessage === 1,
        whatsApp: defaultData.whatsApp === 1,
      }
    : {
        welcomer: '',
        welcomerName: '',
        role: null,
        email: '',
        phoneNumber: '',
        iMessage: true,
        whatsApp: true,
      };

  return (
    <FormWrapper>
      <TitleHasUnderLine
        title={isUpdating ? t('Update Member') : t('Add new Member')}
        size="h6"
        color="primary"
        align="center"
        marginBottom={20}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validation(isUpdating)}
        onSubmit={handleSubmit}
        render={({ dirty, values, ...other }) => {
          return (
            <Form>
              {formFields.map(({ fields, title, helpContent, id }) => (
                <GroupFields
                  key={id}
                  fields={fields}
                  title={title}
                  lg={12}
                  helpContent={helpContent}
                />
              ))}
              <BoxStyled>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || loader}
                >
                  {isUpdating ? t('Update') : t('Add new')}
                  {loader && <CircularProgress size={20} />}
                </Button>
              </BoxStyled>
              {actionStatus && (
                <Typography color="secondary" align="center">{`${
                  isUpdating ? t('Updated') : t('Saved')
                } ${t('successfully')}!`}</Typography>
              )}
              {!!error && (
                <FormHelperText error={!!error}>{error}</FormHelperText>
              )}
            </Form>
          );
        }}
      />
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  reservationDetail: selectReservationDetail(state).data,
  accommodation: selectPropertyDetail(state),
  properties: selectProperties(state),
  memberListRoles: state.member.memberListRoles.data,
});

export default connect(mapStateToProps, {
  createWelcomer,
  getWelcomers,
  updateWelcomer,
})(MemberForm);
