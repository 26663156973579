import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/lab';
import { FormHelperText, FormControl, Grid, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { omit, isString } from 'lodash';
import CusTooltip from '../components/CusTooltip';
import format from 'date-fns/format';

const styles = {
  formControl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
};

const notUseYearSetting = {
  format: 'MM-dd-yyyy',
  mask: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  placeholder: 'MM-dd-yyyy',
};

const isUseYearSetting = {
  format: 'yyyy',
  mask: [/\d/, /\d/, /\d/, /\d/],
  placeholder: 'yyyy',
};

const CustomDatePicker = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const [useYear, setUseYear] = useState(false);
  const [setting, setSetting] = useState(notUseYearSetting);
  const [value, setValue] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setUseYear(props.useYearValue || false);
    setSetting(props.useYearValue ? isUseYearSetting : notUseYearSetting);
  }, [props.useYearValue]);

  useEffect(() => {
    setValue(
      field.value
        ? props.useYearValue && isString(field.value)
          ? field.value.replace('-00-00', '-01-01')
          : field.value
        : null,
    );
  }, [field.value]);

  const handleUseYear = (e) => {
    let isCheck = e.target.checked;
    setUseYear(isCheck);
    isCheck ? setSetting(isUseYearSetting) : setSetting(notUseYearSetting);
    setFieldValue(field.name, value);
    setFieldValue(`useYear`, isCheck);
  };
  return (
    <div style={styles.formControl}>
      {props.showInfo && <CusTooltip title={props.helpContent} />}
      <FormControl fullWidth margin={'dense'}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={props.useYear ? 10 : 12}>
            <DatePicker
              {...omit(props, [
                'showInfo',
                'useYear',
                'helpContent',
                'useYearValue',
              ])}
              fullWidth
              clearable={true}
              keyboard
              format={setting.format}
              mask={setting.mask}
              label={t(props.label)}
              placeholder={t(setting.placeholder)}
              variant="standard"
              onChange={(date) => {
                const newDate = date ? format(date, 'yyyy-MM-dd') : null;
                setValue(newDate);
                setFieldValue(field.name, newDate);
                setFieldValue(`useYear`, useYear);
              }}
              value={value}
              invalidDateMessage={t('Invalid Date Format')}
            />
          </Grid>
          {props.useYear && (
            <Grid item xs={2} align="right">
              <span
                data-tip={t('Use only year')}
                data-place="bottom"
                data-for={'showHiddenYear'}
                className="mgLeft2"
              >
                <Checkbox
                  checked={useYear}
                  onChange={handleUseYear}
                  value="useYear"
                  color="primary"
                  style={{ paddingBottom: 0 }}
                />
                <ReactTooltip
                  id="showHiddenYear"
                  place="right"
                  type="info"
                  effect="float"
                  multiline={true}
                />
              </span>
            </Grid>
          )}
        </Grid>

        {touched[field.name] && errors[field.name] && (
          <FormHelperText error>{t(errors[field.name])}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default CustomDatePicker;
