import produce from 'immer';
import {
  GET_VISA_COUNTRY_LIST,
  GET_VISA_COUNTRY_LIST_SUCCESS,
  GET_VISA_COUNTRY_LIST_FAILURE,
} from '../actions/country';

export const initialState = {
  data: [],
  loading: false,
  error: ''
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_VISA_COUNTRY_LIST:
        draft.loading = true;
        return;
      case GET_VISA_COUNTRY_LIST_SUCCESS:
        draft.loading = false;
        draft.data = action.res;
        return;
      case GET_VISA_COUNTRY_LIST_FAILURE:
        draft.loading = false;
        draft.error = 'Server error';
        return;
      default:
        return state;
    }
  });
