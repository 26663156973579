import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, FastField } from 'formik';
import { Button, Typography } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { updateListing, createListing } from '../../../actions/listing';
import StyledFormWrapper from '../../../components/property/StyledFormWrapper';
import { CLOSE_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import { GlobalDrawerContext } from '../../../contexts';
import { getPropertyDetail } from '../../../actions/property';
import {
  getListingDetail,
  addIcalLink,
  updateIcalLink,
  addClientIntegration,
} from '../../../actions/listing';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  generateListingsFields,
  generateConnectPropertyFields,
} from './generateFields';
import ConnectProperty from '../components/ConnectProperty';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import CusTooltip from '../../../components/CusTooltip';

const listingSchema = Yup.object().shape({
  listingName: Yup.string().required('Required').nullable(),
  specialCode1: Yup.string().required('Required').nullable(),
  specialCode2: Yup.string().required('Required').nullable(),
  checkIn: Yup.string().required('Required').nullable(),
  checkOut: Yup.string().required('Required').nullable(),
  // checkIn: Yup.date().required('Required').nullable(),
  // checkOut: Yup.date().test(
  //   "match",
  //   "Check-out date must be greater than Check-in date at least 1 day",
  //   function (checkOut) {
  //     return validateCheckInCheckOut(this.parent.checkIn, checkOut);
  //   }
  // ),
  wifiName: Yup.string().required('Required').nullable(),
  wifiPassword: Yup.string().required('Required').nullable(),
});

const updatedListingSchema = Yup.object().shape({
  listingName: Yup.string().required('Required').nullable(),
  specialCode1: Yup.string().required('Required').nullable(),
  specialCode2: Yup.string().required('Required').nullable(),
  checkIn: Yup.string().required('Required').nullable(),
  checkOut: Yup.string().required('Required').nullable(),
  // checkIn: Yup.date().required('Required').nullable(),
  // checkOut: Yup.date().test(
  //   "match",
  //   "Check-out date must be greater than Check-in date at least 1 day",
  //   function (checkOut) {
  //     return validateCheckInCheckOut(this.parent.checkIn, checkOut);
  //   }
  // ),
  wifiName: Yup.string().required('Required').nullable(),
  wifiPassword: Yup.string().required('Required').nullable(),
});

export const INTEGRATIONS = 'integrations';
export const ICALS = 'icals';

const ListingForm = ({
  currentListing,
  property,
  createListing,
  updateListing,
  isUpdating,
  getPropertyDetail,
  getListingDetail,
  listingDetail,
  addIcalLink,
  updateIcalLink,
  addClientIntegration,
}) => {
  const { dispatch } = useContext(GlobalDrawerContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (isUpdating && currentListing && currentListing.listingId) {
      getListingDetail({ listingId: currentListing.listingId });
    }
  }, []);

  const [loadingConnectForm, setLoadingConnectForm] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [desynchromiseDialog, setDesynchromiseDialog] = useState(false);

  const formField = generateListingsFields();

  function onSubmit(values, { setErrors }) {
    let params = {
      accommodationId: property.accommodationId,
      listingType: 1,
      listingName: values.listingName,
      specialCode1: values.specialCode1,
      specialCode2: values.specialCode2,
      checkIn: values.checkIn,
      checkOut: values.checkOut,
      wifiName: values.wifiName,
      wifiPassword: values.wifiPassword,
    };
    setSubmitForm(true);
    if (!isUpdating) {
      createListing(params).then((res) => {
        if (res.data && res.result) {
          getPropertyDetail({ accommodationId: property.accommodationId });
          setTimeout(() => {
            setSubmitForm(false);
            dispatch({ type: CLOSE_DRAWER, drawerName: 'create_listing' });
          }, 500);
        } else {
          res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    } else {
      params.listingId = values.listingId;
      updateListing(params).then((res) => {
        if (res.data && res.result) {
          setTimeout(() => {
            setSubmitForm(false);
            getPropertyDetail({ accommodationId: property.accommodationId });
            dispatch({ type: CLOSE_DRAWER, drawserName: 'edit_listing' });
          }, 500);
        } else {
          res.errors.length > 0 &&
            res.errors[0].note &&
            setErrors(res.errors[0].note);
        }
      });
    }
  }

  let initialValues = {
    checkIn: null,
    checkOut: null,
    listingType: '',
    listingName: '',
    specialCode1: '',
    specialCode2: '',
    wifiName: '',
    wifiPassword: '',
  };
  if (isUpdating) {
    initialValues = {
      ...initialValues,
      ...currentListing,
      ...(listingDetail && listingDetail.data),
    };
  }

  const handleAddClientIntegration = (param) => {
    if (isUpdating && currentListing) {
      setLoadingConnectForm(true);
      let _newParam = param;
      _newParam.listingId = currentListing.listingId;
      addClientIntegration(_newParam).then((res) => {
        if (res.data && res.result) {
          getListingDetail({ listingId: currentListing.listingId });
        }
        setLoadingConnectForm(false);
      });
    }
  };

  const handleAddIcalLink = (param) => {
    if (isUpdating && currentListing) {
      setLoadingConnectForm(true);
      let _newParam = param;
      _newParam.listingId = currentListing.listingId;
      addIcalLink(_newParam).then(() => {
        setTimeout(() => {
          setLoadingConnectForm(false);
          getListingDetail({ listingId: currentListing.listingId });
        }, 500);
      });
    }
  };

  // const handleDesynchromiseIcalLink = (param) => {
  //   if (isUpdating && currentListing) {
  //     setLoadingConnectForm(true);
  //     updateIcalLink(param).then(() => {
  //       setTimeout(() => {
  //         setLoadingConnectForm(false);
  //         getListingDetail({ listingId: currentListing.listingId })
  //       }, 500);
  //     });
  //   }
  // }

  const renderConnectPropery = () => {
    const integrations = listingDetail.data.integrations;
    const icals = listingDetail.data.icals;
    const formConnectField = generateConnectPropertyFields();

    let result = [];
    integrations &&
      Object.keys(integrations).forEach((v) => {
        result.push(
          <ConnectProperty
            key={v}
            connectType={INTEGRATIONS}
            name={v}
            {...integrations[v]}
            {...formConnectField[v]}
            handleAddClientIntegration={handleAddClientIntegration}
            handleDesynchromiseIcalLink={() => setDesynchromiseDialog(true)}
            // handleDesynchromiseIcalLink={handleDesynchromiseIcalLink}
            loadingConnectForm={loadingConnectForm}
          />,
        );
      });

    icals &&
      Object.keys(icals).forEach((v) => {
        result.push(
          <ConnectProperty
            key={v}
            connectType={ICALS}
            name={v}
            {...icals[v]}
            {...formConnectField[v]}
            handleAddIcalLink={handleAddIcalLink}
            handleDesynchromiseIcalLink={() => setDesynchromiseDialog(true)}
            // handleDesynchromiseIcalLink={handleDesynchromiseIcalLink}
            loadingConnectForm={loadingConnectForm}
          />,
        );
      });

    return result;
  };

  return (
    <StyledFormWrapper>
      <TitleHasUnderLine
        marginTop={0}
        marginBottom={30}
        size="h6"
        align="center"
        title={
          <span style={{ fontWeight: 300 }}>
            {isUpdating ? t('Updating') : t('Creating')} {t('for property')}:{' '}
            <span style={{ fontSize: 20, fontWeight: 500 }}>
              {property.accommodationName}
            </span>
          </span>
        }
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={isUpdating ? updatedListingSchema : listingSchema}
        onSubmit={onSubmit}
        render={({ dirty, values, ...other }) => {
          return (
            <Form>
              <Typography
                variant={'subtitle1'}
                style={{ marginBottom: 15, fontWeight: 500, display: 'flex' }}
              >
                {t('Automatic messages settings')}
                <CusTooltip
                  title={`${t(
                    'You share the check-in and check-out instructions',
                  )}, ${t('check-in and check-out time')}, ${t(
                    'wifi name and wifi password with your guests in their GuestApp',
                  )}.`}
                />
              </Typography>

              <Grid container spacing={24}>
                {formField.map((v, k) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={k === 0 ? 12 : 6}
                    lg={k === 0 ? 12 : 6}
                    key={k}
                  >
                    <FastField {...v} />
                  </Grid>
                ))}
              </Grid>

              {isUpdating && (
                <div>
                  <Typography
                    variant={'subtitle1'}
                    style={{
                      marginBottom: 15,
                      marginTop: 30,
                      fontWeight: 500,
                      display: 'flex',
                    }}
                  >
                    {t('Connect your property with booking sites')}
                    <CusTooltip
                      title={`${t(
                        'Do you use some channel that you would like to connect with Best Guest',
                      )}?`}
                    />
                  </Typography>

                  {/* integrations */}
                  {listingDetail.loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    renderConnectPropery()
                  )}

                  {/* {formConnectField.map((v, k) => <ConnectProperty key={k} {...v} />)} */}
                </div>
              )}

              <Button
                style={{ marginTop: 15 }}
                variant={'contained'}
                color={'primary'}
                fullWidth
                type={'submit'}
                disabled={!dirty || submitForm}
              >
                {isUpdating ? t('Update') : t('Create Listing')}
                {submitForm && (
                  <CircularProgress size={20} style={{ marginLeft: 10 }} />
                )}
              </Button>
            </Form>
          );
        }}
      />

      <Dialog
        disableBackdropClick
        open={desynchromiseDialog}
        onClose={() => setDesynchromiseDialog(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Please contact us if you want to remove your integration')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setDesynchromiseDialog(false)}
            color="primary"
            autoFocus
          >
            {t('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledFormWrapper>
  );
};

const mapStateToProps = (state) => ({
  listingDetail: state.property.listingDetail,
});

const mapActionToProps = {
  createListing,
  updateListing,
  getPropertyDetail,
  getListingDetail,
  addIcalLink,
  updateIcalLink,
  addClientIntegration,
};

export default connect(mapStateToProps, mapActionToProps)(ListingForm);
