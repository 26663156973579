import guestBook from '../../styles/title-icon/Guestbook_icon.png';
import LocalFeesIcon from '../../styles/title-icon/Local_fees_icon.png';
import ReferralIcon from '../../styles/title-icon/Referral_icon.png';
import {
  Dashboard,
  Autorenew,
  Home,
  AccountBox,
  Group,
  Security,
  NotificationsNone,
  Payment
} from '@mui/icons-material';

export const MENUS = [
  {
    name: 'Dashboard',
    iconName: Dashboard,
    path: 'dashboard'
  },
  {
    name: 'Automation',
    iconName: Autorenew,
    path: 'automation'
  },
  {
    name: 'Properties',
    iconName: Home,
    path: 'properties'
  },
  {
    name: 'Guestbook',
    iconName: guestBook,
    path: 'guestbook',
    isImage: true,
  },
  {
    name: 'Local fees',
    iconName: LocalFeesIcon,
    path: 'local-fees',
    isImage: true,
  }
];

export const SETTINGS_MENUS = [
  {
    name: 'Account',
    iconName: AccountBox,
    path: 'account'
  },
  {
    name: 'Referral',
    iconName: ReferralIcon,
    description:"Get 500 Kč for each host you refer",
    path: 'referrals',
    isImage: true,
  },
  {
    name: 'Members',
    iconName: Group,
    path: 'members'
  },
  {
    name: 'Security',
    iconName: Security,
    path: 'security'
  },
  {
    name: 'Reminders',
    iconName: NotificationsNone,
    path: 'reminders'
  },
  {
    name: 'Payments',
    iconName: Payment,
    path: 'payments'
  }
];
