import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_MESSAGE_SETTING,
  SAVE_MESSAGE_SETTING,
  GET_MESSAGE_SETTING_SUCCESS,
  // SAVE_MESSAGE_SETTING_SUCCESS
} from '../../actions/automation';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_MESSAGE_SETTING]: (state) => {
    state.data = [];
    state.loading = true;
  },
  [GET_MESSAGE_SETTING_SUCCESS]: (state,action) => {
    state.data = action.res;
    state.loading = false;
  },
  // [SAVE_MESSAGE_SETTING]: (state, action) => {
  //   state.loading = false;
  //   state.data = action.res;
  // },
});
