import React from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { isEmpty } from 'lodash';

const Dropdown = ({
  options,
  label,
  disabled,
  value,
  onChange,
  isFullWidth,
  ...props
}) => {
  return (
    <FormControl fullWidth={!!isFullWidth}>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <Select
        {...props}
        fullWidth={!!isFullWidth}
        disabled={!!disabled}
        value={value}
        onChange={onChange}
      >
        {isEmpty(options)
          ? null
          : options.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
