import { useReducer } from 'react';

export const OPEN_DRAWER = 'open_drawer';
export const CLOSE_DRAWER = 'close_drawer';

const drawerReducer = (state, { type, drawerName, data }) => {
  switch (type) {
    case OPEN_DRAWER:
      return {
        ...state,
        isOpen: true,
        drawerName,
        data,
      };

    case CLOSE_DRAWER:
      return {
        ...state,
        isOpen: false,
        drawerName,
      };

    default:
      return state;
  }
};

const useGlobalDrawer = () => {
  const [state, dispatch] = useReducer(drawerReducer, {
    isOpen: false,
    drawerName: '',
  });

  return { state, dispatch };
};

export default useGlobalDrawer;
