/* eslint-disable no-mixed-operators */

import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Button, Grid } from '@mui/material';
import AlertComfirm from '../components/AlertComfirm';
import {
  communicationWithAuthorities,
  getAccommodationHostDuties,
} from '../../../actions/onboading';
import { brown, green, grey, lightGreen } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';
import { connect } from 'react-redux';
import { downloadFile } from '../../../helpers';
import {
  URL_REGISTER_CAPACITY_FEES,
  URL_LOCAL_FEE_AUTHORIZATION_PDF,
  URL_REGISTER_LOCAL_FEES,
} from '../../../shared/urls';

const classes = (theme) => ({
  childItem: {
    paddingLeft: 20,
    '& p, & span': {
      margin: '10px 0',
    },
    '& .pl20': {
      paddingLeft: 20,
    },
    '& .pl40': {
      paddingLeft: 40,
    },
    '& .brown': {
      color: brown['500'],
    },
  },
  title: {
    margin: '20px 0',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  reportingItem: {
    padding: 30,
    marginTop: 20,
    border: `1px solid ${grey[300]}`,
  },
  textCenter: {
    textAlign: 'center',
  },
  customChip: {
    border: `1px solid ${green[500]}`,
    padding: '5px 15px',
    borderRadius: 15,
    color: green[500],
    margin: '0 30px',
    textTransform: 'initial',
  },
  doneStyle: {
    background: lightGreen[500],
    color: `${grey[100]} !important`,
  },
});

const REGISTER_LOCAL_FEE = 'Register to the Spa and recreation fee';
const REGISTER_CAPACITY_FEE = 'Register to the Accommodation capacity fee';
const SHOW_ME_INFORMATION =
  'Show me more information about my sign up to Ubyport';
const INFORM_FOREIGN_POLICE =
  'Inform the Foreign police about using Best Guest';
const ASK_FOREIGN_PROLICE =
  'Ask the Foreign police to send you new login details';
const GRANT_BEST_GUEST = 'Grant Best Guest the power of attorney';

const listingDefault = [
  REGISTER_LOCAL_FEE,
  REGISTER_CAPACITY_FEE,
  SHOW_ME_INFORMATION,
  INFORM_FOREIGN_POLICE,
  ASK_FOREIGN_PROLICE,
  GRANT_BEST_GUEST,
];

const INFORM_FOREIGN_POLICE_CONTENT = (t, data) => {
  return (
    <div>
      {t('To enable to Best Guest to send the data')} ({t('check-in forms')}){' '}
      {t(
        'about your guests from foreign countries directly to the Foreign police',
      )}
      , {t('please')},{' '}
      {t('copy the message below and send it from your contact email')}{' '}
      [ContactEmail] to reguby@pcr.cz. {t('Thank you')}.
      <div
        style={{
          border: '1px solid #999',
          padding: 15,
          marginTop: 15,
          whiteSpace: 'pre-line',
        }}
      >
        {data &&
          data.hostDuties &&
          data.hostDuties &&
          data.hostDuties.informPoliceMessage}
      </div>
    </div>
  );
};

const ASK_FOREIGN_PROLICE_CONTENT = (t, data) => {
  return (
    <div>
      {t('To enable to Best Guest to send the data')} ({t('check-in forms')}){' '}
      {t(
        'about your guests from foreign countries directly to the Foreign police',
      )}
      , {t('please')},{' '}
      {t('copy the message below and send it from your contact email')}{' '}
      [ContactEmail] to reguby@pcr.cz. {t('Thank you')}.
      <div
        style={{
          border: '1px solid #999',
          padding: 15,
          marginTop: 15,
          whiteSpace: 'pre-line',
        }}
      >
        {data &&
          data.hostDuties &&
          data.hostDuties &&
          data.hostDuties.askToPoliceMessage}
      </div>
    </div>
  );
};

const confirmPopupContent = (t, data) => ({
  [REGISTER_LOCAL_FEE]: {
    content: `${t(
      'Please send the downloaded registration form called',
    )} „Registrace k místnímu poplatku za lázeňský a rekreační pobyt - /PropertyName/“ through your Datová schránka to datová schránka ID: /DatovaSchrankaID/.`,
    confirmContent: `${t(
      'I confirm I just sent the registration form to the',
    )} Datová schránka ${t('of the city office')}.`,
    url: URL_REGISTER_LOCAL_FEES,
    name: REGISTER_LOCAL_FEE,
    fileName: REGISTER_LOCAL_FEE,
  },
  [REGISTER_CAPACITY_FEE]: {
    content: `${t(
      'Please send the downloaded registration form called',
    )} „Registrace k místnímu poplatku z ubytovací kapacity - /PropertyName/“ through your Datová schránka to datová schránka ID: /DatovaSchrankaID/ ${t(
      'and attach any evidence that your property is an accommodation facility',
    )}.`,
    confirmContent: `${t(
      'I confirm I just sent the registration form and the evidence to the',
    )} Datová schránka ${t('of the city office')}.`,
    content1: `${t('The document can be the approval decision')}, ${t(
      'extract from the cadastre',
    )}, ${t('list of owners')}, ${t(
      'lease or purchase agreement and it has to be in Pdf format',
    )}.`,
    url: URL_REGISTER_CAPACITY_FEES,
    name: REGISTER_CAPACITY_FEE,
    fileName: REGISTER_CAPACITY_FEE,
  },
  [SHOW_ME_INFORMATION]: {
    content: `${t('Your property')} ${
      data ? `[${data.accommodationName}]` : '[PropertyName#1]'
    } ${t('has been successfully signed up to the Foreign police')}, ${t(
      'you can see the sign up downloaded to your computer and you will receive an email with your login details from the Foreign police to your contact email',
    )} [ContactEmail] ${t('soon')}.`,
    url: null,
    name: SHOW_ME_INFORMATION,
    fileName: SHOW_ME_INFORMATION,
  },
  [INFORM_FOREIGN_POLICE]: {
    content: INFORM_FOREIGN_POLICE_CONTENT(t, data),
    confirmContent: `${t(
      'I confirm I just sent this message to the email address of the Foreign police',
    )}.`,
    url: null,
    name: INFORM_FOREIGN_POLICE,
    fileName: INFORM_FOREIGN_POLICE,
  },
  [ASK_FOREIGN_PROLICE]: {
    content: ASK_FOREIGN_PROLICE_CONTENT(t, data),
    confirmContent: `${t(
      'I confirm I just sent this message to the email address of the Foreign police',
    )}.`,
    url: null,
    name: ASK_FOREIGN_PROLICE,
    fileName: ASK_FOREIGN_PROLICE,
  },
  [GRANT_BEST_GUEST]: {
    content: `${t('Please send the downloaded document called')} "Plná moc" ${t(
      'through your',
    )} Datová schránka to datová shránka ID: /DatovaShrankaID/ ${t(
      'to grant Best Guest the power of attorney',
    )}. ${t(
      'Best Guest gets the right to send the acknowledgents to the city office and pay the local fees for you',
    )}.`,
    confirmContent: `${t(
      'I confirm I just sent the Power of attorner to the Datová schránka of the city office',
    )}.`,
    url: URL_LOCAL_FEE_AUTHORIZATION_PDF,
    name: GRANT_BEST_GUEST,
    fileName: 'Plná moc',
  },
});

const CommunicationWithAuthorities = ({
  handleSubmit,
  classes,
  handleBack,
  propertiesList,
  communicationWithAuthorities,
  getAccommodationHostDuties,
  accommodationHostDuties,
}) => {
  useEffect(() => {
    getAccommodationHostDuties();
  }, []);

  const [confirm, setConfirm] = useState({
    open: false,
    content: null,
    confirmContent: null,
    loading: false,
    type: null,
    accommodationId: null,
    url: null,
  });

  const [isDone, setIsDone] = useState({});

  const { t } = useTranslation();

  const onSubmit = async (values) => {
    return handleSubmit(values);
  };

  const feeRender = () => {
    return (
      <div className={classes.childItem}>
        <Typography variant="subtitle1">
          {`${'Communicate with authorities'} - ${t(
            'the city office administrating the local fees and the Foreign police administrating the check-in forms of foreigners',
          )}.`}
        </Typography>
      </div>
    );
  };

  const handleConfirm = () => {
    if (!confirm.url) return null;
    setConfirm({ ...confirm, loading: true });
    communicationWithAuthorities(
      { accommodationId: confirm.accommodationId, format: 'pdf' },
      confirm.url,
    ).then((res) => {
      setConfirm({ ...confirm, loading: false });
      if (res) {
        const fileName = confirm.fileName || 'Plná moc';
        downloadFile(res, `${fileName}.pdf`, 'pdf');
        setConfirm({ ...confirm, open: false });
        const _isDone = {
          ...isDone,
          [confirm.accommodationId || confirm.type]: {
            ...isDone[confirm.accommodationId],
            [confirm.name || confirm.type]: true,
          },
        };
        setIsDone(_isDone);
      }
    });
  };

  const renderChipListing = (item, key, data) => {
    return (
      <div
        style={{ display: 'flex', padding: 15, alignItems: 'center' }}
        key={key}
      >
        <span>{key + 1})</span>
        <Button
          className={[
            classes.customChip,
            isDone &&
              isDone[data.accommodationId] &&
              isDone[data.accommodationId][item] &&
              classes.doneStyle,
          ].join(' ')}
          disabled={
            isDone &&
            isDone[data.accommodationId] &&
            isDone[data.accommodationId][item]
          }
          onClick={() =>
            setConfirm({
              open: true,
              type: item,
              accommodationId: data.accommodationId,
              ...confirmPopupContent(t, data)[item],
            })
          }
        >
          {t(item)}
        </Button>
        {isDone &&
          isDone[data.accommodationId] &&
          isDone[data.accommodationId][item] && (
            <CheckCircle color="secondary" />
          )}
      </div>
    );
  };

  const checkRenderChipListing = (item) => {
    const hostDuties = item.hostDuties;
    const newList =
      hostDuties &&
      listingDefault.filter(
        (v, k) =>
          (v === REGISTER_LOCAL_FEE &&
            hostDuties.rentingFromLocal &&
            hostDuties.localFeeAddress) ||
          (v === REGISTER_CAPACITY_FEE &&
            hostDuties.rentingFromCapacity &&
            hostDuties.capacityFeeAddress) ||
          (v === SHOW_ME_INFORMATION && hostDuties.databoxId) ||
          (v === INFORM_FOREIGN_POLICE &&
            hostDuties.informPoliceMessage &&
            hostDuties.ubyUsername &&
            hostDuties.ubyPassword &&
            hostDuties.ubyIDUB &&
            hostDuties.ubyClientNameShort) ||
          (v === ASK_FOREIGN_PROLICE &&
            hostDuties.askToPoliceMessage &&
            !hostDuties.ubyUsername &&
            !hostDuties.ubyPassword &&
            hostDuties.ubyIDUB &&
            hostDuties.ubyClientNameShort &&
            // (v === GRANT_BEST_GUEST) &&
            v),
      );

    return newList.map((v, k) => renderChipListing(v, k, item));
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          {feeRender()}
        </Grid>
      </Grid>

      <Grid
        container
        style={{ border: '1px solid #ccc', padding: 20, margin: 20 }}
      >
        <Grid item xs={12}>
          <div style={{ marginBottom: 30 }}>
            <Typography variant="body1" style={{ padding: 15 }}>
              {t(
                `Best Guest will automatically send the local fee acknowledgements for you`,
              )}
              .
            </Typography>
            <div style={{ display: 'flex', placeItems: 'center' }}>
              <Button
                className={[
                  classes.customChip,
                  isDone &&
                    isDone[GRANT_BEST_GUEST] &&
                    isDone[GRANT_BEST_GUEST][GRANT_BEST_GUEST] &&
                    classes.doneStyle,
                ].join(' ')}
                disabled={
                  isDone &&
                  isDone[GRANT_BEST_GUEST] &&
                  isDone[GRANT_BEST_GUEST][GRANT_BEST_GUEST]
                }
                style={{ margin: '15px 30px 15px 15px' }}
                onClick={() =>
                  setConfirm({
                    open: true,
                    content: confirmPopupContent(t)[GRANT_BEST_GUEST].content,
                    confirmContent:
                      confirmPopupContent(t)[GRANT_BEST_GUEST].confirmContent,
                    type: GRANT_BEST_GUEST,
                    url: confirmPopupContent(t)[GRANT_BEST_GUEST].url,
                  })
                }
              >
                {t(GRANT_BEST_GUEST)}
              </Button>
              {isDone &&
                isDone[GRANT_BEST_GUEST] &&
                isDone[GRANT_BEST_GUEST][GRANT_BEST_GUEST] && (
                  <CheckCircle color="secondary" />
                )}
            </div>
          </div>
          {accommodationHostDuties &&
            accommodationHostDuties.length > 0 &&
            accommodationHostDuties.map((item, key) => (
              <div key={key} style={{ marginBottom: 30 }}>
                <Typography
                  variant="h5"
                  style={{ padding: 15, fontWeight: 500 }}
                >
                  {item.accommodationName}
                </Typography>
                {checkRenderChipListing(item)}
              </div>
            ))}
        </Grid>
      </Grid>

      <div className={classes.textCenter} style={{ marginTop: 30 }}>
        <Button
          type="button"
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 15 }}
        >
          {t(`Back`)}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onSubmit}
        >
          {t(`Continue`)}
        </Button>
      </div>

      {confirm.open && (
        <AlertComfirm
          {...confirm}
          handleClose={() =>
            setConfirm({ open: false, content: null, confirmContent: null })
          }
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  propertiesList: state.property.propertiesList.data,
  accommodationHostDuties: state.onboarding.accommodationHostDuties.data,
});

export default withStyles(classes)(
  connect(mapStateToProps, {
    communicationWithAuthorities,
    getAccommodationHostDuties,
  })(CommunicationWithAuthorities),
);
