import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Paper, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { loginStyles } from './style';
import { verifyEmail } from '../actions';
import { Divider, CircularProgress } from '@mui/material';
import { withTranslation } from 'react-i18next';
import logo from '../../../styles/image/transparent__dark_text_logo.png';

class VerifyEmail extends Component {
  componentDidMount() {
    this.props.location.search
      ? this.props.verifyEmail(this.props.location.search)
      : this.props.history('/login');
  }

  renderContent() {
    const { account, t } = this.props;
    if (!!account.loading) {
      return <CircularProgress size={24} style={{ marginLeft: 15 }} />;
    }
    return (
      <Typography
        variant={'h6'}
        color={account.detail.isVerifyEmailSuccess ? 'secondary' : 'error'}
      >
        {account.detail.isVerifyEmailSuccess
          ? t('Verification was successful!')
          : t('Error')}
      </Typography>
    );
  }

  render() {
    const { classes, t } = this.props;
    return (
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className={classes.formLayout}
      >
        <Grid item xs={12} md={3}>
          <Paper className={classes.formWrapper}>
            <div className={classes.formGroup}>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid item xs={12} md={6}>
                  <img src={logo} className={classes.logo} />
                </Grid>
              </Grid>

              <div
                className={classes.formGroup}
                style={{ textAlign: 'center' }}
              >
                {this.renderContent()}

                <Divider style={{ margin: '30px 0' }} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.props.history('/login')}
                >
                  {t('Login')}
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(
  connect(mapStateToProps, { verifyEmail })(
    withStyles(loginStyles)(VerifyEmail),
  ),
);
