
import FormInput from '../../../common/input';
import CustomTimePicker from '../../../common/CustomTimePicker';
import Agoda from '../../../styles/onboarding/connect_account/Agoda_logo.png';
import Airbnb from '../../../styles/onboarding/connect_account/Airbnb_logo.png';
import Booking from '../../../styles/onboarding/connect_account/Booking_logo.png';
import Ctrip from '../../../styles/onboarding/connect_account/Ctrip_logo.png';
import Expedia from '../../../styles/onboarding/connect_account/Expedia_logo.png';
import Homeaway from '../../../styles/onboarding/connect_account/Homeaway_logo.png';
import TripAdvisor from '../../../styles/onboarding/connect_account/TripAdvisor_logo.png';
import Wimdu from '../../../styles/onboarding/connect_account/Wimdu_logo.png';
import HostelWorld  from '../../../styles/onboarding/connect_account/HostelWorld_logo.png';
import HotelTonight from '../../../styles/onboarding/connect_account/HotelTonight_logo.png';


export function generateListingsFields() {

  const fieldsArr = [
    {
      name: "listingName",
      label: "Listing name",
      placeholder: "Listing name",
      component: FormInput,
    },
    {
      name: "specialCode1",
      label: "Listing special code 1",
      placeholder: "Listing special code 1",
      component: FormInput,
    },
    {
      name: "specialCode2",
      label: "Listing special code 2",
      placeholder: "Listing special code 2",
      component: FormInput,
      showInfo: true,
      helpContent: `You can create you own special code for this listing to create messages for your guests - for example Check-in instructions`,
      helpPosition: 'right',
    },
    {
      name: "checkIn",
      label: "Check-in time",
      format: 'MM-dd-yyyy',
      placeholder: 'MM-dd-yyyy',
      component: CustomTimePicker,
    },
    {
      name: "checkOut",
      label: "Check-out time",
      format: 'MM-dd-yyyy',
      placeholder: 'MM-dd-yyyy',
      component: CustomTimePicker,
    },
    {
      name: "wifiName",
      label: "Wifi name",
      placeholder: "Wifi name",
      component: FormInput,
    },
    {
      name: "wifiPassword",
      label: "Wifi password",
      placeholder: "Wifi password",
      component: FormInput,
    },
    // {
    //   name: "airbnbICalLink",
    //   label: "Airbnb iCal Link"
    // },
    // {
    //   name: "bookingComICalLink",
    //   label: "Booking.com iCal Link"
    // },
    // {
    //   name: "airbnbListingId",
    //   label: "Airbnb Listing Id"
    // },
    // {
    //   name: "typeName",
    //   label: "Type name"
    // },
    // {
    //   name: "personsCount",
    //   label: "Number of Guest"
    // },
    // {
    //   name: "primaryBeds",
    //   label: "Primary beds"
    // },
    // {
    //   name: "extraBeds",
    //   label: "Extra beds"
    // }
  ];
  return fieldsArr;
}

export function generateConnectPropertyFields() {
  const fieldsArr = {
    airbnb: {
      label: 'Airbnb',
      img: Airbnb,
      link: 'https://airbnb.com'
    },
    booking: {
      label: 'Booking.com',
      img: Booking,
      link: 'https://booking.com'
    },
    ctrip: {
      label: 'Ctrip',
      img: Ctrip,
    },
    expedia: {
      label: 'Expedia',
      img: Expedia,
    },
    homeAway: {
      label: 'Homeaway',
      img: Homeaway,
    },
    tripadVisor: {
      label: 'TripAdvisor',
      img: TripAdvisor,
    },
    wimdu: {
      label: 'Wimdu',
      img: Wimdu,
    },
    agoda: {
      label: 'Agoda',
      img: Agoda,
    },
    hostelWorld: {
      label: 'HostelWorld',
      img: HostelWorld,
    },
    hotelTonight: {
      label: 'HotelTonight',
      img: HotelTonight,
    },
  };

  return fieldsArr;
}

// export function generateConnectPropertyFields() {

//   const fieldsArr = [
//     {
//       name: 'airbnb',
//       label: 'Airbnb',
//       img: Airbnb,
//       connected: false,
//     },
//     {
//       name: 'booking',
//       label: 'Booking.com',
//       img: Booking,
//       connected: false,
//     },
//     {
//       name: 'ctrip',
//       label: 'Ctrip',
//       img: Ctrip,
//       connected: false,
//     },
//     {
//       name: 'expedia',
//       label: 'Expedia',
//       img: Expedia,
//       connected: false,
//     },
//     {
//       name: 'homeaway',
//       label: 'Homeaway',
//       img: Homeaway,
//       connected: false,
//     },
//     {
//       name: 'tripAdvisor',
//       label: 'TripAdvisor',
//       img: TripAdvisor,
//       connected: false,
//     },
//     {
//       name: 'wimdu',
//       label: 'Wimdu',
//       img: Wimdu,
//       connected: false,
//     },
//     {
//       name: 'agoda',
//       label: 'Agoda',
//       img: Agoda,
//       connected: false,
//     },
//   ];

//   return fieldsArr;
// }
