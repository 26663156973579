import createReducer from '../../../redux/createReducer';
import {
  CREATE_RESIDENTIAL_CLIENT,
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  CREATE_RESIDENTIAL_CLIENT_FAILURE,
} from '../actionTypes';
import { produce } from 'immer';

const initialState = {
  data: {
    bankAccount: '',
    invoiceAddress: '',
    // postalAddress: '',
    email: '',
    phoneNumber: '',
    contactName: '',
    ubyClientContact: '',
    ubyClientName: '',
    ubyClientNameShort: '',
    ubyUsername: '',
    ubyPassword: '',
    ubyIDUB: '',
  },
  loading: false,
  isUpdatedErrorMsg: null,
};

export default createReducer(initialState, {
  [CREATE_RESIDENTIAL_CLIENT]: (state) =>
    produce(state, (draftState) => {
      draftState.loading = true;
      draftState.isUpdatedErrorMsg = null;
    }),
  [CREATE_RESIDENTIAL_CLIENT_FAILURE]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = true;
      draftState.isUpdatedErrorMsg = action.res.msg;
    }),
  [CREATE_RESIDENTIAL_CLIENT_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.data = action.res;
      draftState.isUpdatedErrorMsg = null;
    }),
});
