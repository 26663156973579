import * as Yup from 'yup';

export const getFormProps = (hasProperty) => {
  if (hasProperty) {
    return {
      initialValues: {
        clientName: '',
        businessId: '',
        vatId: '',
        invoiceAddress: '',
        bankAccount: '',
        // postalAddress: '',
        contactName: '',
        email: '',
        phoneNumber: '',
        // bookingEmail: '',
      },
      validationSchema: Yup.object().shape({
        clientName: Yup.string().required('Required').nullable(),
        businessId: Yup.string()
          .test(
            'len',
            'Must be 8 digit characters',
            // val => `${val}`.length === 8,
            (val) => val && val.toString().length === 8,
          )
          .required('Required')
          .nullable(),
        // vatId: Yup.string()
        //   .test(
        //     'len',
        //     'Must be 8-10 digit characters',
        //     // val => `${val}`.length >= 8 && `${val}`.length <= 10,
        //     val => val && val.toString().length >= 8 && val.toString().length <= 10,
        //   )
        //   .required('Required'),
        invoiceAddress: Yup.string().required('Required').nullable(),
        bankAccount: Yup.string().required('Required').nullable(),
        // postalAddress: Yup.string().required('Required'),
        contactName: Yup.string().required('Required').nullable(),
        phoneNumber: Yup.string().required('Required').nullable(),
        email: Yup.string()
          .email('Invalid email')
          .required('Required')
          .nullable(),
        // bookingEmail: Yup.string()
        //   .email('Invalid email')
        //   .required('Required'),
      }),
    };
  }

  return {
    initialValues: {
      clientName: '',
      businessId: '',
      vatId: '',
      invoiceAddress: '',
      // postalAddress: '',
      // bankAccount: '',
      // airbnbEmail: '',
      contactName: '',
      email: '',
      phoneNumber: '',
      ubyUsername: '',
      ubyPassword: '',
      // ubyIDUB: '',
      // ubyClientName: '',
      // ubyClientNameShort: '',
    },
    validationSchema: Yup.object().shape({
      clientName: Yup.string().required('Required').nullable(),
      businessId: Yup.string()
        .test(
          'len',
          'Must be 8 digit characters',
          // val => `${val}`.length === 8,
          (val) => val && val.toString().length === 8,
        )
        .required('Required')
        .nullable(),
      // vatId: Yup.string()
      //   .test(
      //     'len',
      //     'Must be 8-10 digit characters',
      //     // val => `${val}`.length >= 8 && `${val}`.length <= 10,
      //     val => val && val.toString().length >= 8 && val.toString().length <= 10,
      //   )
      //   .required('Required'),
      invoiceAddress: Yup.string().required('Required').nullable(),
      // postalAddress: Yup.string().required('Required'),
      // bankAccount: Yup.string().required('Required'),
      // airbnbEmail: Yup.string()
      //     .email('Invalid email')
      //     .required('Required'),
      contactName: Yup.string().required('Required').nullable(),
      phoneNumber: Yup.string().required('Required').nullable(),
      email: Yup.string()
        .email('Invalid email')
        .required('Required')
        .nullable(),
      // ubyUsername: Yup.string().required('Required'),
      // ubyPassword: Yup.string().required('Required'),
      // ubyIDUB: Yup.string().required('Required'),
      // ubyClientName: Yup.string().required('Required').nullable(),
      // ubyClientNameShort: Yup.string().required('Required'),
    }),
  };
};

export const initialClientValue = {
  clientContact: '',
  clientName: '',
  email: '',
  businessId: '',
  residentialClientId: '',
  contactName: '',
  phoneNumber: '',
  invoiceAddress: '',
  bankAccount: '',
  airbnbEmail: '',
  airbnbPassword: '',
  // ubyUsername: '',
  // ubyClientContact: '',
  // ubyPassword: '',
  // ubyIDUB: '',
  // ubyClientName: '',
  // ubyClientNameShort: '',
};

export const clientValidationSchema = Yup.object().shape({
  clientName: Yup.string().required('Required').nullable(),
  contactName: Yup.string().required('Required').nullable(),
  phoneNumber: Yup.string().required('Required').nullable(),
  businessId: Yup.string()
    .test(
      'len',
      'Must be 8 digit characters',
      // val => `${val}`.length === 8,
      (val) => val && val.toString().length === 8,
    )
    .required('Required')
    .nullable(),
  email: Yup.string().email('Invalid email').required('Required').nullable(),
  // bookingEmail: Yup.string()
  //   .email('Invalid email')
  //   .required('Required'),
  invoiceAddress: Yup.string().required('Required').nullable(),
  bankAccount: Yup.string().required('Required').nullable(),
  airbnbEmail: Yup.string()
    .email('Invalid email')
    .required('Required')
    .nullable(),
  airbnbPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .nullable(),
  // ubyUsername: Yup.string().required('Required'),
  // ubyPassword: Yup.string().required('Required'),
  // ubyIDUB: Yup.string().required('Required'),
  // ubyClientName: Yup.string().required('Required').nullable(),
  // ubyClientNameShort: Yup.string().required('Required'),
  // ubyClientContact: Yup.string().required('Required'),
});

export const updatedClientValidationSchema = Yup.object().shape({
  clientName: Yup.string().required('Required').nullable(),
  contactName: Yup.string().required('Required').nullable(),
  phoneNumber: Yup.string().required('Required').nullable(),
  businessId: Yup.string()
    .test(
      'len',
      'Must be 8 digit characters',
      // val => `${val}`.length === 8,
      (val) => val && val.toString().length === 8,
    )
    .required('Required')
    .nullable(),
  email: Yup.string().email('Invalid email').required('Required').nullable(),

  // airbnbEmail: Yup.string()
  //   .email('Invalid email')
  //   .required('Required').nullable(),
  // airbnbPassword: Yup.string()
  //   .min(2, 'Too Short!')
  //   .max(50, 'Too Long!')
  //   .required('Required').nullable(),

  // bookingEmail: Yup.string()
  //   .email('Invalid email')
  //   .required('Required'),
  invoiceAddress: Yup.string().required('Required').nullable(),
  bankAccount: Yup.string().required('Required').nullable(),
  // ubyUsername: Yup.string().required('Required'),
  // ubyPassword: Yup.string().nullable(),
  // ubyIDUB: Yup.string().required('Required'),
  // ubyClientName: Yup.string().required('Required').nullable(),
  // ubyClientNameShort: Yup.string().required('Required'),
  // ubyClientContact: Yup.string().required('Required'),
});
