export const getTextDay = (date) => {
    const lstWeeksDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const day = new Date(date).getDay();
    return lstWeeksDay[day];
}

export const getMonth = (dateString) => {
    const monthYear = dateString.split(',');
    return monthYear[0];
}

export const getYear = (dateString) => {
    const monthYear = dateString.split(',');
    return monthYear[1];
}

export const getDifferentDay = (fromDate, toDate) => {
    if(fromDate && toDate) {
        const from = new Date(fromDate);
        const to = new Date(toDate);
        const timeDiff = Math.abs(to.getTime() - from.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        return diffDays;
    }
    return 0;
}