import React from 'react';
import { connect } from 'react-redux';
import TitleHasUnderLine from '../../../common/TitleHasUnderLine';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import InvoiceDetails from './InvoiceDetails';
import StepperCustom from '../components/StepperCustom';
import Automation from './Automation';
import ConnectAccount from './ConnectAccount';
import Billing from './Billing';
import { nextStep, previousStep } from '../../../actions/onboading';
import HostDuties from './HostDuties';
import CommunicationWithAuthorities from './CommunicationWithAuthorities';
import UpcomingReservation from './UpcomingReservation';
import Listings from './Listings';
import { AIRBNB, BOOKING, HASAIRBNB, HASBOOKING } from './ConnectAccount';
import Welcomers from './Welcomers';
import { withTranslation } from 'react-i18next';
import { LOGIN_DETAIL } from '../../../routes/onboarding-routes';

const hiddenStepCount = 2;
const BILLING_INFO_KEY = 'billingInfo';
const CONNECT_ACCOUNT_KEY = 'connectAccount';
const LISTINGS_KEY = 'listings';
const INVOICE_DETAILS_KEY = 'invoiceDetails';
const AUTOMATION_KEY = 'automation';
const WELCOMERS_KEY = 'welcomers';
const HOST_DUTIES_KEY = 'hostDuties';
const COMMUNICATION_WITH_AUTHORITIES_KEY = 'communicationWithAuthorities';
const UPCOMING_RESERVATION_KEY = 'upcomingReservation';

const getStepName = [
  {
    key: CONNECT_ACCOUNT_KEY,
    name: 'Connect Account',
    component: ConnectAccount,
  },
  {
    key: LISTINGS_KEY,
    name: 'Listings',
    component: Listings,
  },
  {
    key: BILLING_INFO_KEY,
    name: 'Billing Info',
    component: Billing,
  },
  {
    key: INVOICE_DETAILS_KEY,
    name: 'Invoice Details',
    component: InvoiceDetails,
  },
  {
    key: AUTOMATION_KEY,
    name: 'Automated Acknowledgements',
    component: Automation,
  },
  {
    key: WELCOMERS_KEY,
    name: 'Welcomers',
    component: Welcomers,
  },
  {
    key: HOST_DUTIES_KEY,
    name: 'Host Duties',
    component: HostDuties,
  },
  {
    key: COMMUNICATION_WITH_AUTHORITIES_KEY,
    name: 'Communication With Authorities',
    component: CommunicationWithAuthorities,
  },
  {
    key: UPCOMING_RESERVATION_KEY,
    name: 'Upcoming Reservation',
    component: UpcomingReservation,
  },
];

const getSteps = () => getStepName.map((v, k) => k);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class PersonCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.account.completedStep - 3 || 0,
      formData: {},
      [`${HOST_DUTIES_KEY}Title`]: null,
    };
    console.log('HERE');
  }

  handleNext = (nextStep = 1) => {
    const { activeStep } = this.state;
    // console.log(formData);
    this.props.nextStep(nextStep);
    this.setState({ activeStep: activeStep + nextStep });
  };

  handleBack = () => {
    let backStep = 1;
    let { formData, activeStep } = this.state;
    if (activeStep === 0) {
      this.props.history(LOGIN_DETAIL);
    } else {
      let getActiveKeyName = getStepName[activeStep].key;
      // let checkHasAirBooking = formData && formData[CONNECT_ACCOUNT_KEY] && (formData[CONNECT_ACCOUNT_KEY][AIRBNB] && formData[CONNECT_ACCOUNT_KEY][BOOKING]);
      let checkHasAirBooking =
        formData &&
        formData[CONNECT_ACCOUNT_KEY] &&
        ((!formData[CONNECT_ACCOUNT_KEY][AIRBNB] &&
          formData[CONNECT_ACCOUNT_KEY][BOOKING]) ||
          (!formData[CONNECT_ACCOUNT_KEY][HASAIRBNB] &&
            formData[CONNECT_ACCOUNT_KEY][HASBOOKING]));
      console.log(checkHasAirBooking);
      if (
        formData &&
        getActiveKeyName === BILLING_INFO_KEY &&
        formData[CONNECT_ACCOUNT_KEY] &&
        checkHasAirBooking
      ) {
        backStep = 2;
      }
      this.props.previousStep(backStep);
      this.setState((state) => ({
        activeStep: state.activeStep - backStep,
      }));
    }
  };

  handleSubmit = async (values) => {
    let { formData, activeStep } = this.state;
    let getActiveKeyName = getStepName[activeStep].key;
    formData[getActiveKeyName] = values;
    let checkHasAirBooking =
      formData &&
      formData[getActiveKeyName] &&
      ((!formData[getActiveKeyName][AIRBNB] &&
        formData[getActiveKeyName][BOOKING]) ||
        (!formData[getActiveKeyName][HASAIRBNB] &&
          formData[getActiveKeyName][HASBOOKING]));
    this.setState({ formData });
    if (this.state.activeStep === getSteps().length - 1) {
      this.props.nextStep();
      this.props.history('/dashboard');
      return;
    }
    if (
      formData &&
      getActiveKeyName === CONNECT_ACCOUNT_KEY &&
      formData[getActiveKeyName] &&
      checkHasAirBooking
    ) {
      this.handleNext(2);
      return;
    }
    this.handleNext();
  };

  render() {
    const { classes, t } = this.props;
    const steps = getSteps();
    const customSteps = steps.filter(
      (v, k) => v >= hiddenStepCount && v - hiddenStepCount,
    );
    const { activeStep, formData } = this.state;
    if (activeStep > 11) return null;

    const _getStepNameActiveStep = getStepName[activeStep];
    if (!_getStepNameActiveStep) {
      return null;
    }
    const Component = _getStepNameActiveStep.component;
    const getActiveKeyName = _getStepNameActiveStep.key;

    const HOST_DUTIES_TITLE =
      _getStepNameActiveStep.key === HOST_DUTIES_KEY
        ? this.state[`${HOST_DUTIES_KEY}Title`]
        : '';

    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs>
            <div>
              {_getStepNameActiveStep.key !== WELCOMERS_KEY && (
                <TitleHasUnderLine
                  title={`${t(_getStepNameActiveStep.name)} ${
                    HOST_DUTIES_TITLE || ''
                  }`}
                  size="h4"
                  color="default"
                  borderWidth={3}
                  marginBottom={30}
                  paddingBottom={10}
                />
              )}
              <Component
                handleSubmit={this.handleSubmit}
                formData={formData}
                stepKey={getActiveKeyName}
                activeStep={activeStep}
                handleBack={this.handleBack}
                {...(_getStepNameActiveStep.key === HOST_DUTIES_KEY && {
                  setTitle: (title) =>
                    this.setState({ [`${HOST_DUTIES_KEY}Title`]: title }),
                })}
              />
            </div>
            {activeStep >= hiddenStepCount && (
              <StepperCustom
                activeStep={activeStep - hiddenStepCount}
                steps={customSteps}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

PersonCompany.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.account.detail,
});

export default withStyles(styles)(
  withTranslation()(
    connect(mapStateToProps, { nextStep, previousStep })(PersonCompany),
  ),
);
