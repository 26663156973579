/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useContext, useState } from 'react';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Grid, Paper, Hidden, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { accomodationDetailStyle } from '../components/style';
import { openModal } from '../../../shared/actions';
import ReservationForm from '../../reservation/containers/ReservationForm';
import { Link, useNavigate } from 'react-router-dom';
import CustomLink from '../../../common/CustomLink';
import styled from 'styled-components';
import StyledButtonLink from '../../../styles/StyledButtonLink';
import Listings from './Listings';
import UpdateProperty from './UpdateProperty';
import ListingForm from '../components/ListingForm';
import {
  getPropertyDetail,
  deleteAccommodation,
} from '../../../actions/property';
import { getRuianAddress } from '../../../actions/global';
import { selectClientInfo } from '../../../selectors/client-selectors';
import { selectPropertyDetail } from '../../../selectors/property-selectors';
import { GlobalDrawerContext } from '../../../contexts';
import { OPEN_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import GlobalDrawer from '../../../components/GlobalDrawer';
import DialogConfirm from '../../../common/DialogConfirm';
import { includes } from 'lodash';
import { selecCountriesList } from '../../../selectors/countries-selector';
import { useTranslation } from 'react-i18next';
import BoxHasIcon from '../../../common/BoxHasIcon';
import AddressIcon from '../../../styles/property-detail/Address.png';
import PersonIcon from '../../../styles/property-detail/Person.png';
import SymbolIcon from '../../../styles/property-detail/Variable_symbol.png';
import AccommodationIcon from '../../../styles/property-detail/Accommodation_capacity.png';
import PropertyIcon from '../../../styles/property-detail/Property.png';
import ListingIcon from '../../../styles/property-detail/Listings.png';
import TitleHasIcon from '../../../common/TitleHasIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { addressView } from '../../../helpers/index';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import CusTooltip from '../../../components/CusTooltip';
import {
  residentialClientIdTooltip,
  localFeeIdTooltip,
  welcomerNameTooltip,
} from '../../../components/generateFields';
import ImgDefault from '../../../styles/image/Icon_white.jpg';

const ButtonBox = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #e6ecf1;
  transition: border ease 200ms;
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
  text-decoration: none;
  cursor: pointer;
  color: #f16d36;
  text-align: center;
`;

function renderDrawerChildren(drawerName, property, data) {
  switch (drawerName) {
    case 'create_reservation':
      return <ReservationForm property={property} />;
    case 'update_property':
      return <UpdateProperty property={property} />;
    case 'create_listing':
      return <ListingForm property={property} />;
    case 'edit_listing':
      return (
        <ListingForm currentListing={data} property={property} isUpdating />
      );
    default:
      break;
  }
}

const PropertyDetail = ({
  classes,
  id,
  getPropertyDetail,
  deleteAccommodation,
  propertyReducer,
}) => {
  const history = useNavigate();
  const { state, dispatch } = useContext(GlobalDrawerContext);
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    getPropertyDetail({ accommodationId: id });
  }, []);

  const property = propertyReducer.data;

  const loading = propertyReducer.loading;
  const isOpenDrawer =
    state.isOpen &&
    includes(
      [
        'create_reservation',
        'update_property',
        'create_listing',
        'edit_listing',
      ],
      state.drawerName,
    );

  const hasReservation = property.hasReservation;

  const handleDelete = () => {
    if (!hasReservation) {
      setLoadingDelete(true);
      deleteAccommodation({ accommodationId: id }).then(() => {
        setTimeout(() => {
          setOpenDelete(false);
          setLoadingDelete(false);
          history('/properties');
        }, 1000);
      });
    }
  };

  const styles = {
    colorTitle: {
      color: '#b16c05',
      display: 'flex',
      alignItems: 'center',
    },
    infoIcon: {
      width: 17,
      marginLeft: 10,
    },
    buttonDesktop: {
      marginRight: 8,
      width: 'calc(50% - 8px)',
    },
    buttonMobile: {
      width: '100%',
      marginBottom: 15,
    },
    size: {
      fontSize: '0.875rem',
    },
  };

  function renderTopContent() {
    return (
      <div>
        <Grid container direction="row">
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginBottom: 25 }}>
            <div style={styles.colorTitle}>{t('Property address')}</div>
            <BoxHasIcon width={14} image={AddressIcon}>
              <div style={styles.size}>{addressView(property)}</div>
            </BoxHasIcon>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginBottom: 25 }}>
            <div style={styles.colorTitle}>
              {t('Variable symbols')}{' '}
              <CusTooltip title={localFeeIdTooltip(t)} />
            </div>
            <BoxHasIcon width={16} image={SymbolIcon}>
              <div style={styles.size}>
                <div>
                  {t('Spa and recreation fee')}: {property.localFeeId}
                </div>
                {property.payingCapacityFee === 1 && (
                  <div>
                    {t('Accommodation capacity fee')}:{' '}
                    {property.accommodationFeeId}{' '}
                  </div>
                )}
              </div>
            </BoxHasIcon>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginBottom: 25 }}>
            <div style={styles.colorTitle}>
              {t('Accommodation provider')}{' '}
              <CusTooltip title={residentialClientIdTooltip(t)} />
            </div>
            <BoxHasIcon width={14} image={PersonIcon}>
              <div style={styles.size}>
                {/* Client Name: {clientInfo.clientName} */}
                {property.residentialClientName}
              </div>
            </BoxHasIcon>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginBottom: 25 }}>
            <div style={styles.colorTitle}>{t('Accommodation capacity')}</div>
            <BoxHasIcon width={21} image={AccommodationIcon}>
              <div style={styles.size}>
                {property.numberOfBeds} {t('guest(s)')}
              </div>
            </BoxHasIcon>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ marginBottom: 25 }}>
            <div style={styles.colorTitle}>
              {t('Welcomer')} <CusTooltip title={welcomerNameTooltip(t)} />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
              }}
            >
              <img
                src={property.welcomerUrl || ImgDefault}
                style={{
                  width: 36,
                  height: 36,
                  borderRadius: '50%',
                  marginRight: 15,
                }}
              />
              {property.welcomerName || 'unknown'}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  function renderBottomContent(style) {
    return (
      <div>
        <Listings
          property={property}
          loading={loading}
          openListingDetail={(data) =>
            dispatch({ type: OPEN_DRAWER, drawerName: 'edit_listing', data })
          }
        />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <ButtonBox style={style}>
            <Link to="guestbook">
              <CustomLink
                color={'#f16d36'}
                label={t('Guest Book')}
                classes={classes.button}
                loading={loading}
              />
            </Link>
          </ButtonBox>
          <ButtonBox style={style}>
            <Link to="local-fees">
              <CustomLink
                color={'#f16d36'}
                label={t('Local fees')}
                classes={classes.button}
                loading={loading}
              />
            </Link>
          </ButtonBox>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Hidden xsDown implementation="css">
        <Paper style={{ padding: 20, marginBottom: 20 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TitleHasIcon
              variant="h5"
              title={property.accommodationName}
              label={`${t('Property ID')}: ${property.accommodationId || '_'}`}
              isImage={true}
              icon={PropertyIcon}
            />
            <div style={{ display: 'flex' }}>
              <StyledButtonLink bold>
                <Button
                  style={{ paddingLeft: 8, paddingRight: 8 }}
                  color={'primary'}
                  onClick={() =>
                    dispatch({
                      type: OPEN_DRAWER,
                      drawerName: 'update_property',
                    })
                  }
                >
                  <EditIcon className="icon-left" />
                  <span className="text">{t('Edit')}</span>
                </Button>
                <ButtonWithTooltip
                  show={hasReservation}
                  title={`${t(
                    "You can't delete this property because there exists some reservations connected with this property",
                  )}. ${t(
                    'Please contact us if you want to delete your Guest Book',
                  )}`}
                >
                  <Button
                    color={'primary'}
                    onClick={() => setOpenDelete(true)}
                    disabled={hasReservation}
                  >
                    <DeleteIcon className="icon-left" />
                    <span className="text">{t('Delete')}</span>
                  </Button>
                </ButtonWithTooltip>
              </StyledButtonLink>
            </div>
          </div>
          {renderTopContent()}
        </Paper>
        <Paper style={{ padding: 20, marginBottom: 20 }}>
          <TitleHasIcon
            variant="h5"
            title={t('Listings')}
            label={t(`Listings are accommodation offers inside the property`)}
            isImage={true}
            icon={ListingIcon}
            actionButton={t('Add listing')}
            handleAction={() =>
              dispatch({ type: OPEN_DRAWER, drawerName: 'create_listing' })
            }
          />
          {renderBottomContent(styles.buttonDesktop)}
        </Paper>
      </Hidden>
      {/* Mobile*/}
      <Hidden smUp implementation="css">
        <TitleHasIcon
          variant="h5"
          title={property.accommodationName}
          label={`${t('Property ID')}: ${property.accommodationId || '_'}`}
          isImage={true}
          icon={PropertyIcon}
        />
        <Paper style={{ padding: 20, marginBottom: 20, width: '100%' }}>
          <div style={{ display: 'flex', marginBottom: 20, marginLeft: -15 }}>
            <StyledButtonLink bold style={{ marginLeft: 0 }}>
              <Button
                color={'primary'}
                onClick={() =>
                  dispatch({ type: OPEN_DRAWER, drawerName: 'update_property' })
                }
              >
                <EditIcon className="icon-left" />
                <span className="text">{t('Edit')}</span>
              </Button>

              <ButtonWithTooltip
                show={property.hasReservation}
                title={`You can't delete this property because there exists some reservations connected with this property. Please contact us if you want to delete your Guest Book.`}
              >
                <Button
                  color={'primary'}
                  onClick={() => setOpenDelete(true)}
                  disabled={hasReservation}
                >
                  <DeleteIcon className="icon-left" />
                  <span className="text">{t('Delete')}</span>
                </Button>
              </ButtonWithTooltip>
            </StyledButtonLink>
          </div>
          {renderTopContent()}
        </Paper>
        <TitleHasIcon
          variant="h5"
          title={t('Listings')}
          label={t(`Listings are accommodation offers inside the property`)}
          isImage={true}
          icon={ListingIcon}
          actionButton={t('Add listing')}
          handleAction={() =>
            dispatch({ type: OPEN_DRAWER, drawerName: 'create_listing' })
          }
        />
        {/* {renderBottomContent(styles.buttonDesktop)} */}
        {renderBottomContent(styles.buttonMobile)}
      </Hidden>
      <GlobalDrawer isOpen={isOpenDrawer}>
        {isOpenDrawer
          ? renderDrawerChildren(state.drawerName, property, state.data)
          : null}
      </GlobalDrawer>

      {/* open Delete Reservation */}
      {openDelete && (
        <DialogConfirm
          isOpen={openDelete}
          title={t('Deletion')}
          message={`${t(
            'Are you sure that you want to delete this property',
          )}?`}
          handleClose={() => setOpenDelete(false)}
          handleAccess={handleDelete}
          isLoadding={loadingDelete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientInfoState: selectClientInfo(state),
  propertyReducer: selectPropertyDetail(state),
  countriesList: selecCountriesList(state),
  residentialClients: state.client.residentialClients,
});

export default connect(mapStateToProps, {
  ...actions,
  openModal,
  getPropertyDetail,
  deleteAccommodation,
  getRuianAddress,
})(withStyles(accomodationDetailStyle)(PropertyDetail));
