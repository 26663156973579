import { URL_GET_COUNTRIES, URL_GET_VISA_COUNTRY } from '../shared/urls';
import { makeRequest } from '../redux/apiCall';
// import countries from '../shared/const/countries';
import visaList from '../shared/const/visaCountry';

export const GET_COUNTRIES_LIST = 'get_countries_list';
export const GET_COUNTRIES_LIST_SUCCESS = 'get_countries_list_success';
export const GET_COUNTRIES_LIST_FAILURE = 'get_countries_list_failure';

export const GET_VISA_COUNTRY_LIST = 'get_visa_country_list';
export const GET_VISA_COUNTRY_LIST_SUCCESS = 'get_visa_country_list_success';
export const GET_VISA_COUNTRY_LIST_FAILURE = 'get_visa_country_list_failure';

// const fakeGettingCountries = ms =>
//   new Promise((resolve, reject) => {
//     setTimeout(() => {
//       if (countries) {
//         resolve(countries);
//       } else {
//         reject({ error: 'not found countries' });
//       }
//     }, ms);
//   });

// export const getCountries = () => async dispatch => {
//   dispatch({ type: URL_GET_COUNTRIES });
//   const res = await fakeGettingCountries(500);
//   dispatch({ type: GET_COUNTRIES_LIST_SUCCESS, res });
// };

export const getCountries = (params) =>
  makeRequest(
    GET_COUNTRIES_LIST,
    'get',
    URL_GET_COUNTRIES,
    params,
    getCountries,
  );

const fakeGettingVisaCountry = ms =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (visaList) {
        resolve(visaList);
      } else {
        reject({ error: 'not found visaList' });
      }
    }, ms);
  });

export const getVisaCountry = () => async dispatch => {
  dispatch({ type: URL_GET_VISA_COUNTRY });
  const res = await fakeGettingVisaCountry(500);
  dispatch({ type: GET_VISA_COUNTRY_LIST_SUCCESS, res });
};


// export const getCountries = () =>
//   makeRequest(GET_COUNTRIES_LIST, 'get', URL_GET_COUNTRIES, null, getCountries);
