import styled from 'styled-components';

const StyledFormWrapper = styled.div`
  padding: 20px;
  @media (min-width: 769px) {
    width: 720px;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
  @media (max-width: 576px) {
    width: 100vw;
  }
`;

export default StyledFormWrapper;
