import React from 'react';
import UserFormWithPassword from '../components/UserFormWithPassword';
import { UserFormType } from '../model';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { login } from '../actions';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  user_or_email: Yup.string()
    // .email('Invalid email')
    .required('Required'),
});

class LoginWithPassword extends React.Component {
  handleLogin = (value) => {
    this.props.login(value);
  };

  render() {
    const initialValues = {
      user_or_email: '',
      password: '',
    };

    return (
      <UserFormWithPassword
        onSubmit={this.handleLogin}
        formName={UserFormType.LOGIN}
        initialValues={initialValues}
        validation={LoginSchema}
        history={this.props.history}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { login })(LoginWithPassword);
