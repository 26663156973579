import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations_cs from './locales/cs/translations.json';
import translations_en from './locales/en/translations.json';

export const languageList = ['en-US', 'cs-CZ'];

const resources = {
  [languageList[0]]: {
    translations: translations_en,
  },
  [languageList[1]]: {
    translations: translations_cs,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languageList[0],
    appendNamespaceToCIMode: true,
    appendNamespaceToMissingKey: true,
    saveMissing: true,
    updateMissing: true,
    resources: resources,
    ns: ['translations', 'Note'],
    defaultNS: 'translations',
    react: {
      wait: true,
    },
  });

export default i18n;
