import React, { useContext, useState } from 'react';
import { omit } from 'lodash';
import PropertyForm from '../components/PropertyForm';
import { connect } from 'react-redux';
import StyledFormWrapper from '../../../components/property/StyledFormWrapper';
import { addProperty, getProperties } from '../../../actions/property';
import { GlobalDrawerContext } from '../../../contexts';
import { addressToValue } from '../../../helpers';
import { CLOSE_DRAWER } from '../../../custom-hooks/useGlobalDrawer';

const CreateProperty = ({ addProperty, getProperties }) => {
  const { dispatch } = useContext(GlobalDrawerContext);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  async function handleSubmit(values, { setErrors }) {
    setLoading(true);

    let params = omit(values, [
      'count',
      'prefix',
      'startNumber',
      'isSwitched',
      'coverImage',
      'address',
      'address_obj',
      'Switch_LocalFee',
      // 'residentialClientId'
    ]);

    params = {
      ...params,
      payingCapacityFee: values.Switch_LocalFee ? '1' : '0',
      // rentingFrom: format(params.rentingFrom, 'yyyy-MM-dd'),
      ...(values.address_obj && addressToValue(values.address_obj, false)),
    };

    if (!values.isSwitched) {
      params = {
        ...params,
        listingsPattern: {
          count: values.count,
          prefix: values.prefix,
          startNumber: values.startNumber,
        },
      };
    }

    await addProperty(params).then((res) => {
      setLoading(false);
      if (res.data && res.result) {
        setIsSuccess(true);
        setTimeout(() => {
          getProperties();
          dispatch({ type: CLOSE_DRAWER, drawerName: 'create_property' });
        }, 1000);
      } else {
        res.errors.length > 0 &&
          res.errors[0].note &&
          setErrors(res.errors[0].note);
      }
    });
  }

  const initialValues = {
    accommodationName: '',
    // welcomerName: '',
    welcomerId: '',
    specialCode: '',
    district: '',
    numberOfBeds: '',
    // rentingFrom: new Date(),
    address: '',
    city: '',
    cityDistrict: '',
    street: '',
    registerNumber: '',
    orientationNumber: '',
    residentialClientId: '',
    postalNumber: '',
    count: 1,
    prefix: 'Room',
    startNumber: 1,
    localFeeId: '',
    accommodationFeeId: '',
    isSwitched: false,
    country: '',
    coverImage: null,
    ubyUsername: '',
    ubyPassword: '',
    ubyClientContact: '',
    ubyIDUB: '',
    ubyClientName: '',
    ubyClientNameShort: '',
  };
  return (
    <StyledFormWrapper>
      <PropertyForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        loading={loading}
        isSuccess={isSuccess}
      />
    </StyledFormWrapper>
  );
};

export default connect(null, { addProperty, getProperties })(CreateProperty);
