/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { loginStyles } from './style';
import { Typography, Grid, Paper, Divider, Icon, Button } from '@mui/material';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import { withStyles } from '@mui/styles';

class RegisterSuccess extends React.Component {
  render() {
    const { t, classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className={classes.formLayout}
      >
        <Grid item xs={12} md={3}>
          <Paper className={classes.formWrapper}>
            <div className={classes.formGroup}>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid item xs={12} md={6}>
                  <img src={logo} className={classes.logo} />
                </Grid>
              </Grid>

              <div
                className={classes.formGroup}
                style={{ textAlign: 'center' }}
              >
                <Typography
                  variant={'h5'}
                  color={'secondary'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon fontSize="large" style={{ marginRight: 15 }}>
                    check_circle_outline
                  </Icon>{' '}
                  {t('Sign up was successful')}!
                </Typography>
                <div style={{ padding: 6 }} />
                <Typography variant={'h6'}>
                  {t('Please check your email to activate your account')}
                </Typography>
                <Divider style={{ margin: '30px 0' }} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.props.history('/login')}
                >
                  {t('Login')}
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
export default withTranslation()(withStyles(loginStyles)(RegisterSuccess));
