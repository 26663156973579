import createReducer from '../../../redux/createReducer';
import {
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
  CREATE_RESIDENTIAL_CLIENT_FAILURE,
  DELETE_RESIDENTIAL_CLIENT_SUCCESS,
  GET_RESIDENTIAL_CLIENTS,
  GET_RESIDENTIAL_CLIENTS_SUCCESS,
} from '../actionTypes';
import { produce } from 'immer';

const initialState = {
  data: [],
  isUpdatedErrorMsg: null,
  loading: false,
};

export default createReducer(initialState, {
  [GET_RESIDENTIAL_CLIENTS]: (state) =>
    produce(state, (draftState) => {
      draftState.loading = true;
      draftState.isUpdatedErrorMsg = null;
    }),
  [GET_RESIDENTIAL_CLIENTS_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.data = action.res;
      draftState.isUpdatedErrorMsg = null;
    }),
  [CREATE_RESIDENTIAL_CLIENT_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.data.push(action.res);
      draftState.isUpdatedErrorMsg = null;
    }),
  [CREATE_RESIDENTIAL_CLIENT_FAILURE]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.isUpdatedErrorMsg = action.res.msg;
    }),
  [DELETE_RESIDENTIAL_CLIENT_SUCCESS]: (state, action) =>
    produce(state, (draftState) => {
      draftState.loading = false;
      draftState.data = state.data.filter(
        (client) =>
          client.residentialClientId !== action.res.residentialClientId,
      );
      draftState.isUpdatedErrorMsg = null;
    }),
});
