export const ReservationUrls = {
  CREATE_RESERVATIONS: '/reservations/createReservation',
  GET_RESERVATIONS: '/reservations/getReservations',
  CREATE_CHECK_IN_FORM: '/reservations/submitCheckinForm',
};

export const GET_COUNTRIES_URL = 'staticData/getCountries';
export const EXPORT_GUEST_BOOK_URL = 'guestBook/getGuestBook';

// export const URL_GET_COUNTRIES = 'staticData/getCountries';
export const URL_GET_COUNTRIES = 'staticData/getRestCountries';
export const URL_GET_VISA_COUNTRY = 'staticData/getVisaCountry';
export const URL_GET_PASSPORT_DATA = 'staticData/getPassportData';

export const AccommodationUrl = {
  CREATE_NEW_LISTING: 'listings/createListing',
  GET_LISTING_BY_ACCOMMODATION: 'listings/getAccommodationListings',
  UPDATE_ACCOMMODATION_URL: 'accommodations/editAccommodation',
};

export const AccountUrls = {
  LOG_OUT: 'user/signOut',
  LOG_IN: 'user/signIn',
};

export const ubyportUrls = {
  GET_CLIENT_DETAILS_URL: 'client/getClientDetails',
  INSERT_UBY_PORT_URL: 'client/insertUbyportLoginData',
  UPDATE_UBY_PORT_URL: 'client/editUbyportLoginData',
};

export const URL_RESERVATIONS = 'reservations';
export const URL_ACCOMMODATIONS = 'accommodations';
export const URL_LISTINGS = 'listings';
export const URL_PROPERTIES = 'accommodations';
export const URL_CLIENT = 'client';
export const URL_NOTIFICATION = 'notifications';
export const URL_WELCOMERS = 'welcomers';
export const URL_TAG_SUGGESTION = 'tagSuggestions';
export const URL_INVOICE = 'serviceInvoice';
export const URL_LOCAL_FEES = 'localFees';
export const URL_CAPACITY_FEES = 'capacityFee';
export const URL_AIRBNB = 'airbnb';
export const URL_REFERRAL = 'promotion';

export const ProfileInfoUrl = {
  CREATE_RESIDENTIAL_CLIENT_URL: `${URL_CLIENT}/createResidentialClient`,
  UPDATE_RESIDENTIAL_CLIENT_URL: `${URL_CLIENT}/updateResidentialClient`,
  GET_RESIDENTIAL_CLIENTS_URL: `${URL_CLIENT}/getResidentialClients`,
  GET_CLIENT_INFO_URL: `${URL_CLIENT}/getClientInfo`,
  UPDATE_CLIENT_INFO_URL: `${URL_CLIENT}/updateClientInfo`,
  DELETE_CLIENT_INFO_URL: `${URL_CLIENT}/deleteResidentialClient`,
};

export const URL_INVOICE_GET_TIMES = `${URL_INVOICE}/getTimes`;
export const URL_INVOICE_GET_INVOICE = `${URL_INVOICE}/getInvoice`;

export const URL_GET_SUGGESTIONS = `${URL_TAG_SUGGESTION}/getSuggestions`;
export const URL_GET_RUIAN_ADDRESS = `ruianAddress/getRuianAddress`;

export const URL_CLIENT_GET_INFO = `${URL_CLIENT}/getClientInfo`;
export const URL_CLIENT_GET_RESIDENTIAL_CLIENTS = `${URL_CLIENT}/getResidentialClients`;
export const URL_CLIENT_INTEGRATON = `${URL_CLIENT}/addClientIntegration`;
export const URL_GET_CLIENT_SIGNATURE = `${URL_CLIENT}/getClientSignature`;
export const URL_UPDATE_CLIENT_INTEGRATION = `${URL_CLIENT}/updateClientIntegration`;

export const URL_ACCOMMODATIONS_ADD_NEW = `${URL_ACCOMMODATIONS}/addAccommodation`;

export const URL_RESERVATIONS_GET_ALL = `${URL_RESERVATIONS}/getReservations`;
export const URL_RESERVATIONS_GET_DETAIL = `${URL_RESERVATIONS}/getReservationDetail`;
export const URL_RESERVATIONS_CREATE = `${URL_RESERVATIONS}/createReservation`;
export const URL_RESERVATIONS_CHECK_IN_FORM = `${URL_RESERVATIONS}/submitCheckinForm`;
export const URL_RESERVATIONS_UPDATE = `${URL_RESERVATIONS}/updateReservation`;
export const URL_SHARE_RESERVATION = `${URL_RESERVATIONS}/shareReservation`;
export const URL_UPDATE_CHECK_IN_FORM = `${URL_RESERVATIONS}/changeReservationData`;
export const URL_GET_CHECK_IN_FORM = `${URL_RESERVATIONS}/getCheckinForm`;
export const URL_GET_CHECKIN_FORM_SIGNATURE = `${URL_RESERVATIONS}/getCheckinFormSignature`;
export const URL_ADD_MASTER_GUEST = `${URL_RESERVATIONS}/addMasterGuest`;

export const URL_LISTINGS_CREATE = `${URL_LISTINGS}/createListing`;
export const URL_LISTINGS_GET_PROPERTY = `${URL_LISTINGS}/getAccommodationListings`;
export const URL_UPDATE_LISTING = `${URL_LISTINGS}/updateListing`;
export const URL_DELETE_LISTING = `${URL_LISTINGS}/deleteListing`;
export const URL_GET_LISTING_DETAIL = `${URL_LISTINGS}/getListingDetail`;
export const URL_ADD_ICAL_LINK = `${URL_LISTINGS}/addIcalLink`;
export const URL_UPDATE_ICAL_LINK = `${URL_LISTINGS}/updateIcalLink`;

export const URL_GET_WELCOMERS = `${URL_WELCOMERS}/getWelcomers`;
export const URL_GET_WELCOMERS_SELECT = `${URL_WELCOMERS}/getWelcomersSelect`;
export const URL_GET_WELCOMERS_ROLES = `${URL_WELCOMERS}/getWelcomersRoles`;
export const URL_DELETE_WELCOMERS = `${URL_WELCOMERS}/deleteWelcomer`;
export const URL_CREATE_WELCOMERS = `${URL_WELCOMERS}/createWelcomer`;
export const URL_UPDATE_WELCOMERS = `${URL_WELCOMERS}/updateWelcomer`;

export const URL_PROPERTIES_GET_ONE = `${URL_PROPERTIES}/getAccommodation`;
export const URL_PROPERTIES_GET_ALL = `${URL_PROPERTIES}/getAccommodations`;
export const URL_UPDATE_PROPERTY = `${URL_PROPERTIES}/editAccommodation`;
export const URL_DELETE_PROPERTY = `${URL_PROPERTIES}/deleteAccommodation`;
export const URL_PROPERTIES_HOST_DUTIES = `${URL_PROPERTIES}/getAccommodationHostDuties`;

export const URL_AIRBNB_GET_RESERVATIONS = `${URL_AIRBNB}/getReservations`;
export const URL_AIRBNB_GET_LISTINGS = `${URL_AIRBNB}/getListings`;

export const URL_CHANGE_PASSWORD = 'user/changePassword';
export const URL_GUEST_BOOK_EXPORT = 'guestBook/getGuestBook';
export const URL_REGISTRATION_BOOK_EXPORT =
  'registrationBook/getRegistrationBook';

export const URL_GET_LOCAL_FEES = `${URL_LOCAL_FEES}/getLocalFees`;
export const URL_LOCAL_FEE_GET_TIMES = `${URL_LOCAL_FEES}/getTimes`;
export const URL_LOCAL_FEE_AUTHORIZATION_PDF = `${URL_LOCAL_FEES}/authorizationPdf`;
export const URL_REGISTER_LOCAL_FEES = `${URL_LOCAL_FEES}/registerLocalFees`;

export const URL_GET_CAPACITY_FEES = `${URL_CAPACITY_FEES}/getCapacityFees`;
export const URL_CAPACITY_FEE_GET_TIMES = `${URL_CAPACITY_FEES}/getTimes`;
export const URL_REGISTER_CAPACITY_FEES = `${URL_CAPACITY_FEES}/registerCapacityFees`;

export const URL_LISTINGS_GET_BY_PROPERTY_ID = `${URL_LISTINGS}/getListingsSelect`;
export const URL_UPDATE_NOTIFY_BASE = `${URL_NOTIFICATION}/updateNotificationBaseSettings`;
export const URL_UPDATE_NOTIFY_SETTING = `${URL_NOTIFICATION}/updateNotificationSettings`;
export const URL_ADD_NOTIFY_CONTACT = `${URL_NOTIFICATION}/addNotificationsContacts`;
export const URL_EDIT_NOTIFY_CONTACT = `${URL_NOTIFICATION}/editNotificationsContacts`;
export const URL_GET_NOTIFICATION_SETTING = `${URL_NOTIFICATION}/getNotificationsSettings`;
export const URL_DELETE_NOTIFICATION_CONTACT = `${URL_NOTIFICATION}/deleteNotificationsContacts`;
export const URL_DELETE_RESERVATION = `${URL_RESERVATIONS}/deleteReservation`;
export const URL_DELETE_INVITATION = `${URL_RESERVATIONS}/deleteInvitation`;
export const URL_DELETE_CHECK_IN_FORM = `${URL_RESERVATIONS}/removeCheckinForm`;

export const URL_RESERVATION_TO_POLICE = `${URL_RESERVATIONS}/reservations/sendReservationToPolice`;

//  Onboarding

export const URL_ONBOARDING_USER = 'user';

export const URL_SIGN_UP_WITH_EMAIL = `${URL_ONBOARDING_USER}/signupWithEmail`;
export const URL_RESET_PASSWORD_EMAIL = `${URL_ONBOARDING_USER}/resetPasswordEmail`;
export const URL_EMAIL_VERIFY = `${URL_ONBOARDING_USER}/emailVerify`;
export const URL_ADD_USER_INFO = `${URL_ONBOARDING_USER}/addUserInfo`;
export const URL_VERIFY_OTP = `${URL_ONBOARDING_USER}/verifyOtp`;
export const URL_VERIFY_PASSWORD_OTP = `${URL_ONBOARDING_USER}/verifyPasswordOtp`;
export const URL_ADD_AUTH_INFO = `${URL_ONBOARDING_USER}/addAuthInfo`;
export const URL_UPDATE_USER_INFO = `${URL_ONBOARDING_USER}/updateUserInfo`;
export const URL_COMPLETE_ONBOARDING = `${URL_ONBOARDING_USER}/completeOnboarding`;
export const URL_GET_LISTING_FOR_MAPPING = `${URL_ACCOMMODATIONS}/getListingForMapping`;
export const URL_MAP_LISTINGS = `${URL_ACCOMMODATIONS}/mapListings`;
export const URL_ONBOARDING_SEND_ALERTS_TO_UPCOMING_RESERVATION = `${URL_RESERVATIONS}/sendAlertsToUpcomingReservation`;

export const URL_BOOKING_VERIFY_SMS_CODE = `booking/verifySMSCode`;

//automation

export const URL_MESSAGE_AUTOMATION = 'messageAutomation';

export const URL_GET_MESSAGE = `${URL_MESSAGE_AUTOMATION}/getMessages`;
export const URL_SAVE_MESSAGE = `${URL_MESSAGE_AUTOMATION}/saveMessage`;

//referral
export const URL_REFERRAL_GET_LINK = `${URL_REFERRAL}/getLinkReferral`;
export const URL_REFERRAL_SEND_INVITE = `${URL_REFERRAL}/sentInvitation`;
export const URL_REFERRAL_LIST_INVITE_USER = `${URL_REFERRAL}/getInvitation`;
