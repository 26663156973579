import React from 'react';
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { objectByString } from '../helpers';

const SelectMui = ({
  field,
  options,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth style={{ marginTop: 8 }}>
      <InputLabel htmlFor="age-simple">{t(props.label)}</InputLabel>
      <Select
        fullWidth
        disabled={!!props.disabled}
        label={t(props.label)}
        placeholder={t(props.placeholder)}
        value={field.value || ''}
        onChange={(e) => setFieldValue(field.name, e.target.value || '')}
      >
        {isEmpty(options)
          ? null
          : options.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                disabled={item.disabled || false}
              >
                {props.showImg && (
                  <img src={item.img} style={{ height: 20, marginRight: 15 }} />
                )}
                {item.label}
              </MenuItem>
            ))}
      </Select>
      {objectByString(touched, field.name) &&
        objectByString(errors, field.name) && (
          <FormHelperText error>
            {t(objectByString(errors, field.name))}
          </FormHelperText>
        )}
    </FormControl>
  );
};

export default SelectMui;
