import React, { Component } from 'react';
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { loginStyles } from './style';
import { Form, Formik, Field } from 'formik';
import FormInput from '../../../common/input/index';
import * as Yup from 'yup';
import { confirmEmail } from '../actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import logo from '../../../styles/image/transparent__dark_text_logo.png';

const validation = Yup.object().shape({
  loginName: Yup.string().email('Invalid email').required('Required'),
});

class ForgotPassword extends Component {
  state = {
    email: '',
    loading: false,
    message: '',
  };
  handleSubmit = (data) => {
    this.props.confirmEmail(data);
  };
  render() {
    const { classes, t } = this.props;
    return (
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        className={classes.formLayout}
      >
        <Grid item xs={12} md={3}>
          <Paper className={classes.formWrapper}>
            <div className={classes.formGroup}>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={logo}
                    className={classes.logo}
                    style={window.innerWidth < 500 ? { width: '67%' } : {}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    className={classes.title}
                    variant="h6"
                    gutterBottom
                  >
                    {t('Forgot Password')}
                  </Typography>
                </Grid>
              </Grid>

              <Formik
                initialValues={{
                  loginName: '',
                }}
                validationSchema={validation}
                onSubmit={this.handleSubmit}
              >
                <Form>
                  <Field
                    name="loginName"
                    label={t('Your Email')}
                    placeholder={t('Your Email')}
                    iconName="person"
                    component={FormInput}
                  />
                  <Typography
                    className={classes.title}
                    variant="body1"
                    gutterBottom
                  >
                    {this.state.message}
                  </Typography>
                  <div align="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.margin}
                    >
                      {t('Recover password')}
                      {this.state.loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              </Formik>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({});

export default withTranslation()(
  connect(mapStateToProps, { confirmEmail })(
    withStyles(loginStyles)(ForgotPassword),
  ),
);
