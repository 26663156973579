import { makeRequest } from '../redux/apiCall';
import {
    URL_INVOICE_GET_TIMES,
    URL_INVOICE_GET_INVOICE,
} from '../shared/urls';

export const INVOICE_GET_INVOICE = 'invoice_get_invoice';
export const INVOICE_GET_INVOICE_SUCCESS = 'invoice_get_invoice_success';
export const INVOICE_GET_INVOICE_FAILURE = 'invoice_get_invoice_failure';

export const INVOICE_GET_TIMES = 'invoice_get_times';
export const INVOICE_GET_TIMES_SUCCESS = 'invoice_get_times_success';
export const INVOICE_GET_TIMES_FAILURE = 'invoice_get_times_failure';

export const getTimes = params =>
    makeRequest(
        INVOICE_GET_TIMES,
        'get',
        URL_INVOICE_GET_TIMES,
        params,
        getTimes,
    );

export const getInvoice = params =>
    makeRequest(
        INVOICE_GET_INVOICE,
        'post',
        URL_INVOICE_GET_INVOICE,
        params,
        getInvoice,
        false,
        true
    );