import { pink, indigo, brown, green } from '@mui/material/colors';

export const rootStyles = theme => ({
  rootLayout: {
    backgroundColor: '#f5f6f7',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  item: {
    margin: '50px 0',
  },
  page: {
    padding: 50,
  },
});

export const tooltipStyles = theme => ({
  tooltip: {
    minWidth: 500,
    backgroundColor: '#fff',
    border: '1px solid #999',
    borderRadius: '0',
    padding: '20px 10px',
    '& .title': {
      fontWeight: 'bold',
      paddingBottom: 15,
    },
    '& .body>div>div': {
      marginBottom: 10
    },
  },
});

export const conntectAccountStyles = theme => ({
  chartRoot: {
    margin: '50px 0',
  },
  best_guest_center: {
    padding: '20px 0',
  },
  chart: {
    position: 'relative',
    '& button': {
      minWidth: 150,
      backgroundColor: '#fff !important',
      zIndex: 999,
    },
    '& button.airbnbBtnActive': {
      minWidth: 150,
      backgroundColor: `${green[500]} !important`,
      zIndex: 999,
    },
    '& .best_guest': {
      textAlign: 'right',
      '& .best_guest__button':{
        borderColor: brown[500],
        color: brown[500],
      }
    },
    '& .connect': {
      textAlign: 'center',
    },
    '& .logo_img': {
      '& button': {
        '&.logo_img__airbnb': {
          borderColor: pink[300],
        },
        '&.logo_img__booking': {
          borderColor: indigo[500],
        },
      },
      '& .logo_img__button': {
        height: 21,
        maxWidth: '100%',
      },
    },
    '& .linear_progress': {
      position: 'absolute',
      left: 0,
      right: 0,
      '&.airbnb': {
        top: 18,
      },
      '&.booking': {
        bottom: 18,
      },
      '&>div': {
        height: 3
      },
    },
  },
  airbnbLinearColorPrimary: {
    backgroundColor: pink[300],
  },
  airbnbLinearBarColorPrimary: {
    backgroundColor: pink[100],
  },
  bookingLinearColorPrimary: {
    backgroundColor: indigo[300],
  },
  bookingLinearBarColorPrimary: {
    backgroundColor: indigo[100],
  },
});
