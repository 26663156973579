import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_LINK_REFERRAL,
  GET_LINK_REFERRAL_SUCCESS,
  GET_LIST_INVITE_USER_REFERRAL,
  GET_LIST_INVITE_USER_REFERRAL_SUCCESS,
  SEND_INVITE_REFERRAL,
  SEND_INVITE_REFERRAL_SUCCESS
} from '../../actions/referral';

const initialState = {
  data: {},
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_LINK_REFERRAL]: state => {
    state.data = {};
    state.loading = true;
  },
  [GET_LINK_REFERRAL_SUCCESS]: (state, action) => {
    state.data = {
      ...state.data,
      link: action.res
    }
    state.loading = false;
  },
  [GET_LIST_INVITE_USER_REFERRAL]: state => {
    state.loading = true;
  },
  [GET_LIST_INVITE_USER_REFERRAL_SUCCESS]: (state, action) => {
    state.data = {
      ...state.data,
      listUser: action.res
    }
    state.loading = false;
  },
});
