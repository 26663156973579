/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from 'react';
import { Grid, Button, CircularProgress, Typography } from '@mui/material';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { generateLoginDetail } from '../components/generateFields';
import { PERSON_COMPANY_URL } from '../../../routes/onboarding-routes';
import green from '@mui/material/colors/green';
import logo from '../../../styles/image/transparent__dark_text_logo.png';
import { connect } from 'react-redux';
import { addAuthInfo, nextStep } from '../../../actions/onboading';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const cusStyles = {
  continuesButton: {
    textAlign: 'center',
    marginTop: 50,
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0 4px',
  },
  iconTooltip: {
    marginTop: 20,
  },
  signSuccess: {
    color: green[500],
    marginBottom: 15,
  },
  logo: {
    width: '100%',
    margin: '15px 0 30px',
  },
};

const validation = Yup.object().shape({
  // userName: Yup.string().required('Required').nullable(),
  password: Yup.string()
    .required('Required')
    .min(6, 'Password is too short should be 6 chars minimum')
    .matches(/[0-9]/, 'Password must include at least one numeric character')
    .matches(/[a-z]/, 'Password must contain lowercase characters')
    .matches(/[A-Z]/, 'Password must contain uppercase characters')
    .nullable(),
  passwordAgain: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Confirm Password is required')
    .nullable(),
});

const initialValues = {
  // userName: '',
  password: '',
  passwordAgain: '',
};

const LoginDetail = ({ account, addAuthInfo, nextStep }) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const formFields = generateLoginDetail();

  const handleSubmit = (values, { setErrors }) => {
    setLoading(true);
    values.userName = account.detail.email || '';
    addAuthInfo(values).then((res) => {
      setLoading(false);
      if (res.data && res.result) {
        nextStep();
        history(PERSON_COMPANY_URL);
      } else {
        res.errors.length > 0 &&
          res.errors[0].note &&
          setErrors(res.errors[0].note);
      }
    });
  };

  return (
    <div>
      <Grid container spacing={24} justify="center" alignItems="center">
        <Grid
          item
          xs={8}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: -30,
          }}
        >
          <img
            src={logo}
            style={window.innerWidth < 500 ? { width: '67%' } : cusStyles.logo}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 15 }} spacing={24}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="center"
            style={cusStyles.signSuccess}
          >
            {t('Phone number was verified successfully')}!
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t('Please set your login details')}.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
            render={({ dirty, values }) => {
              return (
                <Form>
                  {formFields.map((v, k) => (
                    <div style={cusStyles.inputTooltip} key={k}>
                      <FastField {...v} key={k} />
                    </div>
                  ))}
                  <div style={cusStyles.continuesButton}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={!dirty || loading}
                    >
                      {t('CONTINUE')}
                      {loading && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { addAuthInfo, nextStep })(LoginDetail);
