import { 
  SET_TITLE_GLOBAL,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
} from '../actions/global';
import produce from 'immer';

export const initialState = {
  globalTitle: {
    title: null,
    label: null
  },
  suggestions: {
    loading: false,
    data: []
  }
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_TITLE_GLOBAL:
        draft.globalTitle = action.params;
        return;

      case GET_SUGGESTIONS:
        draft.suggestions.loading = true;
        draft.suggestions.data = [];
        return;
      case GET_SUGGESTIONS_SUCCESS:
        draft.suggestions.loading = false;
        draft.suggestions.data = action.res;
        return;

      default:
        return state;
    }
  });
