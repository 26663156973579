import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { shareReservation, getReservationDetail } from '../actions/reservation';
import { selectReservationDetail } from '../selectors/reservation-selectors';
import GuestForm from '../forms/GuestForm';
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import FormInput from '../common/input';
import { GlobalDrawerContext } from '../contexts';
import { CLOSE_DRAWER } from '../custom-hooks/useGlobalDrawer';
import { isEmpty } from 'lodash';
import PhoneInput from '../common/PhoneInput';
import { formatPhone } from '../helpers';
import { useTranslation, Trans } from 'react-i18next';

const GUEST_FIELDS = [
  {
    name: 'firstName',
    label: 'First name',
    placeholder: 'First name',
    component: FormInput,
  },
  {
    name: 'lastName',
    label: 'Last name',
    placeholder: 'Last name',
    component: FormInput,
  },
  {
    name: 'toEmail',
    type: 'email',
    label: 'Email address',
    placeholder: 'Email address',
    component: FormInput,
  },
  {
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Phone number',
    component: PhoneInput,
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required').min(3).nullable(),
  lastName: Yup.string().required('Required').min(3).nullable(),
  toEmail: Yup.string()
    .email('Invalid email')
    .when('phoneNumber', {
      is: (val) => !val,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    })
    .nullable(),
  // .required("Required"),
  phoneNumber: Yup.number().nullable(),
});

function Guest({
  isUpdating,
  shareReservation,
  reservationHash,
  guest,
  getReservationDetail,
  reservationDetail,
}) {
  const { dispatch } = useContext(GlobalDrawerContext);
  const [loader, setLoader] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const title = isUpdating ? 'Update Guest' : 'Add new Guest';
  const initialValues =
    isUpdating && !isEmpty(guest)
      ? {
          firstName: guest.firstName,
          lastName: guest.lastName,
          toEmail: guest.toEmail || '',
          phoneNumber: guest.phoneNumber || '',
        }
      : {
          firstName: '',
          lastName: '',
          toEmail: '',
          phoneNumber: '',
        };

  function handleSubmit(values, { setErrors }) {
    const params = {
      ...values,
      type: 'phone',
      reservationHash,
      phoneNumber: isUpdating
        ? values.phoneNumber
        : formatPhone(values.phoneNumber),
    };
    !params.toEmail && delete params['toEmail'];
    !params.phoneNumber && delete params['phoneNumber'];

    if (isUpdating) {
      console.log(params);
    } else {
      try {
        setLoader(true);
        shareReservation(params).then((res) => {
          setLoader(false);
          if (res.data && res.result) {
            setIsDone(true);
            setTimeout(() => {
              dispatch({ type: CLOSE_DRAWER, drawerName: 'guest' });
              getReservationDetail({
                reservationId: reservationDetail.reservationId,
              });
            }, 1000);
          } else {
            res.errors.length > 0 &&
              res.errors[0].note &&
              setErrors(res.errors[0].note);
            res.errors.length > 0 &&
              res.errors[0].note == 'Found as master guest' &&
              setErrors({ toEmail: `${t('This user is the Master guest')}` });
          }
        });
      } catch (e) {
        setLoader(false);
      }
    }
  }

  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h5" color="primary" align="center" gutterBottom>
        {t(title)}
      </Typography>
      <Typography align="center" style={{ marginTop: 4 }}>
        <Trans
          i18nKey={'Send invitation to the guest to'}
          style={{ margin: '20px 0' }}
        >
          {t('Send invitation to the guest to fill in the Check-in form')}.
        </Trans>
      </Typography>
      <GuestForm
        loading={loader}
        isDone={isDone}
        isUpdating={isUpdating}
        formFields={GUEST_FIELDS}
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

const mapState = (state) => {
  return {
    reservationDetail: selectReservationDetail(state).data,
  };
};

export default connect(mapState, { shareReservation, getReservationDetail })(
  Guest,
);
