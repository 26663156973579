import React, { useContext } from 'react';
import { Paper, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles'
import ReservationForm from './ReservationForm';
import { connect } from 'react-redux';
import * as commonActions from '../../../shared/actions';
import FetchReservations from './FetchReservations';
import GlobalDrawer from '../../../components/GlobalDrawer';
import { GlobalDrawerContext } from '../../../contexts';
import { OPEN_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import { useTranslation } from "react-i18next";
import TitleHasIcon from '../../../common/TitleHasIcon';
import { globalStyles } from '../../../styles/styles';
import titleIcon from '../../../styles/title-icon/Unclosed_reservations_icon.png';
import ProppertyReminder from './PropertyReminder';

const ReservationHome = props => {
  const { state, dispatch } = useContext(GlobalDrawerContext);
  const { t } = useTranslation();
  const { classes, propertiesList } = props;

  const renderContent = () => {
    return (
      <div>
          <TitleHasIcon variant="h5" title={t('Unclosed reservations')}
            label={t('Waiting for sending the check-in forms to Ubyport and City office')} isImage={true} icon={titleIcon}
            actionButton={t('Add new reservation')}
            handleAction={() => dispatch({ type: OPEN_DRAWER })}
          />
          <FetchReservations params={{ onlyUnclosed: 1, isOnboardingProcess: 1 }} showReadytosend />
      </div>
    )
  }

  return (
    <div>
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <div className="listing-block">
          {propertiesList.length > 0 && <div className="property-list"><ProppertyReminder propertiesList={propertiesList} /></div>}
          <div className="inner-listing-block">
            <Paper className={classes.root} >
                    {renderContent()}
            </Paper>
          </div>
        </div>
      </Hidden>
      {/* Mobile */}
      <Hidden smUp implementation="css">
        {propertiesList.length > 0 && <ProppertyReminder propertiesList={propertiesList} />}
        {renderContent()}
      </Hidden>

      <GlobalDrawer isOpen={state.isOpen}>
        <ReservationForm />
      </GlobalDrawer>
    </div>
  );
};

const mapStateToProps = state => ({
  reservations: state.reservation.reservationsList.data,
  propertiesList: state.property.propertiesList.data,
});

export default connect(
  mapStateToProps,
  { ...commonActions },
)(withStyles(globalStyles)(ReservationHome));
