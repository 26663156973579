import React, { useContext, useEffect } from 'react';
import PropertiesList from '../components/PropertiesList';
import CreateProperty from './CreateProperty';
import { GlobalDrawerContext } from '../../../contexts';
import { OPEN_DRAWER } from '../../../custom-hooks/useGlobalDrawer';
import GlobalDrawer from '../../../components/GlobalDrawer';
import { withTranslation } from 'react-i18next';
import { globalStyles } from '../../../styles/styles';
import TitleHasIcon from '../../../common/TitleHasIcon';
import { Paper, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles'
import { getProperties } from '../../../actions/property';
import { connect } from 'react-redux';

const Properties = ({ t, classes, getProperties }) => {
  const { state, dispatch } = useContext(GlobalDrawerContext);
    console.log("HERE")
  useEffect(() => {
    getProperties();
  }, []);

  return (
    <div>
      <Hidden xsDown implementation="css">
          <Paper className={classes.root} >
            <TitleHasIcon variant="h5" title={t('Properties')} label={''} icon={'home'}
              actionButton={t('Create new property')}
              handleAction={() => dispatch({ type: OPEN_DRAWER, drawerName: 'create_property' })}
            />
          <PropertiesList />
        </Paper>
      </Hidden>
      <Hidden smUp implementation="css">
            <TitleHasIcon variant="h5" title={t('Properties')} label={''} icon={'home'}
              actionButton={t('Create new property')}
              handleAction={() => dispatch({ type: OPEN_DRAWER, drawerName: 'create_property' })}
            />
          <PropertiesList />
      </Hidden>
      <GlobalDrawer
            isOpen={state.isOpen && state.drawerName === 'create_property'}
        >
        <CreateProperty />
      </GlobalDrawer>
    </div>
  );
};

export default connect(
  null,
  { getProperties },
)(withTranslation()(withStyles(globalStyles)(Properties)));

