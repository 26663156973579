import React from 'react';
import { languageList } from '../i18n';
import { Select, MenuItem, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';

const styles = (theme) => ({
  selectRoot: {
    background: grey[100],
    padding: '0 10px',
    borderRadius: 2,
    '&>div>div': {
      display: 'flex',
    },
    '&:before': {
      content: ' ',
      borderBottomColor: 'transparent !important',
    },
    '& img': {
      height: 20,
    },
  },
});

const LanguagePicker = ({ classes, isMobile }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Paper
      style={
        isMobile
          ? {
              position: 'relative',
              marginBottom: -8,
              width: '100%',
              height: 33,
              background: '#fff',
            }
          : {}
      }
    >
      <Select
        value={i18n.language}
        onChange={changeLanguage}
        className={classes.selectRoot}
        style={
          isMobile
            ? { position: 'absolute', boxShadow: '-1px 1px 1px #ccc', right: 0 }
            : {}
        }
      >
        {languageList.map((v) => (
          <MenuItem value={v} key={v}>
            <img
              src={require('../styles/language/' + v + '.png')}
              style={{ width: 24 }}
            />
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

export default withStyles(styles)(LanguagePicker);
