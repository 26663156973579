import {
  Hidden,
  CircularProgress,
  Typography,
  Paper,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Button,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Placeholder from '../../../common/Placeholder';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import VirtualTableCustom from '../../../components/VirtualTableCustom';
import DialogConfirm from '../../../common/DialogConfirm';
import { connect } from 'react-redux';
import { deleteListing, updateListing } from '../../../actions/listing';
import { getSuggestions } from '../../../actions/global';
import { stripHtml } from '../../../helpers';
import { getPropertyDetail } from '../../../actions/property';
import { withTranslation } from 'react-i18next';
import HtmlInput from '../../../common/HtmlInput';
import { grey, blue } from '@mui/material/colors';
import { suggestFomat, replaceHtmlEntites } from '../../../helpers';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import { omit } from 'lodash';

const appLink =
  // 'https://bestguest-app-web.surge.sh/reservation?listingHash=';
  // 'https://bestguestapptest.surge.sh/reservation?listingHash=';
  'https://bestguestapp-development.firebaseapp.com/reservation?listingHash=';

const CHECK_IN_INSTRUCTIONS = 'checkinString';
const CHECK_OUT_INSTRUCTIONS = 'checkoutString';

const styles = (theme) => ({
  DialogContent: {
    margin: '15px 0',
    padding: 0,
    // minWidth: 400,
    // maxWidth: '100%',
  },
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: 'initial',
      margin: 0,
    },
  },
  chip: {
    margin: 5,
    background: '#fff',
    borderRadius: 10,
    color: grey[600],
    fontWeight: 500,
    '&:hover': {
      color: grey[600],
      background: `${grey[200]} !important`,
    },
  },
  rootChip: {
    // background: grey[500],
    padding: '15px 0',
  },
});

class ListingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      deleteLoading: false,
      listing: {
        listingName: '',
        listingId: '',
        airbnbICalLink: '',
        bookingComICalLink: '',
      },
      copy: {
        isCopied: false,
        index: null,
      },
      checkInOutInstructions: {
        open: false,
        loading: false,
        data: null,
        inputData: '',
        updateSuccess: false,
      },
    };

    this.CheckInOutInstructionsInput = React.createRef();

    this.openCheckInOutInstructions =
      this.openCheckInOutInstructions.bind(this);
    this.openToEdit = this.openToEdit.bind(this);
    this.openToDelete = this.openToDelete.bind(this);
    this.handleConfirmYes = this.handleConfirmYes.bind(this);
    this.handelSaveForm = this.handelSaveForm.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleAddFillToTextField = this.handleAddFillToTextField.bind(this);
  }

  componentDidMount() {
    // get suggestions
    this.props.getSuggestions({ type: 'listing' });
  }

  openCheckInOutInstructions = (data, type) => () => {
    const { suggestions } = this.props;
    let { checkInOutInstructions } = this.state;
    let defaultInputData = data[type] || '';
    suggestions &&
      suggestions.data &&
      suggestions.data.length > 0 &&
      suggestions.data.map((v) => {
        let replace_text = `{ ${v.name} }`;
        defaultInputData = defaultInputData
          .split(replace_text)
          .join(suggestFomat(v.name));
      });
    console.log(defaultInputData);
    this.setState({
      checkInOutInstructions: {
        ...checkInOutInstructions,
        open: true,
        inputData: defaultInputData,
        data: { ...data, type: type },
      },
    });
  };

  openToEdit = (dt) => () => {
    this.props.openListingDetail(dt);
  };

  openToDelete = (listing) => () => {
    this.setState({
      openDialog: true,
      listing: {
        listingName: listing.listingName,
        listingId: listing.listingId,
      },
    });
  };

  handleConfirmYes = async () => {
    const { listing } = this.state;
    const { deleteListing, property, getPropertyDetail } = this.props;
    this.setState({ deleteLoading: true });
    deleteListing({ listingId: listing.listingId }).then((res) => {
      if (res.data && res.result) {
        getPropertyDetail({ accommodationId: property.accommodationId });
        this.setState({ deleteLoading: false, openDialog: false });
      }
    });
  };

  handleKey(position) {
    this.CheckInOutInstructionsInput.current.cursorPosition = position;
  }
  handleChangeTextField(e) {
    let { checkInOutInstructions } = this.state;
    checkInOutInstructions.inputData = e.target.value;
    this.setState({ checkInOutInstructions });
  }

  handleAddFillToTextField(data) {
    let { checkInOutInstructions } = this.state;
    this.CheckInOutInstructionsInput.current.focus();
    let cursorPosition =
      this.CheckInOutInstructionsInput.current.cursorPosition || 0;
    let _check_has_new_name = checkInOutInstructions.inputData;
    _check_has_new_name = replaceHtmlEntites(_check_has_new_name);
    let _new_result = [
      _check_has_new_name.slice(0, cursorPosition),
      suggestFomat(data.name),
      _check_has_new_name.slice(cursorPosition),
    ].join('');
    checkInOutInstructions.inputData = _new_result;
    this.setState({ checkInOutInstructions });
  }

  handelSaveForm = (e) => {
    let { checkInOutInstructions } = this.state;
    const { updateListing, property, getPropertyDetail } = this.props;
    if (checkInOutInstructions.inputData) {
      let instructionName =
        checkInOutInstructions.data.type === CHECK_IN_INSTRUCTIONS
          ? CHECK_IN_INSTRUCTIONS
          : CHECK_OUT_INSTRUCTIONS;
      let params = {
        ...omit(checkInOutInstructions.data, ['type']),
        [instructionName]: stripHtml(checkInOutInstructions.inputData),
      };
      this.setState({
        checkInOutInstructions: { ...checkInOutInstructions, loading: true },
      });
      updateListing(params).then((res) => {
        if (res.data && res.result) {
          this.setState({
            checkInOutInstructions: {
              ...checkInOutInstructions,
              updateSuccess: true,
            },
          });
          setTimeout(() => {
            this.setState({
              checkInOutInstructions: {
                ...checkInOutInstructions,
                open: false,
                formData: '',
                updateSuccess: false,
                loading: false,
              },
            });
            getPropertyDetail({ accommodationId: property.accommodationId });
          }, 1000);
        }
      });
    }
  };

  renderCopyToClipboard(row) {
    const { t } = this.props;
    return (
      <CopyToClipboard
        text={appLink + row.listingHash}
        onCopy={() => {
          this.setState({
            copy: {
              isCopied: true,
              index: row.listingId,
            },
          });
          setTimeout(() => {
            this.setState({
              copy: {
                isCopied: false,
              },
            });
          }, 1200);
        }}
      >
        <IconButton>
          <FileCopyIcon fontSize="small" style={{ color: '#2196f3' }} />
          <Typography style={{ paddingLeft: 4, color: '#2196f3' }}>
            {t('Copy link')}
          </Typography>
        </IconButton>
      </CopyToClipboard>
    );
  }

  renderCheckInOutInstructions() {
    const { classes, t, suggestions } = this.props;
    const { checkInOutInstructions } = this.state;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={`md`}
        disableBackdropClick
        disableEscapeKeyDown
        open={checkInOutInstructions.open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle align="center">
          {checkInOutInstructions.data.listingName}
        </DialogTitle>
        <div>
          <div className={classes.rootChip}>
            {suggestions && suggestions.data && suggestions.data.length > 0 ? (
              suggestions.data.map((v, k) => (
                <Chip
                  key={k}
                  label={t(v.text)}
                  className={classes.chip}
                  variant="outlined"
                  onClick={() => this.handleAddFillToTextField(v)}
                />
              ))
            ) : (
              <CircularProgress size={24} />
            )}
          </div>
          <form>
            <DialogContent className={classes.DialogContent}>
              <FormLabel>
                {checkInOutInstructions.data.type === CHECK_IN_INSTRUCTIONS
                  ? t('Check-in')
                  : t('Check-out')}{' '}
                {t('instructions')}
              </FormLabel>
              <HtmlInput
                name={'inputData'}
                id={'inputData'}
                value={checkInOutInstructions.inputData}
                inputRef={this.CheckInOutInstructionsInput}
                handleChange={this.handleChangeTextField}
                handleKey={this.handleKey}
                style={{ height: 200 }}
              />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="default"
                onClick={() =>
                  this.setState({
                    checkInOutInstructions: {
                      ...checkInOutInstructions,
                      open: false,
                    },
                  })
                }
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handelSaveForm}
                disabled={
                  !checkInOutInstructions.inputData ||
                  checkInOutInstructions.loading
                }
              >
                {t('Save')}
                {checkInOutInstructions.loading && (
                  <CircularProgress size={24} style={{ marginLeft: 10 }} />
                )}
              </Button>
            </DialogActions>
            {checkInOutInstructions.updateSuccess && (
              <Typography variant="caption" align="center" color="secondary">
                {' '}
                {t('successfully')}!
              </Typography>
            )}
          </form>
        </div>
      </Dialog>
    );
  }

  render() {
    console.log('1234r5t6y7');
    const { data, loading, listingsCount, t } = this.props;
    const { openDialog, deleteLoading, listing, copy, checkInOutInstructions } =
      this.state;

    const columns = [
      { name: 'listingName', title: t('Listing name') },
      {
        name: 'copyHash',
        title: t('Copy listing link'),
        getCellValue: (row) => (
          <div>
            {row.listingHash && (
              <div>
                {this.renderCopyToClipboard(row)}
                {copy.isCopied && copy.index === row.listingId && (
                  <span style={{ fontSize: 11 }}>{t('Copied')}</span>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        name: 'detail',
        title: t('Detail'),
        getCellValue: (row) => (
          <IconButton onClick={this.openToEdit(row)}>
            <EditIcon fontSize="inherit" style={{ color: '#2196f3' }} />
            <Typography style={{ paddingLeft: 4, color: '#2196f3' }}>
              {t('Edit')}
            </Typography>
          </IconButton>
        ),
      },
      {
        name: 'checkInInstructions',
        title: ' ',
        getCellValue: (row) => (
          <div>
            <Button
              onClick={this.openCheckInOutInstructions(
                row,
                CHECK_IN_INSTRUCTIONS,
              )}
              style={{ textTransform: 'capitalize', color: blue[500] }}
            >
              {t('Check-in instructions')}
            </Button>
          </div>
        ),
      },
      {
        name: 'checkOutInstructions',
        title: ' ',
        getCellValue: (row) => (
          <div>
            <Button
              onClick={this.openCheckInOutInstructions(
                row,
                CHECK_OUT_INSTRUCTIONS,
              )}
              style={{ textTransform: 'capitalize', color: blue[500] }}
            >
              {t('Check-out instructions')}
            </Button>
          </div>
        ),
      },
      {
        name: 'delete',
        title: t('Delete'),
        getCellValue: (row) => (
          <ButtonWithTooltip
            show={row.hasReservation}
            title={t(
              `You can not delete this listing because there exists some reservation connected with this listing`,
            )}
          >
            <IconButton
              onClick={this.openToDelete(row)}
              disabled={row.hasReservation}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ButtonWithTooltip>
        ),
      },
    ];

    // const tableColumnExtensions = [
    //   { columnName: 'listingName', width: 350, align: 'left' },
    //   { columnName: 'copyHash', width: 150, align: 'left' },
    //   { columnName: 'detail', width: 150, align: 'left' },
    //   { columnName: 'checkInInstructions', width: 250, align: 'center' },
    //   { columnName: 'checkOutInstructions', width: 250, align: 'center' },
    //   { columnName: 'delete', width: 100, align: 'center' },
    // ];

    return (
      <Placeholder
        loading={loading}
        type={'table'}
        numberOfRows={listingsCount}
      >
        <div style={{ marginBottom: 20 }}>
          {/* dialog */}
          {openDialog && (
            <DialogConfirm
              isOpen={openDialog}
              title={t('Are you sure to delete')}
              message={`${t('Listing')}: ${listing.listingName}`}
              handleClose={() => this.setState({ openDialog: false })}
              handleAccess={this.handleConfirmYes}
              isLoadding={deleteLoading}
            />
          )}

          {checkInOutInstructions.open &&
            checkInOutInstructions.data &&
            this.renderCheckInOutInstructions()}

          <Hidden xsDown implementation="css">
            <Grid rows={data} columns={columns}>
              <Table />
              {/* <TableColumnResizing defaultColumnWidths={tableColumnExtensions} /> */}
              <VirtualTableCustom dataCount={data.count} />
              <TableHeaderRow />
            </Grid>
          </Hidden>

          <Hidden smUp implementation="css">
            {data.length > 0 &&
              data.map((v, k) => (
                <Paper style={{ marginTop: 15, paddingTop: 10 }} key={k}>
                  <div style={{ marginLeft: 15, fontWeight: 'bold' }}>
                    {v.listingName}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {v.listingHash && (
                      <div>
                        {this.renderCopyToClipboard(v)}
                        {copy.isCopied && copy.index === v.listingId && (
                          <span style={{ fontSize: 11 }}>Copied</span>
                        )}
                      </div>
                    )}
                    <IconButton onClick={this.openToEdit(v)}>
                      <EditIcon
                        fontSize="inherit"
                        style={{ color: '#2196f3' }}
                      />
                      <Typography style={{ paddingLeft: 4, color: '#2196f3' }}>
                        {t('Edit')}
                      </Typography>
                    </IconButton>
                    <ButtonWithTooltip
                      show={v.hasReservation}
                      title={t(
                        `You can not delete this listing because there exists some reservation connected with this listing`,
                      )}
                    >
                      <IconButton
                        onClick={this.openToDelete(v)}
                        disabled={v.hasReservation}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ButtonWithTooltip>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '15px 0',
                    }}
                  >
                    <Button
                      onClick={this.openCheckInOutInstructions(
                        v,
                        CHECK_IN_INSTRUCTIONS,
                      )}
                      style={{ textTransform: 'capitalize', color: blue[500] }}
                    >
                      {t('Check-in instructions')}
                    </Button>
                    <Button
                      onClick={this.openCheckInOutInstructions(
                        v,
                        CHECK_OUT_INSTRUCTIONS,
                      )}
                      style={{ textTransform: 'capitalize', color: blue[500] }}
                    >
                      {t('Check-out instructions')}
                    </Button>
                  </div>
                </Paper>
              ))}
          </Hidden>
        </div>
      </Placeholder>
    );
  }
}

const mapStateToProps = (state) => ({
  suggestions: state.global.suggestions,
});

export default connect(mapStateToProps, {
  deleteListing,
  updateListing,
  getPropertyDetail,
  getSuggestions,
})(withStyles(styles)(withTranslation()(ListingList)));
