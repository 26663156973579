import { combineEpics, ofType } from 'redux-observable';
import { map, mergeMap, withLatestFrom, catchError } from 'rxjs/operators';
import {
  CREATE_CHECK_IN_FORM,
  GET_COUNTRIES,
  GET_RESERVATION_DETAIL,
  GET_RESERVATIONS,
} from './actionTypes';
import {
  createCheckInFormSuccess,
  getCountriesSuccess,
  getReservationDetailSuccess,
  getReservationsSuccess,
} from './actions';
import requestEpic$, { getJSONEpic } from '../../shared/requestEpic';
import { RequestMethod } from '../../shared/const/requestMethod';
import { GET_COUNTRIES_URL, ReservationUrls } from '../../shared/urls';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { requestFailure } from '../../redux/handleError';

const BASE_URL = process.env.REACT_APP_BEST_GUEST_API_BASE_URL || '';

const getReservationsEpic = getJSONEpic(
  GET_RESERVATIONS,
  ReservationUrls.GET_RESERVATIONS,
  map(res => {
    return getReservationsSuccess(res.data);
  }),
);

const createCheckInFormEpic = requestEpic$(
  CREATE_CHECK_IN_FORM,
  RequestMethod.POST,
  ReservationUrls.CREATE_CHECK_IN_FORM,
  map(res => {
    return createCheckInFormSuccess(res.response.data);
  }),
);

const getCountriesEpic = requestEpic$(
  GET_COUNTRIES,
  RequestMethod.GET,
  GET_COUNTRIES_URL,
  map(res => {
    const formatRes = res.response.data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    return getCountriesSuccess(formatRes);
  }),
);

const getReservationDetailEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_RESERVATION_DETAIL),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const token = state.account.detail.tokens.authToken;
      return ajax
        .getJSON(
          `${BASE_URL}/reservations/getReservationDetail?reservationId=${
            action.id
          }`,
          {
            Authorization: `${token}`,
          },
        )
        .pipe(
          map(res => getReservationDetailSuccess(res.data)),
          catchError(error =>
            of(requestFailure(error.xhr.response, error.status)),
          ),
        );
    }),
  );

export default combineEpics(
  getReservationsEpic,
  getReservationDetailEpic,
  createCheckInFormEpic,
  getCountriesEpic,
);
