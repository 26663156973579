import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography, CircularProgress, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { getListingForMapping, mapListings } from '../../../actions/onboading';
import { useTranslation } from 'react-i18next';
import MappingListing from '../../../components/MappingListing';

import BookingFav from '../../../styles/icon/booking.png';
import AirBnbFav from '../../../styles/icon/airbnb.png';

const width = window.innerWidth;

const cusStyles = (theme) => ({
  continueButton: {
    textAlign: 'center',
    marginTop: 50,
  },
  propertyList: {
    marginBottom: 30,
    '& .propertyTitle': {
      fontWeight: 500,
      marginBottom: 15,
    },
    '& .listingsItems': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: width < 500 ? 'column' : 'row',
      borderBottom: '1px solid #ccc',
      padding: '10px 0',
      '&:first-child': {
        borderTop: '1px solid #ccc',
      },
      '& .listingName': {
        display: 'flex',
        placeItems: 'center',
        '& img': {
          marginRight: 5,
        },
      },
    },
  },
});

// const fakeData = [
//   {
//     "accommodationId": 40,
//     "accommodationName": "Prague Palace - Wenceslas Square",
//     "listings": {
//       "airbnb": [
//         {
//           "listingId": 1,
//           "listingName": "Luxurious apartment sauna and whirlpool 5BDR+3BTH",
//           "integrationsIds": "19224890",
//           "listingSource": "airbnb"
//         },
//         {
//           "listingId": 2,
//           "listingName": "Luxurious apartment ",
//           "integrationsIds": "19224890",
//           "listingSource": "airbnb"
//         },
//         {
//           "listingId": 3,
//           "listingName": "Sauna and whirlpool 5BDR+3BTH",
//           "integrationsIds": "19224890",
//           "listingSource": "airbnb"
//         },
//       ],
//       "booking": [
//         {
//           "listingId": 4,
//           "listingName": "Luxurious",
//           "integrationsIds": "19224891",
//           "listingSource": "booking"
//         },
//         {
//           "listingId": 5,
//           "listingName": "Luxurious apartment",
//           "integrationsIds": "19224891",
//           "listingSource": "booking"
//         }
//       ]
//     }
//   },
//   {
//     "accommodationId": 41,
//     "accommodationName": "Prague Palace - Wenceslas Square 1",
//     "listings": {
//       "airbnb": [
//         {
//           "listingId": 6,
//           "listingName": "Sauna and whirlpool",
//           "integrationsIds": "19224890",
//           "listingSource": "airbnb"
//         },
//         {
//           "listingId": 7,
//           "listingName": "Luxurious apartment",
//           "integrationsIds": "19224890",
//           "listingSource": "airbnb"
//         },
//       ],
//       "booking": [
//         {
//           "listingId": 8,
//           "listingName": "Luxurious apartment sauna and 1",
//           "integrationsIds": "19224891",
//           "listingSource": "booking"
//         },
//         {
//           "listingId": 9,
//           "listingName": "Luxurious apartment sauna and 2",
//           "integrationsIds": "19224891",
//           "listingSource": "booking"
//         }
//       ]
//     }
//   }
// ];

const Listings = ({
  handleSubmit,
  handleBack,
  getListingForMapping,
  listingForMapping,
  mapListings,
  classes,
  listingForMappingLoading,
}) => {
  const [result, setResult] = useState(false);
  useEffect(() => {
    getListingForMapping().then((res) => {
      if (res.result) {
        setResult(true);
      }
    });
    // getListingForMapping();
  }, []);

  const [loading, setLoading] = useState(false);
  const [mapDatas, setMapDatas] = useState([]);

  const { t } = useTranslation();

  function handleMapDatas(params) {
    let index = mapDatas.findIndex(
      (e) =>
        e.accommodationId === params.accommodationId &&
        e.airbnbId === params.airbnbId,
    );
    index === -1 ? mapDatas.push(params) : (mapDatas[index] = params);
    setMapDatas(mapDatas);
  }

  function submitChange() {
    setLoading(true);
    if (mapDatas.length > 0) {
      mapListings({ ids: mapDatas, isOnboardingProcess: true }).then((res) => {
        setLoading(false);
        res.data && res.result && handleSubmit(mapDatas);
      });
    } else {
      handleSubmit(mapDatas);
    }
  }
  // console.log(getListingForMapping)
  // console.log('mapDatas', mapDatas,listingForMapping)
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <div style={{ minHeight: 400 }}>
            {/* {listingForMappingLoading && <CircularProgress />} */}

            {!listingForMappingLoading ? (
              listingForMapping && listingForMapping.length > 0 ? (
                listingForMapping.map((v, k) => (
                  <div className={classes.propertyList} key={k}>
                    <Typography variant="h6" className="propertyTitle">
                      {v.accommodationName}
                    </Typography>
                    <div>
                      {v.listings &&
                        v.listings.airbnb &&
                        v.listings.airbnb.map((item, idx) => {
                          return (
                            <Grid
                              container
                              alignItems="center"
                              key={idx}
                              className={'listingsItems'}
                            >
                              <Grid
                                item
                                xs={12}
                                md={6}
                                className={'listingName'}
                              >
                                <img src={AirBnbFav} height={20} alt="" />
                                <div>{item.listingName}</div>
                              </Grid>
                              <Grid item xs={12} md={2} align="center">
                                {t(`is the same as`)}
                              </Grid>
                              <Grid item xs={12} md={4} align="right">
                                <MappingListing
                                  favIcon={BookingFav}
                                  datas={v.listings && v.listings.booking}
                                  accommodationId={v.accommodationId}
                                  airbnbId={item.listingId}
                                  handleMapDatas={handleMapDatas}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                    </div>
                  </div>
                ))
              ) : (
                <Typography variant="subtitle1">
                  {t(
                    `Please, assign the listings on Airbnb to listings on Booking`,
                  )}
                  .
                </Typography>
              )
            ) : (
              <CircularProgress />
            )}
          </div>
          <div className={classes.continueButton}>
            <Button
              variant="contained"
              color="default"
              onClick={handleBack}
              style={{ marginRight: 15 }}
            >
              {t(`Back`)}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={submitChange}
              disabled={
                !result &&
                (loading ||
                  mapDatas.length === 0 ||
                  listingForMapping.length === 0)
              }
            >
              {t(`Continue`)}
              {loading && (
                <CircularProgress size={20} style={{ marginLeft: 10 }} />
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  listingForMapping: state.onboarding.listingForMapping.data,
  listingForMappingLoading: state.onboarding.listingForMapping.loading,
});

const mapActionToProps = {
  getListingForMapping,
  mapListings,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(cusStyles)(Listings));
