import React, { Suspense, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import PrivateRoute from './PrivateRoute';
import { Route, Routes } from 'react-router-dom';
import { dashboardRouterList } from './dashboardRouterList';
import { currentRouteVar } from '../shared/main-layout';
import { connect } from 'react-redux';
import { getCountries } from '../features/reservation/actions';
import { setTitleGlobal } from '../actions/global';

const DashboardRoutes = (props) => {
  useEffect(() => {
    setTimeout(window.scrollTo(0, 0), 100);
  }, [props[currentRouteVar]]);

  useEffect(() => {
    const { label, title } = props;
    console.log('newprops', props);
    props.setTitleGlobal({ label: label, title: title });
  }, []);

  const loadingRender = () => {
    return <CircularProgress />;
  };

  /*<Router>*/
  return (
    <Suspense fallback={loadingRender()}>
      <Routes>
        {dashboardRouterList.map((route, k) => {
          const Component = route.component;
          return route.child && route.child.length > 0 ? (
            <Route key={k} path={`${route.path}`} element={<Component />} exact>
              {route.child.map((childRoute, k1) => (
                <Route
                  key={k1}
                  path={`${childRoute.path}`}
                  element={
                    <PrivateRoute
                      key={k1}
                      path={`${childRoute.path}`}
                      component={childRoute.component}
                      title={childRoute.title}
                      label={childRoute.label}
                    />
                  }
                />
              ))}
            </Route>
          ) : route.public ? (
            <Route
              key={k}
              path={`/${route.path}`}
              title={route.title}
              label={route.label}
              element={<Component />}
            />
          ) : (
            <Route
              key={k}
              path={`/${route.path}`}
              element={
                <PrivateRoute
                  key={k}
                  path={`/${route.path}`}
                  component={route.component}
                  title={route.title}
                  label={route.label}
                />
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  countriesList: state.countries.data,
});

export default connect(mapStateToProps, { getCountries, setTitleGlobal })(
  DashboardRoutes,
);
