import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { ArrowDownward } from '@mui/icons-material';
import { getTimes, getInvoice } from '../../../actions/invoice';
import { format } from 'date-fns';
import Placeholder from '../../../common/Placeholder';
import { useTranslation } from 'react-i18next';
import TitleHasIcon from '../../../common/TitleHasIcon';
import InvoiceIcon from '../../../styles/icon/icon.png';
import { globalStyles } from '../../../styles/styles';
import { withStyles } from '@mui/styles';
import { downloadFile } from '../../../helpers';
import Payments from './Payments';

const FORMAT_PDF = 'pdf';
const FORMAT_XML = 'xml';

const Row = ({ time, onClick, loader, error, formatType, classes }) => {
  const { t } = useTranslation();
  console.log('time.display', time.display);
  return (
    <Grid
      container
      spacing={16}
      className={classes.invoiceList}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <Typography variant="body2">{time.display}</Typography>
        {!!error && (
          <Typography variant="caption" color="error">
            {t('error')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6} className="invoiceButton">
        <Button
          color="primary"
          onClick={() => onClick(FORMAT_PDF, time.display)}
          disabled={loader && formatType === FORMAT_PDF}
        >
          {t('Download PDF')}
          {loader && formatType === FORMAT_PDF ? (
            <CircularProgress size={20} style={{ marginLeft: 5 }} />
          ) : (
            <ArrowDownward fontSize={'small'} />
          )}
        </Button>
        <Button
          color="primary"
          onClick={() => onClick(FORMAT_XML, time.display)}
          disabled={loader && formatType === FORMAT_XML}
        >
          {t('Download XML')}
          {loader && formatType === FORMAT_XML ? (
            <CircularProgress size={20} style={{ marginLeft: 5 }} />
          ) : (
            <ArrowDownward fontSize={'small'} />
          )}
        </Button>
      </Grid>
    </Grid>
    // <ListItem dense divider={!isLastOne} style={{ paddingTop: 15, paddingBottom: 15 }}>
    //   <ListItemText
    //     primary={format(time.display, 'MMMM YYYY')}
    //     secondary={!!error ? <span style={{ color: 'red' }}>{t(error)}</span> : ''}
    //   />
    //   <ListItemSecondaryAction>
    //     <Button color="primary" onClick={() => onClick(FORMAT_PDF, time.display)} disabled={loader && formatType === FORMAT_PDF}>
    //       {t('Download PDF')}
    //       {loader && formatType === FORMAT_PDF ? <CircularProgress size={20} style={{ marginLeft: 5 }} /> : <ArrowDownward fontSize={'small'} />}
    //     </Button>
    //     <Button color="primary" onClick={() => onClick(FORMAT_XML, time.display)} disabled={loader && formatType === FORMAT_XML}>
    //       {t('Download XML')}
    //       {loader && formatType === FORMAT_XML ? <CircularProgress size={20} style={{ marginLeft: 5 }} /> : <ArrowDownward fontSize={'small'} />}
    //     </Button>

    //   </ListItemSecondaryAction>
    // </ListItem>
  );
};

const Invoices = ({ classes, getTimes, getInvoice, invoice }) => {
  const { t } = useTranslation();

  const [loader, setLoader] = useState({
    loading: false,
    index: null,
    error: '',
    format: null,
  });

  useEffect(() => {
    getTimes();
  }, []);

  function dowloadFile(data, i, display) {
    setLoader({ loading: true, index: i, error: '', format: data.format });
    getInvoice(data).then((res) => {
      if (res) {
        const fileName = `Invoice_${format(display, 'MMMM_YYYY')}.${
          data.format
        }`;
        setLoader({ ...loader, loading: false, error: '', format: null });
        downloadFile(res, fileName, data.format);
      } else {
        setLoader({
          ...loader,
          loading: false,
          error: `Sorry. ${data.format} file is not ready to download!`,
          index: i,
          format: null,
        });
      }
    });
  }

  return (
    <div>
      <Payments />
      <Paper className={classes.root}>
        <TitleHasIcon
          variant="h5"
          title={t('Invoices')}
          label={''}
          icon={InvoiceIcon}
          isImage={true}
        />
        <Placeholder loading={invoice.loading} type="table" numberOfRows={10}>
          {invoice.data.map((time, index) => (
            <Row
              time={time}
              error={
                loader.index === index && !!loader.error ? loader.error : ''
              }
              key={index}
              formatType={loader.format}
              loader={loader.loading && loader.index === index}
              onClick={(format, display) =>
                dowloadFile(
                  { month: time.month, year: time.year, format },
                  index,
                  display,
                )
              }
              isLastOne={index === invoice.data.length - 1}
              classes={classes}
            />
          ))}
        </Placeholder>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  invoice: state.invoice,
});

const mapActionToProps = {
  getTimes,
  getInvoice,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withStyles(globalStyles)(Invoices));
