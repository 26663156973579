import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { closeModal } from '../shared/actions';
import { Dialog, DialogContent, Slide } from '@mui/material';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit * 2,
  },
});

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const DialogAlerts = ({
  content,
  isFullScreen = false,
  isOpen = false,
  handleClose,
  style,
}) => {
  return (
    <Dialog
      fullScreen={isFullScreen}
      disableBackdropClick
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={style}>{content}</DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { closeModal })(
  withStyles(styles)(DialogAlerts),
);
