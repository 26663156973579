import styled, { css } from 'styled-components';

export const StyledSpace = styled.div`
  height: ${props => (!!props.height ? `${props.height}px` : '10px')};
`;

export const StyledDivider = styled.div`
  height: ${props => (!!props.height ? `${props.height}px` : `1px`)};
  background-color: ${props => (!!props.hasColor ? '#fafafa' : '#2196f3')};
  width: 100%;
  margin-top: ${props => (!!props.marginTop ? `${props.marginTop}px` : '10px')};
  margin-bottom: ${props =>
    !!props.marginBottom ? `${props.marginBottom}px` : '10px'};
`;

const sizes = {
  retina: 2560, //retina display
  largeDesktop: 1920,
  desktop: 992,
  tablet: 768,
  phone: 576,
  sphone:320,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
