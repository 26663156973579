import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  IconButton,
  Grid,
  Chip,
  Typography,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline';

import { grey } from '@mui/material/colors';
import { generateLists, generateAutomationFields } from './generateFields';
import CusTooltip from '../../../components/CusTooltip';
import HtmlInput from '../../../common/HtmlInput';
import PlayButton from '../../../common/PlayButton';
import { suggestFomat, replaceHtmlEntites } from '../../../helpers';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getSuggestions } from '../../../actions/global';
import { getMessage, saveMessage } from '../../../actions/automation';

const styles = (theme) => ({
  root: {
    marginBottom: 30,
  },
  imgTitle: {
    height: 30,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: '80%',
    color: theme.palette.text.secondary,
  },
  AccordionSummary: {
    padding: '0 15px',
    '&:hover': {
      cursor: 'default !important',
    },
    '& button': {
      margin: 5,
    },
  },
  GridTitle: {
    paddingRight: '0 !important',
  },
  rightBtn: {
    padding: '5px 10px',
  },
  middleDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  placeholder: {
    color: grey[500],
    display: 'flex',
    alignItems: 'center',
  },
  caption: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: grey[500],
      // '& svg': {
      //   marginRight: 15,
      // }
    },
  },
  content: {
    borderTop: `1px solid ${grey[300]}`,
    paddingTop: 30,
  },
  chip: {
    marginBottom: 10,
    marginRight: 10,
    background: grey[100],
  },
});

class AutomationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentExpanded: false,
      switchForm: false,
      loading: false,
      fillLists: generateLists(),
      formfield: [],
      messageForm: {},
      focusInboxMessageInput: true,
      isSave: false,
    };
    this.InboxMessageInput = React.createRef();
    this.OfficialInboxMessageInput = React.createRef();
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    let { name, inboxMessageValue, reviewValue, automations, suggestions } =
      this.props;
    let { messageForm, formfield } = this.state;
    this.setState({
      formField: generateAutomationFields(this.props.t, suggestions.data),
    });
    formfield.map((v) => {
      messageForm[v.name] = name === v.name ? inboxMessageValue : '' || '';
      if (v.name === 'reviewReminder') {
        messageForm[`${v.name}_official`] = v.name ? reviewValue : '' || '';
        messageForm[`${v.name}_dontSendInboxMessage__checkBox`] = false;
        messageForm[`${v.name}_sendMessageOnly__checkBox`] = false;
      }
    });
    automations.map((v) => {
      v.type == name &&
        this.setState({
          switchForm: v.status,
        });
    });

    messageForm[name] = this.props.defaultMessage;

    this.setState({
      messageForm,
      // switchForm: true
    });
  }

  handleExpanded(expanded) {
    this.setState({ currentExpanded: expanded });
  }

  handleSaveMessage(e, name) {
    // console.log(name, this.state.messageForm[name])
    let { switchForm, messageForm } = this.state;
    const { saveMessage, t } = this.props;
    let params = {
      messages: [
        {
          type: name,
          msg: messageForm[name],
          status: switchForm ? 1 : 0,
        },
      ],
    };
    this.setState({
      loading: true,
      isSave: false,
    });
    saveMessage(params).then((res) => {
      if (res.result && res.data) {
        this.setState({
          loading: false,
          isSave: true,
        });
      } else {
        res.errors &&
          res.errors.length > 0 &&
          toast.error(t(res.errors[0].note));
        this.setState({ loading: false, isSave: false });
      }
    });
  }

  handleKey(position, refName) {
    refName.current.cursorPosition = position;
  }

  handleAddFillToTextField(data) {
    let { messageForm, focusInboxMessageInput } = this.state;
    focusInboxMessageInput
      ? this.InboxMessageInput.current.focus()
      : this.OfficialInboxMessageInput.current.focus();
    let { name } = this.props;
    let _new_name = focusInboxMessageInput ? name : `${name}_official`;
    let _check_has_new_name = messageForm[_new_name]
      ? messageForm[_new_name]
      : '';
    _check_has_new_name = replaceHtmlEntites(_check_has_new_name);
    let cursorPosition = focusInboxMessageInput
      ? this.InboxMessageInput.current.cursorPosition
      : this.OfficialInboxMessageInput.current.cursorPosition;
    let _new_result = [
      _check_has_new_name.slice(0, cursorPosition),
      suggestFomat(data.name),
      _check_has_new_name.slice(cursorPosition),
    ].join('');
    messageForm[_new_name] = _new_result;
    // messageForm[_new_name] = `${messageForm[_new_name] ? messageForm[_new_name] : ''} ${suggestFomat(data.value)}`;
    this.setState({ messageForm });
  }

  handleChangeTextField(e, name, focusInput = true) {
    let { messageForm } = this.state;
    messageForm[name] = e.target.value;
    this.setState({ messageForm });
    this.setFocusInboxMessageInput(focusInput);
  }

  setFocusInboxMessageInput(focusInput) {
    let { focusInboxMessageInput } = this.state;
    focusInboxMessageInput = focusInput;
    this.setState({ focusInboxMessageInput });
  }

  handleChangeCheckBox(e, name) {
    let { messageForm } = this.state;
    messageForm[name] = e.target.checked;
    this.setState({ messageForm });
  }

  handleChangeSwitch(e) {
    let { switchForm, messageForm } = this.state;
    const { name, saveMessage, t } = this.props;
    switchForm = e.target.checked;
    let params = {
      messages: [
        {
          type: name,
          msg: messageForm[name],
          status: switchForm ? 1 : 0,
        },
      ],
    };
    this.setState({
      loading: true,
    });
    saveMessage(params).then((res) => {
      if (res.result && res.data) {
        this.setState({
          loading: false,
          switchForm,
        });
      } else {
        res.errors &&
          res.errors.length > 0 &&
          toast.error(t(res.errors[0].note));
        this.setState({ loading: false });
      }
    });
    // console.log(switchForm,name,messageForm[name]);
  }

  expandIcon() {
    const { classes, t } = this.props;
    const { currentExpanded } = this.state;

    return !currentExpanded ? (
      <Button
        variant={'outlined'}
        color={'primary'}
        className={classes.rightBtn}
        onClick={() => this.handleExpanded(true)}
      >
        {t('Edit message')}
      </Button>
    ) : (
      <IconButton
        onClick={() => this.handleExpanded(false)}
        style={{ padding: 2 }}
      >
        <CloseIcon />
      </IconButton>
    );
  }

  panelDetails() {
    const { classes, name, t, suggestions } = this.props;
    const { fillLists, messageForm, loading, isSave } = this.state;
    const _official_name = `${name}_official`;
    const _checkBox1 = `${name}_dontSendInboxMessage__checkBox`;
    const _checkBox2 = `${name}_sendMessageOnly__checkBox`;
    // console.log(fillLists);

    return (
      <div className={classes.content}>
        <div>
          {suggestions && suggestions.data && suggestions.data.length > 0 ? (
            suggestions.data.map((v, k) => (
              <Chip
                key={k}
                label={t(v.text)}
                className={classes.chip}
                variant="outlined"
                onClick={() => this.handleAddFillToTextField(v)}
              />
            ))
          ) : (
            <CircularProgress size={24} />
          )}
        </div>
        {name === 'reviewReminder' && (
          <div>
            <FormLabel>
              {t('Official 5 star review to the guest (only for Airbnb)')}
            </FormLabel>
            <HtmlInput
              name={_official_name}
              id={_official_name}
              value={messageForm[_official_name]}
              inputRef={this.OfficialInboxMessageInput}
              handleFocusInput={() => this.setFocusInboxMessageInput(false)}
              handleChange={(e) =>
                this.handleChangeTextField(e, _official_name, false)
              }
              handleKey={(pos) =>
                this.handleKey(pos, this.OfficialInboxMessageInput)
              }
              style={{ height: 100 }}
            />
          </div>
        )}
        {name === 'reviewReminder' && (
          <FormLabel>{t('Inbox message')}</FormLabel>
        )}
        <HtmlInput
          name={name}
          id={name}
          value={messageForm[name]}
          inputRef={this.InboxMessageInput}
          handleFocusInput={() => this.setFocusInboxMessageInput(true)}
          handleChange={(e) => this.handleChangeTextField(e, name, true)}
          handleKey={(pos) => this.handleKey(pos, this.InboxMessageInput)}
        />
        {name === 'reviewReminder' && (
          <div>
            <FormControlLabel
              label={t(
                "Don't send the Inbox message if the guest has already left a review",
              )}
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={messageForm[_checkBox1]}
                  value={_checkBox1}
                  onChange={(e) => this.handleChangeCheckBox(e, _checkBox1)}
                />
              }
            />
            <FormControlLabel
              label={t(
                'Send the message only to the Airbnb guests not to the Bookingcom guests',
              )}
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={messageForm[_checkBox2]}
                  z
                  value={_checkBox2}
                  onChange={(e) => this.handleChangeCheckBox(e, _checkBox2)}
                />
              }
            />
          </div>
        )}
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => this.handleSaveMessage(e, name)}
        >
          {' '}
          {loading && <CircularProgress size={20} />}
          {t('Save message')}
        </Button>
        {isSave && (
          <Typography color={'secondary'} align="center">
            {t('Saved successfully')}!
          </Typography>
        )}
      </div>
    );
  }

  render() {
    const { classes, name, label, placeholder, showHelp, t } = this.props;
    const { currentExpanded, switchForm, loading } = this.state;
    return (
      <Accordion square expanded={currentExpanded} className={classes.root}>
        <AccordionSummary className={classes.AccordionSummary}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            className={classes.GridTitle}
          >
            <Grid item xs={12} md={10}>
              <Typography
                variant="h6"
                component="div"
                className={classes.heading}
              >
                {loading && <CircularProgress size={20} />}
                <Switch
                  checked={switchForm}
                  value={name}
                  color="secondary"
                  onChange={this.handleChangeSwitch}
                />
                {label}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
              {this.expandIcon()}
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.placeholder}
              >
                {placeholder}
                {showHelp && <CusTooltip title={showHelp} />}
              </Typography>

              <Typography
                variant="caption"
                component="p"
                className={classes.caption}
              >
                {!switchForm ? (
                  <span>
                    <PauseCircleOutline
                      color="inherit"
                      style={{ marginRight: 10 }}
                    />
                    {t('Message is not being sent to guests')}.
                  </span>
                ) : (
                  <span>
                    <PlayButton color="secondary" />{' '}
                    {t('Message is active and being sent to guests')}.
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {this.panelDetails()}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => ({
  automations: state.automations.settings.data,
  suggestions: state.global.suggestions,
});

export default connect(mapStateToProps, {
  getMessage,
  getSuggestions,
  saveMessage,
})(withStyles(styles)(withTranslation()(AutomationForm)));
