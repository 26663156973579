import React from 'react';
import ListingList from '../components/ListingList';
import { connect } from 'react-redux';
import { openModal } from '../../../shared/actions';
import { getListingByAccommodation } from '../actions';
import { withTranslation } from 'react-i18next';

class Listings extends React.PureComponent {
  render() {
    const { loading, property, openListingDetail } = this.props;
    const listings = property.listings;
    // const {t} = this.props;
    return (
      <div style={{ marginTop: 10 }}>
        <ListingList
          property={property}
          listingsCount={property.listingsCount}
          data={listings || []}
          loading={loading}
          openListingDetail={openListingDetail}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect(null, { openModal, getListingByAccommodation })(Listings),
);
